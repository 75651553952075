import { Descriptions, Divider, Modal, Spin, Steps, Typography } from 'antd';
import React from 'react';
import { restApi } from '#apis';
import dayjs from 'dayjs';

const dateFormat = 'YYYY년 MM월 DD일 HH:mm';

const ShowFormForSweetTracker = ({ invoiceNumber }) => {
  const [loading, setLoading] = React.useState(true);
  const [deliveryData, setDeliveryData] = React.useState(null);
  const [notStart, setNotStart] = React.useState(null);

  const init = React.useCallback(async () => {
    try {
      const { data } = await restApi.get(`/delivery/tracking/${invoiceNumber}`);

      setDeliveryData(data);
      setLoading(false);
    } catch (e) {
      if (e?.response?.status === 404) {
        setLoading(false);
        setNotStart(true);
        return;
      }
      Modal.error({
        title: '에러',
        content: '배송 정보를 가져오는 데 실패했습니다.',
        onOk: Modal.destroyAll,
        centered: true,
        okText: '확인',
      });
    }
  }, [invoiceNumber]);

  React.useEffect(() => {
    init();
  }, []);

  if (loading) return <Spin size="small" />;

  if (notStart) {
    return (
      <div>
        <Typography.Title style={{ fontSize: 20, marginTop: 30 }}>택배사가 전달할 예정이에요</Typography.Title>
        <Typography.Text>배송이 시작되면 상세 안내해 드릴게요</Typography.Text>
      </div>
    );
  }

  return (
    <div>
      <Typography.Title style={{ fontSize: 20, marginTop: 30 }}>
        {deliveryData?.completeAt
          ? `${dayjs(deliveryData?.completeAt).format(dateFormat)} 배송완료 되었습니다`
          : deliveryData?.detailResponse?.length > 0
          ? `${dayjs(deliveryData?.detailResponse[0].timeString).format(dateFormat)} ${
              deliveryData?.detailResponse[0].details
            } 되었습니다.`
          : '판매자가 상품을 택배사로 전달할 예정이에요'}
      </Typography.Title>
      <Typography.Text>
        {deliveryData?.detailResponse?.[0].details === '배달완료'
          ? `아직 상품을 받지 못하셨다면 택배사로 문의해 주세요.`
          : '배송이 시작되면 상세 안내해 드릴게요'}
      </Typography.Text>
      <Divider />
      <Descriptions title="배송정보">
        <Descriptions.Item span={3} label="송장번호">
          {deliveryData?.invoiceNum ?? '-'}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="배송업체">
          {deliveryData?.courier ?? '-'}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="배송기사">
          {deliveryData?.manName || '-'}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <Steps progressDot current={0} direction="vertical">
        {deliveryData?.detailResponse?.map(({ details, d_where, timeString }, index) => (
          <Steps.Step
            title={details}
            subTitle={d_where}
            description={dayjs(timeString).format('YYYY-MM-DD HH:mm')}
            key={index}
          />
        ))}
      </Steps>
    </div>
  );
};

export default ShowFormForSweetTracker;
