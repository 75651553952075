import { restApi } from '#apis/index';
import handleError from '#utils/handleError';
import produce from 'immer';
import React from 'react';
import { Button, Space, Select, Row, Col, Form, Table, DatePicker, Input, Typography, Checkbox, Modal } from 'antd';
import useFetch from '#hooks/useFetch';
import moment from 'moment';

const URL = '/cms/advertisement/search/store';

function SearchPartner({ code, onSuccess }) {
  const initialParams = React.useMemo(
    () => ({
      extra1: null,
      extra2: null,
      isAdOn: false,
      page: 0,
      limit: 10,
      keyword: '',
    }),
    [],
  );

  const [loading, setLoading] = React.useState(false);
  const [params, setParams] = React.useState(initialParams);
  const tableRef = React.useRef();
  const areaCodes = useFetch();
  const areaCodes2 = useFetch();
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [period, setPeriod] = React.useState({
    startAt: null,
    endAt: null,
  });

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys,
  };

  const tableFetcher = useFetch();

  const handleFilter = async () => {
    try {
      if (tableFetcher.loading) return;
      tableFetcher.fetch(restApi.get(URL, { params }));
    } catch (e) {}
  };

  React.useEffect(() => {
    areaCodes.fetch(restApi.get('/area-codes/filter')).catch(handleError);
    tableFetcher.fetch(restApi.get(URL, { params: { limit: 10, page: 0 } }));
  }, []);

  const handleSave = async () => {
    try {
      if (loading) return;
      setLoading(true);
      await restApi.post(`/cms/advertisement/area/${code}`, {
        storeId: selectedRowKeys?.[0],
        startAt: period.startAt,
        endAt: period.endAt,
      });
      await onSuccess?.();
      Modal.success({
        title: '성공',
        content: '파트너 광고 노출에 성공 했습니다.',
        okText: '확인',
      });
    } catch (e) {
      Modal.warning({
        title: '실패',
        content: '광고 노출에 실패 했습니다. 잠시 후 시도해주세요.',
      });
    } finally {
      setLoading(false);
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  };

  React.useEffect(() => {
    tableFetcher.fetch(restApi.get(URL, { params }));
  }, [params.isAdOn]);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Form
        onFinish={handleFilter}
        onReset={() => {
          setParams(initialParams);
          return initialParams;
        }}
      >
        <Row gutter={10} style={{ marginBottom: 10, alignItems: 'center', rowGap: 10 }}>
          <Col>
            <Select
              value={params.extra1}
              loading={areaCodes.loading || areaCodes2.loading}
              onChange={async (value) => {
                await areaCodes2.fetch(restApi.get(`/area-codes/filter/${value}`));
                setParams(
                  produce((draft) => {
                    draft.extra1 = value;
                    draft.extra2 = null;
                  }),
                );
              }}
              options={areaCodes.data?.map((area) => ({ label: area, value: area })) ?? []}
              style={{ width: 200 }}
              disabled={!(areaCodes.data?.length > 0)}
              placeholder="시/도"
              allowClear
            />
          </Col>
          <Col>
            <Select
              value={params.extra2}
              loading={areaCodes2.loading}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.extra2 = value;
                  }),
                );
              }}
              options={areaCodes2.data?.map((area) => ({ label: area, value: area })) ?? []}
              style={{ width: 200 }}
              disabled={!(areaCodes2.data?.length > 0)}
              placeholder="시/군/구"
              allowClear
            />
          </Col>
          <Col>
            <Input
              value={params.keyword}
              placeholder="상점명/아이디를 입력하세요"
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.keyword = value.target.value;
                  }),
                );
              }}
              style={{ width: 300 }}
            />
          </Col>
          <Col>
            <Space>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <Space
        style={{ width: '100%', marginTop: 20, marginBottom: 5, justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Typography>광고할 업체</Typography>
        <Checkbox
          checked={params.isAdOn}
          onChange={(value) => {
            setParams(
              produce((draft) => {
                draft.isAdOn = value.target.checked;
              }),
            );
          }}
        >
          사용여부N은 제외
        </Checkbox>
      </Space>
      <Table
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        rowKey={({ storeId }) => storeId}
        dataSource={tableFetcher?.data?.results || []}
        ref={tableRef}
        columns={[
          {
            title: '아이디',
            dataIndex: 'storeUserId',
            fixed: true,
            width: 100,
            render: (storeUserId) => storeUserId,
          },
          {
            title: '상점명',
            dataIndex: 'storeName',
            fixed: true,
            width: 100,
            render: (value) => value,
          },
          {
            title: '지역',
            dataIndex: 'extra',
            width: 200,
            render: (extra) => extra,
          },
          {
            title: '광고 진행여부',
            dataIndex: 'isAdOn',
            width: 100,
            render: (isAdOn) => (isAdOn ? 'Y' : 'N'),
          },
        ]}
        scroll={{ x: 1000 }}
        pagination={{
          position: ['bottomCenter'],
          total: tableFetcher?.data?.total,
          pageSize: tableFetcher?.data?.limit ?? 10,
          current: tableFetcher?.data?.pageCur + 1,
          showSizeChanger: false,
          onChange: async (p) => {
            tableFetcher.fetch(
              restApi.get(URL, {
                params: { ...params, page: p - 1 },
              }),
            );
          },
        }}
      />
      <Row gutter={10} style={{ marginBlock: 10, alignItems: 'center', rowGap: 10 }}>
        <Col>광고 노출 기간*</Col>
        <Col>
          <DatePicker.RangePicker
            disabledDate={disabledDate}
            value={[period.startAt ? moment(period.startAt) : null, period.endAt ? moment(period.endAt) : null]}
            format="YYYY-MM-DD"
            onChange={([startAt, endAt]) => {
              setPeriod(
                produce((draft) => {
                  draft.startAt = startAt?.format('YYYY-MM-DD');
                  draft.endAt = endAt?.format('YYYY-MM-DD');
                }),
              );
            }}
          />
        </Col>
      </Row>
      <Space style={{ width: '100%', justifyContent: 'flex-end', marginTop: 20 }}>
        <Button
          disabled={selectedRowKeys.length === 0 || !period.startAt || !period.endAt || loading}
          onClick={handleSave}
        >
          저장
        </Button>
      </Space>
    </Space>
  );
}

export default SearchPartner;
