import { restApi } from '#apis/index';
import FileUpload from '#components/datalist/FileUpload';
import FetchElement from '#components/FetchElement';
import { Button, Col, DatePicker, Form, Input, Modal, Radio, Row, Space, Typography, Switch } from 'antd';
import produce from 'immer';
import moment from 'moment';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ImageUploadGuide from '../../components/common/ImageUploadGuide';
import { checkBeforeUpload } from '../../utils/beforeUpload';

const MemoizedBannersRegistPage = React.memo(({ placeCode, id, data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state)
  const initialForm = React.useMemo(
    () => ({
      isActive: data?.isActive ?? null,
      imageId: data?.imageId ?? null,
      endAt: data?.endAt ?? null,
      startAt: data?.startAt ?? null,
      link: data?.imageLinkUrl ?? '',
      title: data?.title ?? '',
      youtubeId: data?.youtubeId ?? '',
    }),
    [data],
  );
  const [form, setForm] = React.useState(initialForm);
  const [formItemDisabled, setFormItemDisabled] = React.useState(false);
  return (
    <Form
      style={{ maxWidth: 800 }}
      onFinish={() => {
        // if (!form.imageUrl)
        //   return Modal.warn({
        //     title: data ? '배너 수정' : '배너 등록',
        //     content: '배너 이미지를 등록하세요.',
        //     centered: true,
        //   });
        if (!form.title)
          return Modal.warn({ title: data ? '배너 수정' : '배너 등록', content: '제목을 입력하세요.', centered: true });
        if (formItemDisabled && !form.imageId)
          return Modal.warn({
            title: data ? '배너 수정' : '배너 등록',
            content: '이미지를 업로드하세요.',
            centered: true,
          });
        if (formItemDisabled && !form.link)
          return Modal.warn({ title: data ? '배너 수정' : '배너 등록', content: '링크를 입력하세요.', centered: true });
        if (!formItemDisabled && !form.youtubeId)
          return Modal.warn({
            title: data ? '배너 수정' : '배너 등록',
            content: '유튜브 주소를 입력하세요.',
            centered: true,
          });
        if (!form.startAt || !form.endAt)
          return Modal.warn({
            title: data ? '배너 수정' : '배너 등록',
            content: '노출기간을 선택하세요.',
            centered: true,
          });
        if (form.isActive === null)
          return Modal.warn({
            title: data ? '배너 수정' : '배너 등록',
            content: '사용여부를 선택하세요.',
            centered: true,
          });

        const requestBody = {
          isActive: form.isActive,
          endAt: form.endAt,
          imageId: form.imageId,
          link: form.link,
          startAt: form.startAt,
          title: form.title,
          youtubeId: form.youtubeId,
        };

        if (data) {
          return restApi
            .put(`/cms/display-banners/${id}`, requestBody)
            .then(() => {
              navigate(`/banners/BP001`);
            })
            .catch((error) => {
              Modal.error({
                title: '배너 수정',
                content: error?.response?.data?.message ?? '배너 수정에 실패했습니다.',
                centered: true,
              });
            });
        }

        return restApi
          .post(`/cms/display-banners/${location.state}`, requestBody)
          .then(() => {
            navigate(`/banners/BP001`);
          })
          .catch((error) => {
            Modal.error({
              title: '배너 등록',
              content: error?.response?.data?.message ?? '배너 등록에 실패했습니다.',
              centered: true,
            });
          });
      }}
    >
      <Space style={{ width: '100%' }} direction="vertical">
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Switch
            checkedChildren="이미지"
            unCheckedChildren="유튜브"
            className="img-youtube-switch"
            onChange={(value) => {
              setFormItemDisabled(value);
              if (!value) {
                setForm(
                  produce((draft) => {
                    draft.imageId = 0;
                    draft.link = '';
                  }),
                );
              } else {
                setForm(
                  produce((draft) => {
                    draft.youtubeId = '';
                  }),
                );
              }
            }}
          />
        </div>
        <Form.Item label="배너이미지" required={formItemDisabled}>
          <FileUpload
            disabled={!formItemDisabled}
            maxCount={1}
            items={form.imageId}
            onDoneChange={(fileListDone) => {
              setForm(
                produce((draft) => {
                  draft.imageId = fileListDone[0]?.response[0]?.id;
                }),
              );
            }}
            beforeUpload={checkBeforeUpload({
              availableLists: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'],
              textWhenFail: 'jpg, jpeg, png, gif 확장자만 가능합니다.',
            })}
            extraItemRender={(file) => {
              const findIndex = form?.images?.findIndex((image) => image === file?.response?.[0]?.id);
              if (!(findIndex > -1)) {
                return null;
              }
              return (
                <Space style={{ marginTop: 10 }}>
                  <Typography.Text>대체 택스트: </Typography.Text>
                  <Input
                    value={form.imageTexts[findIndex]}
                    onChange={(event) => {
                      setForm(
                        produce((draft) => {
                          draft.imageTexts[findIndex] = event.target.value;
                        }),
                      );
                    }}
                  />
                </Space>
              );
            }}
          />
          <ImageUploadGuide />
        </Form.Item>
        <Form.Item label="링크" required={formItemDisabled}>
          <Input
            value={form.link}
            disabled={!formItemDisabled}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.link = event.target.value;
                }),
              );
            }}
          />
        </Form.Item>
        <Form.Item label="유튜브 주소" required={!formItemDisabled}>
          <Input
            value={form.youtubeId}
            disabled={formItemDisabled}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.youtubeId = event.target.value;
                }),
              );
            }}
          />
        </Form.Item>
        <Form.Item label="제목" required>
          <Input
            value={form.title}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.title = event.target.value;
                }),
              );
            }}
          />
        </Form.Item>
        <Form.Item label="노출기간" required>
          <DatePicker.RangePicker
            value={[form.startAt ? moment(form.startAt) : undefined, form.endAt ? moment(form.endAt) : undefined]}
            showTime={{ format: 'HH:mm:ss' }}
            format="YYYY-MM-DD HH:mm:ss"
            onChange={([startAt, endAt]) => {
              setForm(
                produce((draft) => {
                  draft.startAt = startAt.format('YYYY-MM-DD HH:mm:ss');
                  draft.endAt = endAt.format('YYYY-MM-DD HH:mm:ss');
                }),
              );
            }}
          />
        </Form.Item>
        <Form.Item label="사용여부" required>
          <Radio.Group
            value={form.isActive}
            options={[
              { label: '사용', value: true },
              { label: '미사용', value: false },
            ]}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.isActive = event.target.value;
                }),
              );
            }}
          />
        </Form.Item>
        <Row justify="end">
          <Col>
            <Space>
              <Button
                onClick={() => {
                  Modal.confirm({
                    title: '배너 등록',
                    content: (
                      <>
                        입력했던 내용이 전부 사라집니다.
                        <br />
                        계속 하시겠습니까 ?
                      </>
                    ),
                    onOk: () => {
                      navigate(`/banners/${placeCode}`, { state: location.state });
                    },
                    centered: true,
                  });
                }}
              >
                취소
              </Button>
              <Button type="primary" htmlType="submit">
                {data ? '수정' : '저장'}
              </Button>
            </Space>
          </Col>
        </Row>
      </Space>
    </Form>
  );
});

function BannersRegistPage() {
  const location = useLocation();
  const params = useParams();
  const placeCode = React.useMemo(() => location.pathname.split('/')[2], [location.pathname]);
  const id = React.useMemo(() => params?.id, [params?.id]);

  return (
    <FetchElement
      fetch={() => id && restApi.get(`/cms/display-banners/${id}`)}
      Component={(data) => <MemoizedBannersRegistPage id={id} placeCode={placeCode} {...data} />}
    />
  );
}

export default BannersRegistPage;
