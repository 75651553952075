import ContentsRecommendCommunityDetailPage from '#pages/contents/community/detail';
import ContentsRecommendTagsDetail from '#pages/contents/recommend/tags/detail';
import ContentsSpecialistPage from '#pages/contents/specialist';
import PostsCommunityPage from '#pages/posts/community';
import PostsCommunityDetailPage from '#pages/posts/community/detail';
import React, { useMemo } from 'react';
import {
  HomeOutlined,
  PictureOutlined,
  ContactsOutlined,
  GiftOutlined,
  MessageOutlined,
  DollarCircleOutlined,
  PlaySquareFilled,
  ApiOutlined,
  ProfileOutlined,
  KeyOutlined,
  ShareAltOutlined,
  CarryOutOutlined,
  BarChartOutlined,
} from '@ant-design/icons';
import HomeIndex from '../pages';
import ContentsCommunityPage from '../pages/contents/community';
import ContentsCommunityRegistPage from '../pages/contents/community/regist';
import ContentsPopularPage from '../pages/contents/popular';
import ContentsPoplarDetailPage from '../pages/contents/popular/detail';
import ContentsPopularRegistPage from '../pages/contents/main/regist';
import ContentsRecommendThemeDetailPage from '../pages/contents/recommend/theme/detail';
import ContentsRecommendThemeRegistPage from '../pages/contents/recommend/theme/regist';
import ContentsSpecialistDetailPage from '../pages/contents/specialist/detail';
import ContentsSpecialistRegistPage from '../pages/contents/specialist/regist';

// member
import UserManagementIndex from '../pages/member/management/user';
import UserManagementDetail from '../pages/member/management/user/detail';
import RestingManagementIndex from '../pages/member/management/resting';
import WithdrawManagementIndex from '../pages/member/management/withdraw';
import PartnerCompleteIndex from '../pages/member/partner/complete';
import PartnerCompleteDetail from '../pages/member/partner/complete/detail';
import PartnerWaitingIndex from '../pages/member/partner/waiting';
import PartnerWaitingDetail from '../pages/member/partner/waiting/detail';
import PartnerSalesIndex from '../pages/member/partner/sales';
import PartnerSalesDetail from '../pages/member/partner/sales/detail';
import MdCompleteIndex from '../pages/member/md/complete';
import MdCompleteDetail from '../pages/member/md/complete/detail';
import MdCompleteModify from '../pages/member/md/complete/modify';
import MdWaitingIndex from '../pages/member/md/waiting';
import MdWaitingDetail from '../pages/member/md/waiting/detail';
import MdWaitingModify from '../pages/member/md/waiting/modify';

// review
import ReviewIndex from '../pages/review/community';
import ReviewDetail from '../pages/review/community/detail';
import ReviewManagementIndex from '../pages/review/management';
import ReviewManagementDetail from '../pages/review/management/detail';
import ReviewGoodsIndex from '../pages/review/goods';
import ReviewGoodsDetail from '../pages/review/goods/detail';
import ReviewStoreIndex from '../pages/review/store';
import ReviewStoreDetail from '../pages/review/store/detail';
import ReviewServiceIndex from '../pages/review/service';
import ReviewServiceDetail from '../pages/review/service/detail';

// banner
import BannersPage from '#pages/mainBanners';
import BannersDetailPage from '#pages/mainBanners/detail';
import BannersRegistPage from '#pages/mainBanners/regist';

// admin
import AdminPage from '../pages/admin';
import AdminRegistPage from '../pages/admin/regist';
import AdminDetailPage from '../pages/admin/detail';

// store
import GoodsIndex from '../pages/store/goods';
import GoodsRegist from '../pages/store/goods/regist';
import ChargeTemplateIndex from '../pages/store/charge';
import ChargeTemplateRegist from '../pages/store/charge/regist';
import StoreManagementIndex from '../pages/store/management';
import StoreManagementDetail from '../pages/store/management/detail';
import StoreManagementModify from '../pages/store/management/modify';

// posts
import PostsNoticeUserPage from '../pages/posts/notice/user';
import PostsNoticeUserDetailPage from '../pages/posts/notice/user/detail';
import PostsNoticeUserRegistPage from '../pages/posts/notice/user/regist';
import PostsNoticePartnerPage from '../pages/posts/notice/partner';
import PostsNoticePartnersDetailPage from '../pages/posts/notice/partner/detail';
import PostsNoticePartnersRegistPage from '../pages/posts/notice/partner/regist';
import PostsNoticeMDPage from '../pages/posts/notice/md';
import PostsNoticeMDsDetailPage from '../pages/posts/notice/md/detail';
import PostsNoticeMDsRegistPage from '../pages/posts/notice/md/regist';
import PostsFaqPage from '../pages/posts/faq';
import PostsFaqDetailPage from '../pages/posts/faq/detail';
import PostsFaqRegistPage from '../pages/posts/faq/regist';

// sales
import SalesCancel from '../pages/sales/purchase/cancel';
import SalesExchange from '../pages/sales/purchase/exchange';
import SalesRequestEstimate from '../pages/sales/purchase/request_estimate';
import SalesEstimateDetail from '../pages/sales/purchase/request_estimate/detail';
import SalesDelivery from '../pages/sales/purchase/delivery';
import SalesRefund from '../pages/sales/purchase/refund';
import SalesTotalOrder from '../pages/sales/purchase/totalorder';
import SalesPartnerCalculate from '../pages/sales/calculate/partner';
import SalesMDCalculate from '../pages/sales/calculate/md';
import SalesProductOrderDetail from '../pages/sales/purchase/totalorder/ProductOrderDetail';

// contents
import ContentsRecommendPage from '../pages/contents/recommend';
import ContentsRecommendTagsRegistPage from '../pages/contents/recommend/tags/regist';

// adverties
import AdvertiesLocalIndex from '../pages/adverties/searchLocal';
import AdvertiesBannerIndex from '../pages/adverties/banner';

// management
import ManagementCategoryPage from '#pages/management/category';
import ManagementCommonCodePage from '#pages/management/common_code';
import ManagementCommonCodeRegistPage from '#pages/management/common_code/regist';
import ManagementCommonCodeDetailPage from '#pages/management/common_code/detail';
import ManagementForbiddenWordPage from '#pages/management/forbidden_word';
import ManagementRegionPage from '#pages/management/region';
import ManagementTermsPage from '#pages/management/terms';
import DailyPaymentAmount from '../pages/statistics/daily_payment_amount';
import PayersAndPaymentsPerDay from '../pages/statistics/payers_payments_per_day';
import PaymentAmountByDayOfTheWeek from '../pages/statistics/payment_amount_by_day_of_the_week';
import AveragePaymentAmountByDayOfTheWeek from '../pages/statistics/average_payment_amount_by_day_of_the_week';
import DailyRefundRate from '../pages/statistics/daily_refund_rate';

//tour
import TourReservation from '../pages/tour';
import TourReservationDetail from '../pages/tour/detail';
import ContentsMainPage from '../pages/contents/main';
import ContentsMainDetailPage from '../pages/contents/main/detail';
import ContentsMainRegistPage from '../pages/contents/main/regist';
import ContentsSectionPage from '../pages/contents/main/section';
import SubBannersPage from '../pages/banners';
import SubBannersRegistPage from '../pages/banners/regist';
import SubBannersDetailPage from '../pages/banners/detail';

export const pages = [
  {
    path: '/',
    title: '홈',
    icon: HomeOutlined,
    screen: HomeIndex,
    isMenu: true,
  },
  {
    path: '/member',
    title: '회원관리',
    icon: ContactsOutlined,
    isMenu: true,
  },
  // {
  //   path: '/member/management',
  //   title: '사용자관리',
  //   isMenu: true,
  // },
  {
    path: '/member/management/users',
    title: '일반회원',
    isMenu: true,
    screen: UserManagementIndex,
  },
  {
    path: '/member/management/users/:userId',
    title: '일반회원 상세',
    screen: UserManagementDetail,
  },
  {
    path: '/member/management/resting',
    title: '휴면회원',
    isMenu: true,
    screen: RestingManagementIndex,
  },
  {
    path: '/member/management/withdraw',
    title: '탈퇴회원',
    isMenu: true,
    screen: WithdrawManagementIndex,
  },
  // {
  //   path: '/member/partner',
  //   title: '파트너사관리',
  //   isMenu: true,
  // },
  {
    path: '/member/partner/complete',
    title: '승인된 파트너사',
    isMenu: true,
    screen: PartnerCompleteIndex,
  },
  {
    path: '/member/partner/complete/detail/:userId',
    title: '승인된 파트너사 상세',
    screen: PartnerCompleteDetail,
  },
  {
    path: '/member/partner/waiting',
    title: '승인대기중',
    isMenu: true,
    screen: PartnerWaitingIndex,
  },
  {
    path: '/member/partner/waiting/detail/:userId',
    title: '승인대기중 상세',
    screen: PartnerWaitingDetail,
  },
  {
    path: '/member/partner/sales',
    title: '판매권한 신청',
    isMenu: true,
    screen: PartnerSalesIndex,
  },
  {
    path: '/member/partner/sales/detail/:userId',
    title: '판매권한 신청 상세',
    screen: PartnerSalesDetail,
  },
  {
    path: '/member/md/complete',
    title: '승인된 MD',
    isMenu: true,
    screen: MdCompleteIndex,
  },
  {
    path: '/member/md/complete/detail/:userId',
    title: '승인된 MD 상세',
    screen: MdCompleteDetail,
  },
  {
    path: '/member/md/complete/detail/modify/:userId',
    title: '승인된 MD 수정',
    screen: MdCompleteModify,
  },
  {
    path: '/member/md/waiting',
    title: 'MD 승인대기중',
    isMenu: true,
    screen: MdWaitingIndex,
  },
  {
    path: '/member/md/waiting/detail/:userId',
    title: 'MD 승인대기중 상세',
    screen: MdWaitingDetail,
  },
  {
    path: '/member/md/waiting/detail/modify/:userId',
    title: 'MD 승인대기중 수정',
    screen: MdWaitingModify,
  },
  {
    path: '/store',
    title: '상점/상품관리',
    icon: GiftOutlined,
    isMenu: true,
  },
  {
    path: '/store/goods',
    title: '상품 조회/수정',
    screen: GoodsIndex,
    isMenu: true,
  },
  {
    path: '/store/goods/regist',
    title: '상품 등록',
    screen: GoodsRegist,
    isMenu: true,
  },
  {
    path: '/store/goods/modify/:productId',
    title: '상품 수정',
    screen: GoodsRegist,
  },
  {
    path: '/store/goods/copy/:productId',
    title: '상품 복사',
    screen: GoodsRegist,
  },
  {
    path: '/store/charge',
    title: '배송비 템플릿 관리',
    screen: ChargeTemplateIndex,
    isMenu: true,
  },
  {
    path: '/store/charge/regist',
    title: '배송비 템플릿 등록',
    screen: ChargeTemplateRegist,
  },
  {
    path: '/store/charge/modify/:id',
    title: '배송비 템플릿 수정',
    screen: ChargeTemplateRegist,
  },
  {
    path: '/store/management',
    title: '상점 관리',
    screen: StoreManagementIndex,
    isMenu: true,
  },
  {
    path: '/store/management/:storeId/detail',
    title: '상점 정보 상세',
    screen: StoreManagementDetail,
  },
  {
    path: '/store/management/:storeId/modify',
    title: '상점 정보 수정',
    screen: StoreManagementModify,
  },
  {
    path: '/review',
    title: '리뷰/문의 관리',
    icon: MessageOutlined,
    isMenu: true,
  },
  {
    path: '/review/community',
    title: '커뮤니티 관리',
    screen: ReviewIndex,
    isMenu: true,
  },
  {
    path: '/review/community/detail/:reviewId',
    title: '커뮤니티 관리 상세',
    screen: ReviewDetail,
  },
  {
    path: '/review/management',
    title: '리뷰 관리',
    screen: ReviewManagementIndex,
    isMenu: true,
  },
  {
    path: '/review/management/detail/:reviewId',
    title: '리뷰 관리 상세',
    isChild: true,
    screen: ReviewManagementDetail,
  },
  {
    path: '/review/goods',
    title: '상품문의',
    screen: ReviewGoodsIndex,
    isMenu: true,
  },
  {
    path: '/review/goods/detail/:id',
    title: '상품문의 상세',
    screen: ReviewGoodsDetail,
    isChild: true,
  },
  {
    path: '/review/store',
    title: '상점문의',
    screen: ReviewStoreIndex,
    isMenu: true,
  },
  {
    path: '/review/store/detail/:id',
    title: '상점문의 상세',
    screen: ReviewStoreDetail,
    isChild: true,
  },
  {
    path: '/review/service',
    title: '서비스문의',
    screen: ReviewServiceIndex,
    isMenu: true,
  },
  {
    path: '/review/service/detail/:inquireId',
    title: '서비스문의 상세',
    screen: ReviewServiceDetail,
    isChild: true,
  },
  {
    path: '/sales',
    title: '매출/정산관리',
    icon: DollarCircleOutlined,
    isMenu: true,
  },
  // {
  //   path: '/sales/purchase',
  //   title: '사용자 구매내역',
  //   isMenu: true,
  // },
  {
    path: '/sales/purchase/totalorder',
    title: '주문통합관리',
    isMenu: true,
    screen: SalesTotalOrder,
  },
  {
    path: '/sales/purchase/totalorder/detail',
    screen: SalesProductOrderDetail,
  },
  {
    path: '/sales/purchase/delivery',
    title: '발주/배송관리',
    isMenu: true,
    screen: SalesDelivery,
  },
  {
    path: '/sales/purchase/change',
    title: '교환신청',
    isMenu: true,
    screen: SalesExchange,
  },
  {
    path: '/sales/purchase/refund',
    title: '반품신청',
    isMenu: true,
    screen: SalesRefund,
  },
  {
    path: '/sales/purchase/cancel',
    title: '취소신청',
    isMenu: true,
    screen: SalesCancel,
  },
  {
    path: '/sales/purchase/estimate',
    title: '견적의뢰',
    isMenu: true,
    screen: SalesRequestEstimate,
  },
  {
    path: '/sales/purchase/estimate/detail/:estimateId',
    title: '견적의뢰',
    screen: SalesEstimateDetail,
  },
  {
    path: '/sales/calculate/partner',
    title: '파트너스 정산',
    isMenu: true,
    screen: SalesPartnerCalculate,
  },
  {
    path: '/sales/calculate/md',
    title: 'MD 정산',
    isMenu: true,
    screen: SalesMDCalculate,
  },
  {
    path: '/contents',
    title: '컨텐츠관리',
    isMenu: true,
    icon: PlaySquareFilled,
  },
  // {
  //   path: '/contents/popular',
  //   title: '메인화면(인기)',
  //   isMenu: true,
  //   screen: ContentsPopularPage,
  // },
  {
    path: '/contents/main',
    title: '메인화면',
    isMenu: true,
    screen: ContentsMainPage,
  },
  {
    path: '/contents/main/section',
    title: '메인화면 섹션관리',
    screen: ContentsSectionPage,
  },
  {
    path: '/contents/main/detail/:id',
    title: '메인화면 섹션 상세',
    screen: ContentsMainDetailPage,
  },
  {
    path: '/contents/main/regist',
    title: '메인화면 등록',
    screen: ContentsMainRegistPage,
  },
  {
    path: '/contents/main/modify/:id',
    title: '메인화면 수정',
    screen: ContentsMainRegistPage,
  },
  // {
  //   path: '/contents/popular/:id',
  //   title: '메인화면(인기) 상세',
  //   screen: ContentsPoplarDetailPage,
  // },
  // {
  //   path: '/contents/popular/modify/:id',
  //   title: '메인화면(인기) 수정',
  //   screen: ContentsPopularRegistPage,
  // },
  // {
  //   path: '/contents/recommend/:key',
  //   title: '메인화면(추천)',
  //   screen: ContentsRecommendPage,
  // },
  // {
  //   path: '/contents/recommend/tags',
  //   title: '메인화면(추천)',
  //   isMenu: true,
  // },
  // {
  //   path: '/contents/recommend/tags/:id',
  //   title: '메인화면(추천) 컨텐츠 상세',
  //   screen: ContentsRecommendTagsDetail,
  // },
  // {
  //   path: '/contents/recommend/tags/regist',
  //   title: '메인화면(추천) 컨텐츠 등록',
  //   screen: ContentsRecommendTagsRegistPage,
  // },
  // {
  //   path: '/contents/recommend/tags/modify/:id',
  //   title: '메인화면(추천) 컨텐츠 수정',
  //   screen: ContentsRecommendTagsRegistPage,
  // },
  // {
  //   path: '/contents/recommend/theme/regist',
  //   title: '메인화면(추천) 태마 등록',
  //   screen: ContentsRecommendThemeRegistPage,
  // },
  // {
  //   path: '/contents/recommend/theme/:id',
  //   title: '메인화면(추천) 태마 상세',
  //   screen: ContentsRecommendThemeDetailPage,
  // },
  // {
  //   path: '/contents/recommend/theme/modify/:id',
  //   title: '메인화면(추천) 태마 수정',
  //   screen: ContentsRecommendThemeRegistPage,
  // },
  // {
  //   path: '/contents/specialist',
  //   title: '메인화면(특가)',
  //   isMenu: true,
  //   screen: ContentsSpecialistPage,
  // },
  // {
  //   path: '/contents/specialist/regist',
  //   title: '메인화면(특가) 등록',
  //   screen: ContentsSpecialistRegistPage,
  // },
  // {
  //   path: '/contents/specialist/:id',
  //   title: '메인화면(특가) 상세',
  //   screen: ContentsSpecialistDetailPage,
  // },
  // {
  //   path: '/contents/specialist/modify/:id',
  //   title: '메인화면(특가) 수정',
  //   screen: ContentsSpecialistRegistPage,
  // },
  // {
  //   path: '/section/banners/',
  //   title: '메인화면 배너',
  //   isMenu: true,
  //   screen: BannersPage,
  // },
  {
    path: '/contents/community',
    title: '서브메인(커뮤니티)',
    isMenu: true,
    screen: ContentsCommunityPage,
  },
  {
    path: '/contents/community/:id',
    title: '서브메인(커뮤니티) 상세',
    screen: ContentsRecommendCommunityDetailPage,
  },
  {
    path: '/contents/community/regist',
    title: '서브메인(커뮤니티) 등록',
    screen: ContentsCommunityRegistPage,
  },
  {
    path: '/contents/community/modify/:id',
    title: '서브메인(커뮤니티) 수정',
    screen: ContentsCommunityRegistPage,
  },
  {
    path: '/banners',
    title: '배너관리',
    isMenu: true,
    icon: ApiOutlined,
  },
  {
    path: '/banners/BP001',
    title: '메인화면 배너',
    // isMenu: true,
    screen: BannersPage,
  },
  {
    path: '/banners/BP001/regist',
    title: '메인화면 배너 등록',
    screen: BannersRegistPage,
  },
  {
    path: '/banners/BP001/:id',
    title: '메인화면 배너 상세',
    screen: BannersDetailPage,
  },
  {
    path: '/banners/BP001/modify/:id',
    title: '메인화면 배너 수정',
    screen: BannersRegistPage,
  },
  {
    path: '/banners/BP002',
    title: '서브메인(쇼핑) 배너',
    isMenu: true,
    screen: SubBannersPage,
  },
  {
    path: '/banners/BP002/regist',
    title: '서브메인(쇼핑) 배너 등록',
    screen: SubBannersRegistPage,
  },
  {
    path: '/banners/BP002/:id',
    title: '서브메인(쇼핑) 배너 상세',
    screen: SubBannersDetailPage,
  },
  {
    path: '/banners/BP002/modify/:id',
    title: '서브메인(쇼핑) 배너 수정',
    screen: SubBannersRegistPage,
  },
  {
    path: '/banners/BP003',
    title: '서브메인(상점) 배너',
    isMenu: true,
    screen: SubBannersPage,
  },
  {
    path: '/banners/BP003/regist',
    title: '서브메인(상점) 배너 등록',
    screen: SubBannersRegistPage,
  },
  {
    path: '/banners/BP003/:id',
    title: '서브메인(상점) 배너 상세',
    screen: SubBannersDetailPage,
  },
  {
    path: '/banners/BP003/modify/:id',
    title: '서브메인(상점) 배너 수정',
    screen: SubBannersRegistPage,
  },
  {
    path: '/banners/BP004',
    title: '서브메인(커뮤니티) 배너',
    isMenu: true,
    screen: SubBannersPage,
  },
  {
    path: '/banners/BP004/:id',
    title: '서브메인(커뮤니티) 배너 상세',
    screen: SubBannersDetailPage,
  },
  {
    path: '/banners/BP004/regist',
    title: '서브메인(커뮤니티) 배너 등록',
    screen: SubBannersRegistPage,
  },
  {
    path: '/banners/BP004/modify/:id',
    title: '서브메인(커뮤니티) 배너 수정',
    screen: SubBannersRegistPage,
  },
  {
    path: '/posts',
    title: '게시글 관리',
    isMenu: true,
    icon: ProfileOutlined,
  },
  {
    path: '/posts/community',
    title: '커뮤니티관리',
    isMenu: true,
    screen: PostsCommunityPage,
  },
  {
    path: '/posts/community/detail/:id',
    title: '커뮤니티 상세',
    screen: PostsCommunityDetailPage,
  },
  {
    path: '/posts/notice/user',
    title: '사용자앱 공지',
    isMenu: true,
    screen: PostsNoticeUserPage,
  },
  {
    path: '/posts/notice/user/regist',
    title: '사용자앱 공지 등록',
    screen: PostsNoticeUserRegistPage,
  },
  {
    path: '/posts/notice/user/detail/:id',
    title: '사용자앱 공지 상세',
    screen: PostsNoticeUserDetailPage,
  },
  {
    path: '/posts/notice/user/modify/:id',
    title: '사용자앱 공지 수정',
    screen: PostsNoticeUserRegistPage,
  },
  {
    path: '/posts/notice/partner',
    title: '파트너사 공지',
    isMenu: true,
    screen: PostsNoticePartnerPage,
  },
  {
    path: '/posts/notice/partner/regist',
    title: '파트너사 공지 등록',
    screen: PostsNoticePartnersRegistPage,
  },
  {
    path: '/posts/notice/partner/detail/:id',
    title: '파트너사 공지 상세',
    screen: PostsNoticePartnersDetailPage,
  },
  {
    path: '/posts/notice/partner/modify/:id',
    title: '파트너사 공지 수정',
    screen: PostsNoticePartnersRegistPage,
  },
  {
    path: '/posts/notice/md',
    title: 'MD 공지',
    isMenu: true,
    screen: PostsNoticeMDPage,
  },
  {
    path: '/posts/notice/md/regist',
    title: 'MD 공지 등록',
    screen: PostsNoticeMDsRegistPage,
  },
  {
    path: '/posts/notice/md/detail/:id',
    title: 'MD 공지 상세',
    screen: PostsNoticeMDsDetailPage,
  },
  {
    path: '/posts/notice/md/modify/:id',
    title: 'MD 공지 수정',
    screen: PostsNoticeMDsRegistPage,
  },
  {
    path: '/posts/faq',
    title: 'FAQ',
    isMenu: true,
    screen: PostsFaqPage,
  },
  {
    path: '/posts/faq/regist',
    title: 'FAQ 등록',
    screen: PostsFaqRegistPage,
  },
  {
    path: '/posts/faq/detail/:id',
    title: 'FAQ 상세',
    screen: PostsFaqDetailPage,
  },
  {
    path: '/posts/faq/modify/:id',
    title: 'FAQ 수정',
    screen: PostsFaqRegistPage,
  },
  {
    path: '/adverties',
    title: '광고관리',
    isMenu: true,
    icon: PictureOutlined,
  },
  {
    path: '/adverties/searchLocal',
    title: '검색지역광고',
    isMenu: true,
    screen: AdvertiesLocalIndex,
  },
  // {
  //   path: '/adverties/banner',
  //   title: '배너상품#1',
  //   isMenu: true,
  //   screen: AdvertiesBannerIndex,
  // },
  {
    path: '/admin',
    title: '관리자계정 관리',
    isMenu: true,
    icon: KeyOutlined,
  },
  {
    path: '/admin/list',
    title: '관리자계정 관리',
    isMenu: true,
    icon: PictureOutlined,
    screen: AdminPage,
  },
  {
    title: '관리자계정 등록',
    path: '/admin/regist',
    screen: AdminRegistPage,
  },
  {
    title: '관리자계정 상세',
    path: '/admin/:id',
    screen: AdminDetailPage,
  },
  {
    title: '관리자계정 수정',
    path: '/admin/modify/:id',
    screen: AdminRegistPage,
  },
  {
    path: '/statistics',
    title: '통계',
    icon: BarChartOutlined,
    isMenu: true,
  },
  {
    path: '/statistics/daily_payment_amount',
    title: '일별 결제금액',
    isMenu: true,
    screen: DailyPaymentAmount,
  },
  {
    path: '/statistics/payers_payments_per_day',
    title: '일별 결제자수 및 결제수',
    isMenu: true,
    screen: PayersAndPaymentsPerDay,
  },
  {
    path: '/statistics/payment_amount_by_day_of_the_week',
    title: '요일별 결제금액',
    isMenu: true,
    screen: PaymentAmountByDayOfTheWeek,
  },
  {
    path: '/statistics/average_payment_amount_by_day_of_the_week',
    title: '요일별 평균 결제금액',
    isMenu: true,
    screen: AveragePaymentAmountByDayOfTheWeek,
  },
  {
    path: '/statistics/daily_refun_rate',
    title: '일별 환불율',
    isMenu: true,
    screen: DailyRefundRate,
  },
  {
    path: '/management',
    title: '공통관리',
    icon: ShareAltOutlined,
    isMenu: true,
  },
  {
    path: '/management/common_code',
    title: '공통코드',
    isMenu: true,
    screen: ManagementCommonCodePage,
  },
  {
    path: '/management/common_code/detail/:codeGroup',
    title: '공통코드 상세',
    screen: ManagementCommonCodeDetailPage,
  },
  {
    path: '/management/common_code/regist',
    title: '공통코드 등록',
    screen: ManagementCommonCodeRegistPage,
  },
  {
    path: '/management/common_code/modify/:codeGroup',
    title: '공통코드 수정',
    screen: ManagementCommonCodeRegistPage,
  },
  {
    path: '/management/category/product',
    title: '상품카테고리',
    isMenu: true,
    screen: ManagementCategoryPage,
  },
  {
    path: '/management/category/store',
    title: '상점카테고리',
    isMenu: true,
    screen: ManagementCategoryPage,
  },
  {
    path: '/management/forbidden_word',
    title: '금지어 관리',
    isMenu: true,
    screen: ManagementForbiddenWordPage,
  },
  {
    path: '/management/region',
    title: '지역관리',
    isMenu: true,
    screen: ManagementRegionPage,
  },
  {
    path: '/management/terms',
    title: '이용약관',
    isMenu: true,
    screen: ManagementTermsPage,
  },
  {
    path: '/tour',
    title: '투어 예약 확인',
    icon: CarryOutOutlined,
    isMenu: true,
    screen: TourReservation,
  },
  {
    path: '/tour/detail/:tourReservationid',
    title: '투어 예약정보 상세',
    screen: TourReservationDetail,
  },
];

export const useRoutes = () => {
  const routes = useMemo(
    () =>
      pages
        ?.filter((v) => !!v.isMenu || !!v.isSubMenu)
        ?.reduce((output, page) => {
          const path = page.path?.substring(1);
          const parentPath = `/${path?.substring(0, path.indexOf('/'))}`;
          const parentIndex = path?.indexOf('/') >= 0 ? output.findIndex((v) => v.path === parentPath) : undefined;
          if (parentIndex === undefined) {
            output.push({
              path: page.path,
              title: page.title,
              icon: page?.icon,
            });
          } else {
            if (!output[parentIndex].children) output[parentIndex].children = [];
            output[parentIndex].children.push({
              path: page.path,
              title: page.title,
              group: page?.group,
            });
          }
          return output;
        }, []),
    [],
  );

  return { routes, pages };
};

export default useRoutes;
