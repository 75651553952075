import React from 'react';
import { Divider, Row, Col, Space, DatePicker, Select, Input, Button, Modal } from 'antd';
import SearchForm from '#components/SearchForm';
import handleError from '#utils/handleError';
import formatComma from '#utils/formatComma';
import CommTable from '#components/table/CommTable';

import moment from 'moment';
import produce from 'immer';
import useIndex from '#hooks/useIndex';
import { useReduxStore } from '#hooks/useReduxStore';
import { restApi } from '#apis';
import CardWrapper from '#components/common/CardWrapper';

const DATE_OPT = [
  {
    label: '신청일',
    value: 'CreatedAt',
  },
  {
    label: '처리완료일',
    value: 'ProcessAt',
  },
];

function SalesCalculate() {
  const { getIndex } = useIndex();
  const { map: codeMap } = useReduxStore('codes');
  const tableRef = React.useRef(null);
  const [calculationStatus, setCalculationStatus] = React.useState(null);

  const initailParams = React.useMemo(
    () => ({
      startAt: null,
      endAt: null,
      isProcess: null,
      searchType: null,
      keyword: null,
    }),
    [],
  );

  const [params, setParams] = React.useState(initailParams);
  const [dataSource, setDataSource] = React.useState([]);

  const askToCalculate = React.useCallback(
    (id) => () => {
      Modal.confirm({
        content: (
          <>
            <p>정산처리를 하겠습니까?</p>
            <p>※ 정산처리 후 변경이 불가능합니다.</p>
          </>
        ),
        cancelText: '취소',
        okText: '확인',
        onOk: async () => {
          try {
            await restApi.put(`/cms/partners/calculate-request/${id}/process`);
            Modal.destroyAll();
            tableRef.current?.loadData();
            await Promise.all([
              restApi.get('/cms/partners/calculate-request/paid'),
              restApi.get('/cms/partners/calculate-request/unpaid'),
            ])
              .then((result) => {
                setCalculationStatus({ paid: result[0].data, unpaid: result[1].data });
              })
              .catch((e) => {
                handleError(e);
                Modal.error({
                  content: '정산 내역을 가져오는데 실패했습니다.',
                });
              });
            Modal.success({
              content: '정산처리를 완료했습니다.',
            });
          } catch (e) {
            handleError(e);
            Modal.error({
              contnet: '정산처리에 실패했습니다.',
            });
          }
        },
      });
    },
    [],
  );

  React.useEffect(() => {
    Promise.all([
      restApi.get('/cms/partners/calculate-request/paid'),
      restApi.get('/cms/partners/calculate-request/unpaid'),
    ])
      .then((result) => {
        setCalculationStatus({ paid: result[0].data, unpaid: result[1].data });
      })
      .catch((e) => {
        handleError(e);
        Modal.error({
          content: '정산 내역을 가져오는데 실패했습니다.',
        });
      });
  }, []);

  return (
    <div>
      {calculationStatus && (
        <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper title="미처리 된 정산 금액" value={calculationStatus?.unpaid} />
            <CardWrapper title="정산 처리 금액 (최근7일)" value={calculationStatus?.paid} />
          </div>
        </div>
      )}
      <Divider />

      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initailParams);
          return initailParams;
        }}
      >
        <Row gutter={[50, 15]}>
          <Col span={24}>
            <Space style={{ width: '100%' }}>
              <Select
                value={params.dateType}
                placeholder="날짜 옵션"
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.dateType = value;
                    }),
                  );
                }}
                options={DATE_OPT}
                style={{ width: 150 }}
              />
              <DatePicker.RangePicker
                disabled={!params.dateType}
                value={[params.startAt ? moment(params.startAt) : null, params.endAt ? moment(params.endAt) : null]}
                onChange={(e) => {
                  if (e) {
                    const [startAt, endAt] = e;
                    setParams(
                      produce((draft) => {
                        draft.startAt = startAt.format('YYYY-MM-DD');
                        draft.endAt = endAt.format('YYYY-MM-DD');
                      }),
                    );
                  } else {
                    setParams(
                      produce((draft) => {
                        draft.startAt = null;
                        draft.endAt = null;
                      }),
                    );
                  }
                }}
              />
              <span style={{ marginLeft: 20 }}>파트너사</span>
              <Input
                placeholder="키워드를 입력하세요"
                style={{ width: 300 }}
                value={params.keyword}
                onChange={(event) => {
                  setParams(
                    produce((draft) => {
                      draft.keyword = event.target.value;
                    }),
                  );
                }}
              />
            </Space>
          </Col>
        </Row>
        <Row gutter={[50, 15]} style={{ marginTop: 10 }}>
          <Col span={24}>
            <Space style={{ width: '100%' }}>
              <Select
                value={params.isProcess}
                placeholder="정산 여부"
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.isProcess = value;
                    }),
                  );
                }}
                allowClear
                options={[
                  { value: 'false', label: '미처리' },
                  { value: 'true', label: '처리' },
                ]}
                style={{ width: 150 }}
              />
              <Space>
                <Button type="primary" htmlType="submit">
                  검색
                </Button>
                <Button htmlType="reset">초기화</Button>
              </Space>
            </Space>
          </Col>
        </Row>
      </SearchForm>
      <Divider />
      <CommTable
        url="/cms/partners/calculate-request"
        ref={tableRef}
        excel="true"
        columns={[
          {
            title: '번호',
            dataIndex: 'tableIndex',
            key: 'tableIndex',
            fixed: 'left',
            width: 50,
            render: (_, __, index) => getIndex(index),
          },
          {
            title: '신청금액',
            dataIndex: 'amount',
            key: 'amount',
            width: 200,
            render: (value) => `${formatComma(value)}원`,
          },
          {
            title: '신청자',
            dataIndex: 'storeName',
            key: 'storeName',
            width: 100,
            render: (value) => value,
          },
          {
            title: '계좌정보',
            dataIndex: 'bankInfo',
            key: 'bankInfo',
            width: 180,
            render: (value) => {
              const rendered = value?.split(' ')?.map((item, index) => {
                console.log('item :::: ', item);
                if (index === 0) {
                  if (codeMap?.[item]) return codeMap[item];
                  return '';
                }
                return item === 'null' ? '' : item;
              });
              return rendered?.join(' / ') || '';
            },
          },
          {
            title: '신청일',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 200,
            render: (value) => value,
          },
          {
            title: '처리여부',
            dataIndex: 'calculateStateCode',
            key: 'calculateStateCode',
            width: 200,
            render: (value) => codeMap[value],
          },
          {
            title: '처리완료일',
            dataIndex: 'processAt',
            key: 'processAt',
            width: 200,
            render: (value) => value,
          },
          {
            title: '처리자',
            dataIndex: 'adminName',
            key: 'adminName',
            width: 200,
            render: (value) => value,
          },
          {
            title: '정산처리하기',
            dataIndex: 'id',
            key: 'id',
            width: 200,
            render: (value, obj) => {
              if (obj.calculateStateCode === 'CS002') {
                return (
                  <Button onClick={askToCalculate(value)} type="primary">
                    정산처리하기
                  </Button>
                );
              }
              return;
            },
          },
        ]}
        scroll={{ x: 1120 }}
        dataSource={dataSource}
        codeMap={codeMap}
        onLoadData={({ results }) => {
          setDataSource(results);
        }}
      />
    </div>
  );
}

export default SalesCalculate;
