import { restApi } from '#apis/index';
import FetchElement from '#components/FetchElement';
import moment from 'moment';
import React from 'react';
import { Button, Input, DatePicker, Modal, Form, Space, Col, Row, Radio } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

const MemoizedContentsCommunityRegistPage = React.memo(({ id, tagName, startAt, endAt, isActive }) => {
  const navigate = useNavigate();

  return (
    <Form
      style={{ maxWidth: 800 }}
      onFinish={({ tagName, dateRange, isActive }) => {
        const [startAt, endAt] = dateRange.map((date) => date.format('YYYY-MM-DD HH:mm'));

        const requestBody = {
          placeCode: 'DP008',
          endAt,
          startAt,
          tagName,
          isActive,
        };

        if (id) {
          return restApi
            .put(`/cms/displays/${id}`, requestBody)
            .then(() => {
              Modal.success({
                content: '수정을 완료했습니다.',
                onOk: () => {
                  navigate(`/contents/community`);
                },
              });
            })
            .catch((error) => {
              Modal.error({
                title: '서브메인(커뮤니티) 수정',
                content: error?.response?.data?.message ?? '컨텐츠 수정에 실패했습니다.',
                centered: true,
              });
            });
        }

        return restApi
          .post('/cms/displays/tag', requestBody)
          .then(({ data: id }) => {
            navigate(`/contents/community/${id}`);
          })
          .catch((error) => {
            Modal.error({
              title: '서브메인(커뮤니티)',
              content: error?.response?.data?.message ?? '컨텐츠 등록에 실패했습니다.',
              centered: true,
            });
          });
      }}
    >
      <Space style={{ width: '100%' }} direction="vertical" size="middle">
        <Space style={{ width: '100%' }} direction="vertical">
          <Form.Item
            label="태그명"
            required
            name="tagName"
            rules={[{ required: true, message: '태그명을 입력해주세요.' }]}
            initialValue={tagName}
          >
            <Input placeholder="태그명을 입력해주세요" />
          </Form.Item>
          <Form.Item
            label="노출기간"
            required
            name="dateRange"
            rules={[{ required: true, message: '노출기간을 선택해주세요.' }]}
            initialValue={[startAt && moment(startAt), endAt && moment(endAt)]}
          >
            <DatePicker.RangePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" />
          </Form.Item>
          <Form.Item
            label="사용여부"
            required
            name="isActive"
            rules={[{ required: true, message: '사용여부를 선택해주세요.' }]}
            initialValue={isActive}
          >
            <Radio.Group
              options={[
                { label: '사용', value: true },
                { label: '미사용', value: false },
              ]}
            />
          </Form.Item>
        </Space>
        <Row justify="end">
          <Col>
            <Space>
              <Button
                onClick={() => {
                  Modal.confirm({
                    title: '서브메인(커뮤니티)',
                    content: (
                      <>
                        입력했던 내용이 전부 사라집니다.
                        <br />
                        계속 하시겠습니까 ?
                      </>
                    ),
                    centered: true,
                    onOk: () => {
                      navigate(id ? `/contents/community/${id}` : '/contents/community');
                    },
                  });
                }}
              >
                취소
              </Button>
              <Button type="primary" htmlType="submit">
                {id ? '수정' : '저장'}
              </Button>
            </Space>
          </Col>
        </Row>
      </Space>
    </Form>
  );
});

function ContentsCommunityRegistPage() {
  const params = useParams();
  const id = React.useMemo(() => params?.id, [params?.id]);

  return (
    <FetchElement
      fetch={() => id && restApi.get(`/cms/displays/${id}`)}
      Component={(data) => <MemoizedContentsCommunityRegistPage id={id} {...data} />}
    />
  );
}

export default ContentsCommunityRegistPage;
