import FetchElement from '#components/FetchElement';
import useMount from '#hooks/useMount';
import moment from 'moment';
import React from 'react';
import { Button, Modal, Space, Table, Typography } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CommTable from '#components/table/CommTable';
import { restApi } from '#apis/index';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';
import produce from 'immer';
import GetImageFile from '../../components/GetImageFile';

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const MemoizedBannersPage = React.memo(({ placeCode, data = [], setData, refresh, activeList }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const newUsedActive = data?.activeDisplays?.length
    ? [...activeList].sort((a, b) => a.exposeNum - b.exposeNum)
    : activeList;
  if (!data) {
    return null;
  }
  return (
    <>
      <style jsx>{`
        .row-dragging {
          background: #fafafa;
        }

        .row-dragging td {
          padding: 16px;
          vertical-align: middle;
        }

        .row-dragging td img {
          width: 134px !important;
        }

        .row-dragging .drag-visible {
          visibility: visible;
        }
      `}</style>
      <Space style={{ width: '100%', maxWidth: 1200 }} direction="vertical" size="large">
        <Space style={{ width: '100%', marginBottom: 50 }} direction="vertical">
          <Typography.Paragraph>현재 사용 목록</Typography.Paragraph>
          <Table
            dataSource={newUsedActive}
            columns={[
              {
                title: '노출순서',
                width: 80,
                render: (value, record, index) => index + 1,
              },
              {
                title: '배너 이미지',
                width: 150,
                dataIndex: 'thumbnail',
                render: (value, record) => <GetImageFile imageId={record.imageId}></GetImageFile>,
              },
              {
                title: '제목',
                dataIndex: 'title',
                align: 'left',
                render: (value, record) => <Link to={`/banners/${placeCode}/${record?.id}`}>{value}</Link>,
              },
              {
                title: '노출 기간',
                width: 300,
                render: (value, record) =>
                  `${moment(record.startAt).format('YYYY-MM-DD HH:mm')} ~ ${moment(record.endAt).format(
                    'YYYY-MM-DD HH:mm',
                  )}`,
              },
              {
                title: '사용 여부',
                dataIndex: 'id',
                width: 120,
                render: (value, record) => (
                  <Button
                    type="danger"
                    onClick={() => {
                      Modal.confirm({
                        title: '노출 중지',
                        content: '해당 배너의 노출을 중지하시겠습니까 ?',
                        onOk: () => {
                          return restApi
                            .delete(`/cms/display-banners/${record.id}`)
                            .then(refresh)
                            .catch((error) => {
                              Modal.error({
                                title: '노출 중지',
                                content: error?.response?.data?.message ?? '노출 중지에 실패했습니다.',
                                centered: true,
                                okText: '확인',
                              });
                            });
                        },
                        centered: true,
                        okText: '확인',
                        cancelText: '취소',
                      });
                    }}
                  >
                    노출 중지
                  </Button>
                ),
              },
              {
                width: 50,
                className: 'drag-visible',
                render: () => {
                  return <DragHandle />;
                },
              },
            ]}
            components={{
              body: {
                wrapper: (props) => (
                  <SortableBody
                    useDragHandle
                    disableAutoscroll
                    helperClass="row-dragging"
                    onSortEnd={({ oldIndex, newIndex }) => {
                      if (oldIndex !== newIndex) {
                        const changedData = arrayMoveImmutable([...data?.activeList], oldIndex, newIndex)
                          .map((value, index) => ({ ...value, exposeNum: index }))
                          .filter(Boolean);
                        setData(
                          produce((draft) => {
                            draft.activeList = changedData;
                          }),
                        );
                        return restApi.put(`/cms/display-banners/order/${location.state}`, {
                          ids: changedData.map(({ id }) => id),
                        });
                      }
                    }}
                    {...props}
                  />
                ),
                row: (props) => {
                  return (
                    <SortableItem
                      index={data?.activeList?.findIndex((e) => e.id === props['data-row-key'])}
                      {...props}
                    />
                  );
                },
              },
            }}
            rowKey={({ id }) => id}
            pagination={false}
          />
        </Space>
        <Space style={{ width: '100%' }} direction="vertical">
          <Typography.Paragraph>미사용 배너</Typography.Paragraph>
          <CommTable
            url={`/cms/display-banners/list/${window.sessionStorage.getItem('displayId')}`}
            then={({ data: responseData }) => ({ data: responseData?.inactiveList })}
            columns={({ pageCur, limit }) => [
              {
                title: '번호',
                width: 80,
                render: (value, record, index) => pageCur * limit + index + 1,
              },
              {
                title: '배너 이미지',
                width: 150,
                dataIndex: 'thumbnail',
                render: (value, record) => <GetImageFile imageId={record.imageId}></GetImageFile>,
              },
              {
                title: '제목',
                dataIndex: 'title',
                align: 'left',
                render: (value, record) => (
                  <button
                    style={{
                      border: 'none',
                      backgroundColor: 'rgba(0,0,0,0)',
                      fontSize: 13,
                      color: '#4c72f1',
                    }}
                    onClick={() => {
                      navigate(`/banners/${placeCode}/${record?.id}`, { state: location.state });
                    }}
                  >
                    {value}
                  </button>
                ),
                // <Link to={`/banners/${placeCode}/${record?.id}`}>{value}</Link>,
              },
              {
                title: '노출 기간',
                width: 300,
                render: (value, record) =>
                  `${moment(record.startAt).format('YYYY-MM-DD HH:mm')} ~ ${moment(record.endAt).format(
                    'YYYY-MM-DD HH:mm',
                  )}`,
              },
            ]}
            headerExtra={
              // <Link to={`/banners/${placeCode}/regist`}>
              <Button
                type="primary"
                onClick={() => {
                  navigate(`/banners/${placeCode}/regist`, { state: location.state });
                }}
              >
                등록
              </Button>
            }
          />
        </Space>
      </Space>
    </>
  );
});

function BannersPage() {
  const mount = useMount();
  const fetchElementRef = React.useRef();
  const location = useLocation();
  const placeCode = React.useMemo(() => location.pathname.split('/')[2], [location.pathname]);
  console.log(location);
  React.useEffect(() => {
    if (!mount) {
      return;
    }

    fetchElementRef.current?.refresh();
  }, [placeCode]);

  return (
    <FetchElement
      ref={fetchElementRef}
      fetch={() => restApi.get(`/cms/display-banners/list/${window.sessionStorage.getItem('displayId')}`)}
      Component={(data) => <MemoizedBannersPage placeCode={placeCode} {...data} />}
    />
  );
}

export default BannersPage;
