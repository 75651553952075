import React, { useEffect, useRef } from 'react';
import { Button, Checkbox, Col, DatePicker, Form, Input, Radio, Row, Select } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import * as qs from 'query-string';
import moment from 'moment';
import styles from './SearchForm.module.less';
// import placeholder from 'lodash/fp/placeholder';

const SearchForm = React.forwardRef(({ fields, extra }, ref) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = React.useMemo(() => qs.parse(location?.search), [location?.search]);
  const form = useRef();

  React.useImperativeHandle(ref, () => ({
    form,
    getFieldsValue: () => form.current.getFieldsValue(),
    resetFields: () => form.current.resetFields(),
    setFieldsValue: (next) => {
      form.current.setFieldsValue(next);
    },
  }));

  const handleSubmit = (data) => {
    const args = {
      // ...query,
    };
    for (const key of Object.keys(data).filter((v) => !!data[v])) {
      const field = fields.find((v) => v.key === key);
      if (field.type === 'datepicker') {
        args[key] = data[key].format('YYYY-MM-DD');
      } else if (field.type === 'daterange') {
        for (let i = 0; i < field.keys?.length; i++) {
          const subkey = field.keys[i];
          args[subkey] = data[key][i].format('YYYY-MM-DD');
        }
      } else {
        args[key] = data[key];
      }
    }
    navigate(`?${qs.stringify(args)}`, { replace: true });
  };

  const manageFormFields = () => {
    const data = fields?.reduce((p, { key }) => ({ ...p, [key]: undefined }), {});
    const args = qs.parse(location?.search);
    for (const key of Object.keys(args).filter((v) => !!args[v])) {
      const field = fields.find((v) => v.key === key || (v.type === 'daterange' && v.keys?.includes(key)));
      if (!field) continue;
      if (data[field.key]) continue;
      if (field.type === 'datepicker') {
        data[key] = moment(args[key]);
      } else if (field.type === 'daterange') {
        const o = [];
        for (let i = 0; i < field.keys?.length; i++) {
          const subkey = field.keys[i];
          o[i] = moment(args[subkey]);
        }
        data[field.key] = o;
      } else {
        data[key] = args[key];
      }
    }
    form.current.setFieldsValue(data);
  };

  useEffect(() => {
    manageFormFields();
  }, [location?.search]);

  return (
    <div className={styles.search_form}>
      <Form onFinish={handleSubmit} ref={form}>
        <Row gutter={[20, 10]}>
          {fields?.map((field, index) => {
            const onChange = field.submit ? () => form.current.submit() : undefined;
            return (
              <Col xs={24} sm={24} lg={24 * (field?.width ?? 1)} key={`${index}`}>
                <Form.Item name={field.key} label={field.label}>
                  {field.type === 'radio' ? (
                    <Radio.Group onChange={field?.onChange || onChange}>
                      {field?.items?.map((item) => (
                        <Radio value={item.value} key={String(item.value)}>
                          {item.label}
                        </Radio>
                      ))}
                    </Radio.Group>
                  ) : field.type === 'radiobutton' ? (
                    <Radio.Group onChange={field?.onChange || onChange}>
                      {field?.items?.map((item) => (
                        <Radio.Button value={item.value} key={String(item.value)}>
                          {item.label}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  ) : field.type === 'checkbox' ? (
                    <Checkbox.Group onChange={field?.onChange || onChange}>
                      {field?.items?.map((item) => (
                        <Checkbox value={item.value} key={String(item.value)}>
                          {item.label}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  ) : field.type === 'datepicker' ? (
                    <DatePicker />
                  ) : field.type === 'daterange' ? (
                    <DatePicker.RangePicker {...field} />
                  ) : field.type === 'select' ? (
                    <Select
                      {...field}
                      placeholder={field?.placeholder}
                      onChange={field?.onChange || onChange}
                      allowClear
                    >
                      {field?.items?.map((item) => (
                        <Select.Option value={item.value} key={String(item.value)}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Input allowClear {...field} />
                  )}
                </Form.Item>
              </Col>
            );
          })}
        </Row>
        <Row gutter={[5, 10]} className={styles.footer}>
          <Col flex="1" />
          <Col>
            <Button htmlType="submit" type="primary" onClick={extra?.handleSummit}>
              검색
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => {
                navigate('', { replace: true });
                manageFormFields();
                extra?.onInit();
              }}
            >
              초기화
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
});

export default SearchForm;
