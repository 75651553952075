import React from 'react';
import { Divider, Row, Col, Space, Button, Select, DatePicker, Input } from 'antd';
import { Link } from 'react-router-dom';
import SearchForm from '#components/SearchForm';
import CommTable from '../../../components/table/CommTable';
import { useReduxStore } from '../../../hooks/useReduxStore';
import produce from 'immer';
import moment from 'moment';
import useIndex from '../../../hooks/useIndex';

function ReviewStoreIndex() {
  const { tree, map } = useReduxStore('codes');
  const { getIndex } = useIndex();

  const initailParams = React.useMemo(
    () => ({
      dateType: null,
      startAt: null,
      endAt: null,
      storeQuestionTypeCode: null,
      searchType: null,
      keyword: null,
    }),
    [],
  );

  const [params, setParams] = React.useState(initailParams);

  // const rowSelection = {};
  return (
    <div>
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initailParams);
          return initailParams;
        }}
      >
        <Row gutter={[50, 15]}>
          <Col span={24}>
            <Space style={{ width: '100%' }}>
              <Select
                value={params.dateType}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.dateType = value;
                    }),
                  );
                }}
                options={[
                  { label: '등록일', value: 'CREATED_AT' },
                  { label: '답변일', value: 'ANSWER_AT' },
                ]}
                placeholder="날짜옵션"
                style={{ width: 120 }}
              />
              <DatePicker.RangePicker
                disabled={!params.dateType}
                value={[params.startAt ? moment(params.startAt) : null, params.endAt ? moment(params.endAt) : null]}
                onChange={([startAt, endAt]) => {
                  setParams(
                    produce((draft) => {
                      draft.startAt = startAt.format('YYYY-MM-DD');
                      draft.endAt = endAt.format('YYYY-MM-DD');
                    }),
                  );
                }}
              />
              <Select
                placeholder="문의분야"
                style={{ width: 120 }}
                value={params.storeQuestionTypeCode}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.storeQuestionTypeCode = value;
                    }),
                  );
                }}
                options={tree?.SQT.items.map((value) => ({ label: value.label, value: value.code })) || []}
              />
              <Select
                placeholder="검색 옵션"
                style={{ width: 120 }}
                value={params.searchType}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.searchType = value;
                    }),
                  );
                }}
                options={[
                  { label: '문의내용', value: 'CONTENT' },
                  { label: '상점명', value: 'STORE_NAME' },
                  { label: '회원아이디', value: 'USER_ID' },
                  { label: '회원 이름', value: 'USER_NAME' },
                  { label: '상점 아이디', value: 'STORE_USERID' },
                ]}
              />
              <Input
                placeholder=" 키워드를 입력하세요"
                style={{ width: 300 }}
                value={params.keyword}
                onChange={(event) => {
                  setParams(
                    produce((draft) => {
                      draft.keyword = event.target.value;
                    }),
                  );
                }}
              />
            </Space>
          </Col>
          <Col span={24}>
            <Row justify="end">
              <Col>
                <Space>
                  <Button type="primary" htmlType="submit">
                    검색
                  </Button>
                  <Button htmlType="reset">초기화</Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </SearchForm>
      <Divider />
      <CommTable
        url="/cms/store-questions"
        columns={({ limit, pageCur, total }) => [
          {
            title: '번호',
            dataIndex: 'orderNum',
            key: 'orderNum',
            width: 100,
            render: (value, obj, index) => getIndex(index),
          },
          // {
          //   title: '문의분야',
          //   dataIndex: 'storeQuestionTypeCode',
          //   key: 'storeQuestionTypeCode',
          //   width: 300,
          //   render: (storeQuestionTypeCode) => {
          //     return storeQuestionTypeCode || '-';
          //   },
          // },
          // TODO :: partnerName이 명이면 상점아이디가 없는거 반대일 수도 있음
          {
            title: '상점명',
            dataIndex: 'storeName',
            key: 'storeName',
            width: 200,
            render: (storeName) => storeName || '-',
          },
          {
            title: '상점아이디',
            dataIndex: 'storeUserId',
            key: 'storeUserId',
            width: 200,
            render: (storeUserId) => storeUserId || '-',
          },
          {
            title: '문의내용',
            dataIndex: 'contents',
            key: 'contents',
            width: 200,
            render: (contents, obj) => <Link to={`/review/store/detail/${obj.id}`}>{contents || '-'}</Link>,
          },
          {
            title: '첨부파일',
            dataIndex: 'isAttach',
            key: 'isAttach',
            width: 200,
            render: (isAttach) => (isAttach ? 'O' : 'X'),
          },
          {
            title: '회원아이디',
            dataIndex: 'userId',
            key: 'userId',
            width: 200,
            render: (userId) => userId || '-',
          },
          {
            title: '회원이름',
            dataIndex: 'userName',
            key: 'userName',
            width: 200,
            render: (userName) => userName || '-',
          },
          {
            title: '등록일',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 200,
            render: (createdAt) => createdAt || '-',
          },
          {
            title: '답변여부',
            dataIndex: 'answerStateCode',
            key: 'answerStateCode',
            width: 200,
            render: (answerStateCode) => map?.[answerStateCode] || '-',
          },
          {
            title: '답변일',
            dataIndex: 'answerAt',
            key: 'answerAt',
            width: 200,
            render: (answerAt) => answerAt || '-',
          },
        ]}
        scroll={{ x: 1120 }}
      />
    </div>
  );
}

export default ReviewStoreIndex;
