const RegexObj = {
  tel: /^[0-9]{11,12}$/,
  phone: /^01[0-9]{8,9}$/,
  id: /^[A-Za-z0-9]{6,20}$/,
  email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  password: /^[A-Za-z0-9!@#$%^&*()]{6,16}$/,
  onlyNumber: /^[0-9]+$/,
  businessNumber: /^\d{10}$/,
  orderDealNumber: /^([0-9]{4})([가-힣]{4})([0-9]{4})$/,
};

export default RegexObj;

// const phoneRegx = "^821([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$";
