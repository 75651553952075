import { Checkbox, TimePicker } from 'antd';
import moment from 'moment';
import React from 'react';

function SettingOpenAndCloseTime({
  onStartAt,
  startAt,
  onClickCheckOffday,
  onCloseAt,
  closeAt,
  checkOffDay,
  disabled,
}) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TimePicker
        onChange={onStartAt}
        value={startAt ? moment(startAt, 'HH:mm') : null}
        format="HH:mm"
        placeholder="오픈 시간"
        showNow={false}
        disabled={disabled}
      />
      <span style={{ padding: '0 10px' }}>~</span>
      <TimePicker
        onChange={onCloseAt}
        disabled={!startAt || disabled}
        disabledTime={() => {
          const [hour, second] = startAt?.split(':') ?? [];
          return {
            disabledHours: () => [...Array(Number(hour) || 0)].map((_, i) => i),
            disabledMinutes: (selectedHour) =>
              selectedHour <= hour ? [...Array(Number(second) || 0)].map((_, i) => i) : [],
          };
        }}
        value={closeAt ? moment(closeAt, 'HH:mm') : null}
        format="HH:mm"
        placeholder="마감 시간"
        showNow={false}
      />
      <Checkbox disabled={disabled} onChange={onClickCheckOffday} checked={checkOffDay} style={{ marginLeft: 10 }}>
        휴무일
      </Checkbox>
    </div>
  );
}

export default React.memo(SettingOpenAndCloseTime);
