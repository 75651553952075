import moment from 'moment';
import React from 'react';
import { Button, Col, DatePicker, Divider, Form, Input, Row, Select, Space } from 'antd';
import CommTable from '#components/table/CommTable';
import SearchForm from '#components/SearchForm';
import produce from 'immer';
import { Link } from 'react-router-dom';

const SEARCH_TYPE = {
  CONTENTS: 'Contents',
  USER_ID: 'UserId',
  WRITER: 'Writer',
};

function PostsCommunityPage() {
  const initialParams = React.useMemo(
    () => ({
      startAt: undefined,
      endAt: undefined,
      searchType: SEARCH_TYPE.CONTENTS,
      isSecret: undefined,
      keyword: '',
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);

  return (
    <Space style={{ width: '100%', maxWidth: 1200 }} direction="vertical">
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initialParams);
          return initialParams;
        }}
      >
        <Row gutter={15} align="middle">
          <Col>
            <Form.Item label="등록일">
              <DatePicker.RangePicker
                value={[!!params.startAt && moment(params.startAt), !!params.endAt && moment(params.endAt)]}
                onChange={([startAt, endAt]) => {
                  setParams(
                    produce((draft) => {
                      draft.startAt = startAt.format('YYYY-MM-DD');
                      draft.endAt = endAt.format('YYYY-MM-DD');
                    }),
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Select
              value={
                typeof params.isSecret === 'boolean'
                  ? params.isSecret
                  : params.isSecret === 'false'
                  ? false
                  : params.isSecret === 'true'
                  ? true
                  : null
              }
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.isSecret = value;
                  }),
                );
              }}
              options={[
                { label: '공개', value: false },
                { label: '비공개', value: true },
              ]}
              style={{ width: 120 }}
              placeholder="공개여부"
              allowClear
            />
          </Col>
          <Col>
            <Select
              value={params.searchType}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.searchType = value;
                  }),
                );
              }}
              options={[
                {
                  label: '내용',
                  value: SEARCH_TYPE.CONTENTS,
                },
                {
                  label: '작성자',
                  value: SEARCH_TYPE.WRITER,
                },
                {
                  label: '회원아이디',
                  value: SEARCH_TYPE.USER_ID,
                },
              ]}
              style={{ width: 120 }}
            />
          </Col>
          <Col>
            <Input
              value={params.keyword}
              onChange={(event) => {
                setParams(
                  produce((draft) => {
                    draft.keyword = event.target.value;
                  }),
                );
              }}
              placeholder="키워드를 입력하세요."
              style={{ width: 200 }}
            />
          </Col>
          <Col>
            <Space>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Col>
        </Row>
      </SearchForm>
      <Divider />
      <CommTable
        url="/cms/community-boards"
        columns={({ pageCur, limit }) => [
          {
            title: '번호',
            width: 80,
            render: (_, $, index) => pageCur * limit + index + 1,
          },
          {
            title: '제목',
            dataIndex: 'title',
            align: 'left',
            render: (value, record) => <Link to={`/posts/community/detail/${record.id}`}>{value}</Link>,
          },
          {
            title: '작성자',
            dataIndex: 'writer',
            width: 120,
          },
          {
            title: '회원아이디',
            dataIndex: 'userId',
            width: 150,
          },
          {
            title: '공개여부',
            dataIndex: 'isSecret',
            width: 80,
            render: (value) => (value ? '비공개' : '공개'),
          },
          {
            title: '등록일',
            dataIndex: 'createdAt',
            width: 120,
            render: (value) => moment(value).format('YYYY-MM-DD'),
          },
        ]}
        // headerExtra={
        //   <Link to="/posts/faq/regist">
        //     <Button type="primary">등록</Button>
        //   </Link>
        // }
      />
    </Space>
  );
}

export default PostsCommunityPage;
