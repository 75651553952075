import { restApi } from '#apis/index';
import useFetch from '#hooks/useFetch';
import produce from 'immer';
import React from 'react';
import CommTable from '#components/table/CommTable';
import SearchForm from '#components/SearchForm';
import { Button, Col, Divider, Input, Row, Select, Space } from 'antd';

function ManagementRegion() {
  const initialParams = React.useMemo(
    () => ({
      extra1: undefined,
      extra2: undefined,
      extra3: undefined,
      keyword: '',
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);
  const areaCodesExtra1 = useFetch();
  const areaCodesExtra2 = useFetch();
  const areaCodesExtra3 = useFetch();
  const areaCodesExtra1Options = React.useMemo(
    () => areaCodesExtra1.data?.map((areaCode) => ({ label: areaCode, value: areaCode })) ?? [],
    [areaCodesExtra1.data],
  );
  const areaCodesExtra2Options = React.useMemo(
    () => areaCodesExtra2.data?.map((areaCode) => ({ label: areaCode, value: areaCode })) ?? [],
    [areaCodesExtra2.data],
  );
  const areaCodesExtra3Options = React.useMemo(
    () => areaCodesExtra3.data?.map((areaCode) => ({ label: areaCode, value: areaCode })) ?? [],
    [areaCodesExtra3.data],
  );

  React.useEffect(() => {
    areaCodesExtra1.fetch(restApi.get('/area-codes/filter'));
    if (params.extra1) {
      areaCodesExtra2.fetch(restApi.get(`/area-codes/filter/${params.extra1}`));
    } else {
      areaCodesExtra2.clear();
    }
    if (params.extra2) {
      areaCodesExtra3.fetch(restApi.get(`/area-codes/filter/${params.extra1}/${params.extra2}`));
    } else {
      areaCodesExtra3.clear();
    }
  }, [params]);

  return (
    <Space style={{ width: '100%', maxWidth: 800 }} direction="vertical">
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initialParams);
          return initialParams;
        }}
      >
        <Row gutter={10}>
          <Col>
            <Select
              value={params.extra1}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.extra1 = value;
                    draft.extra2 = undefined;
                    draft.extra3 = undefined;
                  }),
                );
              }}
              placeholder="시/도"
              options={areaCodesExtra1Options}
              style={{ width: 120 }}
            />
          </Col>
          <Col>
            <Select
              value={params.extra2}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.extra2 = value;
                    draft.extra3 = undefined;
                  }),
                );
              }}
              placeholder="시/군/구"
              options={areaCodesExtra2Options}
              style={{ width: 120 }}
            />
          </Col>
          <Col>
            <Select
              value={params.extra3}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.extra3 = value;
                  }),
                );
              }}
              placeholder="읍/면/동"
              options={areaCodesExtra3Options}
              style={{ width: 120 }}
            />
          </Col>
          <Col>
            <Input
              value={params.keyword}
              onChange={(event) => {
                setParams(
                  produce((draft) => {
                    draft.keyword = event.target.value;
                  }),
                );
              }}
              placeholder="법정동명"
            />
          </Col>
          <Col>
            <Space>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Col>
        </Row>
      </SearchForm>
      <Divider />
      <CommTable
        url="/area-codes"
        columns={[
          {
            title: '지역코드',
            dataIndex: 'code',
          },
          {
            title: '시/도',
            dataIndex: 'extra1',
          },
          {
            title: '시/군/구',
            dataIndex: 'extra2',
          },
          {
            title: '읍/면/동',
            dataIndex: 'extra3',
          },
        ]}
      />
    </Space>
  );
}

export default ManagementRegion;
