import { restApi } from '#apis/index';
import FileUpload from '#components/datalist/FileUpload';
import FetchElement from '#components/FetchElement';
import { Button, Col, DatePicker, Form, Input, Modal, Radio, Row, Space, Typography } from 'antd';
import produce from 'immer';
import moment from 'moment';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ImageUploadGuide from '../../components/common/ImageUploadGuide';
import { checkBeforeUpload } from '../../utils/beforeUpload';

const MemoizedSubBannersRegistPage = React.memo(({ placeCode, id, data }) => {
  const navigate = useNavigate();
  const initialForm = React.useMemo(
    () => ({
      active: data?.active ?? null,
      images: data?.imageResponse?.image?.split('/').reverse()[0]
        ? [Number(data.imageResponse.image?.split('/').reverse()[0])]
        : [],
      imageTexts: data?.imageResponse ? [data.imageResponse.text] : [],
      endAt: data?.endAt ?? null,
      startAt: data?.startAt ?? null,
      link: data?.link ?? '',
      title: data?.title ?? '',
    }),
    [data],
  );
  const [form, setForm] = React.useState(initialForm);

  return (
    <Form
      style={{ maxWidth: 800 }}
      onFinish={() => {
        if (!(form.images.length > 0))
          return Modal.warn({
            title: data ? '배너 수정' : '배너 등록',
            content: '배너 이미지를 등록하세요.',
            centered: true,
          });
        if (!form.title)
          return Modal.warn({ title: data ? '배너 수정' : '배너 등록', content: '제목을 입력하세요.', centered: true });
        if (!form.link)
          return Modal.warn({ title: data ? '배너 수정' : '배너 등록', content: '링크를 입력하세요.', centered: true });
        if (!form.startAt || !form.endAt)
          return Modal.warn({
            title: data ? '배너 수정' : '배너 등록',
            content: '노출기간을 선택하세요.',
            centered: true,
          });
        if (form.active === null)
          return Modal.warn({
            title: data ? '배너 수정' : '배너 등록',
            content: '사용여부를 선택하세요.',
            centered: true,
          });

        const requestBody = {
          active: form.active,
          endAt: form.endAt,
          image: {
            image: form.images[0],
            text: form.imageTexts[0] ?? '',
          },
          link: form.link,
          placeCode,
          startAt: form.startAt,
          title: form.title,
        };

        if (data) {
          return restApi
            .put(`/cms/banners/${id}`, requestBody)
            .then(() => {
              navigate(`/banners/${placeCode}/${id}`);
            })
            .catch((error) => {
              Modal.error({
                title: '배너 수정',
                content: error?.response?.data?.message ?? '배너 수정에 실패했습니다.',
                centered: true,
              });
            });
        }

        return restApi
          .post('/cms/banners', requestBody)
          .then(() => {
            navigate(`/banners/${placeCode}`);
          })
          .catch((error) => {
            Modal.error({
              title: '배너 등록',
              content: error?.response?.data?.message ?? '배너 등록에 실패했습니다.',
              centered: true,
            });
          });
      }}
    >
      <Space style={{ width: '100%' }} direction="vertical">
        <Form.Item label="배너이미지" required>
          <FileUpload
            maxCount={1}
            items={form.images}
            onDoneChange={(fileListDone) => {
              setForm(
                produce((draft) => {
                  draft.images = fileListDone.map(({ response }) => response?.[0]?.id);
                  draft.imageTexts.splice(draft.imageTexts.length - 1, draft.imageTexts.length - fileListDone.length);
                }),
              );
            }}
            beforeUpload={checkBeforeUpload({
              availableLists: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'],
              textWhenFail: 'jpg, jpeg, png, gif 확장자만 가능합니다.',
            })}
            extraItemRender={(file) => {
              const findIndex = form?.images?.findIndex((image) => image === file?.response?.[0]?.id);
              if (!(findIndex > -1)) {
                return null;
              }
              return (
                <Space style={{ marginTop: 10 }}>
                  <Typography.Text>대체 택스트: </Typography.Text>
                  <Input
                    value={form.imageTexts[findIndex]}
                    onChange={(event) => {
                      setForm(
                        produce((draft) => {
                          draft.imageTexts[findIndex] = event.target.value;
                        }),
                      );
                    }}
                  />
                </Space>
              );
            }}
          />
          <ImageUploadGuide />
        </Form.Item>
        <Form.Item label="제목" required>
          <Input
            value={form.title}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.title = event.target.value;
                }),
              );
            }}
          />
        </Form.Item>
        <Form.Item label="링크" required>
          <Input
            value={form.link}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.link = event.target.value;
                }),
              );
            }}
          />
        </Form.Item>
        <Form.Item label="노출기간" required>
          <DatePicker.RangePicker
            value={[form.startAt ? moment(form.startAt) : undefined, form.endAt ? moment(form.endAt) : undefined]}
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            onChange={([startAt, endAt]) => {
              setForm(
                produce((draft) => {
                  draft.startAt = startAt.format('YYYY-MM-DD HH:mm');
                  draft.endAt = endAt.format('YYYY-MM-DD HH:mm');
                }),
              );
            }}
          />
        </Form.Item>
        <Form.Item label="사용여부" required>
          <Radio.Group
            value={form.active}
            options={[
              { label: '사용', value: true },
              { label: '미사용', value: false },
            ]}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.active = event.target.value;
                }),
              );
            }}
          />
        </Form.Item>
        <Row justify="end">
          <Col>
            <Space>
              <Button
                onClick={() => {
                  Modal.confirm({
                    title: '배너 등록',
                    content: (
                      <>
                        입력했던 내용이 전부 사라집니다.
                        <br />
                        계속 하시겠습니까 ?
                      </>
                    ),
                    onOk: () => {
                      navigate(`/banners/${placeCode}`);
                    },
                    centered: true,
                  });
                }}
              >
                취소
              </Button>
              <Button type="primary" htmlType="submit">
                {data ? '수정' : '저장'}
              </Button>
            </Space>
          </Col>
        </Row>
      </Space>
    </Form>
  );
});

function SubBannersRegistPage() {
  const location = useLocation();
  const params = useParams();
  const placeCode = React.useMemo(() => location.pathname.split('/')[2], [location.pathname]);
  const id = React.useMemo(() => params?.id, [params?.id]);

  return (
    <FetchElement
      fetch={() => id && restApi.get(`/cms/banners/${id}`)}
      Component={(data) => <MemoizedSubBannersRegistPage id={id} placeCode={placeCode} {...data} />}
    />
  );
}

export default SubBannersRegistPage;
