import FetchElement from '#components/FetchElement';
import React from 'react';
import { Button, Descriptions, Form, Input, Modal, Radio, Row, Select, Space, Typography } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import FileUpload from '#components/datalist/FileUpload';
import produce from 'immer';
import { EditorState } from 'draft-js';
import Editor, { draftToHTML, HTMLToDraft } from '#components/Editor';
import { restApi } from '#apis';
import { checkBeforeUpload } from '../../../utils/beforeUpload';
import ImageUploadGuide from '../../../components/common/ImageUploadGuide';

const MemoizedPostsFaqRegistPage = React.memo(({ active, answer, categoryCode, id, images, question }) => {
  const { tree: codesTree } = useSelector((selector) => selector.codes, shallowEqual);
  const navigate = useNavigate();

  const initialForm = React.useMemo(
    () => ({
      active: null,
      answer: EditorState.createEmpty(),
      images: [],
      imageTexts: [],
      categoryCode: null,
      question: '',
    }),
    [],
  );
  const [form, setForm] = React.useState(initialForm);

  const handleSubmit = React.useCallback(async () => {
    try {
      const requestBody = {
        question: form.question,
        answer: draftToHTML(form.answer),
        images: form.images.map((image, index) => ({ image, text: form.imageTexts[index] })),
        categoryCode: form.categoryCode,
        active: form.active,
      };
      if (!requestBody.question)
        return Modal.warn({
          title: id ? 'FAQ 수정' : 'FAQ 등록',
          content: '질문을 입력하세요.',
          centered: true,
          okText: '확인',
          cancelText: '취소',
        });

      if (requestBody.answer === '<p></p>\n')
        return Modal.warn({
          title: id ? 'FAQ 수정' : 'FAQ 등록',
          content: '내용을 입력하세요.',
          centered: true,
          okText: '확인',
          cancelText: '취소',
        });

      if (id) {
        await restApi.put(`/cms/faqs/${id}`, requestBody);

        Modal.success({
          title: 'FAQ 수정',
          content: 'FAQ가 수정되었습니다.',
          onOk: () => {
            navigate(`/posts/faq/detail/${id}`);
          },
          centered: true,
          okText: '확인',
          cancelText: '취소',
        });
      } else {
        await restApi.post(`/cms/faqs`, requestBody);

        Modal.success({
          title: id ? 'FAQ 수정' : 'FAQ 등록',
          content: 'FAQ를 등록했습니다.',
          onOk: () => {
            navigate('/posts/faq');
          },
          centered: true,
          okText: '확인',
          cancelText: '취소',
        });
      }
    } catch (error) {
      Modal.error({
        title: id ? 'FAQ 수정' : 'FAQ 등록',
        content: error?.response?.data?.message ?? 'FAQ를 등록하는데 실패했습니다.',
        centered: true,
        okText: '확인',
        cancelText: '취소',
      });
    }
  }, [form.active, form.answer, form.categoryCode, form.imageTexts, form.images, form.question, id, navigate]);

  React.useEffect(() => {
    if (!!id) {
      setForm({
        active,
        answer: HTMLToDraft(answer),
        images: images?.map(({ id }) => id) ?? [],
        imageTexts: images?.map(({ text }) => text) ?? [],
        question,
        categoryCode,
      });
    }
  }, [id]);

  return (
    <Form onFinish={handleSubmit}>
      <Space direction="vertical" size="middle" style={{ width: '100%', maxWidth: 1200 }}>
        <Form.Item label="카테고리" required>
          <Select
            value={form.categoryCode}
            onChange={(value) => {
              setForm(
                produce((draft) => {
                  draft.categoryCode = value;
                }),
              );
            }}
            style={{ width: 200 }}
            placeholder="카테고리를 선택해주세요"
            options={codesTree?.QA?.items?.map(({ code, label }) => ({ label, value: code })) ?? []}
          />
        </Form.Item>
        <Form.Item label="질문" required>
          <Input
            type="text"
            placeholder="질문을 입력하세요"
            value={form.question}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.question = event.target.value;
                }),
              );
            }}
          />
        </Form.Item>
        <Form.Item label="내용" required>
          <Editor
            editorState={form.answer}
            onEditorStateChange={(editorState) => {
              setForm(
                produce((draft) => {
                  draft.answer = editorState;
                }),
              );
            }}
            editorStyle={{ minHeight: 350 }}
          />
        </Form.Item>
        <Form.Item label="첨부파일">
          <FileUpload
            items={form.images}
            onDoneChange={(fileListDone) => {
              setForm(
                produce((draft) => {
                  draft.images = fileListDone.map(({ response }) => response[0].id);
                  draft.imageTexts.splice(draft.imageTexts.length - 1, draft.imageTexts.length - fileListDone.length);
                }),
              );
            }}
            beforeUpload={checkBeforeUpload({
              availableLists: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'],
              textWhenFail: 'jpg, jpeg, png, gif 확장자만 가능합니다.',
            })}
            extraItemRender={(file) => {
              const findIndex = form?.images?.findIndex((image) => image === file?.response?.[0]?.id);
              if (!(findIndex > -1)) {
                return null;
              }
              return (
                <Space style={{ marginTop: 10 }}>
                  <Typography.Text>대체 택스트: </Typography.Text>
                  <Input
                    value={form.imageTexts[findIndex]}
                    onChange={(event) => {
                      setForm(
                        produce((draft) => {
                          draft.imageTexts[findIndex] = event.target.value;
                        }),
                      );
                    }}
                  />
                </Space>
              );
            }}
          />
          <ImageUploadGuide />
        </Form.Item>
        <Form.Item label="사용여부" required>
          <Radio.Group
            value={form.active}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.active = event.target.value;
                }),
              );
            }}
            options={[
              { label: '사용', value: true },
              { label: '미사용', value: false },
            ]}
          />
        </Form.Item>
        <Row justify="end">
          <Space>
            <Button
              onClick={() => {
                Modal.confirm({
                  title: id ? 'FAQ 수정' : 'FAQ 등록',
                  content: (
                    <>
                      입력했던 내용이 전부 사라집니다.
                      <br />
                      계속 하시겠습니까?
                    </>
                  ),
                  okText: '확인',
                  cancelText: '취소',
                  centered: true,
                  onOk: () => {
                    if (id) {
                      navigate(`/posts/faq/detail/${id}`);
                    } else {
                      navigate('/posts/faq');
                    }
                  },
                });
              }}
            >
              취소
            </Button>
            <Button htmlType="submit" type="primary">
              저장
            </Button>
          </Space>
        </Row>
      </Space>
    </Form>
  );
});

const PostsFaqRegistPage = () => {
  const { id } = useParams();

  return <FetchElement fetch={() => id && restApi.get(`/cms/faqs/${id}`)} Component={MemoizedPostsFaqRegistPage} />;
};

export default PostsFaqRegistPage;
