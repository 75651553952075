import React from 'react';

// used in sales pages
const useCheckOrderStatusCodeRowKeys = (
  dataSource,
  selectedRowKeys,
  { keyword = 'orderProductId', exceptCode = 'OPS011' } = { keyword: 'orderProductId', exceptCode: 'OPS011' },
) => {
  const checkOrderStatusCodeRowKeys = React.useCallback(
    (...codes) => {
      if (selectedRowKeys.length > 0) {
        if (!exceptCode && !codes?.length) return true;
        for (let selectedRowkey of selectedRowKeys) {
          // table에서 해당하는 record 픽
          const selectedRow = dataSource?.find((data) => data[keyword] === selectedRowkey);
          if (selectedRow?.orderProductStateCode === exceptCode) return false;
          if (codes.length > 0) {
            if (!codes.includes(selectedRow?.orderProductStateCode)) {
              return false;
            }
          }
        }
        return true;
      } else {
        return false;
      }
    },
    [dataSource, selectedRowKeys, keyword, exceptCode],
  );

  return { checkOrderStatusCodeRowKeys };
};

export default useCheckOrderStatusCodeRowKeys;
