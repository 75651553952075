import produce from 'immer';
import moment from 'moment';
import React from 'react';
import {
  Table,
  Divider,
  Select,
  Button,
  Modal,
  Input,
  DatePicker,
  Row,
  Col,
  Space,
  Form,
  Radio,
  Checkbox,
  Typography,
  Descriptions,
} from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import handleError from '#utils/handleError';
import formatComma from '#utils/formatComma';
import useFetch from '#hooks/useFetch';
import { restApi } from '#apis/index';
import InputMoney from '#components/Input/InputMoney';
import CommTable from '#components/table/CommTable';
import SearchForm from '#components/SearchForm';
import StoreGoodsForm from '#components/store/goods/form';
import { DELIVERY_METHOD_TYPE, DELIVERY_PRICE_TYPE, DELIVERY_TYPE } from '#components/store/goods/form/Delivery';
import ProductDetails from '#components/App/ProductDetails';

const CHANGE_PRICE_TYPE = { UP: 'Up', DOWN: 'Down', CHANGE: 'As' };

const ChangePrice = React.memo(({ count = 0, onChange = () => {} }) => {
  const [form, setForm] = React.useState({
    value: '',
    type: CHANGE_PRICE_TYPE.UP,
  });
  return (
    <Space direction="vertical" style={{ width: '100%' }} size="middle">
      <Typography.Text>
        선택하신 <b style={{ color: '#f00' }}>{count}</b>개 상품에 대해 [판매가]를 일괄 변경합니다.
        <br />
        특정상품의 경우 판매가가 변경이 되지 않을 수 있습니다.
      </Typography.Text>
      <Row align="middle" gutter={10}>
        <Col>
          <Typography.Text>
            판매가<b style={{ color: '#f00' }}>*</b>
          </Typography.Text>
        </Col>
        <Col flex={1}>
          <InputMoney
            value={form.value}
            onChange={(value) => {
              setForm(
                produce((draft) => {
                  draft.value = value;
                }),
              );
            }}
            min={0}
            suffix="원"
          />
        </Col>
        <Col>
          <Select
            value={form.type}
            defaultValue={CHANGE_PRICE_TYPE.UP}
            onChange={(value) => {
              setForm(
                produce((draft) => {
                  draft.type = value;
                }),
              );
            }}
            options={[
              { label: '인상', value: CHANGE_PRICE_TYPE.UP },
              { label: '인하', value: CHANGE_PRICE_TYPE.DOWN },
              { label: '으로 변경', value: CHANGE_PRICE_TYPE.CHANGE },
            ]}
            style={{ width: 120 }}
          />
        </Col>
      </Row>
      <Row justify="end" style={{ marginBottom: -25 }} gutter={10}>
        <Col>
          <Button
            onClick={() => {
              Modal.destroyAll();
            }}
          >
            취소
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              onChange(form);
              Modal.destroyAll();
            }}
            disabled={!(form.value > 0)}
          >
            변경
          </Button>
        </Col>
      </Row>
    </Space>
  );
});

const ChangeDate = React.memo(({ count = 0, onChange = () => {} }) => {
  const [form, setForm] = React.useState({
    isSet: false,
    startAt: moment().format('YYYY-MM-DD'),
    endAt: moment().add(30, 'day').format('YYYY-MM-DD'),
  });
  const disabled = React.useMemo(
    () => form.isSet && (!form.startAt || !form.endAt),
    [form.endAt, form.isSet, form.startAt],
  );
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Typography.Text>
        선택하신 <b style={{ color: '#f00' }}>{count}</b>개 상품에 대해 [판매기간]를 일괄 변경합니다.
      </Typography.Text>
      <Descriptions colon={false}>
        <Descriptions.Item
          labelStyle={{ paddingTop: 5 }}
          label={
            <>
              판매기간<b style={{ color: '#f00' }}>*</b>
            </>
          }
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <Radio.Group
              value={form.isSet}
              onChange={(event) => {
                setForm(
                  produce((draft) => {
                    draft.isSet = event.target.value;
                  }),
                );
              }}
              options={[
                { label: '설정함', value: true },
                { label: '설정안함', value: false },
              ]}
              optionType="button"
              buttonStyle="solid"
            />
            {form.isSet && (
              <>
                <Radio.Group
                  value={moment(form.endAt).diff(form.startAt, 'day')}
                  onChange={(event) => {
                    setForm(
                      produce((draft) => {
                        draft.startAt = moment().format('YYYY-MM-DD');
                        draft.endAt = moment().add(event.target.value, 'day').format('YYYY-MM-DD');
                      }),
                    );
                  }}
                  options={[3, 7, 15, 30, 60, 90, 120].map((value) => ({
                    label: `${value}일`,
                    value,
                  }))}
                  optionType="button"
                  buttonStyle="solid"
                />
                <DatePicker.RangePicker
                  value={[moment(form.startAt), moment(form.endAt)]}
                  onChange={([startAt, endAt]) => {
                    setForm(
                      produce((draft) => {
                        draft.startAt = startAt.format('YYYY-MM-DD');
                        draft.endAt = endAt.format('YYYY-MM-DD');
                      }),
                    );
                  }}
                />
              </>
            )}
          </Space>
        </Descriptions.Item>
      </Descriptions>
      <Row justify="end" style={{ marginBottom: -25 }} gutter={10}>
        <Col>
          <Button
            onClick={() => {
              Modal.destroyAll();
            }}
          >
            취소
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              onChange(form);
              Modal.destroyAll();
            }}
            disabled={disabled}
          >
            변경
          </Button>
        </Col>
      </Row>
    </Space>
  );
});

// const ChangeDelivery = React.memo(({ onChange, onClose }) => {
//   const initialForm = React.useMemo(
//     () => ({
//       template: null,
//       templateName: '',
//       isTemplateSave: null,
//       deliveryType: DELIVERY_TYPE.PARCEL,
//       isVisit: false,
//       sellerAddr: null,
//       isQuick: false,
//       deliveryDetails: '',
//       deliveryMethodType: DELIVERY_METHOD_TYPE.DEFAULT,
//       isCheckOrder: false,
//       deliveryDate: null,
//       deliveryPriceType: DELIVERY_PRICE_TYPE.FREE,
//       deliveryCompany: null,
//       isDefaultDeliveryCompany: false,
//       deliveryPrice: '',
//       deliveryPriceSum: null,
//       deliveryPriceRepeat: null,
//       deliveryPaymentType: '',
//       deliverySpecialPrice: '',
//       forwardingLocation: null,
//       returnDeliveryCompany: null,
//       returnDeliveryPrice: '',
//       exchangeDeliveryPrice: '',
//       returnAddr: null,
//     }),
//     [],
//   );
//   const [form, setForm] = React.useState(initialForm);
//   const handleSubmit = React.useCallback(() => {
//     function exception(error) {
//       Modal.warn({
//         title: '알림',
//         content: error,
//         centered: true,
//       });
//     }

//     // 방문수령
//     if (form.isVisit === true) {
//       if (!form.sellerAddr) return exception('판매자 주소를 등록하세요.');
//     }

//     // 택배사
//     if (!form.deliveryCompany) return exception('배송 택배사를 선택하세요.');

//     // 상품별 배송비
//     switch (form.deliveryPriceType) {
//       // 무료
//       case DELIVERY_PRICE_TYPE.FREE:
//         break;
//       // 유료
//       case DELIVERY_PRICE_TYPE.CHARGED:
//         if (form.deliveryPrice === '') return exception('기본 배송비를 입력하세요.');
//         if (form.deliveryPaymentType === '') return exception('결제방식을 선택하세요.');
//         break;
//       // 조건부 무료
//       case DELIVERY_PRICE_TYPE.CONDITIONALLY_FREE:
//         if (form.deliveryPrice === '') return exception('기본 배송비를 입력하세요.');
//         if (form.deliveryPriceSum === '') return exception('배송비 조건을 입력하세요.');
//         if (form.deliveryPaymentType === '') return exception('결제방식을 선택하세요.');
//         break;
//       // 수량별
//       case DELIVERY_PRICE_TYPE.BY_COUNT:
//         if (form.deliveryPrice === '') return exception('기본 배송비를 입력하세요.');
//         if (form.deliveryPriceRepeat === '') return exception('배송비 조건을 입력하세요.');
//         if (form.deliveryPaymentType === '') return exception('결제방식을 선택하세요.');
//         break;
//     }

//     // 제주/도서상간 추가배송비
//     if (form.deliverySpecialPrice === '') return exception('제주/도서상간 추가배송비를 입력하세요.');

//     // 별도 설치비
//     if (form.isInstallationAmount === null) return exception('별도 설치비를 선택하세요.');

//     // 출고지
//     if (!form.forwardingLocation) return exception('출고지를 선택하세요.');

//     // 반품/교환 택배사
//     if (!form.returnDeliveryCompany) return exception('반품 택배사를 선택하세요.');

//     // 반품 배송비(편도)
//     if (form.returnDeliveryPrice === '') return exception('반품 배송비(편도)를 입력하세요.');

//     // 교환 배송비(편도)
//     if (form.exchangeDeliveryPrice === '') return exception('교환 배송비(편도)를 입력하세요.');

//     // 반품/교환지
//     if (!form.returnAddr) return exception('반품/교환지를 입력하세요.');

//     onChange(form);
//   }, [form, onChange]);

//   return (
//     <Space direction="vertical" style={{ width: '100%' }}>
//       <StoreGoodsForm.Delivery form={form} setForm={setForm} />
//       <StoreGoodsForm.DeliveryChange form={form} setForm={setForm} />
//       <Row justify="end" gutter={10}>
//         <Col>
//           <Button onClick={onClose}>취소</Button>
//         </Col>
//         <Col>
//           <Button type="primary" onClick={handleSubmit}>
//             변경
//           </Button>
//         </Col>
//       </Row>
//     </Space>
//   );
// });

function GoodsPage() {
  const categoriesTree = useSelector((selector) => selector.categories?.product?.tree ?? [], shallowEqual);
  const { map: codesMap, tree: codesTree } = useSelector((selector) => selector.codes, shallowEqual);
  const saleStateCodesOptions = React.useMemo(
    () => codesTree?.PSS?.items?.map(({ code, label }) => ({ label, value: code })) ?? [],
    [codesTree],
  );
  const tableRef = React.useRef();
  // const [isVisibleDeliveryModal, setIsVisibleDeliveryModal] = React.useState(false);
  const isDisplayOptions = React.useMemo(
    () => [
      { label: '전시중', value: true },
      { label: '전시중지', value: false },
    ],
    [],
  );
  const save = useFetch();
  const initialParams = React.useMemo(
    () => ({
      dateType: null,
      startAt: null,
      endAt: null,
      saleStateCodes: saleStateCodesOptions?.map((option) => option.value) ?? [],
      productName: '',
      productId: '',
      businessName: '',
      category1: null,
      category2: null,
      category3: null,
      category4: null,
      isDisplay: null,
    }),
    [saleStateCodesOptions],
  );
  const [params, setParams] = React.useState(initialParams);
  const isAllSaleStateCodes = React.useMemo(
    () => saleStateCodesOptions.length === params.saleStateCodes.length,
    [saleStateCodesOptions, params.saleStateCodes],
  );
  const [originDataSource, setOriginDataSource] = React.useState([]);
  const [dataSource, setDataSource] = React.useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [form, setForm] = React.useState({
    saleStateCode: undefined,
    isDisplay: undefined,
  });
  const categoryOptions = React.useMemo(
    () =>
      [...Array(4)].reduce(
        (p, _, i) => {
          if (params[`category${i}`]) {
            const c = p[i - 1].find(({ value }) => value === params[`category${i}`]);
            p.push(c.items.map(({ code, label, items }) => ({ label, value: code, items })));
          }
          return p;
        },
        [categoriesTree.map(({ code, label, items }) => ({ label, value: code, items }))],
      ),
    [categoriesTree, params],
  );

  const handleParams = React.useCallback((query) => {
    const categoryArr = [query?.category1, query?.category2, query?.category3, query?.category4].filter(Boolean);
    const category = categoryArr[categoryArr.length - 1];
    return { category };
  }, []);

  const handleSave = React.useCallback(async () => {
    const changedDataSource = dataSource.reduce((p, c, i) => {
      const obj = { ...c };
      if (selectedRowKeys.includes(obj.productId)) {
        if (form.saleStateCode !== undefined) {
          obj.saleState = codesMap[form.saleStateCode];
          obj.saleStateCode = form.saleStateCode;
        }
        if (form.isDisplay !== undefined) {
          obj.isDisplay = form.isDisplay;
        }
      }
      if (JSON.stringify(originDataSource[i]) !== JSON.stringify(obj)) {
        p.push(obj);
      }
      return p;
    }, []);
    const count = changedDataSource.length;

    if (!(count > 0)) return Modal.warn({ title: '일괄변경', content: '변경 사항이 없습니다.', centered: true });

    try {
      await save.fetch(
        Promise.all(
          changedDataSource.map((data) => {
            const originData = originDataSource.find((value) => value.productId === data.productId);
            const requestBody = {};
            if (originData) {
              Object.entries(data).forEach(([key, value]) => {
                if (originData[key] !== value) {
                  requestBody[key] = value;
                }
              });
            }
            return restApi.put(`/cms/products/${data.productId}/instant`, requestBody);
          }),
        ),
      );
      tableRef?.current?.loadData();
      Modal.info({
        title: '일괄변경 결과',
        content: (
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Text>
              총 <b style={{ color: '#f00' }}>{count}</b>개 상품의 정보가 변경되었습니다.
            </Typography.Text>
            <Table
              dataSource={changedDataSource}
              columns={[{ title: '상품번호', dataIndex: 'productId', align: 'left' }]}
              rowKey={({ productId }) => productId}
              pagination={false}
            />
          </Space>
        ),
      });
      setForm(
        produce((draft) => {
          draft.isDisplay = undefined;
          draft.saleStateCode = undefined;
        }),
      );
      setSelectedRowKeys([]);
      const result = dataSource.map((data) => {
        const changedData = changedDataSource.find((changedData) => changedData.productId === data.productId);

        if (changedData) {
          return changedData;
        }

        return data;
      });
      setOriginDataSource(result);
      setDataSource(result);
    } catch (error) {
      handleError(error);
      Modal.warn({ title: '일괄변경 결과', content: '데이터 변경에 실패했습니다.', centered: true });
    }
  }, [dataSource, form.isDisplay, form.saleStateCode, originDataSource, selectedRowKeys]);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {/* <Modal
        visible={isVisibleDeliveryModal}
        title="배송 변경"
        centered
        width={1200}
        footer={null}
        onCancel={() => {
          setIsVisibleDeliveryModal(false);
        }}
        destroyOnClose
      >
        <ChangeDelivery
          onChange={(form) => {
            setIsVisibleDeliveryModal(false);
            setDataSource(
              produce((draft) => {
                selectedRowKeys.forEach((key) => {
                  const _index = draft.findIndex(({ productId }) => productId === key);
                  if (_index > -1) {
                    draft[_index].methods = form.deliveryType;
                    draft[_index].isVisit = Boolean(form.isVisit);
                    draft[_index].sellerAddress = form.isVisit ? String(form.sellerAddr?.jibunAddress ?? '') : null;
                    draft[_index].sellerZipcode = form.isVisit ? String(form.sellerAddr?.zonecode ?? '') : null;
                    draft[_index].hasQuick = Boolean(form.isQuick);
                    draft[_index].deliveryInfo = String(form.deliveryDetails ?? '');
                    draft[_index].isMakeAfterOrder = Boolean(form.isCheckOrder);
                    draft[_index].periods = Number(form.deliveryDate ?? 0);
                    draft[_index].deliveryFreeType = form.deliveryPriceType
                      ? String(codesMap[form.deliveryPriceType])
                      : null;
                    draft[_index].deliveryFreeTypeCode = String(form.deliveryPriceType);
                    draft[_index].deliveryPriceType = form.deliveryPriceType
                      ? String(codesMap[form.deliveryPriceType])
                      : null;
                    draft[_index].deliveryPriceTypeCode = form.deliveryPriceType
                      ? String(form.deliveryPriceType)
                      : null;
                    draft[_index].courier = form.deliveryCompany ? String(codesMap[form.deliveryCompany]) : null;
                    draft[_index].courierCode = String(form.deliveryCompany);
                    draft[_index].deliveryAmount =
                      form.deliveryPriceType !== DELIVERY_PRICE_TYPE.FREE ? Number(form.deliveryPrice) : null;
                    draft[_index].deliveryFreeAmount =
                      form.deliveryPriceType === DELIVERY_PRICE_TYPE.CONDITIONALLY_FREE
                        ? Number(form.deliveryPriceSum)
                        : null;
                    draft[_index].deliveryFreeCount =
                      form.deliveryPriceType === DELIVERY_PRICE_TYPE.BY_COUNT ? Number(form.deliveryPriceRepeat) : null;
                    draft[_index].deliveryPaymentType =
                      form.deliveryPriceType !== DELIVERY_PRICE_TYPE.FREE
                        ? form.deliveryPaymentType
                          ? String(codesMap[form.deliveryPaymentType])
                          : null
                        : null;
                    draft[_index].deliveryPaymentTypeCode =
                      form.deliveryPriceType !== DELIVERY_PRICE_TYPE.FREE ? String(form.deliveryPaymentType) : null;
                    draft[_index].extraAmount = Number(form.deliverySpecialPrice ?? 0);
                    draft[_index].cargoAddress = String(form.forwardingLocation?.jibunAddress ?? '');
                    draft[_index].cargoZipcode = String(form.forwardingLocation?.zonecode ?? '');
                    draft[_index].refundCourier = form.returnDeliveryCompany
                      ? String(codesMap[form.returnDeliveryCompany])
                      : null;
                    draft[_index].refundCourierCode = String(form.returnDeliveryCompany);
                    draft[_index].refundDeliveryAmount = Number(form.returnDeliveryPrice);
                    draft[_index].changeDeliveryAmount = Number(form.exchangeDeliveryPrice);
                    draft[_index].refundAddress = String(form.returnAddr?.jibunAddress ?? '');
                    draft[_index].refundZipcode = String(form.returnAddr?.zonecode ?? '');
                  }
                });
              }),
            );
          }}
          onClose={() => {
            setIsVisibleDeliveryModal(false);
          }}
        />
      </Modal> */}
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initialParams);
          return initialParams;
        }}
      >
        <Row gutter={[50, 15]}>
          <Col span={24}>
            <Space style={{ width: '100%' }}>
              <Select
                placeholder="날짜 옵션"
                value={params.dateType}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.dateType = value;
                    }),
                  );
                }}
                // defaultValue="CREATED_AT"
                options={[
                  { label: '등록일', value: 'CREATED_AT' },
                  { label: '최종 수정일', value: 'UPDATED_AT' },
                ]}
                style={{ width: 120 }}
              />
              <DatePicker.RangePicker
                disabled={!params.dateType}
                value={[params.startAt ? moment(params.startAt) : null, params.endAt ? moment(params.endAt) : null]}
                onChange={([startAt, endAt]) => {
                  setParams(
                    produce((draft) => {
                      draft.startAt = startAt.format('YYYY-MM-DD');
                      draft.endAt = endAt.format('YYYY-MM-DD');
                    }),
                  );
                }}
              />
              <Radio.Group
                disabled={!params.dateType}
                value={moment(params.endAt).diff(params.startAt, 'day')}
                onChange={(event) => {
                  setParams(
                    produce((draft) => {
                      draft.startAt = moment().add(-event.target.value, 'day').format('YYYY-MM-DD');
                      draft.endAt = moment().format('YYYY-MM-DD');
                    }),
                  );
                }}
                options={[
                  { label: '오늘', value: 0 },
                  { label: '일주일', value: 7 },
                  { label: '1개월', value: 30 },
                  { label: '3개월', value: 90 },
                ]}
                optionType="button"
                buttonStyle="solid"
              />
            </Space>
          </Col>
          <Col>
            <Form.Item label="판매상태">
              <Space>
                <Checkbox
                  checked={isAllSaleStateCodes}
                  onChange={(event) => {
                    setParams(
                      produce((draft) => {
                        draft.saleStateCodes = event.target.checked
                          ? saleStateCodesOptions.map(({ value }) => value)
                          : [];
                      }),
                    );
                  }}
                >
                  전체
                </Checkbox>
                {saleStateCodesOptions?.map((option) => {
                  const isChecked = params.saleStateCodes.includes(option.value);
                  return (
                    <Checkbox
                      key={option.value}
                      value={option.value}
                      checked={!isAllSaleStateCodes && isChecked}
                      onChange={(event) => {
                        setParams(
                          produce((draft) => {
                            if (isAllSaleStateCodes) {
                              draft.saleStateCodes = [event.target.value];
                            } else if (isChecked) {
                              draft.saleStateCodes = Array.isArray(draft.saleStateCodes)
                                ? draft.saleStateCodes.filter((value) => value !== event.target.value)
                                : [];
                            } else {
                              Array.isArray(draft.saleStateCodes)
                                ? draft.saleStateCodes.push(event.target.value)
                                : (draft.saleStateCodes = [draft.saleStateCodes, event.target.value]);
                            }
                          }),
                        );
                      }}
                    >
                      {option.label}
                    </Checkbox>
                  );
                })}
              </Space>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="전시상태">
              <Space>
                <Checkbox
                  checked={params.isDisplay === null}
                  onChange={() => {
                    setParams(
                      produce((draft) => {
                        draft.isDisplay = null;
                      }),
                    );
                  }}
                >
                  전체
                </Checkbox>
                <Checkbox
                  checked={params.isDisplay === true || params.isDisplay === 'true'}
                  onChange={(event) => {
                    setParams(
                      produce((draft) => {
                        draft.isDisplay = event.target.checked ? true : null;
                      }),
                    );
                  }}
                >
                  전시중
                </Checkbox>
                <Checkbox
                  checked={params.isDisplay === false || params.isDisplay === 'false'}
                  onChange={(event) => {
                    setParams(
                      produce((draft) => {
                        draft.isDisplay = event.target.checked ? false : null;
                      }),
                    );
                  }}
                >
                  전시중지
                </Checkbox>
              </Space>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Space>
                {[...Array(4)].map((_, index, array) => {
                  return (
                    <Select
                      key={index}
                      style={{ width: 200 }}
                      value={params[`category${index + 1}`] ? params[`category${index + 1}`] : null}
                      allowClear
                      onChange={(value) => {
                        setParams(
                          produce((draft) => {
                            draft[`category${index + 1}`] = value;
                            for (let i = 0; i < array.length; i++) {
                              if (i > index) {
                                draft[`category${i + 1}`] = null;
                              }
                            }
                          }),
                        );
                      }}
                      disabled={!categoryOptions[index]}
                      options={categoryOptions[index]}
                      placeholder={`카테고리${index + 1}`}
                    />
                  );
                })}
              </Space>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="상품명">
              <Input
                value={params.productName}
                onChange={(event) => {
                  setParams(
                    produce((draft) => {
                      draft.productName = event.target.value;
                    }),
                  );
                }}
                placeholder="키워드를 입력하세요."
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="상품번호">
              <Input
                value={params.productId}
                onChange={(event) => {
                  setParams(
                    produce((draft) => {
                      draft.productId = event.target.value;
                    }),
                  );
                }}
                placeholder="키워드를 입력하세요."
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="파트너사">
              <Input
                value={params.businessName}
                onChange={(event) => {
                  setParams(
                    produce((draft) => {
                      draft.businessName = event.target.value;
                    }),
                  );
                }}
                placeholder="키워드를 입력하세요."
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="end">
              <Col>
                <Space>
                  <Button type="primary" htmlType="submit">
                    검색
                  </Button>
                  <Button htmlType="reset">초기화</Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <CommTable
          ref={tableRef}
          url="/cms/products"
          params={handleParams}
          renderHeader={
            <Row justify="space-between">
              <Col>
                <Space size="middle">
                  <Button
                    type="danger"
                    disabled={selectedRowKeys?.length === 0}
                    onClick={() => {
                      Modal.confirm({
                        title: '상품 삭제',
                        closable: true,
                        content: (
                          <>
                            선택하신 상품이 전부 삭제됩니다 <br />
                            계속 하시겠습니까?
                          </>
                        ),
                        onOk: async () => {
                          try {
                            const deleteArr = selectedRowKeys.map((productId) =>
                              restApi.delete(`/cms/products/${productId}`),
                            );
                            await Promise.all(deleteArr);

                            tableRef?.current?.loadData();

                            Modal.success({
                              title: '성공',
                              content: '상품을삭제 했습니다.',
                              onOk: Modal.destroyAll,
                            });
                          } catch (e) {}
                        },
                        centered: true,
                      });
                    }}
                  >
                    선택삭제
                  </Button>
                  <Divider type="vertical" />
                  <Select
                    style={{ width: 120 }}
                    value={form.saleStateCode}
                    onChange={(value) => {
                      setForm(
                        produce((draft) => {
                          draft.saleStateCode = value;
                        }),
                      );
                    }}
                    placeholder="판매상태변경"
                    options={saleStateCodesOptions}
                    allowClear
                  />
                  <Select
                    style={{ width: 120 }}
                    value={form.isDisplay}
                    onChange={(value) => {
                      setForm(
                        produce((draft) => {
                          draft.isDisplay = value;
                        }),
                      );
                    }}
                    placeholder="전시상태변경"
                    options={isDisplayOptions}
                    allowClear
                  />
                  <Divider type="vertical" style={{ height: 28 }} />
                  <Button
                    onClick={() => {
                      if (selectedRowKeys.length > 0) {
                        Modal.info({
                          title: '판매가 변경',
                          closable: true,
                          content: (
                            <ChangePrice
                              count={selectedRowKeys.length}
                              onChange={async ({ value, type }) => {
                                try {
                                  await restApi.put('/cms/products/price', {
                                    amount: value,
                                    productIds: selectedRowKeys,
                                    type: type,
                                  });
                                  setDataSource(
                                    produce((draft) => {
                                      selectedRowKeys.forEach((key) => {
                                        const _index = draft.findIndex(({ productId }) => productId === key);
                                        if (_index > -1) {
                                          switch (type) {
                                            // 인상
                                            case CHANGE_PRICE_TYPE.UP:
                                              draft[_index].price += value;
                                              break;
                                            // 인하
                                            case CHANGE_PRICE_TYPE.DOWN:
                                              draft[_index].price -= value;
                                              break;
                                            // 으로 변경
                                            case CHANGE_PRICE_TYPE.CHANGE:
                                              draft[_index].price = value;
                                              break;
                                            default:
                                          }
                                        }
                                      });
                                    }),
                                  );
                                } catch (e) {
                                  console.warn('failed change prices :::::: ', e);
                                  Modal.destroyAll();
                                  Modal.error({
                                    content: '상품의 가격 변경에 실패했습니다. 관리자에 문의해주세요.',
                                    centered: true,
                                  });
                                }
                              }}
                            />
                          ),
                          centered: true,
                          width: 500,
                          okButtonProps: { style: { display: 'none' } },
                        });
                      } else {
                        Modal.warn({
                          title: '판매가 변경',
                          content: '상품을 1개 이상 선택하세요.',
                          centered: true,
                        });
                      }
                    }}
                  >
                    판매가 변경
                  </Button>
                  <Button
                    onClick={() => {
                      if (selectedRowKeys.length > 0) {
                        Modal.info({
                          title: '판매기간 변경',
                          closable: true,
                          content: (
                            <ChangeDate
                              count={selectedRowKeys.length}
                              onChange={async ({ isSet, startAt, endAt }) => {
                                try {
                                  await restApi.put('/cms/products/sale-date', {
                                    isSetting: isSet,
                                    productIds: selectedRowKeys,
                                    startAt,
                                    endAt,
                                  });
                                  setDataSource(
                                    produce((draft) => {
                                      selectedRowKeys.forEach((key) => {
                                        const _index = draft.findIndex(({ productId }) => productId === key);
                                        if (_index > -1) {
                                          draft[_index].hasSetSalesPeriod = isSet;
                                          draft[_index].startAt = isSet ? startAt : null;
                                          draft[_index].endAt = isSet ? endAt : null;
                                        }
                                      });
                                    }),
                                  );
                                } catch (e) {
                                  Modal.destroyAll();
                                  Modal.error({
                                    content: '상품 날짜 변경에 실패했습니다. 잠시후 시도해주세요.',
                                    centered: true,
                                    okText: '확인',
                                  });
                                }
                              }}
                            />
                          ),
                          centered: true,
                          width: 650,
                          okButtonProps: { style: { display: 'none' } },
                        });
                      } else {
                        Modal.warn({
                          title: '판매가 변경',
                          content: '상품을 1개 이상 선택하세요.',
                          centered: true,
                        });
                      }
                    }}
                  >
                    판매기간 변경
                  </Button>
                  {/* <Button
                    onClick={() => {
                      if (selectedRowKeys.length > 0) {
                        setIsVisibleDeliveryModal(true);
                      } else {
                        Modal.warn({
                          title: '판매가 변경',
                          content: '상품을 1개 이상 선택하세요.',
                          centered: true,
                        });
                      }
                    }}
                  >
                    배송 변경
                  </Button> */}
                </Space>
              </Col>
              <Col>
                <Button type="primary" onClick={handleSave} loading={save.loading}>
                  수정사항 저장
                </Button>
              </Col>
            </Row>
          }
          columns={[
            {
              title: '수정',
              dataIndex: 'productId',
              fixed: 'left',
              width: 80,
              render: (value) => (
                <Link to={`/store/goods/modify/${value}`}>
                  <Button>수정</Button>
                </Link>
              ),
            },
            {
              title: '복사',
              dataIndex: 'productId',
              fixed: 'left',
              width: 80,
              render: (value) => (
                <Link to={`/store/goods/copy/${value}`}>
                  <Button>복사</Button>
                </Link>
              ),
            },
            {
              title: '상품번호',
              dataIndex: 'productId',
              fixed: 'left',
              width: 120,
            },
            {
              title: '파트너사명',
              dataIndex: 'partnerName',
              align: 'left',
              width: 300,
            },
            {
              title: '상품명',
              dataIndex: 'productName',
              align: 'left',
              width: 300,
              ellipsis: true,
            },
            {
              title: '견적의뢰',
              dataIndex: 'isQuotation',
              width: 80,
              render: (value) => (value ? 'Y' : 'N'),
            },
            {
              title: '상세설명',
              dataIndex: 'productId',
              width: 100,
              render: (value) => (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.info({
                      title: '상세설명',
                      width: 550,
                      content: <ProductDetails productId={value} />,
                      centered: true,
                      closable: true,
                    });
                  }}
                >
                  상세설명
                </Button>
              ),
            },
            {
              title: '판매상태',
              dataIndex: 'saleState',
              width: 80,
              render: (value, record) => {
                const isChecked = selectedRowKeys.includes(record.productId);
                if (isChecked && form.saleStateCode && codesMap[form.saleStateCode] !== value) {
                  return <b className="changed">{codesMap[form.saleStateCode]}</b>;
                }
                return value;
              },
            },
            {
              title: '전시상태',
              dataIndex: 'isDisplay',
              width: 120,
              render: (value, record, index) => {
                const isChecked = selectedRowKeys.includes(record.productId);
                const isChanged =
                  originDataSource[index]?.isDisplay !== value ||
                  (isChecked && form.isDisplay && form.isDisplay !== originDataSource[index]?.isDisplay);

                return (
                  <Select
                    className={isChanged ? 'changed' : ''}
                    value={isChecked && form.isDisplay !== undefined ? form.isDisplay : value}
                    onChange={(value) => {
                      setDataSource(
                        produce((draft) => {
                          draft[index].isDisplay = value;
                        }),
                      );
                    }}
                    options={isDisplayOptions}
                    style={{ width: 95 }}
                  />
                );
              },
            },
            {
              title: '재고수량',
              dataIndex: 'count',
              width: 80,
              render: (value, record, index) => {
                const isChanged = originDataSource[index]?.count !== value;
                return (
                  <InputMoney
                    className={isChanged ? 'changed' : ''}
                    value={value}
                    disabled={!!record.isOption}
                    onChange={(value) => {
                      setDataSource(
                        produce((draft) => {
                          draft[index].count = value;
                        }),
                      );
                    }}
                  />
                );
              },
            },
            {
              title: '판매가',
              dataIndex: 'price',
              width: 120,
              render: (value, record, index) => {
                const isChanged = originDataSource[index]?.price !== value;
                if (isChanged) {
                  return <b className="changed">{formatComma(value)}</b>;
                }
                return formatComma(value);
              },
            },
            {
              title: '할인가',
              dataIndex: 'discountAmount',
              width: 120,
              render: (value) => formatComma(value),
            },
            {
              title: '옵션',
              dataIndex: 'isOption',
              width: 80,
              render: (value) => (value ? 'Y' : 'N'),
            },
            {
              title: '배송비유형',
              dataIndex: 'deliveryFreeType',
              width: 120,
              render: (value, record, index) =>
                originDataSource[index]?.deliveryFreeType !== value ? <b className="changed">{value}</b> : value,
            },
            {
              title: '배송비결제방식',
              dataIndex: 'deliveryPaymentType',
              width: 120,
              render: (value, record, index) =>
                originDataSource[index]?.deliveryPaymentType !== value ? <b className="changed">{value}</b> : value,
            },
            {
              title: '기본배송비',
              dataIndex: 'deliveryAmount',
              width: 100,
              render: (value, record, index) =>
                originDataSource[index]?.deliveryAmount !== value ? (
                  <b className="changed">{`${formatComma(value)}원`}</b>
                ) : (
                  `${formatComma(value)}원`
                ),
            },
            {
              title: '반품배송비',
              dataIndex: 'refundDeliveryAmount',
              width: 100,
              render: (value, record, index) =>
                originDataSource[index]?.refundDeliveryAmount !== value ? (
                  <b className="changed">{`${formatComma(value)}원`}</b>
                ) : (
                  `${formatComma(value)}원`
                ),
            },
            {
              title: '교환배송비',
              dataIndex: 'changeDeliveryAmount',
              width: 100,
              render: (value, record, index) =>
                originDataSource[index]?.changeDeliveryAmount !== value ? (
                  <b className="changed">{`${formatComma(value)}원`}</b>
                ) : (
                  `${formatComma(value)}원`
                ),
            },
            {
              title: '카테고리1',
              dataIndex: 'category1',
              width: 120,
            },
            {
              title: '카테고리2',
              dataIndex: 'category2',
              width: 120,
            },
            {
              title: '카테고리3',
              dataIndex: 'category3',
              width: 120,
            },
            {
              title: '카테고리4',
              dataIndex: 'category4',
              width: 120,
            },
            {
              title: '판매시작일',
              dataIndex: 'startAt',
              width: 140,
              render: (value, record, index) =>
                originDataSource[index]?.startAt !== value ? <b className="changed">{value}</b> : value,
            },
            {
              title: '판매종료일',
              dataIndex: 'endAt',
              width: 140,
              render: (value, record, index) =>
                originDataSource[index]?.endAt !== value ? <b className="changed">{value}</b> : value,
            },
            {
              title: '상품등록일',
              dataIndex: 'createdAt',
              width: 140,
            },
            {
              title: '최종수정일',
              dataIndex: 'updatedAt',
              width: 140,
            },
          ]}
          rowSelection={{
            selectedRowKeys,
            onChange: setSelectedRowKeys,
          }}
          scroll={{ x: 1920 }}
          rowKey={({ productId }) => productId}
          dataSource={dataSource}
          onLoadData={({ results }) => {
            setOriginDataSource(results);
            setDataSource(results);
            setSelectedRowKeys([]);
          }}
        />
      </SearchForm>
    </Space>
  );
}

export default GoodsPage;
