import produce from 'immer';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Divider, Input, Row, Select, Space } from 'antd';
import SearchForm from '#components/SearchForm';
import CommTable from '#components/table/CommTable';

const SEARCH_TYPE = {
  CODE: 'CODE',
  LABEL: 'LABEL',
};

function MangementCommonCode() {
  const initialParams = React.useMemo(
    () => ({
      isActive: undefined,
      keyword: '',
      searchType: SEARCH_TYPE.LABEL,
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);

  return (
    <Space style={{ width: '100%', maxWidth: 800 }} direction="vertical">
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initialParams);
          return initialParams;
        }}
      >
        <Row gutter={10}>
          <Col>
            <Select
              value={params.isActive}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.isActive = value;
                  }),
                );
              }}
              options={[
                { label: '사용', value: 'true' },
                { label: '미사용', value: 'false' },
              ]}
              placeholder="사용여부"
              allowClear
              style={{ width: 120 }}
            />
          </Col>
          <Col>
            <Select
              value={params.searchType}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.searchType = value;
                  }),
                );
              }}
              options={[
                { label: '코드그룹명', value: SEARCH_TYPE.CODE },
                { label: '코드', value: SEARCH_TYPE.LABEL },
              ]}
              style={{ width: 120 }}
            />
          </Col>
          <Col>
            <Input
              value={params.keyword}
              onChange={(event) => {
                setParams(
                  produce((draft) => {
                    draft.keyword = event.target.value;
                  }),
                );
              }}
              placeholder="키워드를 입력하세요."
              style={{ width: 200 }}
            />
          </Col>
          <Col>
            <Space>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Col>
        </Row>
      </SearchForm>
      <Divider />
      <CommTable
        url="/cms/codes/group"
        columns={({ limit, pageCur }) => [
          {
            title: '번호',
            dataIndex: 'id',
            width: 80,
            render: (_, $, index) => {
              return limit * pageCur + index + 1;
            },
          },
          {
            title: '코드 그룹명',
            dataIndex: 'label',
            align: 'left',
            render: (value, row) => <Link to={`/management/common_code/detail/${row.codeGroup}`}>{value}</Link>,
          },
          {
            title: '코드',
            dataIndex: 'codeGroup',
            width: 100,
          },
          {
            title: '사용여부',
            dataIndex: 'isActive',
            width: 120,
            render: (value) => {
              return value ? '사용' : '미사용';
            },
          },
          {
            title: '등록일',
            dataIndex: 'createdAt',
            width: 120,
            render: (createdAt) => (createdAt ? moment(createdAt).format('YYYY-MM-DD') : ''),
          },
        ]}
        headerExtra={
          <Link to="/management/common_code/regist">
            <Button type="primary">등록</Button>
          </Link>
        }
      />
    </Space>
  );
}

export default MangementCommonCode;
