import React from 'react';
import { Button, Modal, Space, Descriptions } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from '../../Page.module.less';
import { restApi } from '../../../apis';
import handleError from '#utils/handleError';
import FileUpload from '../../../components/datalist/FileUpload';

const cx = classNames.bind(styles);

function ReviewDetail(props) {
  const navigate = useNavigate();
  const { reviewId } = useParams();
  const [reviewInfo, setReviewInfo] = React.useState(null);

  const init = React.useCallback(() => {
    restApi
      .get(`/cms/community-boards/${reviewId}`)
      .then(({ data }) => {
        const { images, ...rest } = data;
        const newReviewInfo = { ...rest };

        if (images) {
          newReviewInfo.images = images.map((value) => Number(value.split('/').reverse()[0]));
        }
        setReviewInfo(newReviewInfo);
      })
      .catch(handleError);
  }, [reviewId]);

  const handleToggleIsSecret = React.useCallback(() => {
    Modal.confirm({
      content: ` ${reviewInfo?.isSecret ? '' : '비'}공개처리 하시겠습니까?`,
      onOk: async () => {
        try {
          await restApi.put(`/cms/community-boards/${reviewId}/secret`);
          init();
        } catch (e) {
          handleError(e);
        }
      },
    });
  }, [reviewInfo, init, reviewId]);

  React.useEffect(() => {
    if (!reviewId) return;
    init();
  }, [reviewId, init]);

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="제목">
            {reviewInfo?.title}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="회원아이디">
            {reviewInfo?.userId}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="작성자명">
            {reviewInfo?.writer}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="태그"></Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="작성내용">
            {reviewInfo?.contents}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="사진,영상">
            {reviewInfo?.images && (
              <Space direction="vertical">
                <FileUpload items={reviewInfo?.images} disabled listType="picture-card" hideUpload />
              </Space>
            )}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="댓글 수">
            {reviewInfo?.replyCount ?? '0'}{' '}
            {/* {typeof reviewInfo?.replyCount === 'number' && reviewInfo.replyCount > 0 && (
              <Button style={{ marginLeft: 20 }} onClick={showReply}>
                댓글보기
              </Button>
            )} */}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="공개 여부">
            {typeof reviewInfo?.isSecret === 'boolean' && (reviewInfo?.isSecret ? '비공개' : '공개')}
            {reviewInfo?.isSecret && reviewInfo?.adminName && reviewInfo?.secretAt && (
              <span>
                ({reviewInfo?.adminName} / {reviewInfo?.secretAt})
              </span>
            )}
            <Button onClick={handleToggleIsSecret} type="primary" style={{ marginLeft: 20 }}>
              {reviewInfo?.isSecret ? '공개' : '비공개'}처리
            </Button>
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button onClick={() => navigate(-1)}>목록</Button>
      </div>
    </div>
  );
}

export default ReviewDetail;
