import React from 'react';
import { Button, Space, Descriptions } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from '../../Page.module.less';
import { restApi } from '../../../apis';
import handleError from '#utils/handleError';
import { useReduxStore } from '../../../hooks/useReduxStore';
import FileUpload from '../../../components/datalist/FileUpload';

const cx = classNames.bind(styles);

// TODO :: 파일 관련 서버랑 이야기 해봐야함
function ReviewStoreDetail(props) {
  const navigate = useNavigate();
  const { map: codeMap } = useReduxStore('codes');
  const { id } = useParams();
  const [inquireInfo, setInquireInfo] = React.useState(null);

  const handleShowDetailStore = React.useCallback(() => {
    if (!inquireInfo?.storeId) return;
    navigate(`/store/management/${inquireInfo.storeId}/detail`);
  }, [navigate, inquireInfo]);

  React.useEffect(() => {
    if (!id) return;

    restApi
      .get(`/cms/store-questions/${id}`)
      .then(({ data }) => {
        setInquireInfo(data);
      })
      .catch(handleError);
  }, [id]);

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="상점명">
            {inquireInfo?.partnerName}
            {inquireInfo?.partnerName && (
              <Button onClick={handleShowDetailStore} style={{ marginLeft: 20 }}>
                상세정보
              </Button>
            )}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="문의분야">
            {codeMap?.[inquireInfo?.storeQuestionTypeCode]}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="회원아이디">
            {inquireInfo?.userId}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="회원이름">
            {inquireInfo?.userName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="문의내용">
            {inquireInfo?.contents}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="첨부파일">
            {inquireInfo?.images && (
              <Space direction="vertical">
                <FileUpload items={inquireInfo?.images} disabled hideUpload />
              </Space>
            )}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변내용">
            {inquireInfo?.answer}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변일">
            {inquireInfo?.answerAt}
          </Descriptions.Item>
          {/* TODO 답변자는 파트너 정보가 와야 되는데 현재는 admin의 정보가 오고 있음 */}
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변자">
            {inquireInfo?.adminName}
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button onClick={() => navigate(-1)}>목록</Button>
      </div>
    </div>
  );
}

export default ReviewStoreDetail;
