import React from 'react';
import { Button, Space, Descriptions } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from '../../../Page.module.less';
import { restApi } from '#apis';
import handleError from '#utils/handleError';
import FileUpload from '#components/datalist/FileUpload';
import { useReduxStore } from '#hooks/useReduxStore';

const cx = classNames.bind(styles);

// TODO :: 데이터 확인해야 됨.
function SalesEstimateDetail() {
  const navigate = useNavigate();
  const { map: codesMap } = useReduxStore('codes');
  const { estimateId } = useParams();

  const [estimateInfo, setEstimateInfo] = React.useState(null);

  const init = React.useCallback(() => {
    restApi
      .get(`/cms/product-estimates/${estimateId}`)
      .then(({ data }) => {
        const { images, ...rest } = data;
        const newEstimateInfo = { ...rest };

        if (images) {
          newEstimateInfo.images = images.map((value) => Number(value.split('/').reverse()[0]));
        }

        setEstimateInfo(newEstimateInfo);
      })
      .catch(handleError);
  }, [estimateId]);

  React.useEffect(() => {
    if (!estimateId) return;
    init();
  }, [estimateId, init]);

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="파트너명">
            {estimateInfo?.storeName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="카테고리">
            {codesMap[estimateInfo?.categoryCode]}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="상품명">
            {estimateInfo?.productName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="회원아이디">
            {estimateInfo?.userId}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="회원이름">
            {' '}
            {estimateInfo?.userName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="의뢰내용">
            {' '}
            {estimateInfo?.contents}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="사진">
            {estimateInfo?.images && (
              <Space direction="vertical">
                <FileUpload items={estimateInfo?.images} disabled listType="picture-card" hideUpload />
              </Space>
            )}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변내용">
            {' '}
            {estimateInfo?.answer}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변일">
            {' '}
            {estimateInfo?.answerAt}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변자">
            {' '}
            {estimateInfo?.adminUserId &&
              estimateInfo?.adminUserName &&
              `${estimateInfo?.adminUserId} / ${estimateInfo?.adminUserName}`}{' '}
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button onClick={() => navigate(-1)}>목록</Button>
      </div>
    </div>
  );
}

export default SalesEstimateDetail;
