import { restApi } from '#apis/index';
import produce from 'immer';
import React from 'react';
import SearchForm from '#components/SearchForm';
import { Button, Col, Divider, Form, Input, Modal, Radio, Row, Select, Space } from 'antd';
import CommTable from '#components/table/CommTable';

const EditableContext = React.createContext(null);

function EditableRow({ ...props }) {
  const [isEdit, setIsEdit] = React.useState(false);
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false} onFinish={() => {}}>
      <EditableContext.Provider value={{ form, isEdit, setIsEdit }}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
}

function EditableCell({ title, children, dataIndex, record, handleSave, editableItem, renderCell, ...props }) {
  const { form, isEdit, setIsEdit } = React.useContext(EditableContext);

  const toggleEdit = React.useCallback(() => {
    form.resetFields();
    setIsEdit((prevState) => !prevState);
  }, [form]);

  return (
    <td {...props}>
      {renderCell ? (
        renderCell({ isEdit, toggleEdit, record, form })
      ) : isEdit ? (
        <Form.Item initialValue={record[dataIndex]} name={dataIndex}>
          {editableItem()}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
}

function ManagementForbiddenWord() {
  const tableRef = React.useRef();
  const initialParams = React.useMemo(
    () => ({
      isActive: undefined,
    }),
    [],
  );
  const [params, setParams] = React.useState({});
  return (
    <Space style={{ width: '100%', maxWidth: 800 }} direction="vertical">
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initialParams);
          return initialParams;
        }}
      >
        <Row gutter={15}>
          <Col>
            <Select
              value={params.isActive}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.isActive = value;
                  }),
                );
              }}
              placeholder="사용 여부"
              style={{ width: 200 }}
              options={[
                { label: '사용', value: 'true' },
                { label: '미사용', value: 'false' },
              ]}
              allowClear
            />
          </Col>
          <Col>
            <Space>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Col>
        </Row>
      </SearchForm>
      <Divider />
      <CommTable
        ref={tableRef}
        url="/cms/forbidden"
        headerExtra={
          <Button
            type="primary"
            onClick={() => {
              const modal = Modal.info({
                title: '금지어 추가',
                width: 450,
                centered: true,
                closable: true,
                content: (
                  <Form
                    onFinish={({ word, isActive }) =>
                      restApi
                        .post('/cms/forbidden', { word, isActive })
                        .then(() => {
                          tableRef.current?.loadData();
                          modal.destroy();
                          Modal.success({
                            title: '금지어 추가',
                            content: '금지어가 추가되었습니다.',
                            centered: true,
                          });
                        })
                        .catch((error) => {
                          Modal.error({
                            title: '금지어 추가',
                            content: error?.response?.data?.message ?? '금지어 추가 실패했습니다.',
                            centered: true,
                          });
                        })
                    }
                  >
                    <Space style={{ width: '100%' }} direction="vertical">
                      <Form.Item
                        label="금지어"
                        name="word"
                        required
                        rules={[{ required: true, message: '금지어를 입력해주세요.' }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="사용여부"
                        name="isActive"
                        required
                        rules={[{ required: true, message: '사용여부를 선택해주세요.' }]}
                      >
                        <Radio.Group
                          options={[
                            { label: '사용', value: true },
                            { label: '미사용', value: false },
                          ]}
                        />
                      </Form.Item>
                      <Row justify="end" style={{ marginBottom: -25 }}>
                        <Col>
                          <Button type="primary" htmlType="submit">
                            금지어 추가
                          </Button>
                        </Col>
                      </Row>
                    </Space>
                  </Form>
                ),
                okButtonProps: { style: { display: 'none' } },
              });
            }}
          >
            금지어 추가
          </Button>
        }
        columns={[
          {
            title: '금지어',
            dataIndex: 'word',
            align: 'left',
            editableItem: () => <Input />,
          },
          {
            title: '사용여부',
            dataIndex: 'active',
            width: 100,
            render: (value) => (value ? '사용' : '미사용'),
            editableItem: () => (
              <Radio.Group
                style={{ width: 150 }}
                options={[
                  { label: '사용', value: true },
                  { label: '미사용', value: false },
                ]}
              />
            ),
          },
          {
            title: '관리',
            width: 100,
            renderCell: ({ isEdit, toggleEdit, record, form }) =>
              isEdit ? (
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      const { word, active } = form.getFieldsValue();
                      restApi
                        .put(`/cms/forbidden/${record.id}`, {
                          word,
                          isActive: active,
                        })
                        .then(() => {
                          Modal.success({
                            title: '금지어 수정',
                            centered: true,
                            content: '금지어가 수정되었습니다.',
                          });
                          toggleEdit();
                          tableRef.current?.loadData();
                        })
                        .catch((error) => {
                          Modal.error({
                            title: '금지어 수정',
                            centered: true,
                            content: error?.response?.data?.message ?? '금지어 수정에 실패했습니다.',
                          });
                        });
                    }}
                    htmlType="submit"
                  >
                    저장
                  </Button>
                  <Button onClick={toggleEdit} htmlType="reset">
                    취소
                  </Button>
                </Space>
              ) : (
                <Button onClick={toggleEdit}>수정</Button>
              ),
          },
        ].map((col) => ({
          ...col,
          onCell: col.onCell ?? ((record) => ({ record, ...col })),
        }))}
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell,
          },
        }}
      />
    </Space>
  );
}

export default ManagementForbiddenWord;
