import React from 'react';
import qs from 'query-string';
import { restApi } from '../apis';
import useQuery from './useQuery';

const useTable = ({ url, params: extra, onFetch, onLoadData = () => {}, then }) => {
  const { query } = useQuery();
  const [data, setData] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);

  const loadData = React.useCallback(async () => {
    const _extra = typeof extra === 'function' ? extra(query) : extra;
    const params = {
      ...query,
      ..._extra,
      limit: query?.limit ?? 10,
    };
    try {
      const { data } = await restApi
        .get(url, {
          params,
          paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
        })
        .then(then);
      if (onFetch) {
        setData(() => onFetch(data));
      } else {
        setData(data);
      }
      if (typeof onLoadData === 'function') {
        onLoadData(data);
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setIsLoading(false);
    }
  }, [url, query, extra]);

  React.useEffect(() => {
    setIsLoading(true);
    loadData();
  }, [loadData]);

  return {
    data,
    setData,
    loadData,
    isLoading,
  };
};

export default useTable;
