import { restApi } from '#apis/index';
import FetchElement from '#components/FetchElement';
import { Button, Col, Descriptions, Modal, Row, Space, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

function ContentsRecommendTagsDetail() {
  const params = useParams();
  const id = React.useMemo(() => params?.id, [params?.id]);

  return (
    <FetchElement
      fetch={() => restApi.get(`/cms/displays/${id}`)}
      Component={({ endAt, isActive, imageResponse, startAt, tagName, refresh }) => (
        <Space style={{ width: '100%', maxWidth: 1500 }} direction="vertical">
          <Descriptions colon={false} column={1} bordered>
            <Descriptions.Item label="배너 이미지" labelStyle={{ verticalAlign: 'middle' }}>
              <Space>
                <a href={imageResponse?.image} rel="noreferrer" target="_blank">
                  <img src={imageResponse?.image} alt={imageResponse?.text} style={{ width: 150 }} />
                </a>
                <Typography.Text>대체 텍스트: {imageResponse?.text}</Typography.Text>
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label="태그명">{tagName}</Descriptions.Item>
            <Descriptions.Item label="노출기간">{`${moment(startAt).format('YYYY-MM-DD HH:mm:ss')} ~ ${moment(
              endAt,
            ).format('YYYY-MM-DD HH:mm:ss')}`}</Descriptions.Item>
            <Descriptions.Item label="사용여부">
              <Space>
                <Typography.Text>{isActive ? '사용' : '미사용'}</Typography.Text>
                {!isActive && (
                  <Button
                    type="primary"
                    onClick={() => {
                      Modal.confirm({
                        title: '사용 처리',
                        content: '메인화면에 노출하겠습니까 ?',
                        centered: true,
                        onOk: () =>
                          restApi
                            .put(`/cms/displays/active/${id}`)
                            .then(refresh)
                            .catch((error) => {
                              Modal.error({
                                title: '사용처리',
                                content: error?.response?.data?.message ?? '사용처리에 실패했습니다.',
                                centered: true,
                              });
                            }),
                        okText: '확인',
                        cancelText: '취소',
                      });
                    }}
                  >
                    사용처리
                  </Button>
                )}
              </Space>
            </Descriptions.Item>
          </Descriptions>
          <Row justify="end">
            <Col>
              <Space>
                <Link to="/contents/recommend/tags">
                  <Button>목록</Button>
                </Link>
                <Link to={`/contents/recommend/tags/modify/${id}`}>
                  <Button type="primary">수정</Button>
                </Link>
              </Space>
            </Col>
          </Row>
        </Space>
      )}
    />
  );
}

export default ContentsRecommendTagsDetail;
