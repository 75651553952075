import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Card, Col, Form, Input, Modal, Radio, Row, Space, Tree, Typography } from 'antd';
import { restApi } from '#apis/index';
import FetchElement from '#components/FetchElement';
import useMount from '#hooks/useMount';
import FileUpload from '../../../components/datalist/FileUpload';
import { checkBeforeUpload } from '../../../utils/beforeUpload';

const MemoziedManagementCategory = React.memo(({ data = [], refresh = () => {}, type }) => {
  const [selectedCode, setSelectedCode] = React.useState(null);
  const formRef = React.useRef();
  const [expanded, setExpanded] = React.useState([]);
  const [images, setImages] = React.useState([]);
  const treeData = React.useMemo(
    () =>
      data?.reduce((acc, { code, label, isActive, image }) => {
        const obj = {
          key: code,
          code,
          label,
          title: (
            <Space size="small">
              {label}
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  restApi
                    .post(`/cms/categories/${type}/child`, {
                      parent: code,
                    })
                    .then(refresh);
                }}
                size="small"
              >
                추가
              </Button>
              {isActive ? (
                <Button
                  onClick={() => {
                    Modal.confirm({
                      title: '카테고리 수정',
                      content: '선택한 카테고리를 비사용 처리하시겠습니까 ?',
                      onOk: () =>
                        restApi
                          .put(`/cms/categories/${code}/active`, { isActive: false })
                          .then(() => {
                            refresh();
                            Modal.success({
                              title: '카테고리 수정',
                              content: '카테고리가 수정되었습니다.',
                              centered: true,
                            });
                          })
                          .catch((error) => {
                            Modal.error({
                              title: '카테고리 수정',
                              content: error?.response?.data?.message ?? '카테고리 수정에 실패했습니다.',
                              centered: true,
                            });
                          }),
                      okText: '확인',
                      cancelText: '취소',
                      centered: true,
                    });
                  }}
                  size="small"
                  type="danger"
                >
                  비사용
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    Modal.confirm({
                      title: '카테고리 수정',
                      content: '선택한 카테고리를 사용 처리하시겠습니까 ?',
                      onOk: () =>
                        restApi
                          .put(`/cms/categories/${code}/active`, { isActive: true })
                          .then(() => {
                            refresh();
                            Modal.success({
                              title: '카테고리 수정',
                              content: '카테고리가 수정되었습니다.',
                              centered: true,
                            });
                          })
                          .catch((error) => {
                            Modal.error({
                              title: '카테고리 수정',
                              content: error?.response?.data?.message ?? '카테고리 수정에 실패했습니다.',
                              centered: true,
                            });
                          }),
                      okText: '확인',
                      cancelText: '취소',
                      centered: true,
                    });
                  }}
                  size="small"
                  type="primary"
                >
                  사용
                </Button>
              )}
            </Space>
          ),
          isActive,
          parentCode: null,
          children: [],
          image,
        };
        function recursion(array, current, index = 0) {
          const parentCode = current.code.substr(0, (index + 2) * 3);
          const findIndex = array.findIndex((value) => value.code === parentCode);
          if (findIndex > -1) {
            if (parentCode === current.code.substr(0, current.code.length - 3)) {
              current.parent = array[findIndex];
              array[findIndex].children.push(current);
            } else {
              recursion(array[findIndex].children, current, index + 1);
            }
          } else {
            array.push(current);
          }
        }
        recursion(acc, obj);
        return acc;
      }, []) ?? [],
    [data, refresh],
  );

  const isOneDepth = treeData?.find((item) => item?.key === selectedCode?.key);
  return (
    <Row gutter={10} column={2}>
      <Col flex="1 0 0">
        <Card>
          <Space style={{ width: '100%' }} direction="vertical">
            <Row justify="space-between" align="middle">
              <Col>
                <Button
                  onClick={() => {
                    restApi
                      .post(`/cms/categories/${type}`)
                      .then(refresh)
                      .catch(() => {
                        Modal.error({
                          content: '대분류 추가에 실패했습니다. 잠시후에 시도해주세요.',
                        });
                      });
                  }}
                  type="primary"
                >
                  대분류 추가
                </Button>
              </Col>
              <Col>
                <Button onClick={refresh}>새로고침</Button>
              </Col>
            </Row>
            <Card>
              <Tree
                showLine={{ showLeafIcon: false }}
                treeData={treeData}
                selectedKeys={[selectedCode?.key]}
                expandedKeys={expanded}
                onSelect={(_selectedKey, { node }) => {
                  setSelectedCode(node);
                  formRef.current?.setFieldsValue({
                    label: node.label,
                    isActive: node.isActive,
                  });
                  setImages([]);
                }}
                onExpand={(selectedKey) => {
                  setExpanded(selectedKey);
                }}
              />
            </Card>
          </Space>
        </Card>
      </Col>
      <Col flex="1 0 0">
        {!!selectedCode && (
          <Card>
            <Form
              ref={formRef}
              onFinish={({ label, isActive }) => {
                const requestBody = { label, isActive };
                if (!!isOneDepth) {
                  if (images?.length === 0)
                    return Modal.warning({
                      title: '이미지 입력',
                      content: '카테고리 이미지를 선택해주세요.',
                      okText: '확인',
                    });
                  requestBody.image = images?.[0];
                }

                restApi
                  .put(`/cms/categories/${selectedCode.code}`, requestBody)
                  .then(() => {
                    refresh();
                    Modal.success({ title: '카테고리 수정', content: '카테고리가 수정되었습니다.', centered: true });
                  })
                  .catch((error) => {
                    Modal.error({
                      title: '카테고리 수정',
                      content: error?.response?.data?.message ?? '카테고리 수정에 실패했습니다.',
                      centered: true,
                    });
                  });
              }}
            >
              <Space style={{ width: '100%' }} direction="vertical">
                <Form.Item label="상위카테고리">
                  <Typography.Text>{selectedCode?.parent?.label ?? '없음'}</Typography.Text>
                </Form.Item>
                <Form.Item
                  label="카테고리명"
                  name="label"
                  required
                  rules={[{ required: true, message: '카테고리명을 입력해주세요.' }]}
                  initialValue={selectedCode.label}
                >
                  <Input />
                </Form.Item>
                {!!isOneDepth && (
                  <Form.Item label="배너이미지" required>
                    <FileUpload
                      maxCount={1}
                      items={
                        images?.length > 0 ? images : typeof selectedCode.image === 'number' ? [selectedCode.image] : []
                      }
                      onDoneChange={(fileListDone) => {
                        setImages(fileListDone.map(({ response }) => response?.[0]?.id));
                      }}
                      beforeUpload={checkBeforeUpload({
                        availableLists: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'],
                        textWhenFail: 'jpg, jpeg, png, gif 확장자만 가능합니다.',
                      })}
                    />
                  </Form.Item>
                )}

                <Form.Item
                  label="사용여부"
                  name="isActive"
                  required
                  rules={[{ required: true, message: '사용여부를 선택해주세요.' }]}
                  initialValue={selectedCode.isActive}
                >
                  <Radio.Group
                    options={[
                      { label: '사용', value: true },
                      { label: '미사용', value: false },
                    ]}
                  />
                </Form.Item>

                <Row justify="end">
                  <Col>
                    <Button type="primary" htmlType="submit">
                      저장
                    </Button>
                  </Col>
                </Row>
              </Space>
            </Form>
          </Card>
        )}
      </Col>
    </Row>
  );
});

function ManagementCategory() {
  const formRef = React.useRef();
  const location = useLocation();
  const mount = useMount();
  const dispatch = useDispatch();

  const type = React.useMemo(() => {
    const paths = location?.pathname?.split('/');
    return paths[paths.length - 1] ?? '';
  }, [location?.pathname]);

  const handleRefresh = React.useCallback(() => {
    formRef.current?.refresh();
    // dispatch(categories());
  }, []);

  React.useEffect(() => {
    if (!mount) {
      return;
    }
    formRef.current?.refresh();
  }, [type]);

  return (
    <FetchElement
      ref={formRef}
      fetch={() => restApi.get(`/cms/categories/${type}`)}
      Component={(data) => <MemoziedManagementCategory refresh={handleRefresh} type={type} {...data} />}
      notUseLoading
    />
  );
}

export default ManagementCategory;
