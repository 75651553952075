import React from 'react';
import { Divider, Button, Modal } from 'antd';
import SearchForm from '#components/datalist/SearchForm';
import CommTable from '#components/table/CommTable';
import { restApi } from '#apis';
import useQuery from '#hooks/useQuery';
import useIndex from '#hooks/useIndex';
import { useReduxStore } from '#hooks/useReduxStore';

function RestingManagementIndex() {
  const tableRef = React.useRef();
  const { initializeSearchOpt } = useQuery();
  const { getIndex } = useIndex();
  const { map } = useReduxStore('codes');
  const askChageStatusToNormal = (userId) => {
    return Modal.confirm({
      okText: '확인',
      cancelText: '취소',
      centered: true,
      onOk: async () => {
        try {
          await restApi.put(`/cms/accounts/${userId}/dormant`);
          tableRef.current.loadData();
          Modal.success({
            title: '일반회원 전환',
            content: '일반 회원으로 전환되었습니다.',
            okText: '확인',
          });
        } catch (e) {
          console.warn('error in askChageStatusToNormal : ', e?.response);
          Modal.error({
            title: '일반회원 전환',
            content: '일반 회원으로 전환에 실패했습니다. 잠시후 시도해주세요.',
            okText: '확인',
          });
        }
      },
      content: (
        <div>
          <p>일반회원으로 전환하시겠습니까?</p>
        </div>
      ),
    });
  };

  const rowSelection = {};
  return (
    <div>
      <SearchForm
        extra={{ onInit: () => initializeSearchOpt() }}
        fields={[
          {
            width: 0.125,
            type: 'select',
            key: 'dateType',
            items: [
              { label: '가입일', value: 'Join' },
              { label: '최종로그인', value: 'LastLogin' },
            ],
            placeholder: '날짜 옵션',
          },
          {
            width: 0.375,
            type: 'daterange',
            key: 'dateRange',
            keys: ['startDate', 'endDate'],
          },
          {
            width: 0.125,
            type: 'select',
            key: 'searchType',
            placeholder: '검색 옵션',
            items: [
              { label: '회원아이디', value: 'UserId' },
              { label: '회원이름', value: 'UserName' },
              { label: '휴대전화', value: 'Phone' },
            ],
          },
          {
            width: 0.25,
            type: 'input',
            key: 'searchKeyword',
            placeholder: '키워드를 입력하세요.',
          },
        ]}
      />
      <Divider />
      <CommTable
        ref={tableRef}
        url="/cms/accounts/dormant"
        columns={({ limit, pageCur, total }) => [
          {
            title: '번호',
            dataIndex: 'tableIndex',
            key: 'tableIndex',
            fixed: 'left',
            width: 50,
            render: (value, obj, index) => {
              return getIndex(index);
            },
          },
          {
            title: '가입구분',
            dataIndex: 'joinTypeCode',
            key: 'joinTypeCode',
            width: 100,
            render: (value) => map?.[value],
          },
          {
            title: '회원아이디',
            dataIndex: 'userId',
            key: 'userId',
            width: 150,
          },
          {
            title: '회원이름',
            dataIndex: 'userName',
            key: 'userName',
            width: 150,
          },
          {
            title: '휴대전화',
            dataIndex: 'phone',
            key: 'phone',
            width: 100,
          },
          {
            title: '가입일',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 200,
          },
          {
            title: '최종로그인',
            dataIndex: 'lastLoginAt',
            key: 'lastLoginAt',
            width: 200,
          },
          {
            width: 200,
            render: (value, obj) => <Button onClick={() => askChageStatusToNormal(obj.id)}>일반회원 전환</Button>,
          },
        ]}
        rowSelection={rowSelection}
        rowKey={({ id }) => id}
        scroll={{ x: 1120 }}
      />
    </div>
  );
}

export default RestingManagementIndex;
