import React from 'react';
import { Modal, Button, Space, Descriptions, Spin } from 'antd';
import handleError from '#utils/handleError';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import TextArea from 'antd/lib/input/TextArea';
import styles from '../../../Page.module.less';
import { useReduxStore } from '#hooks/useReduxStore';
import { restApi } from '#apis';

const cx = classNames.bind(styles);

function PartnerWaitingDetail() {
  const { map: codesMap } = useReduxStore('codes');
  const { userId } = useParams();
  const [partner, setPartner] = React.useState(null);
  const [rejectReason, setRejectReason] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const navigate = useNavigate();

  // 승인처리
  const askApproval = () => {
    return Modal.confirm({
      okText: '확인',
      cancelText: '취소',
      centered: true,
      onOk: async () => {
        try {
          await restApi.post(`/cms/accounts/partners/confirm/${userId}`);
          loadData();
        } catch (e) {
          handleError(e);
          console.warn('e ::::: ', e);
        }
      },
      content: (
        <div>
          <p>해당 파트너사의 판매권한 신청을</p>
          <p>승인하시겠습니까?</p>
        </div>
      ),
    });
  };

  const FloatingLastCheckIfReject = () => {
    return Modal.confirm({
      okText: '확인',
      cancelText: '취소',
      centered: true,
      onCancel: () => {
        setRejectReason('');
        setShowModal(false);
      },
      onOk: async () => {
        try {
          await restApi.post(`/cms/accounts/partners/reject/${userId}`, {
            reason: rejectReason,
          });
          setRejectReason('');
          setShowModal(false);
          loadData();
        } catch (e) {
          setShowModal(false);
          Modal.destroyAll();
          Modal.error({
            title: '반려 실패',
            content: '반려에 실패 했습니다. 관리자에 문의하세요',
            centered: true,
          });
        }
      },
      content: (
        <div>
          <p>판매권한 신청을 반려처리 하면 되돌릴 수 없습니다.</p>
          <p>반려처리를 하시겠습니까?</p>
        </div>
      ),
    });
  };

  const handlePostReject = () => {
    try {
      if (!rejectReason.length) {
        return Modal.warn({ content: '반려사유를 입력하세요.' });
      }
      FloatingLastCheckIfReject();
    } catch (e) {}
  };

  const loadData = () => {
    restApi
      .get(`/cms/accounts/partners/unconfirmed/${userId}`)
      .then((response) => setPartner(response.data))
      .catch((e) => console.warn('error in PartnerWaitingDetail : ', e));
  };

  React.useEffect(() => {
    if (!userId) return;
    loadData();
  }, [userId]);

  // console.log('partner :::: ', partner);
  // console.log('partner.userStatus ::: ', partner?.userStatus);
  // console.log('rejectReason ::: ', rejectReason);
  // console.log('rejectReason :::: ', rejectReason);
  if (!partner) return <Spin />;

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="파트너사아이디">
            {partner.userId}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="파트너사이름">
            {partner.userName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="휴대전화">
            {partner.phone}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="이메일">
            {partner.email}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="사업자번호">
            {partner.businessNumber}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="사업자 등록증">
            <a rel="noreferrer" target="_blank" href={partner.bizImg} download>
              {partner.bizImgFileName}
            </a>
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="계좌번호">
            {codesMap?.[partner.bankName]} {partner.accountNumber} / {partner.accountHolder} 판매사
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="사업자 주소">
            {partner.bizAddress || '-'}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="승인상태">
            <span style={{ marginRight: 10 }}>
              {codesMap[partner.userStatus]}{' '}
              {(partner.userStatus === 'PAS003' || partner.userStatus === 'PAS002') &&
                `(${partner.handleBy} / ${partner.handleAt})`}
            </span>
            {partner.userStatus === 'PAS001' && (
              <>
                <Button onClick={askApproval} style={{ marginRight: 10 }}>
                  승인처리
                </Button>
                <Button
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  반려처리
                </Button>
              </>
            )}
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button onClick={() => navigate('/member/partner/waiting')}>목록</Button>
      </div>
      <Modal
        visible={showModal}
        centered
        title="반려 처리 사유"
        onCancel={() => {
          setRejectReason('');
          setShowModal(false);
        }}
        onOk={handlePostReject}
      >
        <div style={{ paddingTop: 30, paddingBottom: 30 }}>
          <TextArea onChange={(e) => setRejectReason(e.target.value)} size="large" rows={6} value={rejectReason} />
        </div>
      </Modal>
    </div>
  );
}

export default PartnerWaitingDetail;
