import React, { useState } from 'react';
import { PageHeader, Table, Divider, Select, Button, Alert, Modal, Input, Radio, DatePicker } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { Option } from 'antd/lib/mentions';
import classNames from 'classnames/bind';
import SearchForm from '../../../components/datalist/SearchForm';
import TableHeader from '../../../components/common/TableHeader';
import styles from '../../Page.module.less';

const cx = classNames.bind(styles);

function AdvertiesBannerIndex(props) {
  const navigate = useNavigate();

  const searchField = [
    {
      width: 0.25,
      type: 'select',
      key: 'arg1',
      items: [
        { label: '등록일', value: '1' },
        { label: '최종수정일', value: '2' },
        { label: '1개월', value: '3' },
      ],
    },
    {
      width: 0.5,
      type: 'daterange',
      key: 'arg5',
      keys: ['arg5', 'arg6'],
    },
    {
      width: 0.125,
      type: 'select',
      key: 'arg1',
      items: [{ label: '활성화상태', value: '1' }],
    },
    {
      width: 0.125,
      type: 'select',
      key: 'arg1',
      items: [{ label: '승인여부', value: '1' }],
    },
    {
      width: 0.125,
      type: 'select',
      key: 'arg1',
      items: [
        { label: '카테고리1', value: '1' },
        { label: '카테고리1', value: '2' },
      ],
    },
    {
      width: 0.125,
      type: 'select',
      key: 'arg1',
      disabled: true,
      items: [
        { label: '카테고리2', value: '1' },
        { label: '카테고리2', value: '2' },
      ],
    },
    {
      width: 0.125,
      type: 'select',
      key: 'arg1',
      disabled: true,
      items: [
        { label: '카테고리3', value: '1' },
        { label: '카테고리3', value: '2' },
      ],
    },
    {
      width: 0.125,
      type: 'select',
      key: 'arg1',
      disabled: true,
      items: [
        { label: '카테고리4', value: '1' },
        { label: '카테고리4', value: '2' },
      ],
    },
    {
      width: 0.125,
      type: 'select',
      key: 'arg7',
      items: [{ label: '시/도', value: '1' }],
    },
    {
      width: 0.125,
      type: 'select',
      key: 'arg8',
      disabled: true,
      items: [
        { label: '시/군/구', value: '1' },
        { label: '카테고리2', value: '2' },
      ],
    },
    {
      width: 0.125,
      type: 'select',
      key: 'arg9',
      disabled: true,
      items: [{ label: '동/면/읍', value: '1' }],
    },
    {
      width: 0.5,
      type: 'input',
      key: 'arg8',
      label: '광고명',
      placeholder: '키워드를 입력하세요',
    },
    {
      width: 0.5,
      type: 'input',
      key: 'arg8',
      label: '상점명',
      placeholder: '키워드를 입력하세요',
    },
  ];

  const dataSource = [
    {
      key: '1',
      productNum: 'A10082C',
      productName: '편백나무 수납형 저상침대 프레임',
      payment: 'Y',
      estimate: 'Y',
      salesStatus: '판매대기',
      displayStatus: '게시중',
      inventory: '5',
      salesPrice: '250,000',
      discountPrice: '230,000',
      option: 'Y',
      deliveryType: '유료',
      deliveryMethod: '착불',
      deliveryPrice: '3,000',
      refundPrice: '3,000',
      exchangePrice: '6,000',
      interior: 'Y',
      category1: '가구',
      category2: '침대',
      category3: '침대프레임',
      category4: 'SS',
      maker: '한샘',
      brand: '한샘',
      modelName: 'A10082C',
      inDate: '2020-04-20 08:00',
      outDate: '2020-04-20 08:00',
      createDate: '2020-04-20 08:00',
      modifyDate: '2020-04-20 08:00',
    },
  ];

  const columns = [
    {
      title: '수정',
      dataIndex: 'modify',
      key: 'modify',
      fixed: 'left',
      width: 80,
      render: (item) => <Button onClick={() => navigate('/store/goods/modify')}>수정</Button>,
    },
    {
      title: '복사',
      dataIndex: 'copy',
      key: 'copy',
      fixed: 'left',
      width: 80,
      render: (item) => <Button onClick={() => navigate('/store/goods/regist')}>복사</Button>,
    },
    {
      title: '상품번호',
      dataIndex: 'productNum',
      key: 'productNum',
      fixed: 'left',
      width: 100,
      render: (item) => (
        <Button onClick={() => navigate('/store/goods/regist')} style={{ border: 0, padding: 0 }}>
          12345678
        </Button>
      ),
    },
    {
      title: '파트너사명',
      dataIndex: 'productName',
      key: 'productName',
      width: 300,
    },
    {
      title: '상품명',
      dataIndex: 'productName',
      key: 'productName',
      width: 300,
    },
    {
      title: '결제여부',
      dataIndex: 'payment',
      key: 'payment',
      width: 100,
      render: (item) => <Button>상세설명</Button>,
    },
    {
      title: '견적의뢰',
      dataIndex: 'estimate',
      key: 'estimate',
      width: 80,
    },
    {
      title: '상세설명',
      dataIndex: 'detail',
      key: 'detail',
      width: 100,
    },
    {
      title: '판매상태',
      dataIndex: 'salesStatus',
      key: 'salesStatus',
      width: 80,
    },
    {
      title: '게시상태',
      dataIndex: 'displayStatus',
      key: 'displayStatus',
      width: 80,
    },
    {
      title: '재고수량',
      dataIndex: 'inventory',
      key: 'inventory',
      width: 80,
    },
    {
      title: '판매가',
      dataIndex: 'salesPrice',
      key: 'salesPrice',
      width: 120,
      render: (item) => (
        <>
          <Button>120,000</Button>
          <Input />
        </>
      ),
    },
    {
      title: '할인가',
      dataIndex: 'discountPrice',
      key: 'discountPrice',
      width: 120,
      render: (item) => (
        <>
          <Button>120,000</Button>
          <Input />
        </>
      ),
    },
    {
      title: '옵션',
      dataIndex: 'option',
      key: 'option',
      width: 80,
    },
    {
      title: '배송비유형',
      dataIndex: 'deliveryType',
      key: 'deliveryType',
      width: 120,
    },
    {
      title: '배송비결제방식',
      dataIndex: 'deliveryMethod',
      key: 'deliveryMethod',
      width: 120,
    },
    {
      title: '기본배송비',
      dataIndex: 'deliveryPrice',
      key: 'deliveryPrice',
      width: 100,
    },
    {
      title: '반품배송비',
      dataIndex: 'refundPrice',
      key: 'refundPrice',
      width: 100,
    },
    {
      title: '교환배송비',
      dataIndex: 'exchangePrice',
      key: 'exchangePrice',
      width: 100,
    },
    {
      title: '인테리어',
      dataIndex: 'interior',
      key: 'interior',
      width: 80,
    },
    {
      title: '카테고리1',
      dataIndex: 'category1',
      key: 'category1',
      width: 100,
    },
    {
      title: '카테고리2',
      dataIndex: 'category2',
      key: 'category2',
      width: 100,
    },
    {
      title: '카테고리3',
      dataIndex: 'category3',
      key: 'category3',
      width: 100,
    },
    {
      title: '카테고리4',
      dataIndex: 'category4',
      key: 'category4',
      width: 100,
    },
    {
      title: '제조사명',
      dataIndex: 'maker',
      key: 'maker',
      width: 100,
    },
    {
      title: '브랜드명',
      dataIndex: 'brand',
      key: 'brand',
      width: 100,
    },
    {
      title: '모델명',
      dataIndex: 'modelName',
      key: 'modelName',
      width: 120,
    },
    {
      title: '판매시작일',
      dataIndex: 'inDate',
      key: 'inDate',
      width: 140,
    },
    {
      title: '판매종료일',
      dataIndex: 'outDate',
      key: 'outDate',
      width: 140,
    },
    {
      title: '상품등록일',
      dataIndex: 'createDate',
      key: 'createDate',
      width: 140,
    },
    {
      title: '최종수정일',
      dataIndex: 'modifyDate',
      key: 'modifyDate',
      width: 140,
    },
  ];
  const rowSelection = {};

  return (
    <div>
      <SearchForm fields={searchField} />
      <Divider />
      <TableHeader buttons={['엑셀다운']} counter={{ totalValue: '1020', nowValue: '10-25' }} select={[2]} />
      <Table
        dataSource={dataSource}
        columns={columns}
        rowSelection={rowSelection}
        scroll={{ x: 1920 }}
        pagination={{ position: ['bottomCenter'] }}
      />
    </div>
  );
}

export default AdvertiesBannerIndex;
