import React from 'react';
import { Button, Space, Descriptions, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from '../Page.module.less';
import { restApi } from '../../apis';
import { useReduxStore } from '../../hooks/useReduxStore';
import { formatPhonNumer } from '../../utils/formatPhoneNumber';

const cx = classNames.bind(styles);

const TourReservationDetail = () => {
  const { map } = useReduxStore('codes');
  const { tourReservationid } = useParams() || {};
  const navigate = useNavigate();
  const [user, setUser] = React.useState(null);
  React.useEffect(() => {
    if (!tourReservationid) return;
    restApi
      .get(`/cms/furniture/${tourReservationid}`)
      .then((response) => {
        setUser(response.data);
      })
      .catch((e) => console.warn(e));
  }, [tourReservationid]);

  if (!user) return <Spin />;
  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="고객명">
            {user.name}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="이메일">
            {user.email}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="휴대전화">
            {formatPhonNumer(user.phone)}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="우편번호">
            {user.zipCode}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="주소">
            {user.address1}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="상세주소">
            {user.address2}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="예약시간">
            {user.wantedBuyAt}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="선택 가구">
            {user.furnitureStringList.split(',').map((el) => {
              return (
                <span className={styles.furnitureTag} key={el}>
                  {`${el}`}
                </span>
              );
            })}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="전달사항">
            {user.notification}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="신청일">
            {user.createdAt}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="예약 정보 상태">
            {map[user.status]}
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button onClick={() => navigate('/tour')}>목록</Button>
      </div>
    </div>
  );
};

export default TourReservationDetail;
