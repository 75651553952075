import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import styles from './Login.module.less';
import { setToken } from '../../apis';
import { me } from '../../data/reducers/authReducer';
import { postOauthToken } from '../../apis/auth';
import { useNavigate } from 'react-router-dom';

function Login() {
  const [loading, setLoading] = useState(false);
  const [cookies, setCookies, removeCookies] = useCookies(['ID', 'SAVEID']);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [username, setUsername] = React.useState(null);
  const [password, setPassword] = React.useState(null);

  const handleCheckAutoSave = (event) => {
    if (cookies.SAVEID) {
      removeCookies('SAVEID');
      removeCookies('ID');
    } else {
      setCookies('SAVEID', event.target.checked);
    }
  };

  const handleSubmit = async () => {
    if (loading) return;
    if (cookies.SAVEID) setCookies('ID', username);
    try {
      setLoading(true);
      const requestBody = {
        username,
        password,
        grant_type: 'password',
      };
      const { data: responseData } = await postOauthToken(requestBody);
      setToken(responseData);
      await dispatch(me());
      navigate('/');
    } catch (e) {
      Modal.warning({ okText: '확인', centered: true, content: <p>로그인 정보가 일치하지 않습니다.</p> });
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (!cookies) return;
    if (!username) {
      setUsername(cookies.ID);
    }
  }, [cookies, username]);

  return (
    <div className={styles.login_wrapper}>
      <Form onFinish={handleSubmit}>
        <h2>홈그릿 서비스</h2>
        <h4>관리자 시스템</h4>
        <Form.Item noStyle rules={[{ required: true, message: '아이디를 입력해주세요.' }]}>
          <Input
            prefix="아이디"
            className={styles.input}
            value={username}
            allowClear
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item noStyle rules={[{ required: true, message: '비밀번호를 입력해주세요.' }]}>
          <Input.Password
            prefix="비밀번호"
            className={styles.input}
            allowClear
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Form.Item>
        <Checkbox checked={cookies.SAVEID || false} onChange={handleCheckAutoSave}>
          아이디 저장하기
        </Checkbox>
        <Button
          block
          className={styles.submit}
          type="primary"
          htmlType="submit"
          onClick={handleSubmit}
          loading={loading}
        >
          로그인
        </Button>
      </Form>
      <br />
      <p>※ 아이디/비밀번호 분실 시 </p>
      <p>관리자 및 담당 영업사업에게 문의하세요.</p>
    </div>
  );
}

export default Login;
