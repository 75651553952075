import { restApi } from '#apis/index';
import { Button, Col, Descriptions, Form, Input, Modal, Row, Space, Typography } from 'antd';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { passwordRegex } from '../../common/regex';
import FetchElement from '../../components/FetchElement';

function AdminDetailPage() {
  const params = useParams();
  const { id } = React.useMemo(() => params, [params]);

  return (
    <FetchElement
      fetch={() => restApi.get(`/cms/accounts/admin/${id}`)}
      Component={({ userId, email, userName, phone, active }) => {
        return (
          <Space style={{ width: '100%' }} direction="vertical">
            <Descriptions colon={false} bordered column={1} labelStyle={{ width: 150 }}>
              <Descriptions.Item label="아이디">{userId}</Descriptions.Item>
              <Descriptions.Item label="비밀번호">
                <Button
                  type="primary"
                  onClick={() => {
                    const modal = Modal.info({
                      title: '비밀번호 재설정',
                      centered: true,
                      width: 600,
                      content: (
                        <Form
                          onFinish={async ({ newPassword, newPassword2 }) => {
                            if (!newPassword || !newPassword2) {
                              return Modal.warn({
                                title: '비밀번호 재설정',
                                content: '신규 비밀번호를 입력하세요.',
                                centered: true,
                              });
                            }

                            if (newPassword !== newPassword2) {
                              return Modal.warn({
                                title: '비밀번호 재설정',
                                content: '입력한 비밀번호가 일치하지 않습니다.',
                                centered: true,
                              });
                            }

                            if (!passwordRegex.test(newPassword)) {
                              return Modal.warn({
                                title: '비밀번호 재설정',
                                content: (
                                  <>
                                    입력한 비밀번호 형식이 맞지 않습니다.
                                    <br />※ 문자,숫자 조합 / 6~20자
                                  </>
                                ),
                                centered: true,
                              });
                            }

                            try {
                              await restApi.put(`/cms/accounts/admin/${id}/password`, {
                                newPassword,
                              });
                              Modal.success({
                                title: '비밀번호 재설정',
                                content: '비밀번호가 재설정되었습니다.',
                                centered: true,
                                onOk: () => {
                                  Modal.destroyAll();
                                },
                              });
                            } catch (error) {
                              Modal.error({
                                title: '비밀번호 재설정',
                                content: '비밀번호 재설정에 실패했습니다.',
                                centered: true,
                              });
                            }
                          }}
                        >
                          <Space direction="vertical" style={{ width: '100%' }} size="middle">
                            <Form.Item label="신규 비밀번호" name="newPassword" required>
                              <Space direction="vertical" style={{ width: '100%' }}>
                                <Input.Password />
                                <Typography.Text>※ 문자,숫자 조합 / 6~20자</Typography.Text>
                              </Space>
                            </Form.Item>
                            <Form.Item label="비밀번호 확인" name="newPassword2" required>
                              <Input.Password />
                            </Form.Item>
                            <Row align="middle" justify="end" style={{ marginBottom: -25 }}>
                              <Col>
                                <Space>
                                  <Button
                                    onClick={() => {
                                      modal.destroy();
                                    }}
                                  >
                                    취소
                                  </Button>
                                  <Button type="primary" htmlType="submit">
                                    저장
                                  </Button>
                                </Space>
                              </Col>
                            </Row>
                          </Space>
                        </Form>
                      ),
                      okButtonProps: { style: { display: 'none' } },
                    });
                  }}
                >
                  재설정
                </Button>
              </Descriptions.Item>
              <Descriptions.Item label="관리자명">{userName}</Descriptions.Item>
              <Descriptions.Item label="이메일">{email}</Descriptions.Item>
              <Descriptions.Item label="휴대폰번호">{phone}</Descriptions.Item>
              <Descriptions.Item label="사용여부">{active ? '사용' : '미사용'}</Descriptions.Item>
            </Descriptions>
            <Row align="middle" justify="end">
              <Col>
                <Space>
                  <Link to="/admin/list">
                    <Button>목록</Button>
                  </Link>
                  <Link to={`/admin/modify/${id}`}>
                    <Button type="primary">수정</Button>
                  </Link>
                </Space>
              </Col>
            </Row>
          </Space>
        );
      }}
    />
  );
}

export default AdminDetailPage;
