import React from 'react';
import { Modal, Button, PageHeader, Space, Descriptions, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import produce from 'immer';
import styles from '../../../Page.module.less';
import { restApi } from '../../../../apis';
import { useReduxStore } from '../../../../hooks/useReduxStore';

const cx = classNames.bind(styles);

function PartnerCompleteDetail(props) {
  const { map } = useReduxStore('codes');
  const { userId } = useParams() || {};
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [partner, setPartner] = React.useState(null);
  const [salesButtonDisabled, setSalesButtonDisabled] = React.useState(false);
  const [activeBtnDisabled, setActiveBtnDisabled] = React.useState(false);

  const handleToggleSales = async () => {
    try {
      setSalesButtonDisabled(true);
      const { data } = await restApi.put(`/cms/accounts/partners/permission/${userId}`);
      setPartner(
        produce((draft) => {
          draft.salesStatus = data ? 'PASS002' : 'PASS004';
        }),
      );
      setSalesButtonDisabled(false);
    } catch (e) {
      setSalesButtonDisabled(false);
      Modal.confirm({
        content: <div>판매 권한 변경에 실패했습니다. 잠시 후 시도해주세요.</div>,
      });
    }
  };

  const handleToggleActive = async () => {
    try {
      setActiveBtnDisabled(true);
      const { data } = await restApi.put(`/cms/accounts/partners/active/${userId}`);
      console.log(data)
      if(data.isActive){
      setPartner(
        produce((draft) => {
          draft.isActive = data.isActive;

        }),
      );
      
    }
    else{
      setPartner(
        produce((draft) => {
          draft.isActive = data.isActive;
          draft.handleActiveBy = data.handleActiveBy;
          draft.handleActiveAt = data.handleActiveAt;
        }),
      );
    }
      setActiveBtnDisabled(false);
    } catch (e) {
      setActiveBtnDisabled(false);
      Modal.warning({
        contnet: '실패 잠시 후 시도 부탁',
      });
    }
  };

  const askToggleActive = () => {
    return Modal.confirm({
      okText: '확인',
      cancelText: '취소',
      centered: true,
      onOk: handleToggleActive,
      content: (
        <div>
          <p>파트너사를 {partner.isActive && '비'}활성화 처리하겠습니까?</p>
        </div>
      ),
    });
  };

  // 판매권한 부여
  const askSalesAuth = () => {
    return Modal.confirm({
      okText: '확인',
      cancelText: '취소',
      centered: true,
      onOk: handleToggleSales,
      content: (
        <div>
          <p>파트너사의 판매권한을{partner.salesStatus === 'PASS004' ? '부여하시겠습니까?' : '해제하기겠습니까?'} </p>
        </div>
      ),
    });
  };

  React.useEffect(() => {
    if (!userId) return;
    restApi
      .get(`/cms/accounts/partners/confirmed/${userId}`)
      .then((response) => {
        setPartner(response.data);
      })
      .catch((e) => console.warn(e));
  }, [userId]);

  if (!partner) return <Spin />;

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="파트너사아이디">
            {partner.userId}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="파트너사이름">
            {partner.userName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="휴대전화">
            {partner.phone}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="이메일">
            {partner.email}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="활동내역" contentStyle={{ padding: 0 }}>
            <Descriptions bordered>
              <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="판매상품">
                {partner.productCount}
              </Descriptions.Item>
              <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="정산금액">
                {partner.amount}
              </Descriptions.Item>
              <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="주문건수">
                {partner.requestAmount}
              </Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="택배사">
            {partner.deliveries.length > 0 ? partner.deliveries.join(', ') : '-'}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="사업자번호">
            {partner.businessNumber}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="사업자 등록증">
            <a rel="noreferrer" target="_blank" href={partner.bizImg} download>
              {partner.bizImgFileName}
            </a>
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="계좌번호">
            {map[partner.bankName]} {partner.accountNumber} / {partner.accountHolder} 판매사
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="사업자 주소">
            채워주셈
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="마지막 로그인" />
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="사용여부">
            <span style={{ marginRight: 10 }}>
              {partner.isActive ? '활성화' : `비활성화 (${partner?.handleActiveBy} / ${partner?.handleActiveAt})`}
            </span>
            <button disabled={activeBtnDisabled} onClick={askToggleActive}>
              {partner.isActive ? '비활성화' : '활성화'} 처리
            </button>
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="판매권한">
            <span style={{ marginRight: 10 }}>{map[partner.salesStatus]}</span>
            {partner.salesStatue && (partner.salesStatus === 'PASS004' || partner.salesStatus === 'PASS002') && (
              <button disabled={salesButtonDisabled} onClick={askSalesAuth}>
                판매권한{partner.isActive ? '처리' : '부여'}
              </button>
            )}
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button onClick={() => navigate('/member/management/users')}>목록</Button>
      </div>
    </div>
  );
}

export default PartnerCompleteDetail;
