import { Typography } from 'antd';
import React from 'react';

const ImageUploadGuide = ({
  guideList = ['※ 첨부파일은 jpg, jpeg, png, gif 만 첨부가 가능합니다.', '※ 이미지 권장 사이즈 : 1000px * 400px'],
}) => {
  return (
    <div style={{ marginBlock: 10, display: 'flex', flexDirection: 'column' }}>
      {guideList.map((guide) => (
        <Typography.Text>{guide}</Typography.Text>
      ))}
    </div>
  );
};

export default React.memo(ImageUploadGuide);
