import Postcode from '@actbase/react-daum-postcode';
import { Button, Checkbox, Descriptions, Input, Modal, Radio, Select, Space, Typography } from 'antd';
import produce from 'immer';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import handleError from '#utils/handleError';
import InputMoney from '#components/Input/InputMoney';
import useFetch from '#hooks/useFetch';
import { restApi } from '#apis/index';

export const DELIVERY_TYPE = {
  PARCEL: '택배',
  SELF: '직접배송',
};

export const DELIVERY_PAYMENT_TYPE = {
  COLLECTIBLE: 'DPT001',
  ADVANCE: 'DPT002',
};

export const DELIVERY_METHOD_TYPE = {
  DEFAULT: 'DEFAULT',
};

export const DELIVERY_PRICE_TYPE = {
  FREE: 'DFT001',
  CHARGED: 'DFT002',
  CONDITIONALLY_FREE: 'DFT003',
  BY_COUNT: 'DFT004',
};

function Delivery({ form, setForm }) {
  const codesTree = useSelector((selector) => selector?.codes?.tree ?? {}, shallowEqual);
  const deliveryCompanyOptions = React.useMemo(
    () => codesTree?.DV?.items?.map(({ code, label }) => ({ label, value: code })) ?? [],
    [codesTree],
  );
  const templates = useFetch([]);
  const template = useFetch();
  const templatesOptions = React.useMemo(
    () => templates.data.map((template) => ({ label: template.name, value: template.id })),
    [templates.data],
  );

  const init = React.useCallback(() => {
    templates
      .fetch(
        restApi
          .get('/templates', { params: { limit: 100 } })
          .then(({ data, ...props }) => ({ ...props, data: data.results })),
      )
      .catch(handleError);
  }, [templates]);

  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    if (!templatesOptions) return;
    if (templatesOptions.length === 0) {
      setForm(
        produce((draft) => {
          draft.isTemplateSave = true;
        }),
      );
    } else {
      setForm(
        produce((draft) => {
          draft.isTemplateSave = false;
        }),
      );
    }
  }, [templatesOptions]);

  return (
    <Descriptions colon={false} bordered>
      <Descriptions.Item labelStyle={{ width: 180 }} label="배송비 템플릿 *" span={3}>
        <Space direction="vertical" style={{ width: '100%' }}>
          {templatesOptions?.length > 0 && (
            <Select
              value={form.template}
              placeholder="배송비 템플릿을 선택하세요"
              onChange={(value) => {
                template
                  .fetch(restApi.get(`/templates/${value}`))
                  .then(({ data: responseData }) => {
                    setForm(
                      produce((draft) => {
                        draft.template = responseData.id;
                        draft.templateName = responseData.name;
                        draft.deliveryType = responseData.methods;
                        draft.isVisit = responseData.isVisit;
                        draft.sellerAddr = responseData.sellerAddress
                          ? {
                              jibunAddress: responseData.sellerAddress,
                              zonecode: responseData.sellerZipcode,
                            }
                          : null;
                        draft.deliveryPriceType = responseData.deliveryFreeTypeCode;
                        draft.deliveryPrice = responseData.deliveryAmount;
                        draft.deliveryPriceSum = responseData.deliveryFreeAmount;
                        draft.deliveryPriceRepeat = responseData.deliveryFreeCount;
                        draft.deliveryPaymentType = responseData.deliveryPaymentTypeCode;
                        draft.deliveryCompany = responseData.courierCode;
                        draft.isInstallationAmount = responseData.isInstallationAmount;
                        draft.returnDeliveryCompany = responseData.refundCourierCode;
                        draft.returnDeliveryPrice = responseData.refundDeliveryAmount;
                        draft.exchangeDeliveryPrice = responseData.changeDeliveryAmount;
                        draft.returnAddr = {
                          jibunAddress: responseData.refundAddress,
                          zonecode: responseData.refundZipcode,
                        };
                        draft.forwardingLocation = {
                          jibunAddress: responseData.cargoAddress,
                          zonecode: responseData.cargoZipcode,
                        };
                        draft.deliverySpecialPrice = responseData.extraAmount;
                      }),
                    );
                  })
                  .catch((error) => {
                    handleError(error);
                    Modal.warn({
                      title: '배송비 템플릿',
                      content: '배송비 템플릿 설정에 실패했습니다.',
                      centered: true,
                    });
                  });
              }}
              options={templatesOptions}
              loading={templates.loading || template.loading}
              style={{ width: 220 }}
            />
          )}

          <Space align="center">
            <Typography.Text>배송비 템플릿명</Typography.Text>
            <Input
              value={form.templateName}
              onChange={(event) => {
                setForm(
                  produce((draft) => {
                    draft.templateName = event.target.value;
                  }),
                );
              }}
            />
            <Checkbox
              checked={form.isTemplateSave}
              onChange={(event) => {
                setForm(
                  produce((draft) => {
                    draft.isTemplateSave = event.target.checked;
                  }),
                );
              }}
            >
              신규 배송비 템플릿으로 저장
            </Checkbox>
          </Space>
        </Space>
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ width: 180 }} label="배송방법 *" span={3}>
        <Space direction="vertical" style={{ width: '100%' }} size="middle">
          <Radio.Group
            value={form.deliveryType}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.deliveryType = event.target.value;
                }),
              );
            }}
            defaultValue={DELIVERY_TYPE.PARCEL}
            options={[
              { label: '택배, 소포, 등기', value: DELIVERY_TYPE.PARCEL },
              { label: '직접배송(화물배달)', value: DELIVERY_TYPE.SELF },
            ]}
            optionType="button"
            buttonStyle="solid"
          />
          <Checkbox
            checked={form.isVisit}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.isVisit = event.target.checked;
                }),
              );
            }}
          >
            방문수령
          </Checkbox>
          {form.isVisit && (
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  Modal.info({
                    title: '판매자 주소',
                    content: (
                      <Postcode
                        style={{ marginLeft: -30 }}
                        onSelected={(data) => {
                          setForm(
                            produce((draft) => {
                              draft.sellerAddr = data;
                            }),
                          );
                          Modal.destroyAll();
                        }}
                      />
                    ),
                    width: 600,
                    closable: true,
                    centered: true,
                    okButtonProps: { style: { display: 'none' } },
                  });
                }}
              >
                판매자 주소
              </Button>
              {form.sellerAddr && (
                <Typography.Text>
                  {form.sellerAddr.jibunAddress} {form.sellerAddr.buildingName} (우: {form.sellerAddr.zonecode})
                </Typography.Text>
              )}
            </Space>
          )}
          <Checkbox
            checked={form.isQuick}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.isQuick = event.target.checked;
                }),
              );
            }}
          >
            퀵서비스
          </Checkbox>
          <Input
            value={form.deliveryDetails}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.deliveryDetails = event.target.value;
                }),
              );
            }}
            placeholder="상세내용을 입력해주세요."
          />
        </Space>
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ width: 180 }} label="배송속성 *" span={3}>
        <Space direction="vertical" style={{ width: '100%' }} size="middle">
          <Radio.Group
            value={form.deliveryMethodType}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.deliveryMethodType = event.target.value;
                }),
              );
            }}
            defaultValue={DELIVERY_METHOD_TYPE.DEFAULT}
            options={[{ label: '일반배송', value: DELIVERY_METHOD_TYPE.DEFAULT }]}
            optionType="button"
            buttonStyle="solid"
          />
          <Checkbox
            checked={form.isCheckOrder}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.isCheckOrder = event.target.checked;
                }),
              );
            }}
          >
            주문확인 후 제작
          </Checkbox>
        </Space>
      </Descriptions.Item>
      {form.isCheckOrder && (
        <Descriptions.Item labelStyle={{ width: 180 }} label="발송예정일 *" span={3}>
          <Space align="center">
            <Select
              value={form.deliveryDate}
              onChange={(value) => {
                setForm(
                  produce((draft) => {
                    draft.deliveryDate = value;
                  }),
                );
              }}
              placeholder="일자를 선택해주세요."
              options={[...Array(13)].map((_, index) => ({ label: index + 2, value: index + 2 }))}
            />
            <Typography.Text>일 이내 발송 예정</Typography.Text>
          </Space>
        </Descriptions.Item>
      )}
      <Descriptions.Item labelStyle={{ width: 180 }} label="상품별 배송비 *" span={3}>
        <Space align="center">
          <Select
            value={form.deliveryPriceType}
            onChange={(value) => {
              setForm(
                produce((draft) => {
                  draft.deliveryPriceType = value;
                }),
              );
            }}
            defaultValue={DELIVERY_PRICE_TYPE.FREE}
            options={[
              { label: '무료', value: DELIVERY_PRICE_TYPE.FREE },
              { label: '유료', value: DELIVERY_PRICE_TYPE.CHARGED },
              { label: '조건부 무료', value: DELIVERY_PRICE_TYPE.CONDITIONALLY_FREE },
              { label: '수량별', value: DELIVERY_PRICE_TYPE.BY_COUNT },
            ]}
            style={{ width: 120 }}
          />
          <Select
            value={form.deliveryCompany}
            onChange={(value) => {
              setForm(
                produce((draft) => {
                  draft.deliveryCompany = value;
                }),
              );
            }}
            options={deliveryCompanyOptions}
            placeholder="택배사 선택"
            style={{ width: 200 }}
          />
          <Checkbox
            checked={form.isDefaultDeliveryCompany}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.isDefaultDeliveryCompany = event.target.checked;
                }),
              );
            }}
          >
            배송 기본 택배사로 설정
          </Checkbox>
        </Space>
      </Descriptions.Item>
      {[DELIVERY_PRICE_TYPE.CHARGED, DELIVERY_PRICE_TYPE.CONDITIONALLY_FREE, DELIVERY_PRICE_TYPE.BY_COUNT].includes(
        form.deliveryPriceType,
      ) && (
        <Descriptions.Item labelStyle={{ width: 180 }} label="기본배송비 *" span={3}>
          <InputMoney
            value={form.deliveryPrice}
            onChange={(value) => {
              setForm(
                produce((draft) => {
                  draft.deliveryPrice = value;
                }),
              );
            }}
            placeholder="숫자만 입력"
            style={{ width: 200 }}
            suffix="원"
          />
        </Descriptions.Item>
      )}
      {[DELIVERY_PRICE_TYPE.CONDITIONALLY_FREE].includes(form.deliveryPriceType) && (
        <Descriptions.Item labelStyle={{ width: 180 }} label="배송비 조건 *" span={3}>
          <Space direction="vertical">
            <Typography.Text>상품 판매가 합계 (할인이 적용되지 않은 판매가 + 옵션가 포함금액)</Typography.Text>
            <Space>
              <InputMoney
                value={form.deliveryPriceSum}
                onChange={(value) => {
                  setForm(
                    produce((draft) => {
                      draft.deliveryPriceSum = value;
                    }),
                  );
                }}
                placeholder="숫자만 입력"
                style={{ width: 200 }}
                suffix="원"
              />
              <Typography.Text>이상 무료</Typography.Text>
            </Space>
          </Space>
        </Descriptions.Item>
      )}
      {[DELIVERY_PRICE_TYPE.BY_COUNT].includes(form.deliveryPriceType) && (
        <Descriptions.Item labelStyle={{ width: 180 }} label="배송비 조건 *" span={3}>
          <Space>
            <InputMoney
              placeholder="숫자만 입력"
              value={form.deliveryPriceRepeat}
              onChange={(value) => {
                setForm(
                  produce((draft) => {
                    draft.deliveryPriceRepeat = value;
                  }),
                );
              }}
              style={{ width: 200 }}
              suffix="개"
            />
            <Typography.Text>마다 기본 배송비 반복부과</Typography.Text>
          </Space>
        </Descriptions.Item>
      )}
      {[DELIVERY_PRICE_TYPE.CHARGED, DELIVERY_PRICE_TYPE.CONDITIONALLY_FREE, DELIVERY_PRICE_TYPE.BY_COUNT].includes(
        form.deliveryPriceType,
      ) && (
        <Descriptions.Item labelStyle={{ width: 180 }} label="결제방식 *" span={3}>
          <Radio.Group
            value={form.deliveryPaymentType}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.deliveryPaymentType = event.target.value;
                }),
              );
            }}
            options={[
              { label: '착불', value: DELIVERY_PAYMENT_TYPE.COLLECTIBLE },
              { label: '선결제', value: DELIVERY_PAYMENT_TYPE.ADVANCE },
            ]}
          />
        </Descriptions.Item>
      )}
      <Descriptions.Item
        labelStyle={{ width: 180 }}
        label={
          <>
            제주/도서상간
            <br />
            추가배송비 *
          </>
        }
        span={3}
      >
        <InputMoney
          value={form.deliverySpecialPrice}
          onChange={(value) => {
            setForm(
              produce((draft) => {
                draft.deliverySpecialPrice = value;
              }),
            );
          }}
          placeholder="숫자만 입력"
          style={{ width: 200 }}
          suffix="원"
        />
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ width: 180 }} label="별도 설치비 *" span={3}>
        <Radio.Group
          value={form?.isInstallationAmount}
          options={[
            { label: '있음', value: true },
            { label: '없음', value: false },
          ]}
          optionType="button"
          buttonStyle="solid"
          onChange={(event) => {
            setForm(
              produce((draft) => {
                draft.isInstallationAmount = event.target.value;
              }),
            );
          }}
        />
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ width: 180 }} label="출고지 *" span={3}>
        <Space>
          <Button
            type="primary"
            onClick={() => {
              Modal.info({
                title: '출고지',
                content: (
                  <Postcode
                    style={{ marginLeft: -30 }}
                    onSelected={(data) => {
                      setForm(
                        produce((draft) => {
                          draft.forwardingLocation = data;
                        }),
                      );
                      Modal.destroyAll();
                    }}
                  />
                ),
                width: 600,
                closable: true,
                centered: true,
                okButtonProps: { style: { display: 'none' } },
              });
            }}
          >
            판매자 주소
          </Button>
          {form.forwardingLocation && (
            <Typography.Text>
              {form.forwardingLocation?.jibunAddress ||
                form.forwardingLocation?.address ||
                form.forwardingLocation?.roadAddress}{' '}
              {form.forwardingLocation.buildingName} (우: {form.forwardingLocation.zonecode})
            </Typography.Text>
          )}
        </Space>
      </Descriptions.Item>
    </Descriptions>
  );
}

export default React.memo(Delivery);
