import { getAPIHost } from '#apis';

const parseImageUrl = (id, w = -1, h = -1) => {
  const querystring = [];
  if (w > 0) querystring.push(`w=${w}`);
  if (h > 0) querystring.push(`w=${h}`);

  return `${getAPIHost()}/api/files/${id}${querystring?.length ? `?${querystring.join('&')}` : ''}`;
};

export default parseImageUrl;
