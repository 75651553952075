import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Col, Menu, PageHeader, Popover, Row, Spin } from 'antd';
import { Route, Routes, useNavigate, matchPath, useLocation } from 'react-router-dom';
import { CloseOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons';
import Login from '../components/auth/Login';
import useRoutes from './useRoutes';
import styles from './BasicLayout.module.less';
import { clearPrincipal } from '../data/reducers/authReducer';
import AuthAside from '../components/auth/Aside';
import { restApi } from '../apis';

const childRenderer = (page, parent) => {
  const args = {
    path: [parent, page.path].filter((v) => !!v).join('/'),
  };
  if (page.children?.length) {
    // , args.path
    args.path += '/*';
    args.children = page.children?.map((child) => childRenderer(child));
  }
  if (page.screen) {
    const Elem = page.screen;
    args.element = <Elem />;
  }
  return <Route key={page.path} {...args} />;
};

function BasicLayout({ loading }) {
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const { routes, pages } = useRoutes();
  const routeMenu = routes.map((route) => route.path);
  const [sideOpen, setSideOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPage = React.useMemo(
    () => pages.find(({ path }) => matchPath(path, location.pathname)),
    [pages, location],
  );
  const handleLogout = async () => {
    if (!window.confirm('로그아웃 하시겠습니까?')) {
      return;
    }
    localStorage.clear();
    dispatch(clearPrincipal());

    restApi.defaults.headers.common.authorization = null;
    navigate('/login');
  };

  //deps에 따른 openkey 변경
  const currentRoute = location.pathname.includes('member/partner')
    ? ['/' + location.pathname.split('/').splice(0, 2).join(''), '/member/p-menu']
    : location.pathname.includes('member/md')
    ? ['/' + location.pathname.split('/').splice(0, 2).join(''), '/member/m-menu']
    : location.pathname.includes('sales/calculate/')
    ? ['/' + location.pathname.split('/').splice(0, 2).join(''), '/sales/calculate']
    : location.pathname === '/'
    ? ['/']
    : ['/' + location.pathname.split('/').splice(0, 2).join('')];
  const [openKeys, setOpenKeys] = useState(currentRoute);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (routeMenu.indexOf(latestOpenKey) === -1) {
      if (keys.length >= 3) {
        setOpenKeys(keys.splice(1, keys.length - 2));
      }
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  return (
    <div
      className={`${styles.basic_layout} ${!principal || loading ? styles.auth : ''} ${
        sideOpen ? styles.side_open : ''
      }`}
    >
      <aside>
        <div className={styles.intro_bg} />
        <div className={styles.bi}>
          <img
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/')}
            src="/assets/logo_white.png"
            alt="main logo"
          />
          {!!principal && (
            <Button
              onClick={() => setSideOpen((x) => !x)}
              type="text"
              icon={<CloseOutlined style={{ color: '#fff' }} />}
            />
          )}
        </div>
        {!principal ? (
          <div className={styles.center_contents}>
            <Helmet>
              <title>홈그릿 관리자</title>
            </Helmet>
            <AuthAside />
          </div>
        ) : (
          <div className={styles.aside_body}>
            <Helmet>
              <title>{`홈그릿 관리자 | ${currentPage?.title}`}</title>
            </Helmet>
            <nav className={styles.menu}>
              <Menu
                onClick={(item) => {
                  navigate(item.key);
                }}
                style={{ width: '100%' }}
                theme="dark"
                mode="inline"
                selectedKeys={[location.pathname]}
                selectable={false}
                openKeys={openKeys}
                onOpenChange={onOpenChange}
              >
                {routes?.map((route) => {
                  const Icon = route.icon;
                  if (!route.children?.length) {
                    return (
                      <Menu.Item
                        key={route.path}
                        icon={<Icon />}
                        onClick={() => {
                          setOpenKeys(['/']);
                        }}
                      >
                        {route.title}
                      </Menu.Item>
                    );
                  }
                  const items = route?.children ?? [];

                  const grouped = items.reduce((a, c) => {
                    const group = c.group?.replace(/\s+/g, '_') || '_';
                    if (!a[group]) a[group] = [];
                    a[group].push(c);
                    return a;
                  }, {});

                  //2deps 출력용 변수
                  const partnerGroup = grouped['_'].filter((child) => {
                    if (child.path.includes('member/partner')) {
                      return { path: child.path, title: child.title };
                    }
                  });
                  const mdGroup = grouped['_'].filter((child) => {
                    if (child.path.includes('member/md')) {
                      return { path: child.path, title: child.title };
                    }
                  });
                  const calcGroup = grouped['_'].filter((child) => {
                    if (child.path.includes('sales/calculate/')) {
                      return { path: child.path, title: child.title };
                    }
                  });
                  const keys = Object.keys(grouped).filter((v) => v !== '_');

                  return (
                    <Menu.SubMenu key={route.path} icon={<Icon />} title={route.title}>
                      {keys.map((key) => (
                        <Menu.ItemGroup key={key} title={key.replace(/\_/g, ' ')}>
                          {grouped[key].map((menu) => (
                            <Menu.Item key={menu.path}>{menu.title}</Menu.Item>
                          ))}
                        </Menu.ItemGroup>
                      ))}
                      {grouped['_'].map((child) => {
                        if (
                          !child.path.includes('member/partner') &&
                          !child.path.includes('member/md') &&
                          !child.path.includes('sales/calculate/')
                        )
                          return <Menu.Item key={child.path}>{child.title}</Menu.Item>;
                      })}
                      {partnerGroup.length > 0 && (
                        <Menu.SubMenu openKeys={['/p-menu']} title={'파트너스'} key={'/member/p-menu'}>
                          {partnerGroup.map((partner) => (
                            <Menu.Item key={partner.path} style={{ backgroundColor: '#253772' }}>
                              {partner.title}
                            </Menu.Item>
                          ))}
                        </Menu.SubMenu>
                      )}
                      {mdGroup.length > 0 && (
                        <Menu.SubMenu title={'MD'} key={'/member/m-menu'}>
                          {mdGroup.map((md) => (
                            <Menu.Item key={md.path} style={{ backgroundColor: '#253772' }}>
                              {md.title}
                            </Menu.Item>
                          ))}
                        </Menu.SubMenu>
                      )}
                      {calcGroup.length > 0 && (
                        <Menu.SubMenu title={'정산신청내역'} key={'/sales/calculate'}>
                          {calcGroup.map((calc) => (
                            <Menu.Item key={calc.path} style={{ backgroundColor: '#253772' }}>
                              {calc.title}
                            </Menu.Item>
                          ))}
                        </Menu.SubMenu>
                      )}
                    </Menu.SubMenu>
                  );
                })}
              </Menu>
            </nav>
          </div>
        )}
      </aside>

      {loading ? (
        <div className={styles.auth_body}>
          <Spin />
        </div>
      ) : (
        <>
          {!principal ? (
            <div className={styles.auth_body}>
              <Login />
            </div>
          ) : (
            <div className={styles.body}>
              <header>
                <Row gutter={5}>
                  <Col className={styles.menu_button}>
                    <Button onClick={() => setSideOpen((x) => !x)} shape="circle" icon={<MenuOutlined />} />
                  </Col>
                  <Col flex="1" />
                  <Popover
                    placement="bottomLeft"
                    trigger="click"
                    content={
                      <Button type="text" onClick={handleLogout}>
                        로그아웃
                      </Button>
                    }
                  >
                    <Col>
                      <Button shape="circle" icon={<UserOutlined />} />
                    </Col>
                  </Popover>
                </Row>
              </header>
              <main>
                <PageHeader title={currentPage?.title} />
                <Routes>{pages?.filter((v) => !!v.screen)?.map((page) => childRenderer(page, undefined))}</Routes>
              </main>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default BasicLayout;
