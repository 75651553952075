import FetchElement from '#components/FetchElement';
import useMount from '#hooks/useMount';
import formatComma from '#utils/formatComma';
import produce from 'immer';
import moment from 'moment';
import React from 'react';
import { Button, Modal, Space, Table, Typography } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import CommTable from '#components/table/CommTable';
import { restApi } from '#apis/index';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const MemoizedContentsPopularPage = React.memo(({ data, used = [], setData, refresh }) => {
  const newUsed = [...used].sort((a, b) => a.exposeNum - b.exposeNum);

  if (!data) {
    return null;
  }

  return (
    <>
      <style jsx>{`
        .row-dragging {
          background: #fafafa;
        }

        .row-dragging td {
          padding: 16px;
          vertical-align: middle;
        }

        .row-dragging .drag-visible {
          visibility: visible;
        }
      `}</style>
      <Space style={{ width: '100%', maxWidth: 1200 }} direction="vertical" size="large">
        <Space style={{ width: '100%', marginBottom: 50 }} direction="vertical">
          <Typography.Paragraph>현재 사용 목록</Typography.Paragraph>
          <Table
            dataSource={newUsed}
            columns={[
              {
                title: '노출순서',
                width: 80,
                render: (value, record, index) => index + 1,
              },
              {
                title: '테마명',
                dataIndex: 'themeName',
                align: 'left',
                render: (value, record) => <Link to={`/contents/popular/${record?.id}`}>{value}</Link>,
              },
              {
                title: '컨텐츠 수',
                dataIndex: 'count',
                width: 120,
                render: (value) => `${formatComma(value)}개`,
              },
              {
                title: '노출 기간',
                width: 300,
                render: (value, record) =>
                  `${moment(record.startAt).format('YYYY-MM-DD HH:mm')} ~ ${moment(record.endAt).format(
                    'YYYY-MM-DD HH:mm',
                  )}`,
              },
              {
                title: '사용 여부',
                dataIndex: 'id',
                width: 120,
                render: (value) => (
                  <Button
                    type="danger"
                    onClick={() => {
                      Modal.confirm({
                        title: '노출 중지',
                        content: '해당 테마의 노출을 중지하시겠습니까 ?',
                        onOk: () => {
                          return restApi
                            .put(`/cms/displays/active/${value}`)
                            .then(refresh)
                            .catch((error) => {
                              Modal.error({
                                title: '노출 중지',
                                content: error?.response?.data?.message ?? '노출 중지에 실패했습니다.',
                                centered: true,
                                okText: '확인',
                              });
                            });
                        },
                        centered: true,
                        okText: '확인',
                        cancelText: '취소',
                      });
                    }}
                  >
                    노출 중지
                  </Button>
                ),
              },
              {
                width: 50,
                className: 'drag-visible',
                render: () => {
                  return <DragHandle />;
                },
              },
            ]}
            components={{
              body: {
                wrapper: (props) => (
                  <SortableBody
                    useDragHandle
                    disableAutoscroll
                    helperClass="row-dragging"
                    onSortEnd={({ oldIndex, newIndex }) => {
                      if (oldIndex !== newIndex) {
                        const changedData = arrayMoveImmutable([...data.used], oldIndex, newIndex)
                          .map((value, index) => ({ ...value, exposeNum: index }))
                          .filter(Boolean);
                        setData(
                          produce((draft) => {
                            draft.used = changedData;
                          }),
                        );
                        return restApi.put(
                          '/cms/banners/expose-num',
                          changedData.map(({ id, exposeNum }) => ({ id, exposeNum })),
                        );
                      }
                    }}
                    {...props}
                  />
                ),
                row: (props) => {
                  return <SortableItem index={used?.findIndex((e) => e.id === props['data-row-key'])} {...props} />;
                },
              },
            }}
            rowKey={({ id }) => id}
            pagination={false}
          />
        </Space>
        <Space style={{ width: '100%' }} direction="vertical">
          <Typography.Paragraph style={{ marginBottom: -20 }}>미사용 배너</Typography.Paragraph>
          <CommTable
            url="/cms/displays/unused"
            then={({ data: responseData }) => ({ data: responseData?.unused })}
            params={{ placeCode: 'DP001' }}
            columns={({ pageCur, limit }) => [
              {
                title: '번호',
                width: 80,
                render: (value, record, index) => pageCur * limit + index + 1,
              },
              {
                title: '테마명',
                dataIndex: 'themeName',
                align: 'left',
                render: (value, record) => <Link to={`/contents/popular/${record?.id}`}>{value}</Link>,
              },
              {
                title: '컨텐츠 수',
                dataIndex: 'count',
                width: 120,
                render: (value) => `${formatComma(value)}개`,
              },
              {
                title: '노출 기간',
                width: 300,
                render: (value, record) =>
                  `${moment(record.startAt).format('YYYY-MM-DD HH:mm')} ~ ${moment(record.endAt).format(
                    'YYYY-MM-DD HH:mm',
                  )}`,
              },
            ]}
            headerExtra={
              <Link to="/contents/popular/regist">
                <Button type="primary">등록</Button>
              </Link>
            }
          />
        </Space>
      </Space>
    </>
  );
});

function ContentsPopularPage() {
  const fetchElementRef = React.useRef();

  return (
    <FetchElement
      ref={fetchElementRef}
      fetch={() =>
        restApi.get('/cms/displays/used', {
          params: { placeCode: 'DP001' },
        })
      }
      Component={(data) => <MemoizedContentsPopularPage {...data} />}
    />
  );
}

export default ContentsPopularPage;
