import React from 'react';
import FetchElement from '#components/FetchElement';
import { restApi } from '#apis/index';
import styles from './StoreDetails.module.less';

const MemoizedStoretDetails = React.memo(
  ({
    storeName,
    reviewCount,
    images,
    addrMap,
    addrDetail,
    business,
    phone,
    storeOpens,
    holiday,
    logo,
    // orderDealerNumber,
  }) => {
    return (
      // <div>hihi</div>
      <div className={styles.container}>
        {!!logo && (
          <div className={styles.mainLogo}>
            <img className={styles.thumbnail} src={logo} alt="상점 로고" />
          </div>
        )}

        <h2 className={styles.storeName}>{storeName || ''}</h2>
        <div className={styles.mainContainer}>
          <div className={styles.navigationContainer}>
            <a className={`${styles.navigation} ${styles.active}`}>상품정보</a>
            <a className={styles.navigation}>
              리뷰 <b className={styles.count}>{reviewCount ?? 0}</b>
            </a>
          </div>
        </div>
        <div className={styles.sectionWrapper}>
          <h4 className={styles.sectionTitle}>상점정보</h4>
          <dl className={styles.deliveryInfo}>
            <dt className={styles.deliveryInfoTitle}>주소</dt>
            <dd className={styles.deliveryInfoDescription}>{`${addrMap || ''} ${addrDetail}`}</dd>
          </dl>
          <dl className={styles.deliveryInfo}>
            <dt className={styles.deliveryInfoTitle}>전화번호</dt>
            <dd className={styles.deliveryInfoDescription}>{phone ?? ''}</dd>
          </dl>
          <dl className={styles.deliveryInfo}>
            <dt className={styles.deliveryInfoTitle}>운영시간</dt>
            <dd className={styles.deliveryInfoDescription}>
              {storeOpens?.map((time) => (
                <div>{time}</div>
              ))}
            </dd>
          </dl>
          <dl className={styles.deliveryInfo}>
            <dt className={styles.deliveryInfoTitle}>휴무일</dt>
            <dd className={styles.deliveryInfoDescription}>{holiday ? `${holiday}` : '없음'}</dd>
          </dl>
          <button className={styles.moreButton} type="button">
            문의하기
          </button>
        </div>

        <div className={styles.sectionWrapper}>
          <h4 className={styles.sectionTitle}>상점소개</h4>
          <p style={{ paddingBottom: '8px' }}>테스트를 위한 상점입니다.</p>
          {images?.length > 0 &&
            images.map((image) => (
              <div className={styles.thumbnailContainer}>
                <img className={styles.thumbnail} src={image} alt="상점 소개 이미지" />
              </div>
            ))}
        </div>

        <div className={styles.sectionWrapper}>
          <h4 className={styles.sectionTitle}>사업자정보</h4>
          <dl className={styles.deliveryInfo}>
            <dt className={styles.deliveryInfoTitle}>대표자명</dt>
            <dd className={styles.deliveryInfoDescription}>{business?.representative || '-'}</dd>
          </dl>
          <dl className={styles.deliveryInfo}>
            <dt className={styles.deliveryInfoTitle}>상호명</dt>
            <dd className={styles.deliveryInfoDescription}>{business?.businessName || ''}</dd>
          </dl>
          <dl className={styles.deliveryInfo}>
            <dt className={styles.deliveryInfoTitle}>통신판매업</dt>
            <dd className={styles.deliveryInfoDescription}>{business?.orderDealerNumber ?? ''}</dd>
          </dl>
          <dl className={styles.deliveryInfo}>
            <dt className={styles.deliveryInfoTitle}>사업자번호</dt>
            <dd className={styles.deliveryInfoDescription}>{business?.businessNumber || ''}</dd>
          </dl>
        </div>
      </div>
    );
  },
);

function StoretDetails({ storeId }) {
  return (
    <FetchElement
      fetch={() => restApi.get(`/stores/${storeId}/detail`)}
      Component={(data) => <MemoizedStoretDetails storeId={storeId} {...data} />}
    />
  );
}

export default StoretDetails;
