import React from 'react';
import { Button, Checkbox, Col, Divider, Input, Row, Select, Space } from 'antd';
import CommTable from '#components/table/CommTable';
import SearchForm from '#components/SearchForm';
import produce from 'immer';
import { Link } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

function PostsNoticePartnerPage() {
  const { tree: codesTree, map: codesMap } = useSelector((selector) => selector.codes, shallowEqual);

  const initialParams = React.useMemo(
    () => ({
      searchType: null,
      keyword: null,
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);

  return (
    <Space style={{ width: '100%', maxWidth: 1000 }} direction="vertical">
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initialParams);
          return initialParams;
        }}
      >
        <Row gutter={10} align="middle">
          <Col>
            <Select
              placeholder="검색 옵션"
              style={{ width: 120 }}
              value={params.searchType}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.searchType = value;
                  }),
                );
              }}
              options={[
                { label: '제목', value: 'TITLE' },
                { label: '내용', value: 'CONTENTS' },
              ]}
            />
          </Col>
          <Col>
            <Input
              placeholder="키워드를 입력하세요"
              style={{ width: 200 }}
              value={params.keyword}
              onChange={(event) => {
                setParams(
                  produce((draft) => {
                    draft.keyword = event.target.value;
                  }),
                );
              }}
            />
          </Col>
          <Col>
            <Space>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Col>
        </Row>
      </SearchForm>
      <Divider />
      <CommTable
        url="/cms/notices/partners"
        columns={({ pageCur, limit }) => [
          {
            title: '번호',
            width: 80,
            render: (_, $, index) => pageCur * limit + index + 1,
          },
          {
            title: '카테고리',
            dataIndex: 'categoryCode',
            width: 150,
            render: (value) => codesMap[value],
          },
          {
            title: '제목',
            dataIndex: 'title',
            align: 'left',
            render: (value, record) => <Link to={`/posts/notice/partner/detail/${record?.id}`}>{value}</Link>,
          },
          {
            title: '사용여부',
            dataIndex: 'active',
            width: 80,
            render: (value) => (value ? '사용' : '미사용'),
          },
          {
            title: '등록일',
            dataIndex: 'createdAt',
            width: 120,
            render: (value) => value,
          },
        ]}
        headerExtra={
          <Link to="/posts/notice/partner/regist">
            <Button type="primary">등록</Button>
          </Link>
        }
      />
    </Space>
  );
}

export default PostsNoticePartnerPage;
