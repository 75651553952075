import React from 'react';
import { Space, Table } from 'antd';
import useTable from '../../hooks/useTable';
import CommTableHeader from './CommTableHeader';
import usePagination from '../../hooks/usePagination';

const CommTable = React.forwardRef(
  (
    {
      url,
      params,
      columns,
      scroll,
      customPagination,
      onAdd,
      onFetch,
      toggleObj,
      renderHeader,
      onLoadData,
      onChangePagination,
      headerExtra,
      then,
      excel,
      reloadData = false,
      codeMap,
      ...args
    },
    ref,
  ) => {
    const { data, setData, loadData, isLoading } = useTable({ then, url, params, onFetch, onLoadData });
    const { pagination, paging } = usePagination({ data, onChangePagination });
    const path = window.location.pathname.split('/');
    const title = path.length >= 3 ? path[path.length - 1] : path[1];
    const dataSource = React.useMemo(() => data?.results ?? [], [data]);
    const ObjToChangeKey = React.useMemo(() => {
      if (Array.isArray(columns)) {
        return Object.fromEntries(columns.map((el) => [el.dataIndex, el.title]));
      } else {
        return false;
      }
    }, [columns]);
    React.useImperativeHandle(ref, () => ({
      data,
      setData,
      loadData,
      isLoading,
      dataSource,
    }));
    React.useEffect(() => {
      if (reloadData) {
        loadData();
      }
    }, [reloadData]);
    return (
      <Space direction="vertical" style={{ width: '100%', marginTop: 20 }}>
        <CommTableHeader
          toggleObj={toggleObj}
          paging={paging}
          onAdd={onAdd}
          extra={headerExtra}
          excel={excel}
          dataSource={dataSource}
          title={title}
          ObjToChangeKey={ObjToChangeKey}
          codeMap={codeMap}
        />
        {renderHeader}
        <Table
          dataSource={dataSource}
          columns={typeof columns === 'function' ? columns(data ?? {}) : columns}
          scroll={scroll}
          pagination={customPagination ?? pagination}
          loading={isLoading}
          {...args}
        />
      </Space>
    );
  },
);

export default CommTable;
