import { restApi } from '#apis/index';
import handleError from '#utils/handleError';
import produce from 'immer';
import React from 'react';
import { Button, Space, Select, Radio, Row, Col, Modal } from 'antd';
import SearchForm from '#components/SearchForm';
import CommTable from '#components/table/CommTable';
import useFetch from '#hooks/useFetch';
import useIndex from '#hooks/useIndex';
import ChangeAdPriceBtn from '#components/advertise/ChangeAdPriceBtn';
import InputMoney from '#components/Input/InputMoney';
import AddAdvertisement from '#components/advertise/AddAdvertisement';

function Advertise() {
  const { getIndex } = useIndex();
  const [defaultPrice, setDefaultPrice] = React.useState(0);
  const [priceToChange, setPriceToChange] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const initialParams = React.useMemo(
    () => ({
      page: 0,
      range: 10,
      adFilterType: 'ALL',
    }),
    [],
  );

  const [params, setParams] = React.useState(initialParams);
  const tableRef = React.useRef();
  const areaCodes = useFetch();
  const areaCodes2 = useFetch();

  React.useEffect(() => {
    areaCodes.fetch(restApi.get('/area-codes/filter')).catch(handleError);
    restApi
      .get('/cms/advertisement/area/default-price')
      .then(({ data }) => {
        setDefaultPrice(data);
      })
      .catch(handleError);
  }, []);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initialParams);
          return initialParams;
        }}
      >
        <Row gutter={10} style={{ marginBottom: 10, alignItems: 'center', rowGap: 10 }}>
          <Col>
            <Select
              value={params.extra1}
              loading={areaCodes.loading || areaCodes2.loading}
              onChange={async (value) => {
                await areaCodes2.fetch(restApi.get(`/area-codes/filter/${value}`));
                setParams(
                  produce((draft) => {
                    draft.extra1 = value;
                    draft.extra2 = null;
                  }),
                );
              }}
              options={areaCodes.data?.map((area) => ({ label: area, value: area })) ?? []}
              style={{ width: 200 }}
              disabled={!(areaCodes.data?.length > 0)}
              placeholder="시/도"
              allowClear
            />
          </Col>
          <Col>
            <Select
              value={params.extra2}
              loading={areaCodes2.loading}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.extra2 = value;
                  }),
                );
              }}
              options={areaCodes2.data?.map((area) => ({ label: area, value: area })) ?? []}
              style={{ width: 200 }}
              disabled={!(areaCodes2.data?.length > 0)}
              placeholder="시/군/구"
              allowClear
            />
          </Col>
          <Col>
            <Radio.Group
              value={params.adFilterType}
              onChange={(event) => {
                setParams(
                  produce((draft) => {
                    draft.adFilterType = event.target.value;
                  }),
                );
              }}
              options={[
                { label: '전체', value: 'ALL' },
                { label: '광고 있는 지역만 보기', value: 'ADON' },
                { label: '광고 없는 지역만 보기', value: 'ADOFF' },
              ]}
              buttonStyle="solid"
              optionType="button"
            />
          </Col>
          <Col>
            <Space>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Col>
        </Row>
      </SearchForm>
      <Row gutter={10} style={{ marginBottom: 10, alignItems: 'center', rowGap: 10 }}>
        <Col>기본 가격설정(1일기준)</Col>
        <Col>
          <InputMoney value={priceToChange ?? defaultPrice} placeholder="기본 가격 입력" onChange={setPriceToChange} />
        </Col>
        <Col>
          <Space>
            <Button
              type="primary"
              onClick={async () => {
                try {
                  if (loading || typeof priceToChange !== 'number') return;
                  setLoading(true);
                  if (defaultPrice !== priceToChange) {
                    restApi.put('/cms/advertisement/area/default-price', { defaultPrice: priceToChange });
                    setDefaultPrice(priceToChange);
                  }
                  Modal.success({
                    title: '성공',
                    content: '기본 가격을 변경했습니다.',
                    centered: true,
                    okText: '확인',
                  });
                } catch (e) {
                  Modal.error({
                    title: '실패',
                    content: '기본 가격 변경에 실패했습니다. 잠시후 시도해주세요.',
                    centered: true,
                    okText: '확인',
                  });
                } finally {
                  setLoading(false);
                  setPriceToChange(null);
                }
              }}
            >
              설정
            </Button>
            <Button
              onClick={() => {
                setPriceToChange(null);
              }}
            >
              초기화
            </Button>
          </Space>
        </Col>
      </Row>
      <CommTable
        ref={tableRef}
        url="/cms/advertisement/area"
        columns={[
          {
            title: '번호',
            dataIndex: 'tableIndex',
            index: 'tableIndex',
            fixed: true,
            width: 50,
            render: (_, __, index) => getIndex(index),
          },
          {
            title: '시도',
            dataIndex: 'extra1',
            fixed: true,
            width: 100,
            render: (value) => value,
          },
          {
            title: '시군구',
            dataIndex: 'extra2',
            fixed: true,
            width: 100,
            render: (value) => value,
          },
          {
            title: '광고 수(진행중/전체)',
            dataIndex: 'advertiseNumber',
            width: 200,
            render: (_, row) => `${row.adCount ?? 0}/${row.totalCount ?? 0}`,
          },
          {
            title: '별도 가격',
            dataIndex: 'addPrice',
            width: 200,
            render: (orderAt, row) => (
              <ChangeAdPriceBtn
                onChange={async () => {
                  tableRef.current?.loadData();
                }}
                {...row}
              />
            ),
          },
          {
            title: '등록',
            dataIndex: 'add',
            width: 200,
            render: (_, row) => (
              <Button
                onClick={() => {
                  Modal.info({
                    title: '검색지역광고 등록',
                    width: 1300,
                    content: (
                      <AddAdvertisement
                        defaultPrice={defaultPrice}
                        {...row}
                        onChange={() => {
                          tableRef.current?.loadData();
                        }}
                      />
                    ),
                    centered: true,
                    closable: true,
                    okText: '목록',
                  });
                }}
              >
                등록 현황
              </Button>
            ),
          },
        ]}
        scroll={{ x: 1400 }}
      />
    </Space>
  );
}

export default Advertise;
