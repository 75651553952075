import React from 'react';
import Postcode from '@actbase/react-daum-postcode';
import { Button, Descriptions, Input, Modal, Select, Space, Typography } from 'antd';
import produce from 'immer';
import { shallowEqual, useSelector } from 'react-redux';
import InputMoney from '../../../Input/InputMoney';

function DeliveryChange({ form, setForm }) {
  const codesTree = useSelector((selector) => selector?.codes?.tree ?? {}, shallowEqual);
  const deliveryCompanyOptions = React.useMemo(
    () => codesTree?.DV?.items?.map(({ code, label }) => ({ label, value: code })) ?? [],
    [codesTree],
  );
  return (
    <Descriptions colon={false} bordered>
      <Descriptions.Item labelStyle={{ width: 180 }} label="반품/교환 택배사 *" span={3}>
        <Space>
          <Select
            value={form.returnDeliveryCompany}
            onChange={(value) => {
              setForm(
                produce((draft) => {
                  draft.returnDeliveryCompany = value;
                }),
              );
            }}
            options={deliveryCompanyOptions}
            style={{ width: 200 }}
            placeholder="택배사 선택"
          />
          <Typography.Text>반품택배사 설정 안내</Typography.Text>
        </Space>
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ width: 180 }} label="반품 배송비(편도) *" span={3}>
        <Space>
          <InputMoney
            value={form.returnDeliveryPrice}
            onChange={(value) => {
              setForm(
                produce((draft) => {
                  draft.returnDeliveryPrice = value;
                }),
              );
            }}
            placeholder="숫자만 입력"
            suffix="원"
            style={{ width: 200 }}
          />
          <Typography.Text>최초 배송시의 배송비가 무료인 경우 왕복 배송비 6,000원이 청구됩니다.</Typography.Text>
        </Space>
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ width: 180 }} label="교환 배송비(편도) *" span={3}>
        <InputMoney
          value={form.exchangeDeliveryPrice}
          onChange={(value) => {
            setForm(
              produce((draft) => {
                draft.exchangeDeliveryPrice = value;
              }),
            );
          }}
          placeholder="숫자만 입력"
          suffix="원"
          style={{ width: 200 }}
        />
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ width: 180 }} label="반품/교환지 *" span={3}>
        <Space>
          <Button
            type="primary"
            onClick={() => {
              Modal.info({
                title: '반품/교환지',
                content: (
                  <Postcode
                    style={{ marginLeft: -30 }}
                    onSelected={(data) => {
                      setForm(
                        produce((draft) => {
                          draft.returnAddr = data;
                        }),
                      );
                      Modal.destroyAll();
                    }}
                  />
                ),
                width: 600,
                closable: true,
                centered: true,
                okButtonProps: { style: { display: 'none' } },
              });
            }}
          >
            주소변경하기
          </Button>
          {form.returnAddr && (
            <Typography.Text>
              {form.returnAddr?.jibunAddress || form.returnAddr?.address || form.returnAddr?.roadAddress}{' '}
              {form.returnAddr.buildingName} (우: {form.returnAddr.zonecode})
            </Typography.Text>
          )}
        </Space>
      </Descriptions.Item>
      <Descriptions.Item labelStyle={{ width: 180 }} label="반품교환 안내 *" span={3}>
        <Input.TextArea
          value={form.returnInfo}
          onChange={(event) => {
            setForm(
              produce((draft) => {
                draft.returnInfo = event.target.value;
              }),
            );
          }}
        />
      </Descriptions.Item>
    </Descriptions>
  );
}

export default React.memo(DeliveryChange);
