import React from 'react';
import { Modal, Button, Space, Descriptions, Spin } from 'antd';
import handleError from '#utils/handleError';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import TextArea from 'antd/lib/input/TextArea';
import styles from '../../../Page.module.less';
import { useReduxStore } from '#hooks/useReduxStore';
import { restApi } from '#apis';
import { getFileHostAndFile } from '../../../../apis';
import { formatPhonNumer } from '../../../../utils/formatPhoneNumber';

const cx = classNames.bind(styles);

function MdWaitingDetail() {
  const { map: codesMap } = useReduxStore('codes');
  const { userId } = useParams();
  const [partner, setPartner] = React.useState(null);
  const navigate = useNavigate();

  // 승인처리
  const askApproval = () => {
    return Modal.confirm({
      okText: '확인',
      cancelText: '취소',
      centered: true,
      onOk: async () => {
        try {
          await restApi.post(`/cms/accounts/md/request/${userId}/confirm`);
          loadData();
          Modal.success({
            title: '승인 완료',
            content: '승인을 완료했습니다.',
            centered: true,
            okText: '확인',
          });
          navigate('/member/md/waiting');
        } catch (e) {
          handleError(e);
          console.warn('e ::::: ', e);
        }
      },
      content: (
        <div>
          <p>해당 MD의 회원 승인을 진행하시겠습니까?</p>
        </div>
      ),
    });
  };
  const loadData = () => {
    restApi
      .get(`/cms/accounts/md/unconfirmed/${userId}`)
      .then((response) => setPartner(response.data))
      .catch((e) => console.warn('error in MdWaitingDetail : ', e));
  };

  React.useEffect(() => {
    if (!userId) return;
    loadData();
  }, [userId]);

  // console.log('partner :::: ', partner);
  // console.log('partner.userStatus ::: ', partner?.userStatus);
  // console.log('rejectReason ::: ', rejectReason);
  // console.log('rejectReason :::: ', rejectReason);
  if (!partner) return <Spin />;

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="MD아이디">
            {partner.userId}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="MD이름">
            {partner.userName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="휴대전화">
            {formatPhonNumer(partner.phone)}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="이메일">
            {partner.email}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="연관 파트너사">
            <ul>
              {partner?.storeList.length > 0 ? (
                partner.storeList.map((store) => {
                  return (
                    <li key={store.id} style={{ marginBottom: '10px' }}>
                      <Link to={`/store/management/${store.id}/detail`}>
                        {store.storeName}({store.userId})
                      </Link>
                    </li>
                  );
                })
              ) : (
                <li>X</li>
              )}
            </ul>
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="신분증">
            <a rel="noreferrer" target="_blank" href={getFileHostAndFile(partner.identificationFile)} download>
              <img src={getFileHostAndFile(partner.identificationFile)} alt="신분증.jpg" />
            </a>
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="계좌번호">
            {codesMap?.[partner.bankName]} {partner.accountNumber} / {partner.accountHolder} 판매사
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="승인처리">
            <span style={{ marginRight: 10 }}>
              {codesMap[partner.userStatus]}{' '}
              {(partner.userStatus === 'PAS003' || partner.userStatus === 'PAS002') &&
                `(${partner.handleBy} / ${partner.handleAt})`}
            </span>
            {partner && (
              <>
                <Button onClick={askApproval} style={{ marginRight: 10 }}>
                  승인
                </Button>
              </>
            )}
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button onClick={() => navigate(`/member/md/waiting/detail/modify/${userId}`)}>파트너사 수정</Button>
        <Button onClick={() => navigate('/member/md/waiting')}>목록</Button>
      </div>
    </div>
  );
}

export default MdWaitingDetail;
