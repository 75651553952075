import React from 'react';
import { Descriptions, Modal, Timeline } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

function DeliveryInfo({ children }) {
  const DeliveryInfo2 = () => {
    return Modal.info({
      title: '배송조회',
      width: '500px',
      centered: true,
      content: (
        <>
          <h3>12월 22일(수) 15:14 배송완료 되었습니다.</h3>
          <Descriptions>
            <Descriptions.Item>
              <p>아직 상품을 받지 못하셨다면 택배사로 문의해 주세요</p>
            </Descriptions.Item>
          </Descriptions>
          <Descriptions>
            <Descriptions.Item label="송장번호">12345678</Descriptions.Item>
          </Descriptions>
          <Descriptions style={{ width: '400px' }}>
            <Descriptions.Item style={{ width: '20px' }} label="배송업체">
              CJ대한통운
            </Descriptions.Item>
            <Descriptions.Item style={{ width: '20px' }} label="배송기사">
              박창범
            </Descriptions.Item>
          </Descriptions>
          <Descriptions colon={false}>
            <Descriptions.Item label="배송이력" />
          </Descriptions>
          <Timeline>
            <Timeline.Item color="green" dot={<CheckCircleOutlined />}>
              <Descriptions style={{ height: '40px' }}>
                <Descriptions.Item style={{ width: '250px' }}>
                  <div>
                    <p>OOOOOOOOOOOOOOOO</p>
                    <p>0000-00-00(수) 00:00</p>
                  </div>
                </Descriptions.Item>
                <Descriptions.Item>배달완료</Descriptions.Item>
              </Descriptions>
            </Timeline.Item>
            <Timeline.Item color="gray">
              <Descriptions contentStyle={{ color: 'gray' }} style={{ height: '40px' }}>
                <Descriptions.Item style={{ width: '250px' }}>
                  <div>
                    <p>OOOOOOOOOOOOOOOO</p>
                    <p>0000-00-00(수) 00:00</p>
                  </div>
                </Descriptions.Item>
                <Descriptions.Item>배달중</Descriptions.Item>
              </Descriptions>
            </Timeline.Item>
            <Timeline.Item color="red">
              <Descriptions contentStyle={{ color: 'gray' }} style={{ height: '40px' }}>
                <Descriptions.Item style={{ width: '250px' }}>
                  <div>
                    <p>OOOOOOOOOOOOOOOO</p>
                    <p>0000-00-00(수) 00:00</p>
                  </div>
                </Descriptions.Item>
                <Descriptions.Item>배송중(입고)</Descriptions.Item>
              </Descriptions>
            </Timeline.Item>
            <Timeline.Item>
              <Descriptions style={{ height: '40px' }}>
                <Descriptions.Item style={{ width: '250px' }}>
                  <div>
                    <p>OOOOOOOOOOOOOOOO</p>
                    <p>0000-00-00(수) 00:00</p>
                  </div>
                </Descriptions.Item>
                <Descriptions.Item>배송중(입고)</Descriptions.Item>
              </Descriptions>
            </Timeline.Item>
            <Timeline.Item>
              <Descriptions style={{ height: '40px' }}>
                <Descriptions.Item style={{ width: '250px' }}>
                  <div>
                    <p>OOOOOOOOOOOOOOOO</p>
                    <p>0000-00-00(수) 00:00</p>
                  </div>
                </Descriptions.Item>
                <Descriptions.Item>배송중(출고)</Descriptions.Item>
              </Descriptions>
            </Timeline.Item>
            <Timeline.Item>
              <Descriptions contentStyle={{ color: 'gray' }} style={{ height: '40px' }}>
                <Descriptions.Item style={{ width: '250px' }}>
                  <div>
                    <p>OOOOOOOOOOOOOOOO</p>
                    <p>0000-00-00(수) 00:00</p>
                  </div>
                </Descriptions.Item>
                <Descriptions.Item>집하</Descriptions.Item>
              </Descriptions>
            </Timeline.Item>
          </Timeline>
        </>
      ),
    });
  };
  return <a onClick={DeliveryInfo2}>{children}</a>;
}

export default DeliveryInfo;
