import { Button, Space } from 'antd';
import React from 'react';
import { restApi } from '../../apis';
import InputMoney from '../Input/InputMoney';

const ChangeAdPriceBtn = ({ adCount, addPrice, code, extra1, extra2, totalCount, onChange }) => {
  const [isChangeable, setIsChangeable] = React.useState(false);
  const [price, setPrice] = React.useState(addPrice ?? 0);
  const handleChangeMode = React.useCallback(() => {
    setIsChangeable(true);
  }, []);

  const handleChangeAdPrice = React.useCallback(async () => {
    if (addPrice !== price) {
      await restApi.put(`/cms/advertisement/area/${code}/addPrice`, {
        addPrice: price,
      });
      onChange();
    }
    setIsChangeable(false);
  }, [addPrice, price, code, onChange]);

  React.useEffect(() => {
    setPrice(addPrice);
  }, [addPrice]);

  if (isChangeable) {
    return (
      <Space>
        <InputMoney value={price} onChange={setPrice} />
        <Button onClick={handleChangeAdPrice}>확인</Button>
      </Space>
    );
  }

  return (
    <Space>
      <div style={{ width: 100 }}>{(addPrice ?? 0).toLocaleString()}</div>
      <Button onClick={handleChangeMode}>수정</Button>
    </Space>
  );
};

export default ChangeAdPriceBtn;
