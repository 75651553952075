import React from 'react';
import { Button, Col, Form, Input, Modal, Radio, Row, Space } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../apis';
import { passwordRegex } from '../../common/regex';
import FetchElement from '../../components/FetchElement';

function AdminRegistPage() {
  const params = useParams();
  const { id } = React.useMemo(() => params, [params]);
  const navigate = useNavigate();

  return (
    <FetchElement
      fetch={() => id && restApi.get(`/cms/accounts/admin/${id}`)}
      Component={(responseData) => (
        <Form
          style={{ maxWidth: 800 }}
          initialValues={responseData}
          onFinish={async ({ userId, password, password2, userName, email, phone, active }) => {
            const title = id ? '관리자계정 수정' : ' 관리자계정 등록';
            if (!userId) return Modal.warning({ title, content: '아이디를 입력하세요.', centered: true });
            if (!password || !password2)
              return Modal.warning({ title, content: '비밀번호를 입력하세요.', centered: true });
            if (!userName) return Modal.warning({ title, content: '관리자명을 입력하세요.', centered: true });
            if (!active) return Modal.warning({ title, content: '사용여부를 선택하세요.', centered: true });
            if (!passwordRegex.test(password))
              return Modal.warning({
                title,
                content: (
                  <>
                    입력한 비밀번호 형식이 맞지 않습니다.
                    <br />※ 문자,숫자 조합 / 6~20자
                  </>
                ),
                centered: true,
              });
            if (phone) {
              if (!/^[0-9]+$/.test(phone))
                return Modal.warning({ title, content: '휴대폰 번호는 숫자만 입력할 수 있습니다.', centered: true });
            }

            try {
              if (id) {
                await restApi.put(`/cms/accounts/admin/${id}`, {
                  active,
                  email,
                  password,
                  phone,
                  userId,
                  userName,
                });
                Modal.success({
                  title,
                  content: '관리자계정이 수정되었습니다.',
                  centered: true,
                  onOk: () => {
                    navigate(`/admin/${id}`);
                  },
                });
              } else {
                await restApi.post('/cms/accounts/admin/join', {
                  active,
                  email,
                  password,
                  phone,
                  userId,
                  userName,
                });
                Modal.success({
                  title,
                  content: '관리자계정이 등록되었습니다.',
                  centered: true,
                  onOk: () => {
                    navigate('/admin/list');
                  },
                });
              }
            } catch (error) {
              Modal.error({
                title: '관리자계정 등록',
                content: error?.response?.data?.message ?? '관리자 계정 등록에 실패했습니다',
                centered: true,
              });
            }
          }}
        >
          <Space style={{ width: '100%' }} direction="vertical">
            <Form.Item
              label="아이디"
              name="userId"
              rules={[{ required: true, message: '아이디를 입력해주세요.' }]}
              required
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="비밀번호"
              name="password"
              rules={[{ required: true, message: '비밀번호를 입력해주세요.' }]}
              required
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="비밀번호 확인"
              name="password2"
              rules={[{ required: true, message: '비밀번호를 입력해주세요.' }]}
              required
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="관리자명"
              name="userName"
              rules={[{ required: true, message: '관리자명을 입력해주세요.' }]}
              required
            >
              <Input />
            </Form.Item>
            <Form.Item label="이메일" name="email">
              <Input />
            </Form.Item>
            <Form.Item label="휴대폰번호" name="phone">
              <Input />
            </Form.Item>
            <Form.Item
              label="사용여부"
              name="active"
              rules={[{ required: true, message: '사용여부를 선택해주세요.' }]}
              required
            >
              <Radio.Group
                options={[
                  { label: '사용', value: true },
                  { label: '미사용', value: false },
                ]}
              />
            </Form.Item>
            <Row justify="end" align="middle">
              <Col>
                <Space>
                  <Button
                    onClick={() => {
                      Modal.confirm({
                        title: '관리자계정 등록',
                        content: (
                          <>
                            입력했던 내용이 전부 사라집니다.
                            <br />
                            계속 하시겠습니까?
                          </>
                        ),
                        centered: true,
                        onOk: () => {
                          navigate('/admin');
                        },
                      });
                    }}
                  >
                    취소
                  </Button>
                  <Button type="primary" htmlType="submit">
                    저장
                  </Button>
                </Space>
              </Col>
            </Row>
          </Space>
        </Form>
      )}
    />
  );
}

export default AdminRegistPage;
