import React from 'react';
import { Button, Modal, Space, Descriptions, Rate } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from '../../Page.module.less';
import handleError from '#utils/handleError';
import { restApi } from '#apis';
import FileUpload from '#components/datalist/FileUpload';
import ProductDetails from '#components/App/ProductDetails';

const cx = classNames.bind(styles);

function ReviewManagementDetail() {
  const navigate = useNavigate();
  const { reviewId } = useParams();
  const [reviewInfo, setReviewInfo] = React.useState(null);

  const init = React.useCallback(() => {
    restApi
      .get(`/cms/product-reviews/${reviewId}`)
      .then(({ data }) => {
        const { images, ...rest } = data;
        const newReviewInfo = { ...rest };

        if (images) {
          newReviewInfo.images = images.map((value) => Number(value.split('/').reverse()[0]));
        }
        setReviewInfo(newReviewInfo);
      })
      .catch(handleError);
  }, [reviewId]);

  const handleToggleIsSecret = React.useCallback(() => {
    Modal.confirm({
      content: `${reviewInfo?.isSecret ? '' : '비'}공개 처리 하시겠습니까?`,
      onOk: async () => {
        try {
          await restApi.put(`/cms/product-reviews/${reviewId}`);
          init();
        } catch (e) {
          handleError(e);
          Modal.error({
            title: `${reviewInfo?.isSecret ? '' : '비'}공개 처리`,
            content: `${reviewInfo?.isSecret ? '' : '비'}공개 처리에 실패했습니다. 잠시 후 시도해주세요.`,
            okText: '확인',
          });
        }
      },
      okText: '확인',
      cancelText: '취소',
    });
  }, [reviewInfo, init, reviewId]);

  React.useEffect(() => {
    if (!reviewId) return;
    init();
  }, [reviewId, init]);

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="상품명">
            <Space>
              {reviewInfo?.productName}
              {!!reviewInfo?.productId && (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.info({
                      title: '상세설명',
                      width: 550,
                      content: <ProductDetails productId={reviewInfo?.productId} />,
                      centered: true,
                      closable: true,
                    });
                  }}
                >
                  상세설명
                </Button>
              )}
            </Space>
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="작성자명">
            {reviewInfo?.creator}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="별점">
            {reviewInfo?.score && <Rate style={{ fontSize: 14 }} disabled value={reviewInfo?.score} />}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="작성내용">
            {reviewInfo?.contents}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="사진,영상">
            {reviewInfo?.images && (
              <Space direction="vertical">
                <FileUpload items={reviewInfo?.images} disabled listType="picture-card" hideUpload />
              </Space>
            )}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="공개 여부">
            {typeof reviewInfo?.isSecret === 'boolean' && (reviewInfo?.isSecret ? '비공개' : '공개')}
            {reviewInfo?.isSecret && reviewInfo?.adminName && reviewInfo?.secretAt && (
              <span>
                ({reviewInfo?.adminName} / {reviewInfo?.secretAt})
              </span>
            )}
            <Button onClick={handleToggleIsSecret} type="primary" style={{ marginLeft: 20 }}>
              {reviewInfo?.isSecret ? '공개' : '비공개'}처리
            </Button>
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button onClick={() => navigate(-1)}>목록</Button>
      </div>
    </div>
  );
}

export default ReviewManagementDetail;
