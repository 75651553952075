import { restApi } from '#apis/index';
import FetchElement from '#components/FetchElement';
import moment from 'moment';
import React from 'react';
import { Button, Input, DatePicker, Modal, Form, Space, Col, Row, Radio } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import produce from 'immer';
const MemoizedContentsMainRegistPage = React.memo(({ id, name, startAt, endAt, isActive, state }) => {
  const [initialRequest, setInitialRequest] = React.useState({
    name: name,
    startAt: startAt,
    endAt: endAt,
    isActive: isActive,
  });
  const navigate = useNavigate();
  return (
    <Form
      style={{ maxWidth: 800 }}
      onFinish={({ name, dateRange }) => {
        const [startAt, endAt] = dateRange.map((date) => date.format('YYYY-MM-DD HH:mm:ss'));
        const requestBody = {
          placeCode: 'DP001',
          endAt,
          startAt,
          name,
        };
        if (state?.type === 'section') {
          if (id) {
            return restApi
              .put(`/cms/sections/${id}`, initialRequest)
              .then(() => {
                Modal.success({
                  content: '수정을 완료했습니다.',
                  onOk: () => {
                    navigate(`/contents/main/section`);
                  },
                });
              })
              .catch((error) => {
                Modal.error({
                  title: '메인화면 수정',
                  content: error?.response?.data?.message ?? '컨텐츠 수정에 실패했습니다.',
                  centered: true,
                });
              });
          }

          return restApi
            .post(`/cms/sections/${window.sessionStorage.getItem('displayId')}`, initialRequest)
            .then(({ data }) => {
              navigate(`/contents/main/section`);
            })
            .catch((error) => {
              Modal.error({
                title: '메인화면 등록',
                content: error?.response?.data?.message ?? '컨텐츠 등록에 실패했습니다.',
                centered: true,
              });
            });
        } else {
          if (id) {
            return restApi
              .put(`/cms/new-displays/${id}`, requestBody)
              .then(() => {
                Modal.success({
                  content: '수정을 완료했습니다.',
                  onOk: () => {
                    navigate(`/contents/main`);
                  },
                });
              })
              .catch((error) => {
                Modal.error({
                  title: '메인화면 수정',
                  content: error?.response?.data?.message ?? '컨텐츠 수정에 실패했습니다.',
                  centered: true,
                });
              });
          }

          return restApi
            .post('/cms/new-displays', initialRequest)
            .then(({ data }) => {
              navigate(`/contents/main`);
            })
            .catch((error) => {
              Modal.error({
                title: '메인화면 등록',
                content: error?.response?.data?.message ?? '컨텐츠 등록에 실패했습니다.',
                centered: true,
              });
            });
        }
      }}
    >
      <Space style={{ width: '100%' }} direction="vertical" size="middle">
        <Space style={{ width: '100%' }} direction="vertical">
          <Form.Item
            label="메뉴명"
            required
            name="name"
            rules={[{ required: true, message: '메뉴명을 입력해주세요.' }]}
            initialValue={name}
          >
            <Input
              placeholder="메뉴명을 입력해주세요"
              onChange={(e) => {
                setInitialRequest(
                  produce((draft) => {
                    draft.name = e.target.value;
                  }),
                );
              }}
            />
          </Form.Item>
          <Form.Item
            label="노출기간"
            required
            name="dateRange"
            rules={[{ required: true, message: '노출기간을 선택해주세요.' }]}
            initialValue={[startAt && moment(startAt), endAt && moment(endAt)]}
          >
            <DatePicker.RangePicker
              showTime={{ format: 'HH:mm:ss' }}
              format="YYYY-MM-DD HH:mm:ss"
              onChange={(e) => {
                const dateStr = e[0]._d;
                const dateEnd = e[1]._d;
                const momentDateStr = moment(dateStr).format('YYYY-MM-DD HH:mm:ss');
                const momentDateEnd = moment(dateEnd).format('YYYY-MM-DD HH:mm:ss');
                console.log(momentDateStr, momentDateEnd);
                setInitialRequest(
                  produce((draft) => {
                    draft.startAt = momentDateStr;
                    draft.endAt = momentDateEnd;
                  }),
                );
              }}
            />
          </Form.Item>
          <Form.Item
            label="사용여부"
            required
            name="isActive"
            rules={[{ required: true, message: '사용여부를 선택해주세요' }]}
            initialValue={isActive}
          >
            <Radio.Group
              onChange={(e) => {
                setInitialRequest(
                  produce((draft) => {
                    draft.isActive = e.target.value;
                  }),
                );
              }}
            >
              <Radio value={true} key={'isActiveTrue'}>
                사용
              </Radio>
              <Radio value={false} key={'isActiveFalse'}>
                미사용
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Space>
        <Row justify="end">
          <Col>
            <Space>
              <Button
                onClick={() => {
                  Modal.confirm({
                    title: '메인화면 등록',
                    content: (
                      <>
                        입력했던 내용이 전부 사라집니다.
                        <br />
                        계속 하시겠습니까 ?
                      </>
                    ),
                    centered: true,
                    onOk: () => {
                      navigate(id ? `/contents/main/detail/${id}` : '/contents/main/section');
                    },
                  });
                }}
              >
                취소
              </Button>
              <Button type="primary" htmlType="submit">
                {id ? '수정' : '저장'}
              </Button>
            </Space>
          </Col>
        </Row>
      </Space>
    </Form>
  );
});

function ContentsMainRegistPage() {
  const params = useParams();
  const id = React.useMemo(() => params?.id, [params?.id]);
  const location = useLocation();
  return (
    <FetchElement
      fetch={() =>
        id && restApi.get(location.state.type === 'section' ? `/cms/sections/${id}` : `/cms/new-displays/${id}`)
      }
      Component={(data) => <MemoizedContentsMainRegistPage id={id} {...data} state={location.state} />}
    />
  );
}

export default ContentsMainRegistPage;
