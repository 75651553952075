import FetchElement from '#components/FetchElement';
import useMount from '#hooks/useMount';
import formatComma from '#utils/formatComma';
import produce from 'immer';
import moment from 'moment';
import React from 'react';
import { Button, Modal, Space, Table, Typography } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CommTable from '#components/table/CommTable';
import { restApi } from '#apis/index';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const MemoizedContentsMainPage = React.memo(({ data, activeDisplays = [], used = [], setData, refresh }) => {
  const navigate = useNavigate();
  const newUsedActive = [...activeDisplays].sort((a, b) => a.exposeNum - b.exposeNum);
  if (!data) {
    return null;
  }

  return (
    <>
      <style jsx>{`
        .row-dragging {
          background: #fafafa;
        }

        .row-dragging td {
          padding: 16px;
          vertical-align: middle;
        }

        .row-dragging .drag-visible {
          visibility: visible;
        }
      `}</style>
      <Space style={{ width: '100%', maxWidth: 1200 }} direction="vertical" size="large">
        <Space style={{ width: '100%', marginBottom: 50 }} direction="vertical">
          <Typography.Paragraph>현재 사용 목록</Typography.Paragraph>
          <Table
            dataSource={newUsedActive}
            columns={[
              {
                title: '노출순서',
                dataIndex: 'exposeNum',
                width: 80,
                render: (value, record, index) => index + 1,
              },
              {
                title: '메뉴명',
                dataIndex: 'name',
                align: 'left',
                render: (value, record) => (
                  <Button
                    style={{
                      border: 'none',
                      backgroundColor: 'rgba(0,0,0,0)',
                      fontSize: 13,
                      color: '#4c72f1',
                    }}
                    onClick={() => {
                      navigate(`/contents/main/modify/${record.id}`, { state: { type: 'main' } });
                    }}
                  >
                    <span>{value}</span>
                  </Button>
                ),
              },
              {
                title: '섹션 관리',
                width: 120,
                render: (value, record) => (
                  <Button
                    onClick={() => {
                      window.sessionStorage.setItem('displayId', record.id);
                      navigate(`/contents/main/section`, { state: record.id });
                    }}
                  >
                    섹션 관리
                  </Button>
                ),
              },
              {
                title: '배너 관리',
                width: 120,
                render: (value, record) => (
                  <Button
                    onClick={() => {
                      window.sessionStorage.setItem('displayId', record.id);
                      navigate('/banners/BP001', { state: record.id });
                    }}
                  >
                    배너 관리
                  </Button>
                ),
              },
              {
                title: '노출 기간',
                width: 300,
                render: (value, record) =>
                  `${moment(record.startAt).format('YYYY-MM-DD HH:mm')} ~ ${moment(record.endAt).format(
                    'YYYY-MM-DD HH:mm',
                  )}`,
              },
              {
                title: '사용 여부',
                dataIndex: 'isActive',
                width: 120,
                render: (value, record) => (
                  <Button
                    type="danger"
                    onClick={() => {
                      Modal.confirm({
                        title: '노출 중지',
                        content: '해당 테마의 노출을 중지하시겠습니까 ?',
                        onOk: () => {
                          console.log(value);
                          return restApi
                            .delete(`/cms/new-displays/${record.id}`)
                            .then(refresh)
                            .catch((error) => {
                              Modal.error({
                                title: '노출 중지',
                                content: error?.response?.data?.message ?? '노출 중지에 실패했습니다.',
                                centered: true,
                                okText: '확인',
                              });
                            });
                        },
                        centered: true,
                        okText: '확인',
                        cancelText: '취소',
                      });
                    }}
                  >
                    노출 중지
                  </Button>
                ),
              },
              {
                width: 50,
                className: 'drag-visible',
                render: () => {
                  return <DragHandle />;
                },
              },
            ]}
            components={{
              body: {
                wrapper: (props) => (
                  <SortableBody
                    useDragHandle
                    disableAutoscroll
                    helperClass="row-dragging"
                    onSortEnd={({ oldIndex, newIndex }) => {
                      if (oldIndex !== newIndex) {
                        const changedData = arrayMoveImmutable([...data.activeDisplays], oldIndex, newIndex)
                          .map((value, index) => ({ ...value, exposeNum: index }))
                          .filter(Boolean);
                        setData(
                          produce((draft) => {
                            draft.activeDisplays = changedData;
                          }),
                        );
                        return restApi.put(`/cms/new-displays/order`, {
                          ids: changedData.map(({ id }) => id),
                        });
                      }
                    }}
                    {...props}
                  />
                ),
                row: (props) => {
                  return (
                    <SortableItem index={activeDisplays?.findIndex((e) => e.id === props['data-row-key'])} {...props} />
                  );
                },
              },
            }}
            rowKey={({ id }) => id}
            pagination={false}
          />
        </Space>
        <Space style={{ width: '100%' }} direction="vertical">
          <Typography.Paragraph style={{ marginBottom: -20 }}>미사용 메뉴</Typography.Paragraph>
          <CommTable
            url="/cms/new-displays"
            then={({ data: responseData }) => ({ data: responseData?.inactiveDisplays })}
            params={{ placeCode: 'DP001' }}
            columns={({ pageCur, limit }) => [
              {
                title: '번호',
                width: 80,
                render: (value, record, index) => pageCur * limit + index + 1,
              },
              {
                title: '메뉴명',
                dataIndex: 'name',
                align: 'left',
                render: (value, record) => (
                  <Button
                    style={{
                      border: 'none',
                      backgroundColor: 'rgba(0,0,0,0)',
                      fontSize: 13,
                      color: '#4c72f1',
                    }}
                    onClick={() => {
                      navigate(`/contents/main/modify/${record.id}`, { state: { type: 'main' } });
                    }}
                  >
                    <span>{value}</span>
                  </Button>
                ),
              },
              {
                title: '컨텐츠 수',
                dataIndex: 'count',
                width: 120,
                render: (value) => `${formatComma(value)}개`,
              },
              {
                title: '노출 기간',
                width: 300,
                render: (value, record) =>
                  `${moment(record.startAt).format('YYYY-MM-DD HH:mm')} ~ ${moment(record.endAt).format(
                    'YYYY-MM-DD HH:mm',
                  )}`,
              },
            ]}
            headerExtra={
              <Link to="/contents/main/regist">
                <Button type="primary">등록</Button>
              </Link>
            }
          />
        </Space>
      </Space>
    </>
  );
});

function ContentsMainPage() {
  const fetchElementRef = React.useRef();

  return (
    <FetchElement
      ref={fetchElementRef}
      fetch={() => restApi.get('/cms/new-displays')}
      Component={(data) => <MemoizedContentsMainPage {...data} />}
    />
  );
}

export default ContentsMainPage;
