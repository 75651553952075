import React from 'react';

const usePreventLeave = () => {
  const preventGoBack = React.useCallback(() => {
    const result = window.confirm('페이지 이동 시 변경사항이 저장되지 않을 수 있습니다.');
    if (result) {
      window.removeEventListener('popstate', preventGoBack);
      window.history.back();
    } else {
      window.history.pushState(null, '', window.location.href);
    }
  }, []);
  const listener = React.useCallback((event) => {
    event.preventDefault();
    event.returnValue = '';
  }, []);
  const enablePrevent = () => {
    window.history.pushState(null, '', window.location.href);
    window.addEventListener('beforeunload', listener);
    window.addEventListener('popstate', preventGoBack);
  };
  const disablePrevent = () => {
    window.removeEventListener('beforeunload', listener);
    window.removeEventListener('popstate', preventGoBack);
  };

  const justRemovePopState = () => {
    window.removeEventListener('popstate', preventGoBack);
  };
  return { enablePrevent, disablePrevent, justRemovePopState };
};

export default usePreventLeave;
