import React from 'react';
import qs from 'query-string';
import useQuery from './useQuery';

const usePagination = ({ data, onChangePagination }) => {
  const { query, navigate } = useQuery();
  const pagination = React.useMemo(() => {
    return {
      position: ['bottomCenter'],
      total: data?.total,
      pageSize: data?.limit ?? 10,
      current: data?.pageCur + 1,
      showSizeChanger: false,
      onChange: async (p) => {
        onChangePagination?.();
        navigate(`?${qs.stringify({ ...query, page: p - 1 })}`, { replace: true });
      },
    };
  }, [data, navigate, query]);

  const paging = React.useMemo(() => {
    return {
      total: data?.total,
      pageCur: data?.pageCur,
      limit: data?.limit,
      offset: data?.offset,
    };
  }, [data]);

  return {
    pagination,
    paging,
  };
};

export default usePagination;
