import { restApi } from '#apis/index';
import FetchElement from '#components/FetchElement';
import useFetch from '#hooks/useFetch';
import formatComma from '#utils/formatComma';
import { MenuOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, Form, Input, Modal, Row, Select, Space, Table, Typography } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import moment from 'moment';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

const ProductSearch = React.memo(({ tableRef, id, categoriesOptions = [], onChange = () => {} }) => {
  const formRef = React.useRef();
  const [selected, setSelected] = React.useState(null);
  const { data, fetch, loading } = useFetch([]);
  const [param,setParam] = React.useState({ page: 0 , limit: 20});
  const loadData= ()=>{
    fetch(
        restApi
            .get('/cms/products', { params: { ...param } })
        // .then(({ data: responseData }) => ({ data: responseData?.results })),
    ).then(() => {
      setSelected(null);
    });
  }
  React.useEffect(()=>{
    loadData()
  },[param])
  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Form
        ref={formRef}
        onFinish={({ categoryCode, productName }) => {
          setParam({categoryCode, productName, page: 0 , limit: 20})
        }}
        onReset={formRef.current?.resetFields}
      >
        <Row gutter={10} align="middle">
          <Col>
            <Form.Item name="categoryCode">
              <Select options={categoriesOptions} style={{ width: 200 }} placeholder="카테고리" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="productName">
              <Input style={{ width: 250 }} placeholder="상품명을 입력하세요" />
            </Form.Item>
          </Col>
          <Col>
            <Space>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <Table
        dataSource={data?.results}
        loading={loading}
        rowKey={({ productId }) => productId}
        rowSelection={{
          type: 'radio',
          selectedRowKeys: [selected?.productId],
          onChange: (selectedRowKeys, [selectedRow]) => {
            setSelected(selectedRow);
          },
        }}
        columns={[
          {
            title: '카테고리',
            dataIndex: 'category1',
          },
          {
            title: '이미지',
            dataIndex: 'productThumbnail',
            width: 75,
            render: (value) => <img src={value} style={{ width: '100%' }} alt="상품 이미지" />,
          },
          {
            title: '상품명',
            dataIndex: 'productName',
            align: 'left',
          },
          {
            title: '가격',
            dataIndex: 'price',
            render: (value) => `${formatComma(value)}원`,
          },
          {
            title: '할인가',
            dataIndex: 'discountAmount',
            render: (value) => `${formatComma(value)}원`,
          },
          {
            title: '상점명',
            dataIndex: 'partnerName',
          },
          {
            title: '사용여부',
            render: () => 'Y',
          },
        ]}
        pagination={{
          position: ['bottomCenter'],
          pageSize: data?.limit,
          total: data?.total,
          current: data?.pageCur+1,
          showSizeChanger: false,
          onChange: async (p) => {
            setParam((prev)=>({...prev,page:p-1}))
          },
        }}
      />
      <Row justify="end" style={{ marginBottom: -25 }}>
        <Col>
          <Button
            onClick={() => {
              restApi
                .post(`/cms/displays/${id}/product/${selected.productId}`)
                .then(() => {
                  onChange(selected);
                  Modal.destroyAll();
                })
                .catch((error) => {
                  Modal.error({
                    title: '상품 검색',
                    content: error?.response?.data?.message ?? '상품 저장에 실패했습니다.',
                    centered: true,
                  });
                });
            }}
            type="primary"
            disabled={!selected}
          >
            저장
          </Button>
        </Col>
      </Row>
    </Space>
  );
});

function ContentsPoplarDetailPage() {
  const params = useParams();
  const id = React.useMemo(() => params?.id, [params?.id]);
  const { tree: categoriesTree, map: categoriesMap } = useSelector(
    (selector) => selector?.categories?.product,
    shallowEqual,
  );
  const categoriesOptions = React.useMemo(
    () => categoriesTree?.map(({ code, label }) => ({ key: code, label, value: code })) ?? [],
    [categoriesTree],
  );
  const [category, setCategory] = React.useState(null);

  return (
    <>
      <style jsx>{`
        .row-dragging {
          background: #fafafa;
        }

        .row-dragging td {
          padding: 16px;
          vertical-align: middle;
        }

        .row-dragging td img {
          width: 59px !important;
        }

        .row-dragging .drag-visible {
          visibility: visible;
        }
      `}</style>
      <FetchElement
        fetch={() => restApi.get(`/cms/displays/${id}`)}
        Component={({ endAt, isActive, products, startAt, themeName, refresh, setData }) => (
          <Space style={{ width: '100%', maxWidth: 1500 }} direction="vertical">
            <Descriptions colon={false} column={1} bordered>
              <Descriptions.Item label="테마명">{themeName}</Descriptions.Item>
              <Descriptions.Item label="노출기간">{`${moment(startAt).format('YYYY-MM-DD HH:mm:ss')} ~ ${moment(
                endAt,
              ).format('YYYY-MM-DD HH:mm:ss')}`}</Descriptions.Item>
              <Descriptions.Item label="사용여부">
                <Space>
                  <Typography.Text>{isActive ? '사용' : '미사용'}</Typography.Text>
                  {!isActive && (
                    <Button
                      type="primary"
                      onClick={() => {
                        Modal.confirm({
                          title: '사용 처리',
                          content: '메인화면에 노출하겠습니까 ?',
                          centered: true,
                          onOk: () =>
                            restApi
                              .put(`/cms/displays/active/${id}`)
                              .then(refresh)
                              .catch((error) => {
                                Modal.error({
                                  title: '사용처리',
                                  content: error?.response?.data?.message ?? '사용처리에 실패했습니다.',
                                  centered: true,
                                });
                              }),
                          okText: '확인',
                          cancelText: '취소',
                        });
                      }}
                    >
                      사용처리
                    </Button>
                  )}
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="컨텐츠">
                <Row gutter={10} justify="space-between" align="middle" style={{ marginBottom: 15 }}>
                  <Col></Col>
                  <Col>
                    <Select
                      value={category}
                      onChange={setCategory}
                      placeholder="카테고리"
                      style={{ width: 200, marginRight: '8px' }}
                      options={categoriesOptions}
                      allowClear
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        Modal.info({
                          title: '상품검색',
                          width: 1000,
                          content: <ProductSearch id={id} categoriesOptions={categoriesOptions} onChange={refresh} />,
                          centered: true,
                          closable: true,
                          okButtonProps: { style: { display: 'none' } },
                        });
                      }}
                    >
                      추가
                    </Button>
                  </Col>
                </Row>
                <Table
                  dataSource={products
                    ?.filter(({ categoryCode }) => (category ? categoryCode?.includes(category) : true))
                    .sort((a, b) => a.exposeNum - b.exposeNum)}
                  columns={[
                    {
                      title: '노출순서',
                      width: 120,
                      render: (value, record, index) => index + 1,
                    },
                    {
                      title: '카테고리',
                      width: 150,
                      dataIndex: 'categoryCode',
                      render: (value) => categoriesMap[value],
                    },
                    {
                      title: '이미지',
                      dataIndex: 'thumbnail',
                      width: 75,
                      render: (value) => <img src={value} style={{ width: '100%' }} alt="상품 이미지" />,
                    },
                    {
                      title: '상품명',
                      dataIndex: 'productName',
                      width: 200,
                      align: 'left',
                    },
                    {
                      title: '가격',
                      width: 150,
                      dataIndex: 'price',
                      render: (value) => `${formatComma(value)}원`,
                    },
                    {
                      title: '할인가',
                      width: 150,
                      dataIndex: 'discountAmount',
                      render: (value) => `${formatComma(value)}원`,
                    },
                    {
                      title: '상점명',
                      width: 200,
                      dataIndex: 'storeName',
                    },
                    {
                      title: '노출여부',
                      width: 120,
                      dataIndex: 'id',
                      render: (value) => (
                        <Button
                          type="danger"
                          onClick={() => {
                            Modal.confirm({
                              title: '컨텐츠 삭제',
                              content: '해당 컨텐츠를 목록에서 삭제하시겠습니까 ?',
                              centered: true,
                              onOk: () =>
                                restApi
                                  .delete(`/cms/display-products/${value}`)
                                  .then(refresh)
                                  .catch((error) => {
                                    Modal.error({
                                      title: '컨텐츠 삭제',
                                      content: error?.response?.data?.message ?? '컨텐츠 삭제 실패했습니다.',
                                      centered: true,
                                    });
                                  }),
                              okText: '확인',
                              cancelText: '취소',
                            });
                          }}
                        >
                          삭제
                        </Button>
                      ),
                    },
                    {
                      width: 50,
                      className: 'drag-visible',
                      render: () => {
                        return <DragHandle />;
                      },
                    },
                  ]}
                  pagination={false}
                  components={{
                    body: {
                      wrapper: (props) => (
                        <SortableBody
                          useDragHandle
                          disableAutoscroll
                          helperClass="row-dragging"
                          onSortEnd={({ oldIndex, newIndex }) => {
                            if (oldIndex !== newIndex) {
                              const changedData = arrayMoveImmutable([...products], oldIndex, newIndex)
                                .map((value, index) => ({ ...value, exposeNum: index }))
                                .filter(Boolean);
                              setData((prevState) => ({ ...prevState, products: changedData }));
                              return restApi.put(
                                '/cms/display-products',
                                changedData.map(({ id, exposeNum }) => ({ id, exposeNum })),
                              );
                            }
                          }}
                          {...props}
                        />
                      ),
                      row: (props) => {
                        return (
                          <SortableItem index={products?.findIndex((e) => e.id === props['data-row-key'])} {...props} />
                        );
                      },
                    },
                  }}
                  rowKey={({ id }) => id}
                />
              </Descriptions.Item>
            </Descriptions>
            <Row justify="end">
              <Col>
                <Space>
                  <Link to="/contents/popular">
                    <Button>목록</Button>
                  </Link>
                  <Link to={`/contents/popular/modify/${id}`}>
                    <Button type="primary">수정</Button>
                  </Link>
                </Space>
              </Col>
            </Row>
          </Space>
        )}
      />
    </>
  );
}

export default ContentsPoplarDetailPage;
