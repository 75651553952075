import { restApi } from '#apis/index';
import produce from 'immer';
import React from 'react';
import { Button, Space, Select, Row, Col, Table, DatePicker, Modal, Checkbox, Divider } from 'antd';
import useFetch from '#hooks/useFetch';
import moment from 'moment';
import InputMoney from '../Input/InputMoney';
import SearchPartner from './SearchPartner';

const AD_STATUS_TYPE = {
  submit: 'SUBMIT',
  on: 'ADON',
  off: 'ADOFF',
};

function AddAdvertisement({ code, adCount, addPrice, extra1, extra2, totalCount, defaultPrice, onChange }) {
  const initialParams = React.useMemo(
    () => ({
      adStatusType: null,
      isEnd: false,
      page: 0,
      limit: 10,
      keyword: '',
    }),
    [],
  );

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  };

  const [params, setParams] = React.useState(initialParams);
  const [areaAddPrice, setAreaAddPrice] = React.useState(0);
  const [priceToChange, setPriceToChange] = React.useState(null);
  const tableRef = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const [modifiedRow, setModifiedRow] = React.useState([]);

  const handleChangeAddPrice = async () => {
    try {
      if (loading) return;
      if (areaAddPrice !== priceToChange && !!priceToChange) {
        setLoading(true);
        await restApi.put(`/cms/advertisement/area/${code}/addPrice`, {
          addPrice: priceToChange,
        });
        onChange?.();
        setAreaAddPrice(priceToChange);
      }
      Modal.success({
        title: '가격 변경',
        content: '지역 가격을 변경하였습니다.',
      });
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const tableFetcher = useFetch();

  const handleAccept = (id) => async () => {
    try {
      if (loading) return;
      setLoading(true);
      await restApi.put(`/cms/advertisement/area/advertisement/${id}/permit`);
      await tableFetcher.fetch(restApi.get(`/cms/advertisement/area/${code}`, { params }));
      Modal.success({
        title: '수락 완료',
        content: '광고 수락을 완료 했습니다',
        centered: true,
        okText: '확인',
      });
    } catch (e) {
      Modal.error({
        title: '수락 실패',
        content: '수락을 실패했습니다. 잠시 후 시도해주세요.',
        centered: true,
        okText: '확인',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRefuse = (id) => async () => {
    try {
      if (loading) return;
      setLoading(true);
      await restApi.delete(`/cms/advertisement/area/advertisement/${id}/wait`);
      await tableFetcher.fetch(restApi.get(`/cms/advertisement/area/${code}`, { params }));
      Modal.success({
        title: '거절 완료',
        content: '광고를 거절 했습니다',
        centered: true,
        okText: '확인',
      });
    } catch (e) {
      Modal.error({
        title: '거절 실패',
        content: '거절을 실패했습니다. 잠시 후 시도해주세요.',
        centered: true,
        okText: '확인',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeDisplay = (id, toStart) => async () => {
    Modal.confirm({
      title: '광고 노출',
      content: `해당 컨텐츠의 노출을 ${toStart ? '시작' : '중지'}하시겠습니까?`,
      centered: true,
      onOk: async () => {
        try {
          if (loading) return;
          setLoading(true);
          await restApi.put(`/cms/advertisement/area/advertisement/${id}/display`);
          await tableFetcher.fetch(restApi.get(`/cms/advertisement/area/${code}`, { params }));
          Modal.success({
            title: '수정 완료',
            content: '수정을 완료했습니다.',
            centered: true,
            okText: '확인',
          });
        } catch (e) {
          Modal.error({
            title: '수정 실패',
            content: '수정을 실패했습니다. 잠시 후 시도해주세요.',
            centered: true,
            okText: '확인',
          });
        } finally {
          setLoading(false);
        }
      },
      okText: '확인',
    });
  };

  React.useEffect(() => {
    tableFetcher.fetch(restApi.get(`/cms/advertisement/area/${code}`, { params }));
  }, [code, params]);

  React.useEffect(() => {
    setAreaAddPrice(addPrice);
  }, [addPrice]);
  return (
    <Space direction="vertical" style={{ width: '100%', minHeight: 300 }}>
      <Space style={{ width: '100%', marginBlock: 20, justifyContent: 'space-between' }}>
        <div>
          {extra1} {'>'} {extra2}
        </div>
        <Space>
          <div>기본 가격</div>
          <div>{(defaultPrice ?? 0).toLocaleString()}원</div>
          <div>+</div>
          <InputMoney value={priceToChange ?? areaAddPrice} onChange={setPriceToChange} />
          <Button onClick={handleChangeAddPrice}>변경</Button>
        </Space>
      </Space>
      <Divider />
      <Row gutter={10} style={{ marginBottom: 10, alignItems: 'center', rowGap: 10, justifyContent: 'flex-end' }}>
        <Col>
          <Checkbox
            checked={params.isEnd}
            onChange={(value) => {
              setParams(
                produce((draft) => {
                  draft.isEnd = value.target.checked;
                }),
              );
            }}
          >
            종료된 광고 포함
          </Checkbox>
        </Col>
        <Col>
          <Select
            value={params.adStatusType}
            onChange={(value) => {
              setParams(
                produce((draft) => {
                  draft.adStatusType = value;
                }),
              );
            }}
            placeholder="광고진행여부"
            options={[
              { label: '진행중', value: AD_STATUS_TYPE.on },
              { label: '진행중지', value: AD_STATUS_TYPE.off },
              { label: '대기중', value: AD_STATUS_TYPE.submit },
            ]}
            style={{ width: 120 }}
          />
        </Col>

        <Col>
          <Space>
            <Button
              onClick={() => {
                Modal.info({
                  title: '파트너 검색',
                  width: 1000,
                  content: (
                    <SearchPartner
                      onSuccess={async () => {
                        await tableFetcher.fetch(restApi.get(`/cms/advertisement/area/${code}`, { params }));
                      }}
                      code={code}
                    />
                  ),
                  centered: true,
                  closable: true,
                  okText: '저장',
                  okButtonProps: { style: { display: 'none' } },
                });
              }}
              type="primary"
            >
              추가
            </Button>
          </Space>
        </Col>
      </Row>
      <Table
        rowKey={({ id }) => id}
        dataSource={tableFetcher?.data?.results || []}
        ref={tableRef}
        columns={[
          {
            title: '아이디',
            dataIndex: 'storeUserId',
            fixed: true,
            width: 150,
            render: (value) => value,
          },
          {
            title: '상점명',
            dataIndex: 'storeName',
            width: 200,
            fixed: true,
          },
          {
            title: '시작일',
            dataIndex: 'startAt',
            width: 200,
            render: (startAt, row) => {
              const id = modifiedRow.findIndex((item) => item.id === row.id);

              if (id > -1 && !!modifiedRow[id].startAt) {
                return (
                  <DatePicker
                    disabledDate={disabledDate}
                    value={moment(modifiedRow[id]?.startAt)}
                    onChange={(value) => {
                      setModifiedRow(
                        produce((draft) => {
                          draft[id].startAt = value?.format('YYYY-MM-DD');
                        }),
                      );
                    }}
                  />
                );
              }

              return (
                <Button
                  onClick={() => {
                    setModifiedRow(
                      produce((draft) => {
                        const foundIndex = draft.findIndex((item) => item.id === row.id);
                        if (foundIndex > -1) {
                          draft[foundIndex].startAt = startAt;
                          return;
                        }

                        draft.push({ startAt, id: row.id });
                      }),
                    );
                  }}
                >
                  {startAt}
                </Button>
              );
            },
          },
          {
            title: '종료일',
            dataIndex: 'endAt',
            width: 200,
            render: (endAt, row) => {
              const id = modifiedRow.findIndex((item) => item.id === row.id);

              if (id > -1 && !!modifiedRow[id].endAt) {
                return (
                  <DatePicker
                    disabledDate={disabledDate}
                    value={moment(modifiedRow[id]?.endAt)}
                    onChange={(value) => {
                      setModifiedRow(
                        produce((draft) => {
                          draft[id].endAt = value?.format('YYYY-MM-DD');
                        }),
                      );
                    }}
                  />
                );
              }

              return (
                <Button
                  onClick={() => {
                    setModifiedRow(
                      produce((draft) => {
                        const foundIndex = draft.findIndex((item) => item.id === row.id);
                        if (foundIndex > -1) {
                          draft[foundIndex].endAt = endAt;
                          return;
                        }

                        draft.push({ endAt, id: row.id });
                      }),
                    );
                  }}
                >
                  {endAt}
                </Button>
              );
            },
          },
          {
            title: '광고 진행 여부',
            dataIndex: 'adStatusType',
            width: 200,
            render: (adStatusType) =>
              adStatusType === AD_STATUS_TYPE.submit
                ? '대기중'
                : adStatusType === AD_STATUS_TYPE.on
                ? '진행중'
                : '진행중지',
          },
          {
            title: '노출여부',
            dataIndex: 'adStatusType',
            width: 200,
            render: (adStatusType, row) =>
              adStatusType === AD_STATUS_TYPE.submit ? (
                <Space>
                  <Button onClick={handleAccept(row.id)}>수락</Button>
                  <Button onClick={handleRefuse(row.id)}>삭제</Button>
                </Space>
              ) : row.isDisplay ? (
                <Button disabled={loading} onClick={handleChangeDisplay(row.id)}>
                  노출 중지
                </Button>
              ) : (
                <Button disabled={loading} onClick={handleChangeDisplay(row.id, true)}>
                  노출 시작
                </Button>
              ),
          },
          {
            title: '수정',
            dataIndex: 'id',
            width: 200,
            render: (id, row) => (
              <Button
                disabled={!modifiedRow.find((item) => item.id === id)}
                onClick={async () => {
                  const foundItem = modifiedRow.find((item) => item.id === id);
                  if (!foundItem) return;
                  const { id: _id, ...rest } = foundItem;
                  const { startAt, endAt } = row;
                  await restApi.put(`/cms/advertisement/area/advertisement/${_id}/date`, { startAt, endAt, ...rest });
                  await tableFetcher.fetch(restApi.get(`/cms/advertisement/area/${code}`, { params }));
                  Modal.success({
                    title: '수정 완료',
                    content: '수정을 완료했습니다.',
                    centered: true,
                    okText: '확인',
                  });
                }}
              >
                수정
              </Button>
            ),
          },
        ]}
        scroll={{ x: 1000 }}
        pagination={{
          position: ['bottomCenter'],
          total: tableFetcher?.data?.total,
          pageSize: tableFetcher?.data?.limit ?? 10,
          current: tableFetcher?.data?.pageCur + 1,
          showSizeChanger: false,
          onChange: async (p) => {
            setParams(
              produce((draft) => {
                draft.page = p - 1;
              }),
            );
          },
        }}
      />
    </Space>
  );
}

export default AddAdvertisement;
