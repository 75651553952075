export const formatPhonNumer = (value) => {
  let renderValue = [...value.split('')];
  if (value.length == 11) {
    renderValue.splice(3, 0, '-');
    renderValue.splice(8, 0, '-');
  } else {
    renderValue.splice(3, 0, '-');
    renderValue.splice(7, 0, '-');
  }
  return renderValue.join('');
};
export const formatPhoneNumber = (value) => {
  let nonPhoneNumber = [...value.split('')];
  nonPhoneNumber.splice(0, 3, '0');
  let renderValue = [...nonPhoneNumber];
  if (value.length == 11) {
    renderValue.splice(3, 0, '-');
    renderValue.splice(8, 0, '-');
  } else {
    renderValue.splice(3, 0, '-');
    renderValue.splice(7, 0, '-');
  }
  return renderValue.join('');
};
