import FetchElement from '#components/FetchElement';
import React from 'react';
import { Button, Checkbox, Descriptions, Form, Input, Modal, Radio, Row, Select, Space, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import FileUpload from '#components/datalist/FileUpload';
import produce from 'immer';
import { EditorState } from 'draft-js';
import Editor, { draftToHTML, HTMLToDraft } from '#components/Editor';
import { restApi } from '#apis';
import { shallowEqual, useSelector } from 'react-redux';
import { checkBeforeUpload } from '../../../../utils/beforeUpload';
import ImageUploadGuide from '../../../../components/common/ImageUploadGuide';

const MemoizedPostsNoticePartnersRegistPage = React.memo(
  ({ active, contents, categoryCode, id, images, isHeader, title }) => {
    const navigate = useNavigate();
    const { tree: codesTree } = useSelector((selector) => selector.codes, shallowEqual);

    const initialForm = React.useMemo(
      () => ({
        active: true,
        contents: EditorState.createEmpty(),
        images: [],
        imageTexts: [],
        isHeader: true,
        categoryCode: null,
        title: '',
      }),
      [],
    );
    const [form, setForm] = React.useState(initialForm);

    const handleSubmit = React.useCallback(async () => {
      try {
        const requestBody = {
          title: form.title,
          contents: draftToHTML(form.contents),
          images: form.images.map((image, index) => ({ image, text: form.imageTexts[index] })),
          isHeader: form.isHeader,
          categoryCode: form.categoryCode,
          active: form.active,
        };
        if (!requestBody.title)
          return Modal.warn({
            title: id ? '파트너사 공지 수정' : '파트너사 공지 등록',
            content: '제목을 입력하세요.',
            centered: true,
            okText: '확인',
            cancelText: '취소',
          });

        if (requestBody.contents === '<p></p>\n')
          return Modal.warn({
            title: id ? '파트너사 공지 수정' : '파트너사 공지 등록',
            content: '내용을 입력하세요.',
            centered: true,
            okText: '확인',
            cancelText: '취소',
          });

        if (id) {
          await restApi.put(`/cms/notices/partners/${id}`, requestBody);

          Modal.success({
            title: '파트너사 공지 수정',
            content: '공지가 수정되었습니다.',
            onOk: () => {
              navigate(`/posts/notice/partner/detail/${id}`);
            },
            centered: true,
            okText: '확인',
            cancelText: '취소',
          });
        } else {
          await restApi.post(`/cms/notices/partners`, requestBody);

          Modal.success({
            title: '파트너사 공지 등록',
            content: '공지를 등록했습니다.',
            onOk: () => {
              navigate('/posts/notice/partner');
            },
            centered: true,
            okText: '확인',
            cancelText: '취소',
          });
        }
      } catch (error) {
        Modal.error({
          title: id ? '파트너사 공지 수정' : '파트너사 공지 등록',
          content: error?.response?.data?.message ?? '공지를 등록하는데 실패했습니다.',
          centered: true,
          okText: '확인',
          cancelText: '취소',
        });
      }
    }, [
      id,
      form.active,
      form.contents,
      form.categoryCode,
      form.imageTexts,
      form.images,
      form.isHeader,
      form.title,
      navigate,
    ]);

    React.useEffect(() => {
      if (!!id) {
        setForm({
          active,
          contents: HTMLToDraft(contents),
          images: images?.map(({ id }) => id) ?? [],
          imageTexts: images?.map(({ text }) => text) ?? [],
          isHeader,
          categoryCode,
          title,
        });
      }
    }, [id]);

    return (
      <Form onFinish={handleSubmit}>
        <Space direction="vertical" size="middle" style={{ width: '100%', maxWidth: 1200 }}>
          {/* <Form.Item label="상단고정">
          <Checkbox
            checked={form.isHeader}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.isHeader = event.target.checked;
                }),
              );
            }}
          >
            상단 공지로 등록
          </Checkbox>
        </Form.Item> */}
          <Form.Item label="카테고리" required>
            <Select
              value={form.categoryCode}
              onChange={(value) => {
                setForm(
                  produce((draft) => {
                    draft.categoryCode = value;
                  }),
                );
              }}
              style={{ width: 200 }}
              placeholder="카테고리를 선택해주세요"
              options={codesTree?.NPT?.items?.map(({ code, label }) => ({ label, value: code })) ?? []}
            />
          </Form.Item>
          <Form.Item label="제목" required>
            <Input
              type="text"
              placeholder="제목을 입력하세요"
              value={form.title}
              onChange={(event) => {
                setForm(
                  produce((draft) => {
                    draft.title = event.target.value;
                  }),
                );
              }}
            />
          </Form.Item>
          <Form.Item label="내용" required>
            <Editor
              editorState={form.contents}
              onEditorStateChange={(editorState) => {
                setForm(
                  produce((draft) => {
                    draft.contents = editorState;
                  }),
                );
              }}
              editorStyle={{ minHeight: 350, backgroundColor: 'white' }}
            />
          </Form.Item>
          <Form.Item label="첨부파일">
            <FileUpload
              items={form.images}
              onDoneChange={(fileListDone) => {
                setForm(
                  produce((draft) => {
                    draft.images = fileListDone.map(({ response }) => response[0].id);
                    draft.imageTexts.splice(draft.imageTexts.length - 1, draft.imageTexts.length - fileListDone.length);
                  }),
                );
              }}
              beforeUpload={checkBeforeUpload({
                availableLists: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'],
                textWhenFail: 'jpg, jpeg, png, gif 확장자만 가능합니다.',
              })}
              extraItemRender={(file) => {
                const findIndex = form?.images?.findIndex((image) => image === file?.response?.[0]?.id);
                if (!(findIndex > -1)) {
                  return null;
                }
                return (
                  <Space style={{ marginTop: 10 }}>
                    <Typography.Text>대체 택스트: </Typography.Text>
                    <Input
                      value={form.imageTexts[findIndex]}
                      onChange={(event) => {
                        setForm(
                          produce((draft) => {
                            draft.imageTexts[findIndex] = event.target.value;
                          }),
                        );
                      }}
                    />
                  </Space>
                );
              }}
            />
            <ImageUploadGuide />
          </Form.Item>
          <Form.Item label="사용여부" required>
            <Radio.Group
              value={form.active}
              onChange={(event) => {
                setForm(
                  produce((draft) => {
                    draft.active = event.target.value;
                  }),
                );
              }}
              options={[
                { label: '사용', value: true },
                { label: '미사용', value: false },
              ]}
            />
          </Form.Item>
          <Row justify="end">
            <Space>
              <Button
                onClick={() => {
                  Modal.confirm({
                    title: id ? '파트너사 공지 수정' : '파트너사 공지 등록',
                    content: (
                      <>
                        입력했던 내용이 전부 사라집니다.
                        <br />
                        계속 하시겠습니까?
                      </>
                    ),
                    okText: '확인',
                    cancelText: '취소',
                    centered: true,
                    onOk: () => {
                      if (id) {
                        navigate(`/posts/notice/partner/detail/${id}`);
                      } else {
                        navigate('/posts/notice/partner');
                      }
                    },
                  });
                }}
              >
                취소
              </Button>
              <Button htmlType="submit" type="primary">
                저장
              </Button>
            </Space>
          </Row>
        </Space>
      </Form>
    );
  },
);

const PostsNoticePartnersRegistPage = () => {
  const { id } = useParams();

  return (
    <FetchElement
      fetch={() => id && restApi.get(`/cms/notices/partners/${id}`)}
      Component={MemoizedPostsNoticePartnersRegistPage}
    />
  );
};

export default PostsNoticePartnersRegistPage;
