import React from 'react';
import { Button, Space, Descriptions, Input, Modal } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from '../../Page.module.less';
import { restApi } from '../../../apis';
import { useReduxStore } from '../../../hooks/useReduxStore';

const cx = classNames.bind(styles);

function ReviewServiceDetail(props) {
  const { map: codeMap } = useReduxStore('codes');
  const navigate = useNavigate();
  const { inquireId } = useParams();
  const [inquire, setInquire] = React.useState(null);
  const [saving, setSaving] = React.useState(false);
  const [answer, setAnswer] = React.useState('');

  const handleSave = async () => {
    if (answer.length < 1) {
      Modal.warning({
        content: '답변을 입력해주세요.',
      });
      return;
    }
    try {
      if (saving) return;
      setSaving(true);
      const {
        data: { answerAt, adminName, adminRole },
      } = await restApi.put(`cms/account-service-questions/${inquireId}/answer`, {
        answer,
      });
      setInquire((prev) => ({ ...prev, answerAt, adminName, adminRole }));
      Modal.success({
        content: '저장을 완료했습니다.',
      });
      setSaving(false);
    } catch (e) {
      setSaving(false);
    }
  };

  React.useEffect(() => {
    if (!inquireId) return;
    restApi.get(`cms/account-service-questions/${inquireId}`).then(({ data }) => {
      setInquire(data);
      if (data.answer) {
        setAnswer(data.answer);
      }
    });
  }, [inquireId]);

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="문의분야">
            {codeMap?.[inquire?.categoryCode]}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="회원아이디">
            {inquire?.userId}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="회원이름">
            {inquire?.userName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="문의내용">
            {inquire?.contents}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="첨부파일">
            <a rel="noreferrer" target="_blank" href={inquire?.image} download>
              {inquire?.fileName || '-'}
            </a>
          </Descriptions.Item>
        </Descriptions>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변내용">
            <Input
              type="text"
              value={answer}
              placeholder="답변은 입력해주세요."
              onChange={(event) => setAnswer(event.target.value)}
            />
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변일">
            {inquire?.answerAt}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변자">
            {inquire?.adminName} / {inquire?.adminRole}
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button onClick={() => navigate(-1)}>목록</Button>
        <Button disabled={saving} onClick={handleSave}>
          저장
        </Button>
      </div>
    </div>
  );
}

export default ReviewServiceDetail;
