import { Button, Col, Divider, Input, Row, Select, Space } from 'antd';
import produce from 'immer';
import React from 'react';
import { Link } from 'react-router-dom';
import SearchForm from '../../components/SearchForm';
import CommTable from '../../components/table/CommTable';

const SEARCH_TYPE = {
  EMAIL: 'EMAIL',
  NAME: 'NAME',
  PHONE: 'PHONE',
  USERID: 'USERID',
};

const AdminPage = () => {
  const initialParams = React.useMemo(
    () => ({
      keyword: '',
      searchType: SEARCH_TYPE.NAME,
      isActive: null,
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);

  return (
    <Space style={{ width: '100%', maxWidth: 1200 }} direction="vertical">
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initialParams);
          return initialParams;
        }}
      >
        <Row align="middle" gutter={15}>
          <Col>
            <Select
              value={params.isActive}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.isActive = value;
                  }),
                );
              }}
              options={[
                { label: '사용', value: true },
                { label: '미사용', value: false },
              ]}
              style={{ width: 150 }}
              placeholder="사용여부"
              allowClear
            />
          </Col>
          <Col>
            <Select
              value={params.searchType}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.searchType = value;
                  }),
                );
              }}
              options={[
                {
                  label: '관리자명',
                  value: SEARCH_TYPE.NAME,
                },
                {
                  label: '아이디',
                  value: SEARCH_TYPE.USERID,
                },
                {
                  label: '이메일',
                  value: SEARCH_TYPE.EMAIL,
                },
                {
                  label: '휴대폰번호',
                  value: SEARCH_TYPE.PHONE,
                },
              ]}
              style={{ width: 150 }}
            />
          </Col>
          <Col>
            <Input
              value={params.keyword}
              onChange={(event) => {
                setParams(
                  produce((draft) => {
                    draft.keyword = event.target.value;
                  }),
                );
              }}
              style={{ width: 200 }}
              placeholder="키워드를 입력하세요."
            />
          </Col>
          <Col>
            <Space>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Col>
        </Row>
      </SearchForm>
      <Divider />
      <CommTable
        url="/cms/accounts/admin"
        columns={({ limit, pageCur }) => [
          {
            title: '번호',
            width: 80,
            render: (_, $, index) => pageCur * limit + index + 1,
          },
          {
            title: '아이디',
            width: 150,
            dataIndex: 'userId',
            render: (value, record) => <Link to={`/admin/${record.id}`}>{value}</Link>,
          },
          {
            title: '관리자명',
            width: 150,
            dataIndex: 'userName',
          },
          {
            title: '이메일',
            dataIndex: 'email',
          },
          {
            title: '휴대폰번호',
            width: 150,
            dataIndex: 'phone',
          },
          {
            title: '사용여부',
            width: 100,
            dataIndex: 'active',
            render: (value) => (value ? '사용' : '미사용'),
          },
          {
            title: '등록일',
            width: 120,
            dataIndex: 'createdAt',
          },
        ]}
        headerExtra={
          <Link to="/admin/regist">
            <Button type="primary">등록</Button>
          </Link>
        }
      />
    </Space>
  );
};

export default AdminPage;
