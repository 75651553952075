import React from 'react';
import { Button, Menu, Space, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import CommTable from '#components/table/CommTable';
import { useReduxStore } from '#hooks/useReduxStore';
import useIndex from '#hooks/useIndex';
import { restApi } from '#apis/index';

const TourReservation = () => {
  const { map, tree } = useReduxStore('codes');
  const { getIndex } = useIndex();

  const [modify, setModify] = React.useState(false);
  const [reloadData, setReloadData] = React.useState(false);

  const onLoadData = () => {
    setReloadData(false);
  };

  const items = React.useMemo(
    () => tree['TOST']?.items?.map((item) => ({ statusCode: item?.code, label: item?.label })),
    [],
  );

  const handleSelectItem = (value, key) => {
    const { id: tourReservationId, status: prevStatus } = value;
    const status = key.key;

    if (prevStatus !== status) {
      try {
        restApi.put(`/cms/furniture/${tourReservationId}?status=${status}`).then((res) => {
          if (res.status === 200) setReloadData(true);
        });
      } catch (error) {
        console.warn(error);
      }
    }
  };

  return (
    <div>
      <CommTable
        onLoadData={onLoadData}
        reloadData={reloadData}
        headerExtra={
          <Button onClick={() => setModify((prev) => !prev)} type="primary">
            {modify ? '수정 완료' : '수정'}
          </Button>
        }
        url="/cms/furniture"
        columns={({ limit, pageCur, total }) => [
          {
            title: '번호',
            dataIndex: 'tableIndex',
            key: 'tableIndex',
            fixed: 'left',
            width: 50,
            render: (value, obj, index) => getIndex(index),
          },
          {
            title: '고객명',
            dataIndex: 'name',
            key: 'name',
            // fixed: 'left',
            width: 80,
          },
          {
            title: '예약일',
            dataIndex: 'wantedBuyAt',
            key: 'wantedBuyAt',
            // fixed: 'left',
            width: 180,
            render: (userId, obj) => <Link to={`/tour/detail/${obj.id}`}>{userId}</Link>,
          },
          {
            title: '휴대전화',
            dataIndex: 'phone',
            key: 'phone',
            width: 150,
          },
          {
            title: '신청일',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 180,
          },
          {
            title: '이메일',
            dataIndex: 'email',
            key: 'email',
            width: 150,
          },
          {
            title: '예약 정보 상태',
            // dataIndex: 'email',
            key: 'modifyState',
            width: 100,
            render: (value) => {
              return (
                <Dropdown
                  disabled={!modify}
                  overlay={
                    <Menu>
                      {items?.map((item) => (
                        <Menu.Item key={item?.statusCode} onClick={(key) => handleSelectItem(value, key)}>
                          <span>{item.label}</span>
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <Button style={{ border: 'none', backgroundColor: 'inherit' }}>
                    <Space>
                      <span>{map[value?.status]}</span>
                    </Space>
                  </Button>
                </Dropdown>
              );
            },
          },
        ]}
        rowKey={({ id }) => id}
        scroll={{ x: 1120 }}
      />
    </div>
  );
};

export default TourReservation;
