import React from 'react';
import { getFileHost, restApi } from '#apis/index';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import { Editor as REditor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';

const Editor = React.memo((props) => (
  <REditor
    toolbar={{
      list: { inDropdown: true },
      textAlign: { inDropdown: true },
      link: { inDropdown: true },
      history: { inDropdown: false },
      image: {
        defaultSize: { height: 'auto', width: '100%' },
        uploadCallback: (file) => {
          return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onloadend = async (event) => {
              const image = new Image();
              image.src = event.target.result;
              image.onload = async function () {
                // if (this.width * 3 < this.height) {
                //   alert('이미지의 비율이 1 : 3를 초과할 수 없습니다.\n이미지가 너무 크다면 나눠서 업로드해주세요.');
                //   reject('이미지의 비율이 1 : 3를 초과할 수 없습니다.');
                // }
                const formData = new FormData();
                formData.append('files', file);
                const { data: responseData } = await restApi.post('/files', formData, {
                  headers: {
                    'content-type': 'multipart/form-data',
                  },
                });
                const link = `${getFileHost()}/${responseData[0].id}`;
                resolve({ data: { link } });
              };
            };
          });
        },
      },
    }}
    placeholder="내용을 작성해주세요."
    localization={{
      locale: 'ko',
    }}
    {...props}
  />
));

export const draftToHTML = (editorState) =>
  editorState ? draftToHtml(convertToRaw(editorState?.getCurrentContent())) : '';

export const HTMLToDraft = (html) => {
  if (!html) return EditorState.createEmpty();
  const { contentBlocks, entityMap } = htmlToDraft(html);
  if (!contentBlocks || !entityMap) return EditorState.createEmpty();
  return EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap));
};

export default Editor;
