import React from 'react';
import { Button, Col, DatePicker, Divider, Input, Row, Select, Space } from 'antd';
import { Link } from 'react-router-dom';
import SearchForm from '#components/SearchForm';
import CommTable from '#components/table/CommTable';
import { useReduxStore } from '#hooks/useReduxStore';
import useIndex from '#hooks/useIndex';
import produce from 'immer';
import moment from 'moment';
import useCheckSearchTypeAndKeyword from '../../../../hooks/useCheckSearchTypeAndKeyword';
import { formatPhonNumer } from '../../../../utils/formatPhoneNumber';

function MdWaitingIndex() {
  const { map } = useReduxStore('codes');
  const { getIndex } = useIndex();

  const initailParams = React.useMemo(
    () => ({
      dateOpt: 'createdAt',
      startAt: null,
      endAt: null,
      keywordOpt: null,
      keyword: null,
    }),
    [],
  );

  const [params, setParams] = React.useState(initailParams);
  const [onClickSubmit] = useCheckSearchTypeAndKeyword(params.keywordOpt, params.keyword);

  return (
    <div>
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initailParams);
          return initailParams;
        }}
      >
        <Row gutter={[50, 15]}>
          <Col span={24}>
            <Space style={{ width: '100%' }}>
              <Select
                value={params.dateOpt}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.dateOpt = value;
                    }),
                  );
                }}
                options={[
                  { label: '신청일', value: 'createdAt' },
                  // { label: '최종로그인', value: 'lastLoginAt' },
                ]}
                placeholder="날짜옵션"
                style={{ width: 120 }}
              />
              <DatePicker.RangePicker
                disabled={!params.dateOpt}
                value={[params.startAt ? moment(params.startAt) : null, params.endAt ? moment(params.endAt) : null]}
                onChange={(e) => {
                  if (e) {
                    let [startAt, endAt] = e;
                    if (params.selectDateType === 'WEEK') {
                      startAt = startAt.startOf('isoWeek');
                      endAt = endAt.startOf('isoWeek');
                    } else if (params.selectDateType === 'MONTH') {
                      startAt = startAt.startOf('month');
                      endAt = endAt.startOf('month');
                    }
                    setParams(
                      produce((draft) => {
                        draft.startAt = startAt.format('YYYY-MM-DD');
                        draft.endAt = endAt.format('YYYY-MM-DD');
                      }),
                    );
                  } else {
                    setParams(
                      produce((draft) => {
                        draft.startAt = null;
                        draft.endAt = null;
                      }),
                    );
                  }
                }}
              />
            </Space>
          </Col>
          <Col span={24}>
            <Space style={{ width: '100%' }}>
              <Select
                placeholder="검색 옵션"
                style={{ width: 120 }}
                value={params.keywordOpt}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.keywordOpt = value;
                    }),
                  );
                }}
                allowClear
                options={[
                  { label: 'MD아이디', value: 'userId' },
                  { label: 'MD이름', value: 'username' },
                  { label: '휴대전화', value: 'phone' },
                ]}
              />
              <Input
                placeholder="키워드를 입력하세요"
                style={{ width: 300, paddingBlock: '5.8px' }}
                value={params.keyword}
                onChange={(event) => {
                  setParams(
                    produce((draft) => {
                      draft.keyword = event.target.value;
                    }),
                  );
                }}
              />
            </Space>
          </Col>
          <Col span={24}>
            <Row justify="end">
              <Col>
                <Space>
                  <Button type="primary" htmlType="submit" onClick={onClickSubmit}>
                    검색
                  </Button>
                  <Button htmlType="reset">초기화</Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </SearchForm>
      <Divider />
      <CommTable
        // toggleObj={{ text: '승인반려 내용 포함해서 보기', key: 'includeReject' }}
        url="/cms/accounts/md/unconfirmed"
        columns={[
          {
            title: '번호',
            dataIndex: 'tableIndex',
            key: 'tableIndex',
            fixed: true,
            width: 50,
            render: (value, obj, index) => {
              return getIndex(index);
            },
          },
          {
            title: 'MD아이디',
            dataIndex: 'userId',
            key: 'userId',
            fixed: true,
            width: 100,
            render: (userId, obj) => <Link to={`/member/md/waiting/detail/${obj.id}`}>{userId}</Link>,
          },
          {
            title: 'MD이름',
            dataIndex: 'userName',
            key: 'userNamerId',
            width: 100,
          },
          {
            title: '휴대전화',
            dataIndex: 'phone',
            key: 'phone',
            width: 100,
            render: (value) => formatPhonNumer(value),
          },
          {
            title: '신청일',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 100,
          },
        ]}
      />
    </div>
  );
}

export default MdWaitingIndex;
