import { Space } from 'antd';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { restApi } from '../../../apis';
import Chart from 'react-apexcharts';
import moment from 'moment';

const PaymentAmountByDayOfTheWeek = () => {
  const { data, isLoading, error } = useQuery(
    ['payment_amount_by_day_of_the_week'],
    async () => {
      const { data } = await restApi.get('/statistics/day-of-week/amount');

      const earliest = data['3'];
      const middle = data['2'];
      const latest = data['1'];

      const parsedData = {
        earliest: {
          name: `${earliest.weekNum}주(${moment(earliest.startAt).format('YYYY.MM.DD')} ~ ${moment(
            earliest.endAt,
          ).format('YYYY.MM.DD')})`,
          data: [
            earliest.monAmount,
            earliest.tueAmount,
            earliest.wedAmount,
            earliest.thrAmount,
            earliest.friAmount,
            earliest.satAmount,
            earliest.sunAmount,
          ],
        },
        middle: {
          name: `${middle.weekNum}주(${moment(middle.startAt).format('YYYY.MM.DD')} ~ ${moment(middle.endAt).format(
            'YYYY.MM.DD',
          )})`,
          data: [
            middle.monAmount,
            middle.tueAmount,
            middle.wedAmount,
            middle.thrAmount,
            middle.friAmount,
            middle.satAmount,
            middle.sunAmount,
          ],
        },
        latest: {
          name: `${latest.weekNum}주(${moment(latest.startAt).format('YYYY.MM.DD')} ~ ${moment(latest.endAt).format(
            'YYYY.MM.DD',
          )})`,
          data: [
            latest.monAmount,
            latest.tueAmount,
            latest.wedAmount,
            latest.thrAmount,
            latest.friAmount,
            latest.satAmount,
            latest.sunAmount,
          ],
        },
      };

      return parsedData;
    },
    {
      staleTime: 1000,
      // keepPreviousData: true,
    },
  );

  // console.log('data :::: ', data);

  return (
    <Space style={{ width: '100%', maxWidth: 800 }} direction="vertical">
      <Chart
        type="bar"
        width="800"
        series={[
          {
            name: data?.earliest.name,
            data: data?.earliest.data || [],
          },
          {
            name: data?.middle.name,
            data: data?.middle.data || [],
          },
          {
            name: data?.latest.name,
            data: data?.latest.data || [],
          },
        ]}
        options={{
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          xaxis: {
            categories: ['월', '화', '수', '목', '금', '토', '일'],
          },
          yaxis: {
            // title: {
            //   text: '',
            // },
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            // y: {
            //   formatter: function (val) {
            //     return '$ ' + val + ' thousands';
            //   },
            // },
          },
        }}
      />
    </Space>
  );
};

export default PaymentAmountByDayOfTheWeek;
