import { restApi } from '#apis/index';
import handleError from '#utils/handleError';
import produce from 'immer';
import moment from 'moment';
import { useReduxStore } from '#hooks/useReduxStore';
import React from 'react';
import { Button, Modal, Input, Descriptions, Space, Select, Radio, Row, Col, DatePicker, Typography } from 'antd';
import SearchForm from '#components/SearchForm';
import CommTable from '#components/table/CommTable';
import ShowOrderDetailBtn from '#components/Buttons/ShowOrderDetailBtn';
import useCheckOrderStatusCodeRowKeys from '#hooks/useCheckOrderStatusCodeRowKeys';

const DATE_TYPE = {
  REQUEST_AT: 'RequestAt',
  PAY_AT: 'PayAt',
};

const SEARCH_TYPE = {
  BUYER: 'Buyer',
  BUYER_ID: 'BuyerId',
  ORDER_NUMBER: 'OrderId',
  ORDER_PRODUCT_NUMBER: 'OrderProductId',
  PRODUCT_NUMBER: 'ProductId',
  RECIPIENT: 'Recipient',
};

function SalesCancel() {
  const { map: codesMap, tree: codesTree } = useReduxStore('codes');

  const ORDER_STATUS_TYPE = React.useMemo(
    () =>
      codesTree?.OPS.items.reduce((p, v) => {
        p[v.code] = v.code;
        return p;
      }, {}),
    [codesTree],
  );

  const initialParams = React.useMemo(
    () => ({
      dateType: null,
      startAt: null,
      endAt: null,
      cancelCode: null,
      searchType: null,
    }),
    [],
  );

  const [params, setParams] = React.useState(initialParams);
  const tableRef = React.useRef();
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

  const handleChangePagination = React.useCallback(() => {
    setSelectedRowKeys([]);
  }, []);

  const { checkOrderStatusCodeRowKeys } = useCheckOrderStatusCodeRowKeys(
    tableRef.current?.dataSource,
    selectedRowKeys,
    { keyword: 'cancelId' },
  );

  const handleRefresh = React.useCallback(() => {
    setSelectedRowKeys([]);
    tableRef.current?.loadData();
  }, []);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initialParams);
          return initialParams;
        }}
      >
        <Row gutter={10} style={{ marginBottom: 10, alignItems: 'center' }}>
          <Col>
            <Select
              value={params.dateType}
              placeholder="날짜 옵션"
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.dateType = value;
                  }),
                );
              }}
              options={[
                { label: '결제일', value: DATE_TYPE.PAY_AT },
                { label: '요청일', value: DATE_TYPE.REQUEST_AT },
              ]}
              style={{ width: 150 }}
            />
          </Col>
          <Col>
            <DatePicker.RangePicker
              disabled={!params.dateType}
              value={[
                params.startAt ? moment(params.startAt) : undefined,
                params.endAt ? moment(params.endAt) : undefined,
              ]}
              onChange={([startAt, endAt]) => {
                setParams(
                  produce((draft) => {
                    draft.startAt = startAt.format('YYYY-MM-DD');
                    draft.endAt = endAt.format('YYYY-MM-DD');
                  }),
                );
              }}
            />
          </Col>
          <Col>
            <Radio.Group
              disabled={!params.dateType}
              value={moment(params.endAt).diff(params.startAt, 'day')}
              onChange={(event) => {
                setParams(
                  produce((draft) => {
                    draft.startAt = moment().add(-event.target.value, 'day').format('YYYY-MM-DD');
                    draft.endAt = moment().format('YYYY-MM-DD');
                  }),
                );
              }}
              options={[
                { label: '오늘', value: 0 },
                { label: '1주일', value: 7 },
                { label: '1개월', value: 30 },
                { label: '3개월', value: 90 },
              ]}
              buttonStyle="solid"
              optionType="button"
            />
          </Col>
        </Row>
        <Row gutter={10} style={{ alignItems: 'center' }}>
          <Col>
            <Select
              value={params.cancelCode}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.cancelCode = value;
                  }),
                );
              }}
              options={codesTree?.CAS.items.map((value) => ({ label: value.label, value: value.code }))}
              placeholder="취소상태"
              allowClear
              style={{ width: 150 }}
            />
          </Col>
          <Col>
            <Select
              value={params.searchType}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.searchType = value;
                  }),
                );
              }}
              placeholder="검색 옵션"
              options={[
                { label: '수취인명', value: SEARCH_TYPE.RECIPIENT },
                { label: '구매자명', value: SEARCH_TYPE.BUYER },
                { label: '구매자 ID', value: SEARCH_TYPE.BUYER_ID },
                { label: '주문번호', value: SEARCH_TYPE.ORDER_NUMBER },
                { label: '상품번호', value: SEARCH_TYPE.PRODUCT_NUMBER },
                { label: '상품주문번호', value: SEARCH_TYPE.ORDER_PRODUCT_NUMBER },
              ]}
              style={{ width: 150 }}
            />
          </Col>
          <Col>
            <Input
              disabled={!params.searchType}
              value={params.keyword}
              onChange={(event) => {
                setParams(
                  produce((draft) => {
                    draft.keyword = event.target.value;
                  }),
                );
              }}
              placeholder="키워드를 입력하세요"
              style={{ width: 250 }}
            />
          </Col>
          <Col>
            <Space>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Col>
        </Row>
        <CommTable
          ref={tableRef}
          url="/cms/account-orders/cancel-order"
          rowSelection={{
            selectedRowKeys,
            onChange: setSelectedRowKeys,
            type: 'radio',
          }}
          rowKey={({ cancelId }) => cancelId}
          onChangePagination={handleChangePagination}
          columns={[
            {
              title: '주문번호',
              dataIndex: 'orderProductId',
              index: 'key',
              fixed: true,
              width: 200,
              render: (value) => <ShowOrderDetailBtn orderId={value} />,
            },
            {
              title: '파트너사',
              dataIndex: 'businessName',
              fixed: true,
              width: 100,
              render: (value) => value,
            },
            {
              title: '주문상태',
              dataIndex: 'orderProductStateCode',
              fixed: true,
              width: 100,
              render: (orderProductStateCode) => codesMap[orderProductStateCode],
            },
            {
              title: '취소 처리상태',
              dataIndex: 'cancelStateCode',
              width: 200,
              render: (cancelStateCode) => codesMap[cancelStateCode],
            },
            {
              title: '주문일시',
              dataIndex: 'orderAt',
              width: 200,
              render: (orderAt) => orderAt,
            },
            {
              title: '취소요청일',
              dataIndex: 'requestAt',
              width: 200,
              render: (requestAt) => requestAt,
            },
            {
              title: '취소사유',
              dataIndex: 'reason',
              width: 150,
              render: (reason, obj) => (
                <Button
                  type="text"
                  onClick={() => {
                    Modal.info({
                      title: '취소 사유',
                      width: 650,
                      content: (
                        <div>
                          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                            <Descriptions>
                              <Descriptions.Item span={3} label="상품명">
                                {obj.productName}
                              </Descriptions.Item>
                              <Descriptions.Item label="상품주문번호" span={3}>
                                {obj.orderProductId}
                              </Descriptions.Item>
                              <Descriptions.Item label="사유" span={3}>
                                {reason}
                              </Descriptions.Item>
                            </Descriptions>
                          </Space>
                          <Row justify="end" style={{ marginBottom: -25 }} gutter={10}>
                            <Col>
                              <Button
                                onClick={() => {
                                  Modal.destroyAll();
                                }}
                              >
                                취소
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      ),
                      okButtonProps: { style: { display: 'none' } },
                      centered: true,
                    });
                  }}
                >
                  <span style={{ fontSize: 12 }}>{reason}</span>
                </Button>
              ),
            },
            {
              title: '취소승일일',
              dataIndex: 'cancelAcceptAt',
              width: 200,
              render: (cancelAcceptAt) => cancelAcceptAt,
            },
            {
              title: '취소승인처리자',
              dataIndex: 'accepter',
              width: 100,
              render: (accepter) => accepter,
            },
            {
              title: '취소완료일',
              dataIndex: 'cancelAt',
              width: 200,
              render: (value) => value,
            },
            // { title: '환불처리자', dataIndex: 'refunderChannel', width: 100, render: (value) => value },
            { title: '수량', dataIndex: 'count', width: 100, render: (value) => value },
            { title: '구매자명', dataIndex: 'buyerName', width: 100, render: (value) => value },
            { title: '구매자ID', dataIndex: 'buyerUserId', width: 150, render: (value) => value },
            { title: '수취인명', dataIndex: 'recipient', width: 100, render: (value) => value },
            { title: '상품명', dataIndex: 'productName', width: 200, render: (value) => value },
            { title: '옵션정보', dataIndex: 'options', width: 100, render: (value) => value },
            { title: '수량', dataIndex: 'count', width: 100, render: (value) => value },
            { title: '옵션가격', dataIndex: 'optionAmountEach', width: 100, render: (value) => value },
            { title: '상품가격', dataIndex: 'productAmountEach', width: 100, render: (value) => value },
            { title: '상품별 할인액', dataIndex: 'productDiscountAmountEach', width: 100, render: (value) => value },
            { title: '상품별 총 주문금액', dataIndex: 'totalAmountEach', width: 100, render: (value) => value },
            { title: '구매자명', dataIndex: 'buyerName', width: 100, render: (value) => value },
            { title: '구매자ID', dataIndex: 'buyerUserId', width: 100, render: (value) => value },
            { title: '수취인명', dataIndex: 'recipient', width: 100, render: (value) => value },
            {
              title: '배송비 형태',
              dataIndex: 'deliveryPaymentTypeCode',
              width: 100,
              render: (value) => codesMap?.[value],
            },
            {
              title: '배송비 유형',
              dataIndex: 'deliveryFreeTypeCode',
              width: 100,
              render: (value) => codesMap?.[value],
            },
            { title: '배송비 합계', dataIndex: 'deliveryTotalAmount', width: 100, render: (value) => value },
            // TODO server에 deliveryDiscountAmount key 추가해달라고 하기
            // { title: '배송비 할인액', dataIndex: 'deliveryDiscountAmount', width: 100, render: (value) => value },
            { title: '구매자연락처', dataIndex: 'buyerPhone', width: 130, render: (value) => value },
            { title: '수취인연락처1', dataIndex: 'phone', width: 100, render: (value) => value },
            { title: '수취인연락처2', dataIndex: 'subPhone', width: 100, render: (value) => value },
            { title: '배송지', dataIndex: 'addr', width: 100, render: (value) => value },
            { title: '우편번호', dataIndex: 'zipCode', width: 100, render: (value) => value },
          ]}
          scroll={{ x: 1400 }}
        />
      </SearchForm>
      <Descriptions colon={false} bordered column={1}>
        <Descriptions.Item label="취소 완료처리" labelStyle={{ width: 140, alignItems: 'center' }}>
          <Space>
            <Button
              disabled={!checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS008)}
              onClick={() => {
                // 선택된 값이 1개 이상일 경우
                if (selectedRowKeys.length > 0) {
                  // 선택된 값이 취소인것들만 있을 경우
                  if (checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS008)) {
                    Modal.confirm({
                      title: '취소 완료처리',
                      content: (
                        <Typography.Text>
                          취소 완료 처리 후에는 되돌릴 수 없으며, 결제된 내역은 자동 환불처리가 됩니다. 선택한 내역을
                          취소 완료처리를 하시겠습니까?
                        </Typography.Text>
                      ),
                      centered: true,
                      okText: '확인',
                      cancelText: '취소',
                      async onOk() {
                        try {
                          // 발송처리
                          await Promise.all(
                            selectedRowKeys.map((cancelId) =>
                              restApi.put(`/cms/account-orders/cancel-order/${cancelId}/confirm-cancel`),
                            ),
                          );
                          Modal.destroyAll();
                          handleRefresh();
                          Modal.success({
                            title: '취소 완료처리',
                            content: '취소 완료처리가 되었습니다.',
                            centered: true,
                          });
                        } catch (error) {
                          // 발송지연처리 실패
                          handleError(error);
                          Modal.error({
                            title: '취소 완료처리',
                            content: '취소 완료처리 실패했습니다.',
                            centered: true,
                          });
                        }
                      },
                    });
                  }
                  // 선택된 값이 취소가 아닌게 있을 경우
                  else {
                    Modal.warn({
                      title: '취소 완료처리',
                      content: '취소 처리 된 내역만 취소 완료처리를 할 수 있습니다.',
                      centered: true,
                    });
                  }
                }
                // 선택된 값이 1개도 없을경우
                else {
                  Modal.warn({
                    title: '취소 완료처리',
                    content: '처리할 상품주문번호를 먼저 선택해주세요.',
                    centered: true,
                  });
                }
              }}
            >
              취소 완료처리
            </Button>
            <Button
              disabled={!checkOrderStatusCodeRowKeys()}
              onClick={() => {
                // 선택된 값이 1개 이상일 경우
                if (selectedRowKeys.length > 0) {
                  // 선택된 값이 취소인것들만 있을 경우
                  if (checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS008)) {
                    Modal.confirm({
                      title: '취소 거부(철회)처리',
                      content: <Typography.Text>* 취소 거부(철회)처리 후에는 되돌릴 수 없습니다.</Typography.Text>,
                      centered: true,
                      okText: '확인',
                      cancelText: '취소',
                      async onOk() {
                        try {
                          await Promise.all(
                            selectedRowKeys.map((cancelId) =>
                              restApi.put(`/cms/account-orders/cancel-order/${cancelId}/withdrawal`),
                            ),
                          );
                          Modal.destroyAll();
                          handleRefresh();
                          Modal.success({
                            title: '취소 거부(철회)',
                            content: '취소 거부(철회)가 되었습니다.',
                            centered: true,
                          });
                        } catch (error) {
                          // 발송지연처리 실패
                          handleError(error);
                          Modal.error({
                            title: '취소 거부(철회)',
                            content: '취소 거부(철회) 실패했습니다.',
                            centered: true,
                          });
                        }
                      },
                    });
                  } else {
                    Modal.warn({
                      title: '취소 거부(철회)',
                      content:
                        '선택하신 주문 건은 취소 거부(철회)처리가 불가합니다. 취소 거부처리는 처리상태가 “취소요청/취소중”인 주문건만 처리 가능합니다.취소 처리상태를 확인해 주세요.',
                      centered: true,
                    });
                  }
                }
                // 선택된 값이 1개도 없을경우
                else {
                  Modal.warn({
                    title: '취소 거부(철회)',
                    content: '처리할 상품주문번호를 먼저 선택해주세요.',
                    centered: true,
                  });
                }
              }}
            >
              취소 거부(철회)처리
            </Button>
          </Space>
          <div style={{ marginTop: 20 }}>
            <p>ㆍ취소 대기 주문건에 대해서만 취소완료/취소거부 처리 가능합니다.</p>
          </div>
        </Descriptions.Item>
      </Descriptions>
    </Space>
  );
}

export default SalesCancel;
