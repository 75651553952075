import React from 'react';
import { restApi } from '#apis/index';
import { useSearchParams } from 'react-router-dom';
import Editor, { draftToHTML, HTMLToDraft } from '#components/Editor';
import useFetch from '#hooks/useFetch';
import { Button, Col, Form, Input, Modal, Row, Space, Spin, Tabs, Typography } from 'antd';
import { EditorState } from 'draft-js';
import produce from 'immer';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';

const RenderForm = React.memo(({ code }) => {
  const { map: codesMap } = useSelector((selector) => selector.codes, shallowEqual);
  const term = useFetch();
  const [form, setForm] = React.useState({
    title: '',
    contents: EditorState.createEmpty(),
  });

  React.useEffect(() => {
    term
      .fetch(restApi.get(`/cms/agreement/${code}`))
      .then(({ data: responseData }) => {
        setForm({ title: responseData.title, contents: HTMLToDraft(responseData.contents) });
      })
      .catch();
  }, []);

  if (term.loading) {
    return <Spin />;
  }

  return (
    <Form
      onFinish={() => {
        Modal.confirm({
          title: codesMap[code],
          content: '입력한 내용을 반영하겠습니까 ?',
          centered: true,
          async onOk() {
            if (!form.title)
              return Modal.warn({
                title: codesMap[code],
                content: '약관제목을 입력하세요.',
                centered: true,
                okText: '확인',
              });
            try {
              await restApi.put(`/cms/agreement/${code}`, { title: form.title, contents: draftToHTML(form.contents) });
              await term.fetch(restApi.get(`/cms/agreement/${code}`));
            } catch (error) {
              Modal.error({ title: codesMap[code], content: '저장을 실패했습니다.', centered: true, okText: '확인' });
            }
          },
          okText: '확인',
          cancelText: '취소',
        });
      }}
    >
      <Space style={{ width: '100%', maxWidth: 1200 }} direction="vertical">
        <Form.Item label="약관제목" required>
          <Input
            value={form.title}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.title = event.target.value;
                }),
              );
            }}
            maxLength={30}
          />
        </Form.Item>
        <Form.Item label="약관내용" required>
          <Editor
            editorState={form.contents}
            onEditorStateChange={(editorState) => {
              setForm(
                produce((draft) => {
                  draft.contents = editorState;
                }),
              );
            }}
            editorStyle={{ height: 350, backgroundColor: '#ffffff' }}
          />
        </Form.Item>
        <Form.Item label="최종 수정자">
          <Typography.Text>
            {[term.data?.updaterUserId, term.data?.updaterName].filter(Boolean).join(' / ') || '-'}
          </Typography.Text>
        </Form.Item>
        <Form.Item label="최종 수정시간">
          <Typography.Text>
            {term.data?.updatedAt ? moment(term.data?.updatedAt).format('YYYY-MM-DD HH:mm:ss') : '-'}
          </Typography.Text>
        </Form.Item>
        <Row justify="end">
          <Col>
            <Button type="primary" htmlType="submit">
              저장
            </Button>
          </Col>
        </Row>
      </Space>
    </Form>
  );
});

function ManagementTerms() {
  const { tree: codesTree } = useSelector((selector) => selector.codes ?? [], shallowEqual);
  const tabs = React.useMemo(() => codesTree?.TCT?.items ?? [], [codesTree]);
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    if (tabs?.length > 0) {
      if (!searchParams.get('code')) {
        setSearchParams({ code: tabs?.[0]?.code });
      }
    }
  }, []);

  return (
    <Tabs
      activeKey={searchParams.get('code')}
      onChange={(activeKey) => {
        setSearchParams({ code: activeKey });
      }}
    >
      {tabs?.map(({ code, label }) => {
        return (
          <Tabs.TabPane key={code} tab={label}>
            <RenderForm code={code} />
          </Tabs.TabPane>
        );
      })}
    </Tabs>
  );
}

export default ManagementTerms;
