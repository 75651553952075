import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import styles from './CategorySelectItem.module.less';

function CategorySelectItem({ selected, onSelect, children, isShowIcon = false }) {
  return (
    <button
      type="button"
      className={`${styles.button} ${selected ? styles.active : ''}`}
      onClick={() => {
        onSelect();
      }}
    >
      <p>{children}</p>
      {isShowIcon && <RightOutlined />}
    </button>
  );
}

export default CategorySelectItem;
