import qs from 'query-string';
import React from 'react';
import produce from 'immer';
import moment from 'moment';
import { Divider, Button, Row, Col, Select, DatePicker, Form, Input, Space, Typography } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import useFetch from '#hooks/useFetch';
import handleError from '#utils/handleError';
import formatComma from '#utils/formatComma';
import CardWrapper from '#components/common/CardWrapper';
import SearchForm from '#components/SearchForm';
import { restApi } from '#apis';
import CommTable from '#components/table/CommTable';

const PRODUCT_DATE_TYPE = {
  CREATED_AT: 'CREATED_AT',
  UPDATED_AT: 'UPDATED_AT',
};

function StoreManageMentPage() {
  const navigate = useNavigate();
  const count = useFetch();
  const { tree: codesTree, map: codesMap } = useSelector((selector) => selector?.codes, shallowEqual);
  const initialParams = React.useMemo(
    () => ({
      // 필터 등록일/마지막 수정일
      cmsProductDateType: PRODUCT_DATE_TYPE.CREATED_AT,
      // 필터 시작일
      startAt: null,
      // 필터 종료일
      endAt: null,
      // 사용 여부
      isActive: null,
      // 시/도
      extra1: null,
      // 시/군/구
      extra2: null,
      // 동/면/읍
      extra3: null,
      // 대표자 이름
      representative: '',
      // 판매권한
      salesPermissionCode: null,
      // 상점 이름 검색
      storeName: '',
      // 파트너사 아이디
      userId: '',
    }),
    [],
  );

  const salesPermissionCodeOptions = React.useMemo(
    () => codesTree?.PASS?.items?.map(({ code, label }) => ({ label, value: code })) ?? [],
    [codesTree],
  );
  const areaCodes = useFetch();
  const areaCodes2 = useFetch();
  const areaCodes3 = useFetch();
  const [params, setParams] = React.useState(initialParams);

  React.useEffect(() => {
    areaCodes.fetch(restApi.get('/area-codes/filter')).catch(handleError);
    count.fetch(restApi.get('/cms/stores/count')).catch(handleError);
  }, []);

  return (
    <div>
      <Row gutter={10}>
        <Col>
          <Typography.Paragraph style={{ marginBottom: 10 }}>판매권한</Typography.Paragraph>
          <Space>
            <Link
              to={`/store/management?${qs.stringify({
                cmsProductDateType: PRODUCT_DATE_TYPE.CREATED_AT,
                salesPermissionCode: 'PASS002',
              })}`}
            >
              <CardWrapper title="정상" value={`${formatComma(count.data?.PASS002)}건`} />
            </Link>
            <Link
              to={`/store/management?${qs.stringify({
                cmsProductDateType: PRODUCT_DATE_TYPE.CREATED_AT,
                salesPermissionCode: 'PASS001',
              })}`}
            >
              <CardWrapper title="권한신청" value={`${formatComma(count.data?.PASS001)}건`} />
            </Link>
            <Link
              to={`/store/management?${qs.stringify({
                cmsProductDateType: PRODUCT_DATE_TYPE.CREATED_AT,
                salesPermissionCode: 'PASS004',
              })}`}
            >
              <CardWrapper title="이용정지" value={`${formatComma(count.data?.PASS004)}건`} />
            </Link>
          </Space>
        </Col>
      </Row>
      <Divider />
      <SearchForm
        params={params}
        onInit={(params) => {
          setParams({
            cmsProductDateType: params?.cmsProductDateType || PRODUCT_DATE_TYPE.CREATED_AT,
            startAt: params?.startAt || null,
            endAt: params?.endAt || null,
            isActive: JSON.parse(params?.isActive || 'null'),
            isApproved: JSON.parse(params?.isApproved || 'null'),
            extra1: params?.extra1 || null,
            extra2: params?.extra2 || null,
            extra3: params?.extra3 || null,
            representative: params?.representative || '',
            salesPermissionCode: params?.salesPermissionCode || null,
            storeName: params?.storeName || '',
            userId: params?.userId || '',
          });
        }}
        onReset={() => {
          setParams(initialParams);
          return initialParams;
        }}
      >
        <Row gutter={15} style={{ marginBottom: 10, rowGap: 8 }}>
          <Col>
            <Select
              value={params.cmsProductDateType}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.cmsProductDateType = value;
                  }),
                );
              }}
              options={[
                { label: '등록일', value: PRODUCT_DATE_TYPE.CREATED_AT },
                { label: '최종수정일', value: PRODUCT_DATE_TYPE.UPDATED_AT },
              ]}
              style={{ width: 120 }}
            />
          </Col>
          <Col>
            <DatePicker.RangePicker
              value={[
                params.startAt ? moment(params.startAt) : undefined,
                params.endAt ? moment(params.endAt) : undefined,
              ]}
              onChange={([startAt, endAt]) => {
                setParams(
                  produce((draft) => {
                    draft.startAt = startAt.format('YYYY-MM-DD');
                    draft.endAt = endAt.format('YYYY-MM-DD');
                  }),
                );
              }}
            />
          </Col>
          <Col>
            <Select
              placeholder="사용여부"
              allowClear
              value={params.isActive}
              options={[
                { label: 'Y', value: true },
                { label: 'N', value: false },
              ]}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.isActive = value;
                  }),
                );
              }}
            />
          </Col>
          <Col>
            <Select
              placeholder="판매권한"
              allowClear
              value={params.salesPermissionCode}
              options={salesPermissionCodeOptions}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.salesPermissionCode = value;
                  }),
                );
              }}
            />
          </Col>
        </Row>
        <Row gutter={15} style={{ rowGap: 8, marginBottom: 10 }}>
          <Col>
            <Select
              value={params.extra1}
              loading={areaCodes.loading || areaCodes2.loading}
              onChange={async (value) => {
                await areaCodes2.fetch(restApi.get(`/area-codes/filter/${value}`));
                setParams(
                  produce((draft) => {
                    draft.extra1 = value;
                    draft.extra2 = null;
                    draft.extra3 = null;
                  }),
                );
              }}
              options={areaCodes.data?.map((area) => ({ label: area, value: area })) ?? []}
              style={{ width: 200 }}
              disabled={!(areaCodes.data?.length > 0)}
              placeholder="시/도"
              allowClear
            />
          </Col>
          <Col>
            <Select
              value={params.extra2}
              loading={areaCodes2.loading || areaCodes3.loading}
              onChange={async (value) => {
                await areaCodes3.fetch(restApi.get(`/area-codes/filter/${params.extra1}/${value}`));
                setParams(
                  produce((draft) => {
                    draft.extra2 = value;
                    draft.extra3 = null;
                  }),
                );
              }}
              options={areaCodes2.data?.map((area) => ({ label: area, value: area })) ?? []}
              style={{ width: 200 }}
              disabled={!(areaCodes2.data?.length > 0)}
              placeholder="시/군/구"
              allowClear
            />
          </Col>
          <Col>
            <Select
              value={params.extra3}
              loading={areaCodes3.loading}
              onChange={async (value) => {
                setParams(
                  produce((draft) => {
                    draft.extra3 = value;
                  }),
                );
              }}
              options={areaCodes3.data?.map((area) => ({ label: area, value: area })) ?? []}
              style={{ width: 200 }}
              disabled={!(areaCodes3.data?.length > 0)}
              placeholder="동/면/읍"
              allowClear
            />
          </Col>
        </Row>
        <Row gutter={15} style={{ rowGap: 8 }}>
          <Col style={{ marginRight: 20 }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{ wordBreak: 'keep-all', marginRight: 20 }}>상점명</div>
              <Input
                value={params.storeName}
                onChange={(event) => {
                  setParams(
                    produce((draft) => {
                      draft.storeName = event.target.value;
                    }),
                  );
                }}
                placeholder="키워드를 입력하세요"
              />
            </div>
          </Col>
          <Col>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{ wordBreak: 'keep-all', marginRight: 20 }}>아이디</div>
              <Input
                value={params.userId}
                onChange={(event) => {
                  setParams(
                    produce((draft) => {
                      draft.userId = event.target.value;
                    }),
                  );
                }}
                placeholder="키워드를 입력하세요"
              />
            </div>
          </Col>
          <Col>
            <Space>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Col>
        </Row>
      </SearchForm>
      <Divider />
      <CommTable
        url="/cms/stores"
        columns={({ limit, pageCur }) => [
          {
            title: '번호',
            dataIndex: 'tableIndex',
            fixed: 'left',
            width: 50,
            render: (value, obj, index) => {
              return limit * pageCur + index + 1;
            },
          },
          {
            title: '상점명',
            dataIndex: 'storeName',
            width: 150,
            render: (storeName, obj) => <Link to={`/store/management/${obj.id}/detail`}>{storeName}</Link>,
          },
          {
            title: '아이디',
            dataIndex: 'userId',
            width: 100,
          },
          {
            title: '한줄소개',
            dataIndex: 'introduction',
            align: 'left',
            width: 200,
          },
          {
            title: '전화번호',
            dataIndex: 'phone',
            width: 150,
          },
          {
            title: '주소',
            dataIndex: 'addrMap',
            width: 200,
          },
          {
            title: '사용여부',
            dataIndex: 'isActive',
            width: 100,
            render: (value) => (value ? 'Y' : 'N'),
          },
          {
            title: '승인',
            dataIndex: 'stateCode',
            width: 100,
            render: (stateCode) => codesMap?.[stateCode],
          },
          {
            title: '판매권한',
            dataIndex: 'salesPermissionCode',
            width: 100,
            render: (salesPermissionCode) => codesMap?.[salesPermissionCode],
          },
          {
            title: '최종수정일',
            dataIndex: 'updatedAt',
            width: 200,
          },
        ]}
        scroll={{ x: 1120 }}
        onRow={(data) => {
          return {
            onClick: (event) => {
              if (data?.id) {
                navigate(`/store/management/${data.id}/detail`);
              }
            },
          };
        }}
      />
    </div>
  );
}

export default StoreManageMentPage;
