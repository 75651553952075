import React from 'react';
import { Modal, Button, PageHeader, Space, Descriptions, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import { restApi } from '#apis/index';
import styles from '../../../Page.module.less';
import { useReduxStore } from '#hooks/useReduxStore';

const cx = classNames.bind(styles);

function UserManagementDetail() {
  const { map } = useReduxStore('codes');
  const { userId } = useParams();
  const [user, setUser] = React.useState(null);
  const navigate = useNavigate();

  const handleToggleActive = async () => {
    try {
      const { data } = await restApi.put(`/cms/accounts/${userId}/active`);
      // console.log('data :::: ', data);
      loadUserData();
    } catch (e) {
      Modal.warning({
        contnet: '실패 잠시 후 시도 부탁',
      });
    }
  };

  const askToggleActive = () => {
    return Modal.confirm({
      okText: '확인',
      cancelText: '취소',
      centered: true,
      onOk: handleToggleActive,
      content: (
        <div>
          <p>선택한 회원을 {user.isActive && '비'}활성화 처리하겠습니까? </p>
        </div>
      ),
    });
  };

  const loadUserData = React.useCallback(() => {
    restApi
      .get(`/cms/accounts/${userId}`)
      .then(({ data }) => {
        setUser(data);
      })
      .catch((e) => console.warn(e));
  }, [userId]);

  React.useEffect(() => {
    if (!userId) return;
    loadUserData();
  }, [userId, loadUserData]);

  if (!user) return <Spin />;

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="가입구분">
            {user?.joinTypeCode ? map[user.joinTypeCode] : '-'}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="회원아이디">
            {user?.userId || '-'}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="회원이름">
            {user?.userName || '-'}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="휴대전화">
            {user?.phone || '-'}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="활동내역" contentStyle={{ padding: 0 }}>
            <Descriptions bordered>
              <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="작성리뷰">
                {user?.reviewCount ?? '-'}건
                <Button
                  onClick={() => {
                    navigate(`/review/management?searchType=UserId&keyword=${user.userId}`);
                  }}
                  style={{ marginLeft: 20 }}
                >
                  작성리뷰
                </Button>
              </Descriptions.Item>
              <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="구매리뷰">
                {user?.productCount ?? '-'}건
                <Button
                  onClick={() => {
                    navigate(`/sales/purchase/totalorder?keyword=${user.userId}&searchType=BuyerId`);
                  }}
                  style={{ marginLeft: 20 }}
                >
                  구매상품
                </Button>
              </Descriptions.Item>
              <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="견적의뢰">
                {user?.estimateCount ?? '-'}건
                <Button
                  onClick={() => {
                    navigate(`/sales/purchase/estimate?searchType=UserId&keyword=${user.userId}`);
                  }}
                  style={{ marginLeft: 20 }}
                >
                  견적의뢰
                </Button>
              </Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="선택약관 동의여부">
            {user?.marketingAt || '-'}
          </Descriptions.Item>
          {/* NOTICE 해당 key가 없습니다 */}
          {/* <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="알림설정">
            {user?.turnOffAt || '-'}
          </Descriptions.Item> */}
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="마지막 로그인">
            {user.lastLoginAt}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="회원상태">
            <span>{user.isActive ? '활성화' : '비활성화'}</span>{' '}
            {user?.turnOffAt && user?.adminName && (
              <span>
                ({user?.adminName} / {user?.turnOffAt})
              </span>
            )}
            <Button style={{ marginLeft: 20 }} onClick={askToggleActive}>
              {user.isActive && '비'}활성화처리
            </Button>
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button
          onClick={() =>
            navigate(-1, {
              replace: true,
            })
          }
        >
          목록
        </Button>
      </div>
    </div>
  );
}

export default UserManagementDetail;
