import Delivery from './Delivery';
import DeliveryChange from './DeliveryChange';

function StoreGoodsForm() {
  return <></>;
}

StoreGoodsForm.Delivery = Delivery;
StoreGoodsForm.DeliveryChange = DeliveryChange;

export default StoreGoodsForm;
