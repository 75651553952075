import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { restApi } from '../../apis';

export const me = createAsyncThunk('principal/get', async (thunkAPI) => {
  // console.log('restApi.defaults.headers.common.authorization ::: ', restApi.defaults.headers.common.authorization);
  const { data: principal } = await restApi.get(`/accounts/authentication`);
  return { principal };
});

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    principal: null,
  },
  reducers: {
    setPrincipal: (state, action) => {
      state.principal = action.payload.principal;
    },
    clearPrincipal: (state) => {
      state.principal = null;
    },
    setTimeout: (state, action) => {
      state.timeout = action.payload;
    },
  },
  extraReducers: {
    [me.fulfilled]: (state, action) => {
      state.principal = action.payload.principal;
      state.permission = action.payload.permission;
    },
    [me.rejected]: (state) => {
      state.principal = null;
      state.error = '내 정보를 불러오는데 실패했습니다.';
    },
  },
});

export const { setPrincipal, clearPrincipal } = authSlice.actions;

export default authSlice.reducer;
