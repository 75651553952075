import { restApi } from '#apis/index';
import FetchElement from '#components/FetchElement';
import { Button, Col, Descriptions, Form, Input, Modal, Radio, Row, Select, Space, Table } from 'antd';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

const SORT_TYPE = {
  RECENT: 'RECENT',
  NAME: 'NAME',
  NAME_REVERSE: 'NAME_REVERSE',
};

const EditableContext = React.createContext(null);

function EditableRow({ ...props }) {
  const [isEdit, setIsEdit] = React.useState(false);
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false} onFinish={() => {}}>
      <EditableContext.Provider value={{ form, isEdit, setIsEdit }}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
}

function EditableCell({ title, children, dataIndex, record, handleSave, editableItem, renderCell, ...props }) {
  const { form, isEdit, setIsEdit } = React.useContext(EditableContext);

  const toggleEdit = React.useCallback(() => {
    form.resetFields();
    setIsEdit((prevState) => !prevState);
  }, [form]);

  return (
    <td {...props}>
      {renderCell ? (
        renderCell({ isEdit, toggleEdit, record, form })
      ) : isEdit ? (
        <Form.Item initialValue={record[dataIndex]} name={dataIndex}>
          {editableItem()}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
}

function ManagementCommonCodeDetail() {
  const fetchElementRef = React.useRef();
  const params = useParams();
  const [sortType, setSortType] = React.useState(SORT_TYPE.RECENT);

  return (
    <FetchElement
      ref={fetchElementRef}
      fetch={() => restApi.get(`/cms/codes/group/${params?.codeGroup}`)}
      Component={({ codeGroup, codes, isActive, label }) => (
        <Space style={{ width: '100%', maxWidth: 1400 }} direction="vertical" size="large">
          <Descriptions colon={false} bordered column={1}>
            <Descriptions.Item label="코드그룹명" labelStyle={{ width: 140 }}>
              {label}
            </Descriptions.Item>
            <Descriptions.Item label="코드" labelStyle={{ width: 140 }}>
              {codeGroup}
            </Descriptions.Item>
            <Descriptions.Item label="사용여부" labelStyle={{ width: 140 }}>
              {isActive ? '사용' : '미사용'}
            </Descriptions.Item>
          </Descriptions>
          <Row align="middle" justify="end">
            <Col>
              <Space>
                <Link to="/management/common_code">
                  <Button>목록</Button>
                </Link>
                <Link to={`/management/common_code/modify/${codeGroup}`}>
                  <Button type="primary">수정</Button>
                </Link>
              </Space>
            </Col>
          </Row>
          <Form
            style={{ marginBottom: 50 }}
            onFinish={({ code, label, exposeNum, extra1, extra2, extra3, isActive }) => {
              if (codes.findIndex((code) => Number(code.exposeNum) === Number(exposeNum)) > -1) {
                return Modal.error({
                  title: '상세코드 추가',
                  content: '동일한 정렬순서가 등록되어 있습니다.',
                  centered: true,
                });
              }
              return restApi
                .post(`/cms/codes/group/${codeGroup}`, {
                  code,
                  label,
                  exposeNum,
                  extra1,
                  extra2,
                  extra3,
                  isActive,
                })
                .then(fetchElementRef.current?.refresh)
                .catch((error) =>
                  Modal.error({
                    title: '상세코드 추가',
                    content: error?.response?.data?.message ?? '상세코드 추가에 실패했습니다.',
                    centered: true,
                  }),
                );
            }}
          >
            <Space style={{ width: '100%' }} direction="vertical">
              <Table
                dataSource={[{}]}
                columns={[
                  {
                    title: '코드*',
                    dataIndex: 'code',
                    render: () => (
                      <Form.Item name="code" required rules={[{ required: true, message: '코드를 입력해주세요.' }]}>
                        <Input />
                      </Form.Item>
                    ),
                  },
                  {
                    title: '코드명*',
                    dataIndex: 'label',
                    render: () => (
                      <Form.Item name="label" required rules={[{ required: true, message: '코드명을 입력해주세요.' }]}>
                        <Input />
                      </Form.Item>
                    ),
                  },
                  {
                    title: '정렬순서*',
                    dataIndex: 'exposeNum',
                    render: () => (
                      <Form.Item
                        name="exposeNum"
                        required
                        rules={[{ required: true, message: '정렬순서를 입력해주세요.' }]}
                      >
                        <Input />
                      </Form.Item>
                    ),
                  },
                  {
                    title: '비고1',
                    dataIndex: 'extra1',
                    render: () => (
                      <Form.Item name="extra1">
                        <Input />
                      </Form.Item>
                    ),
                  },
                  {
                    title: '비고2',
                    dataIndex: 'extra2',
                    render: () => (
                      <Form.Item name="extra2">
                        <Input />
                      </Form.Item>
                    ),
                  },
                  {
                    title: '비고3',
                    dataIndex: 'extra3',
                    render: () => (
                      <Form.Item name="extra3">
                        <Input />
                      </Form.Item>
                    ),
                  },
                  {
                    title: '사용여부*',
                    dataIndex: 'isActive',
                    render: () => (
                      <Form.Item required name="isActive" rules={[{ required: true, message: '코드를 입력해주세요.' }]}>
                        <Radio.Group
                          options={[
                            { label: '사용', value: true },
                            { label: '미사용', value: false },
                          ]}
                        />
                      </Form.Item>
                    ),
                  },
                ]}
                pagination={false}
              />
              <Row justify="end">
                <Col>
                  <Button type="primary" htmlType="submit">
                    상세코드 추가
                  </Button>
                </Col>
              </Row>
            </Space>
          </Form>
          <Space style={{ width: '100%' }} direction="vertical">
            <Row justify="end">
              <Col>
                <Select
                  value={sortType}
                  onChange={setSortType}
                  style={{ width: 150 }}
                  options={[
                    { label: '최신 등록 순', value: SORT_TYPE.RECENT },
                    { label: <>코드 A&rarr;Z</>, value: SORT_TYPE.NAME },
                    { label: <>코드 Z&rarr;A</>, value: SORT_TYPE.NAME_REVERSE },
                  ]}
                />
              </Col>
            </Row>
            <Table
              dataSource={codes?.concat().sort((a, b) => {
                switch (sortType) {
                  case SORT_TYPE.RECENT:
                    return new Date(b.createdAt) - new Date(a.createdAt);
                  case SORT_TYPE.NAME:
                    if (a.code > b.code) {
                      return 1;
                    } else if (a.code < b.code) {
                      return -1;
                    } else {
                      return 0;
                    }
                  case SORT_TYPE.NAME_REVERSE:
                    if (a.code < b.code) {
                      return 1;
                    } else if (a.code > b.code) {
                      return -1;
                    } else {
                      return 0;
                    }
                  default:
                    return 0;
                }
              })}
              columns={[
                { title: '코드', dataIndex: 'code', editableItem: () => <Input /> },
                { title: '코드명', dataIndex: 'label', editableItem: () => <Input /> },
                { title: '정렬순서', dataIndex: 'exposeNum', editableItem: () => <Input /> },
                { title: '비고1', dataIndex: 'extra1', editableItem: () => <Input /> },
                { title: '비고2', dataIndex: 'extra2', editableItem: () => <Input /> },
                { title: '비고3', dataIndex: 'extra3', editableItem: () => <Input /> },
                {
                  title: '사용여부',
                  dataIndex: 'isActive',
                  editableItem: () => (
                    <Radio.Group
                      style={{ width: 150 }}
                      options={[
                        { label: '사용', value: true },
                        { label: '미사용', value: false },
                      ]}
                    />
                  ),
                },
                {
                  title: '관리',
                  dataIndex: 'code',
                  renderCell: ({ isEdit, toggleEdit, record, form }) =>
                    isEdit ? (
                      <Space>
                        <Button
                          type="primary"
                          onClick={() => {
                            const { code, exposeNum, extra1, extra2, extra3, isActive, label } = form.getFieldsValue();
                            if (
                              codes.findIndex(
                                (code) => code.code !== record.code && Number(code.exposeNum) === Number(exposeNum),
                              ) > -1
                            ) {
                              return Modal.error({
                                title: '상세코드 추가',
                                content: '동일한 정렬순서가 등록되어 있습니다.',
                                centered: true,
                              });
                            }
                            return restApi
                              .put(`/cms/codes/${record.code}`, {
                                code,
                                exposeNum,
                                extra1,
                                extra2,
                                extra3,
                                isActive,
                                label,
                              })
                              .then(fetchElementRef.current?.refresh)
                              .catch((error) => {
                                Modal.error({
                                  title: '공통코드 수정',
                                  centered: true,
                                  content: error?.response?.data?.message ?? '공통코드 수정에 실패했습니다.',
                                });
                              });
                          }}
                          htmlType="submit"
                        >
                          저장
                        </Button>
                        <Button onClick={toggleEdit} htmlType="reset">
                          취소
                        </Button>
                      </Space>
                    ) : (
                      <Button onClick={toggleEdit}>수정</Button>
                    ),
                },
              ].map((col) => ({
                ...col,
                onCell: col.onCell ?? ((record) => ({ record, ...col })),
              }))}
              pagination={false}
              components={{
                body: {
                  row: EditableRow,
                  cell: EditableCell,
                },
              }}
            />
          </Space>
        </Space>
      )}
    />
  );
}

export default ManagementCommonCodeDetail;
