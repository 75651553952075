import React from 'react';
import { Divider } from 'antd';
import { Link } from 'react-router-dom';
import SearchForm from '../../../../components/datalist/SearchForm';
import CommTable from '../../../../components/table/CommTable';
import useQuery from '../../../../hooks/useQuery';
import useIndex from '../../../../hooks/useIndex';
import { useReduxStore } from '../../../../hooks/useReduxStore';

function UserManagementIndex() {
  const { getIndex } = useIndex();
  const { map: codesMap } = useReduxStore('codes');
  const { initializeSearchOpt } = useQuery();

  const rowSelection = {};

  return (
    <div>
      <SearchForm
        extra={{
          onInit: () => {
            initializeSearchOpt();
          },
        }}
        fields={[
          {
            width: 0.125,
            type: 'select',
            key: 'dateType',
            items: [
              { label: '가입일', value: 'Join' },
              { label: '최종로그인', value: 'LastLogin' },
            ],
            placeholder: '날짜 옵션',
          },
          {
            width: 0.375,
            type: 'daterange',
            key: 'dateRange',
            keys: ['startDate', 'endDate'],
          },
          {
            width: 0.125,
            type: 'select',
            key: 'isActive',
            placeholder: '활성 여부',
            items: [
              { label: '활성화', value: 'true' },
              { label: '비활성화', value: 'false' },
            ],
          },
          {
            width: 0.125,
            type: 'select',
            key: 'searchType',
            placeholder: '검색 옵션',
            items: [
              { label: '회원아이디', value: 'UserId' },
              { label: '회원이름', value: 'UserName' },
              { label: '휴대전화', value: 'Phone' },
            ],
          },
          {
            width: 0.25,
            type: 'input',
            key: 'searchKeyword',
            placeholder: '키워드를 입력하세요.',
          },
        ]}
      />
      <Divider />
      <CommTable
        url="/cms/accounts"
        columns={[
          {
            title: '번호',
            dataIndex: 'tableIndex',
            key: 'tableIndex',
            fixed: 'left',
            width: 50,
            render: (_, __, index) => getIndex(index),
          },
          {
            title: '가입구분',
            dataIndex: 'joinTypeCode',
            key: 'joinTypeCode',
            width: 100,
            render: (value) => codesMap[value],
          },
          {
            title: '회원아이디',
            dataIndex: 'userId',
            key: 'userId',
            width: 150,
            render: (userId, obj) => <Link to={`/member/management/users/${obj.id}`}>{userId}</Link>,
          },
          {
            title: '회원이름',
            dataIndex: 'userName',
            key: 'userName',
            width: 150,
          },
          {
            title: '휴대전화',
            dataIndex: 'phone',
            key: 'phone',
            width: 100,
          },
          {
            title: '가입일',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 100,
          },
          {
            title: '회원상태',
            dataIndex: 'isActive',
            key: 'isActive',
            width: 100,
            render: (value) => {
              return value ? '활성화' : '비활성화';
            },
          },
          {
            title: '최종로그인',
            dataIndex: 'lastLoginAt',
            key: 'lastLoginAt',
            width: 300,
          },
        ]}
        rowSelection={rowSelection}
        scroll={{ x: 1120 }}
        rowKey={({ id }) => id}
      />
    </div>
  );
}

export default UserManagementIndex;
