import React from 'react';
import { Modal, Button, PageHeader, Space, Descriptions, Spin, Form, Select } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import produce from 'immer';
import styles from '../../../Page.module.less';
import { getFileHostAndFile, restApi, restApiNotUsingToken } from '../../../../apis';
import { useReduxStore } from '../../../../hooks/useReduxStore';
import { formatPhonNumer } from '../../../../utils/formatPhoneNumber';
import { MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import { shallowEqual, useSelector } from 'react-redux';
import { queryString } from 'query-string';
const cx = classNames.bind(styles);

function MdCompleteDetail(props) {
  const { map } = useReduxStore('codes');
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const queryString = require('query-string');
  const { userId } = useParams() || {};
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [partner, setPartner] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [partnerResult, setPartnerResult] = React.useState([]);
  const [storeName, SetStoreName] = React.useState([]);
  const [storeList, SetStoreList] = React.useState([]);
  const [mdId, setMdId] = React.useState();
  const PARTNERS = {
    key: 'storeIdList',
    required: true,
    label: '파트너사 설정',
    error: '파트너사를 입력해주세요',
  };
  const [requestBody, setRequestBody] = React.useState({
    [PARTNERS.key]: [''],
  });
  const handleError = (error) => {
    console.error(error);
  };
  const handleSearch = async (v) => {
    if (!v) {
      setPartnerResult([]);
      return;
    }
    try {
      const { data } = await restApiNotUsingToken.get(`/md/accounts/find/userAndStore`, {
        params: { keyword: v },
      });
      console.log(data);
      setPartnerResult([
        ...data
          .filter((v) => !!v.userId && !!v.storeName)
          .map((v) => ({
            value: v.id,
            label: `${v.userId}(${v.storeName})`,
            disabled: requestBody[PARTNERS.key]
              ?.map((el) => {
                return el[0];
              })
              .includes(v.id),
          })),
        ...storeList.map((v) => ({
          value: v.id,
          label: `${v.userId}(${v.storeName})`,
          disabled: requestBody[PARTNERS.key]
            ?.map((el) => {
              return el[0];
            })
            .includes(v.id),
        })),
      ]);
    } catch (e) {
      handleError(e);
    }
  };

  const handleAskGoBeforePage = React.useCallback(() => {
    Modal.confirm({
      onOk: () => {
        navigate(`/member/md/waiting/detail/${userId}`, {
          replace: true,
        });
      },
      title: '뒤로가기 시, 입력했던 내용이 전부 사라집니다.',
    });
  }, [navigate]);

  const handleModify = async () => {
    try {
      await restApi.put('/cms/accounts/md/unConfirmed/contract', undefined, {
        params: {
          mdRequestId: mdId,
          storeIds: requestBody[PARTNERS.key].map((el) => el[0]).filter((el) => el),
        },
        paramsSerializer: (params) => queryString.stringify(params, { arrayFormat: 'repeat' }),
      });
      Modal.success({
        content: '수정이 완료 되었습니다.',
        onOk: () => {
          navigate(`/member/md/waiting`, {
            replace: true,
          });
          console.log('성공!');
        },
        centered: true,
      });
    } catch (e) {
      Modal.error({
        title: '수정 실패',
        content: '상품을 수정하는 데 실패했습니다. 잠시후 시도해주세요.',
        onOk: () => {
          navigate(`/member/md/waiting/detail/${userId}`, {
            replace: true,
          });
        },
      });
      console.warn('error in put ::: ', e.response);
    }
  };

  React.useEffect(() => {
    if (!userId) return;
    const getStore = async () => {
      let total = await restApi.get('/cms/stores/count');
      let totalNum = Object.values(total.data).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      let Store = await restApi.get(`/stores?limait=${totalNum}`);
      let StoreList = await restApi.get(`/cms/accounts/md/unconfirmed/${userId}`);
      console.log(StoreList.data);
      SetStoreList(StoreList.data.storeList);
      setMdId(StoreList.data.id);
      setRequestBody(
        StoreList.data.storeList.length
          ? {
              [PARTNERS.key]: StoreList.data.storeList.map((el, i) => [el.id, el.storeName]),
            }
          : {
              [PARTNERS.key]: [[]],
            },
      );
      SetStoreName(Store.data.results);
      setPartner(StoreList.data);
    };
    getStore();
  }, [userId]);
  console.log(requestBody[PARTNERS.key]);

  if (!partner) return <Spin />;

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="MD아이디">
            {partner.userId}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="MD이름">
            {partner.userName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="휴대전화">
            {formatPhonNumer(partner.phone)}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="이메일">
            {partner.email}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="연관 파트너사">
            {requestBody[PARTNERS.key].map((v, i) => (
              <Form.Item
                style={{ marginLeft: 20, marginBlock: 15, minWidth: 450 }}
                label={`파트너${i + 1}`}
                name="storeIdList"
              >
                <div style={{ display: 'flex', width: '100%' }}>
                  <Select
                    suffixIcon={null}
                    showSearch
                    disabled={isSubmitting}
                    optionFilterProp={'label'}
                    placeholder="파트너 아이디를 입력 해주세요"
                    // onBlur={() => setPartnerResult([])}
                    onSearch={handleSearch}
                    onFocus={(value) => {
                      setPartnerResult((draft) => {
                        draft = '';
                      });
                    }}
                    onChange={(value) =>
                      setRequestBody(
                        produce((draft) => {
                          draft[PARTNERS.key][i][0] = value;
                          draft[PARTNERS.key][i][1] = storeName
                            .map((el) => {
                              if (el.id === value) {
                                return el.storeName;
                              } else {
                                return '';
                              }
                            })
                            .join('');
                        }),
                      )
                    }
                    options={partnerResult}
                    value={requestBody[PARTNERS.key][i][1]}
                    style={{ width: 200, marginLeft: -20 }}
                  />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {requestBody[PARTNERS.key]?.length === i + 1 && (
                      <PlusCircleTwoTone
                        size={32}
                        onClick={() => {
                          setRequestBody(
                            produce((draft) => {
                              draft[PARTNERS.key] = [...requestBody[PARTNERS.key], []];
                            }),
                          );
                        }}
                        style={{
                          marginLeft: 10,
                          fontSize: 20,
                          cursor: 'pointer',
                        }}
                        twoToneColor={'#4c72f1'}
                      />
                    )}
                    {requestBody[PARTNERS.key]?.length > 1 && (
                      <MinusCircleTwoTone
                        onClick={() => {
                          setRequestBody(
                            produce((draft) => {
                              draft[PARTNERS.key].splice(i, 1);
                            }),
                          );
                        }}
                        style={{ marginInline: 10, fontSize: 20, cursor: 'pointer' }}
                        twoToneColor={'#4c72f1'}
                      />
                    )}
                  </div>
                </div>
              </Form.Item>
            ))}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="정산" contentStyle={{ padding: 0 }}>
            <Descriptions bordered>
              <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="정산 승인 금액">
                {partner.amount}
              </Descriptions.Item>
              <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="정산 요청 금액">
                {partner.requestAmount}
              </Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="신분증">
            <a rel="noreferrer" target="_blank" href={getFileHostAndFile(partner.identificationFile)} download>
              <img src={getFileHostAndFile(partner.identificationFile)} alt="신분증.jpg" />
            </a>
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="계좌번호">
            {map[partner.bankName]} {partner.accountNumber} / {partner.accountHolder}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="승인 신청일">
            {partner.createdAt}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="마지막 로그인">
            {partner.lastLoginAt}
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button onClick={handleAskGoBeforePage}>취소</Button>
        <Button type="primary" onClick={handleModify}>
          수정
        </Button>
      </div>
    </div>
  );
}

export default MdCompleteDetail;
