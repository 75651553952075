import { restApi } from '#apis/index';
import FileUpload from '#components/datalist/FileUpload';
import FetchElement from '#components/FetchElement';
import produce from 'immer';
import moment from 'moment';
import React from 'react';
import { Button, Input, DatePicker, Modal, Form, Space, Col, Row, Typography, Radio } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { checkBeforeUpload } from '#utils/beforeUpload';
import ImageUploadGuide from '#components/common/ImageUploadGuide';

const MemoizedContentsRecommendTagsRegistPage = React.memo(
  ({ id, imageResponse, tagName, startAt, endAt, isActive }) => {
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();
    const initialForm = React.useMemo(
      () => ({
        images: imageResponse?.image?.split('/').reverse()[0]
          ? [Number(imageResponse?.image?.split('/').reverse()[0])]
          : [],
        imageTexts: imageResponse?.text ? [imageResponse?.text] : [],
      }),
      [],
    );
    const [form, setForm] = React.useState(initialForm);

    return (
      <Form
        style={{ maxWidth: 800 }}
        onFinish={({ tagName, dateRange, isActive }) => {
          if (loading) return;
          if (!(form.images.length > 0))
            return Modal.warn({
              title: id ? '배너 수정' : '배너 등록',
              content: '배너 이미지를 등록하세요.',
              centered: true,
            });

          const [startAt, endAt] = dateRange.map((date) => date.format('YYYY-MM-DD HH:mm'));

          const requestBody = {
            placeCode: 'DP003',
            image: form.images[0],
            text: form.imageTexts[0] ?? '',
            endAt,
            startAt,
            tagName,
            isActive,
          };

          setLoading(true);

          if (id) {
            return restApi
              .put(`/cms/displays/${id}`, requestBody)
              .then(() => {
                navigate(`/contents/recommend/tags`);
                setLoading(false);
              })
              .catch((error) => {
                setLoading(false);
                Modal.error({
                  title: '메인화면(인기) 수정',
                  content: error?.response?.data?.message ?? '컨텐츠 수정에 실패했습니다.',
                  centered: true,
                });
              });
          }

          return restApi
            .post('/cms/displays/tag', requestBody)
            .then(({ data: id }) => {
              navigate(`/contents/recommend/tags/${id}`);
              setLoading(false);
            })
            .catch((error) => {
              setLoading(false);
              Modal.error({
                title: '메인화면(인기)',
                content: error?.response?.data?.message ?? '컨텐츠 등록에 실패했습니다.',
                centered: true,
              });
            });
        }}
      >
        <Space style={{ width: '100%' }} direction="vertical" size="middle">
          <Space style={{ width: '100%' }} direction="vertical">
            <Form.Item label="상품 등록" required>
              <FileUpload
                disabled={loading}
                maxCount={1}
                items={form.images}
                onDoneChange={(fileListDone) => {
                  setForm(
                    produce((draft) => {
                      draft.images = fileListDone.map(({ response }) => response?.[0]?.id);
                      draft.imageTexts.splice(
                        draft.imageTexts.length - 1,
                        draft.imageTexts.length - fileListDone.length,
                      );
                    }),
                  );
                }}
                beforeUpload={checkBeforeUpload({
                  availableLists: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'],
                  textWhenFail: 'jpg, jpeg, png, gif 확장자만 가능합니다.',
                })}
                extraItemRender={(file) => {
                  const findIndex = form?.images?.findIndex((image) => image === file?.response?.[0]?.id);
                  if (!(findIndex > -1)) {
                    return null;
                  }
                  return (
                    <Space style={{ marginTop: 10 }}>
                      <Typography.Text>대체 택스트: </Typography.Text>
                      <Input
                        disabled={loading}
                        value={form.imageTexts[findIndex]}
                        onChange={(event) => {
                          setForm(
                            produce((draft) => {
                              draft.imageTexts[findIndex] = event.target.value;
                            }),
                          );
                        }}
                      />
                    </Space>
                  );
                }}
              />
              <ImageUploadGuide />
            </Form.Item>
            <Form.Item
              label="태그명"
              required
              name="tagName"
              rules={[{ required: true, message: '태그명을 입력해주세요.' }]}
              initialValue={tagName}
            >
              <Input placeholder="태그명을 입력해주세요" disabled={loading} />
            </Form.Item>
            <Form.Item
              label="노출기간"
              required
              name="dateRange"
              rules={[{ required: true, message: '노출기간을 선택해주세요.' }]}
              initialValue={[startAt && moment(startAt), endAt && moment(endAt)]}
            >
              <DatePicker.RangePicker disabled={loading} showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" />
            </Form.Item>
            <Form.Item
              label="사용여부"
              required
              name="isActive"
              rules={[{ required: true, message: '사용여부를 선택해주세요.' }]}
              initialValue={isActive}
            >
              <Radio.Group
                options={[
                  { label: '사용', value: true },
                  { label: '미사용', value: false },
                ]}
              />
            </Form.Item>
          </Space>
          <Row justify="end">
            <Col>
              <Space>
                <Button
                  onClick={() => {
                    Modal.confirm({
                      title: '메인화면(인기)',
                      content: (
                        <>
                          입력했던 내용이 전부 사라집니다.
                          <br />
                          계속 하시겠습니까 ?
                        </>
                      ),
                      centered: true,
                      onOk: () => {
                        navigate(id ? `/contents/recommend/tags/${id}` : '/contents/recommend/tags');
                      },
                    });
                  }}
                >
                  취소
                </Button>
                <Button disabled={loading} type="primary" htmlType="submit">
                  {id ? '수정' : '저장'}
                </Button>
              </Space>
            </Col>
          </Row>
        </Space>
      </Form>
    );
  },
);

function ContentsRecommendTagsRegistPage() {
  const params = useParams();
  const id = React.useMemo(() => params?.id, [params?.id]);

  return (
    <FetchElement
      fetch={() => id && restApi.get(`/cms/displays/${id}`)}
      Component={(data) => <MemoizedContentsRecommendTagsRegistPage id={id} {...data} />}
    />
  );
}

export default ContentsRecommendTagsRegistPage;
