import { restApi } from '#apis/index';
import FileUpload from '#components/datalist/FileUpload';
import FetchElement from '#components/FetchElement';
import produce from 'immer';
import moment from 'moment';
import React from 'react';
import { Button, Input, DatePicker, Modal, Form, Space, Col, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import ImageUploadGuide from '#components/common/ImageUploadGuide';
import { checkBeforeUpload } from '#utils/beforeUpload';

const MemoizedContentsSpecialistRegistPage = React.memo(({ id, image, themeName, startAt, endAt }) => {
  const navigate = useNavigate();
  const initialForm = React.useMemo(
    () => ({
      images: image?.split('/').reverse()[0] ? [Number(image?.split('/').reverse()[0])] : [],
    }),
    [],
  );
  const [form, setForm] = React.useState(initialForm);

  return (
    <Form
      style={{ maxWidth: 800 }}
      onFinish={({ themeName, dateRange }) => {
        if (!(form.images.length > 0))
          return Modal.warn({
            title: id ? '배너 수정' : '배너 등록',
            content: '배너 이미지를 등록하세요.',
            centered: true,
          });

        const [startAt, endAt] = dateRange.map((date) => date.format('YYYY-MM-DD HH:mm'));

        const requestBody = {
          placeCode: 'DP004',
          image: form.images[0],
          endAt,
          startAt,
          themeName,
        };

        if (id) {
          return restApi
            .put(`/cms/displays/${id}`, requestBody)
            .then(() => {
              navigate(`/contents/specialist`);
            })
            .catch((error) => {
              Modal.error({
                title: '메인화면(특가) 수정',
                content: error?.response?.data?.message ?? '컨텐츠 수정에 실패했습니다.',
                centered: true,
              });
            });
        }

        return restApi
          .post('/cms/displays', requestBody)
          .then(({ data: id }) => {
            navigate(`/contents/specialist/${id}`);
          })
          .catch((error) => {
            Modal.error({
              title: '메인화면(특가) 등록',
              content: error?.response?.data?.message ?? '컨텐츠 등록에 실패했습니다.',
              centered: true,
            });
          });
      }}
    >
      <Space style={{ width: '100%' }} direction="vertical" size="middle">
        <Space style={{ width: '100%' }} direction="vertical">
          <Form.Item label="배너이미지" required>
            <FileUpload
              maxCount={1}
              items={form.images}
              onDoneChange={(fileListDone) => {
                setForm(
                  produce((draft) => {
                    draft.images = fileListDone.map(({ response }) => response?.[0]?.id);
                  }),
                );
              }}
              beforeUpload={checkBeforeUpload({
                availableLists: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'],
                textWhenFail: 'jpg, jpeg, png, gif 확장자만 가능합니다.',
              })}
            />
            <ImageUploadGuide />
          </Form.Item>
          <Form.Item
            label="테마명"
            required
            name="themeName"
            rules={[{ required: true, message: '테마명을 입력해주세요.' }]}
            initialValue={themeName}
          >
            <Input placeholder="테마명을 입력해주세요" />
          </Form.Item>
          <Form.Item
            label="노출기간"
            required
            name="dateRange"
            rules={[{ required: true, message: '노출기간을 선택해주세요.' }]}
            initialValue={[startAt && moment(startAt), endAt && moment(endAt)]}
          >
            <DatePicker.RangePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" />
          </Form.Item>
        </Space>
        <Row justify="end">
          <Col>
            <Space>
              <Button
                onClick={() => {
                  Modal.confirm({
                    title: '메인화면(특가)',
                    content: (
                      <>
                        입력했던 내용이 전부 사라집니다.
                        <br />
                        계속 하시겠습니까 ?
                      </>
                    ),
                    centered: true,
                    onOk: () => {
                      navigate(id ? `/contents/specialist/${id}` : '/contents/specialist');
                    },
                  });
                }}
              >
                취소
              </Button>
              <Button type="primary" htmlType="submit">
                {id ? '수정' : '저장'}
              </Button>
            </Space>
          </Col>
        </Row>
      </Space>
    </Form>
  );
});

function ContentsSpecialistRegistPage() {
  const params = useParams();
  const id = React.useMemo(() => params?.id, [params?.id]);

  return (
    <FetchElement
      fetch={() => id && restApi.get(`/cms/displays/${id}`)}
      Component={(data) => <MemoizedContentsSpecialistRegistPage id={id} {...data} />}
    />
  );
}

export default ContentsSpecialistRegistPage;
