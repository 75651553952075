import React from 'react';
import { Divider, PageHeader, Spin } from 'antd';
import CardWrapper from '../components/common/CardWrapper';
import formatComma from '../utils/formatComma';
import styles from './Page.module.less';
import classNames from 'classnames/bind';
import { restApi } from '../apis';
import handleError from '#utils/handleError';
import qs from 'query-string';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const cx = classNames.bind(styles);

const HomeIndex = () => {
  const [dashboardInfo, setDashboardInfo] = React.useState(null);
  const navigate = useNavigate();

  const today = React.useMemo(() => moment().format('YYYY-MM-DD'), []);

  const dayBeforeSeven = React.useMemo(() => moment().subtract(7, 'd').format('YYYY-MM-DD'), []);

  const handleNavigate = React.useCallback(
    (url, params) => () => {
      if (params) {
        navigate(`${url}?${qs.stringify(params)}`);
        return;
      }

      navigate(`${url}`);
    },
    [navigate],
  );

  const requestData = React.useCallback(() => {
    restApi
      .get('/cms/dashboard')
      .then(({ data }) => {
        setDashboardInfo(data);
      })
      .catch((e) => {
        handleError(e);
      });
  }, []);

  React.useEffect(() => {
    if (!requestData) return;

    requestData();
    const intervalId = setInterval(requestData, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [requestData]);

  if (!dashboardInfo) {
    return <Spin />;
  }

  return (
    <div>
      <PageHeader className="site-page-header" title="주문/매출현황" subTitle="" />
      <div className={cx({ main_section: true })}>
        <div style={{ margin: 10 }}>
          <label>주문</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              onClick={handleNavigate('/sales/purchase/totalorder', {
                startAt: today,
                endAt: today,
              })}
              title="오늘"
              value={formatComma(dashboardInfo.order.today)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/purchase/totalorder', {
                startAt: dayBeforeSeven,
                endAt: today,
              })}
              title="최근 7일"
              value={formatComma(dashboardInfo.order.week)}
            />
          </div>
        </div>
        <div style={{ margin: 10 }}>
          <label>견적의뢰</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              onClick={handleNavigate('/sales/purchase/estimate', {
                dateType: 'CreatedAt',
                startAt: today,
                endAt: today,
              })}
              title="오늘"
              value={formatComma(dashboardInfo.estimate.today)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/purchase/estimate', {
                dateType: 'CreatedAt',
                startAt: dayBeforeSeven,
                endAt: today,
              })}
              title="최근 7일"
              value={formatComma(dashboardInfo.estimate.week)}
            />
          </div>
        </div>
        <div style={{ margin: 10 }}>
          <label>매출</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper title="오늘" value={formatComma(dashboardInfo.sales.today)} />
            <CardWrapper title="최근 7일" value={formatComma(dashboardInfo.sales.week)} />
          </div>
        </div>
      </div>
      <Divider />
      <PageHeader className="site-page-header" title="신규회원" subTitle="" />
      <div className={cx({ main_section: true })}>
        <div style={{ margin: 10 }}>
          <label>일반회원</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              onClick={handleNavigate('/member/management/users', {
                startDate: today,
                endDate: today,
              })}
              title="오늘"
              value={formatComma(dashboardInfo.member.today)}
            />
            <CardWrapper
              onClick={handleNavigate('/member/management/users', {
                startDate: dayBeforeSeven,
                endDate: today,
              })}
              title="최근 7일"
              value={formatComma(dashboardInfo.member.week)}
            />
          </div>
        </div>
        <div style={{ margin: 10 }}>
          <label>파트너</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              onClick={handleNavigate('/member/partner/complete', {
                dateOpt: 'createdAt',
                startAt: today,
                endAt: today,
              })}
              title="오늘"
              value={formatComma(dashboardInfo.partners.today)}
            />
            <CardWrapper
              onClick={handleNavigate('/member/partner/complete', {
                dateOpt: 'createdAt',
                startAt: dayBeforeSeven,
                endAt: today,
              })}
              title="최근 7일"
              value={formatComma(dashboardInfo.partners.week)}
            />
          </div>
        </div>
      </div>
      <Divider />
      <PageHeader className="site-page-header" title="리뷰/문의" subTitle={`(최근7일 ${dayBeforeSeven} ~ ${today})`} />
      <div className={cx({ main_section: true })}>
        <div style={{ margin: 10 }}>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              onClick={handleNavigate('/review/management', {
                startAt: dayBeforeSeven,
                endAt: today,
              })}
              title="리뷰"
              value={formatComma(dashboardInfo.reviewQuestion.review)}
            />
            <CardWrapper
              // TODO :: dateType 확인한번해야함
              onClick={handleNavigate('/review/service', {
                dateType: 'CREATED_AT',
                startAt: dayBeforeSeven,
                endAt: today,
              })}
              title="서비스문의"
              value={formatComma(dashboardInfo.reviewQuestion.serviewQ)}
            />
            <CardWrapper
              onClick={handleNavigate('/review/goods', {
                dateType: 'CREATED_AT',
                startAt: dayBeforeSeven,
                endAt: today,
              })}
              title="상품문의"
              value={formatComma(dashboardInfo.reviewQuestion.productQ)}
            />
            <CardWrapper
              onClick={handleNavigate('/review/store', {
                dateType: 'CREATED_AT',
                startAt: dayBeforeSeven,
                endAt: today,
              })}
              title="상점문의"
              value={formatComma(dashboardInfo.reviewQuestion.storeQ)}
            />
          </div>
        </div>
      </div>
      <Divider />
      {/* <PageHeader className="site-page-header" title="광고" />
      <div className={cx({ main_section: true })}>
        <div style={{ margin: 10 }}>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              onClick={handleNavigate()}
              title="신청중인 광고"
              value={formatComma(dashboardInfo.ad.requested)}
            />
            <CardWrapper
              onClick={handleNavigate()}
              title="진행중인 광고"
              value={formatComma(dashboardInfo.ad.proceeding)}
            />
            <CardWrapper
              onClick={handleNavigate()}
              title="7일 이내 종료 광고"
              value={formatComma(dashboardInfo.ad.completed)}
            />
          </div>
        </div>
      </div>
      <Divider /> */}
      {/* TODO ::: 미발주 페이지 어디로 할지 정해지면 하기 */}
      <PageHeader className="site-page-header" title="발주" />
      <div className={cx({ main_section: true })}>
        <div style={{ margin: 10 }}>
          <label>미발주</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper onClick={() => {}} title="오늘" value={formatComma(dashboardInfo.unordered.today)} />
            <CardWrapper onClick={() => {}} title="최근 7일" value={formatComma(dashboardInfo.unordered.week)} />
          </div>
        </div>
        <div style={{ margin: 10 }}>
          <label>발주확인(배송대기)</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              // TODO ::: 서버에서 code 픽스나면 다시 확인
              onClick={handleNavigate('/sales/purchase/delivery', {
                orderProductStateCode: 'OPS002',
                dateType: 'PayAt',
                startAt: today,
                endAt: today,
              })}
              title="오늘"
              value={formatComma(dashboardInfo.ordered.today)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/purchase/delivery', {
                orderProductStateCode: 'OPS002',
                dateType: 'PayAt',
                startAt: dayBeforeSeven,
                endAt: today,
              })}
              title="최근 7일"
              value={formatComma(dashboardInfo.ordered.week)}
            />
          </div>
        </div>
      </div>
      <Divider />
      <PageHeader className="site-page-header" title="배송/구매확정" />
      <div className={cx({ main_section: true })}>
        <div style={{ margin: 10 }}>
          <label>배송중</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              onClick={handleNavigate('/sales/purchase/delivery', {
                orderProductStateCode: 'OPS002',
                dateType: 'PayAt',
                startAt: today,
                endAt: today,
              })}
              title="오늘"
              value={formatComma(dashboardInfo.shipping.today)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/purchase/delivery', {
                orderProductStateCode: 'OPS002',
                dateType: 'PayAt',
                startAt: dayBeforeSeven,
                endAt: today,
              })}
              title="최근 7일"
              value={formatComma(dashboardInfo.shipping.week)}
            />
          </div>
        </div>
        <div style={{ margin: 10 }}>
          <label>배송완료</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              onClick={handleNavigate('/sales/purchase/delivery', {
                orderProductStateCode: 'OPS006',
                dateType: 'PayAt',
                startAt: today,
                endAt: today,
              })}
              title="오늘"
              value={formatComma(dashboardInfo.shipped.today)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/purchase/delivery', {
                orderProductStateCode: 'OPS002',
                dateType: 'PayAt',
                startAt: dayBeforeSeven,
                endAt: today,
              })}
              title="최근 7일"
              value={formatComma(dashboardInfo.shipped.week)}
            />
          </div>
        </div>
        {/* TODO ::: 구매확정 매칭되는 페이지 어디죠?*/}
        <div style={{ margin: 10 }}>
          <label>구매확정(오늘)</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper onClick={() => {}} title="오늘" value={formatComma(dashboardInfo.applyOrder)} />
          </div>
        </div>
      </div>
      <Divider />
      <PageHeader
        className="site-page-header"
        title="교환/반품/취소"
        subTitle={`(최근7일 ${dayBeforeSeven} ~ ${today})`}
      />
      <div className={cx({ main_section: true })}>
        <div style={{ margin: 10 }}>
          <label>교환</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              onClick={handleNavigate('/sales/purchase/change', {
                dateType: 'RequestAt',
                startAt: dayBeforeSeven,
                endAt: today,
                changeStateCode: 'CHS001',
              })}
              title="신청"
              value={formatComma(dashboardInfo.exchange.requested)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/purchase/change', {
                dateType: 'RequestAt',
                startAt: dayBeforeSeven,
                endAt: today,
                changeStateCode: 'CHS002',
              })}
              title="진행중"
              value={formatComma(dashboardInfo.exchange.proceeding)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/purchase/change', {
                dateType: 'RequestAt',
                startAt: dayBeforeSeven,
                endAt: today,
                changeStateCode: 'CHS003',
              })}
              title="완료"
              value={formatComma(dashboardInfo.exchange.completed)}
            />
          </div>
        </div>
        <div style={{ margin: 10 }}>
          <label>반품</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              onClick={handleNavigate('/sales/purchase/refund', {
                dateType: 'RequestAt',
                startAt: dayBeforeSeven,
                endAt: today,
                returnStateCode: 'RTS001',
              })}
              title="신청"
              value={formatComma(dashboardInfo.refund.requested)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/purchase/refund', {
                dateType: 'RequestAt',
                startAt: dayBeforeSeven,
                endAt: today,
                returnStateCode: 'RTS002',
              })}
              title="진행중"
              value={formatComma(dashboardInfo.refund.proceeding)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/purchase/refund', {
                dateType: 'RequestAt',
                startAt: dayBeforeSeven,
                endAt: today,
                returnStateCode: 'RTS003',
              })}
              title="완료"
              value={formatComma(dashboardInfo.refund.completed)}
            />
          </div>
        </div>
        <div style={{ margin: 10 }}>
          <label>취소</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              onClick={handleNavigate('/sales/purchase/cancel', {
                dateType: 'RequestAt',
                cancelCode: 'CAS001',
                startAt: dayBeforeSeven,
                endAt: today,
              })}
              title="신청"
              value={formatComma(dashboardInfo.cancel.requested)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/purchase/cancel', {
                dateType: 'RequestAt',
                cancelCode: 'CAS002',
                startAt: dayBeforeSeven,
                endAt: today,
              })}
              title="진행중"
              value={formatComma(dashboardInfo.cancel.proceeding)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/purchase/cancel', {
                dateType: 'RequestAt',
                cancelCode: 'CAS003',
                startAt: dayBeforeSeven,
                endAt: today,
              })}
              title="완료"
              value={formatComma(dashboardInfo.cancel.completed)}
            />
          </div>
        </div>
      </div>
      <Divider />
      <PageHeader className="site-page-header" title="정산" />
      <div className={cx({ main_section: true })}>
        <div style={{ margin: 10 }}>
          <label>정산</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              onClick={handleNavigate('/sales/calculate', {
                isProcess: 'false',
              })}
              title="정산가능금액(예치금)"
              value={formatComma(dashboardInfo.settlement.available)}
            />
            {/* TODO :: 아래 두개 매칭해야함 */}
            <CardWrapper
              onClick={handleNavigate('/sales/calculate', {
                dateType: 'CreatedAt',
                startAt: dayBeforeSeven,
                endAt: today,
                isProcess: 'false',
              })}
              title="정산신청금액(최근 7일)"
              value={formatComma(dashboardInfo.settlement.requested)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/calculate', {
                dateType: 'ProcessAt',
                startAt: dayBeforeSeven,
                endAt: today,
                isProcess: 'true',
              })}
              title="정산처리금액(최근 7일)"
              value={formatComma(dashboardInfo.settlement.completed)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeIndex;
