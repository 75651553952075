import React from 'react';
import { Button, Checkbox, Descriptions, List, Row, Space, Typography } from 'antd';
import FetchElement from '#components/FetchElement';
import { Link, useParams } from 'react-router-dom';
import { restApi } from '#apis';
import { getFileHost } from '../../../../apis';
import { shallowEqual, useSelector } from 'react-redux';

function PostsNoticeMDDetailPage() {
  const { id } = useParams();
  const { tree: codesTree } = useSelector((selector) => selector.codes, shallowEqual);

  return (
    <FetchElement
      fetch={() => restApi.get(`/cms/notices/md/${id}`)}
      Component={({ active, contents, id, images, isHeader, title, categoryCode }) => (
        <Space direction="vertical" size="middle" style={{ width: '100%', maxWidth: 1200 }}>
          <Descriptions bordered>
            <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="상단고정">
              <Checkbox disabled checked={isHeader}>
                상단 공지로 등록
              </Checkbox>
            </Descriptions.Item>
            <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="카테고리">
              <Typography.Text>
                {codesTree?.NPT?.items?.find(({ code }) => code === categoryCode)?.label || ''}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="제목">
              <Typography.Text>{title}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="내용">
              <div dangerouslySetInnerHTML={{ __html: contents }} />
            </Descriptions.Item>
            <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="첨부파일">
              <List
                bordered
                dataSource={images}
                renderItem={({ id, fileName, text }) => (
                  <List.Item>
                    <Typography.Text>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        style={{ marginRight: 10 }}
                        href={`${getFileHost()}/${id}`}
                        download
                      >
                        {fileName}
                      </a>
                      대체텍스트: {text}
                    </Typography.Text>
                  </List.Item>
                )}
              />
            </Descriptions.Item>
            <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="사용여부">
              {active ? '사용' : '미사용'}
            </Descriptions.Item>
          </Descriptions>
          <Row justify="end">
            <Space>
              <Link to="/posts/notice/md">
                <Button>목록</Button>
              </Link>
              <Link to={`/posts/notice/md/modify/${id}`}>
                <Button type="primary">수정</Button>
              </Link>
            </Space>
          </Row>
        </Space>
      )}
    />
  );
}

export default PostsNoticeMDDetailPage;
