import { Modal, Upload } from 'antd';

export const checkBeforeUpload =
  ({ availableLists = [], textWhenFail = '에러가 발생했습니다. 잠시후 시도해주세요.' }) =>
  (file) => {
    console.log('file.type :::: ', file.type);
    const isAvailable = availableLists.includes(file?.type);

    if (!isAvailable) {
      Modal.warning({
        content: textWhenFail,
        centered: true,
        okText: '확인',
      });

      return Upload.LIST_IGNORE;
    }

    return isAvailable || Upload.LIST_IGNORE;
  };

// beforeUpload={checkBeforeUpload({
//   availableLists: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'],
//   textWhenFail: 'jpg, jpeg, png, gif 확장자만 가능합니다.',
// })}
