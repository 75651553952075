import { restApi } from '#apis/index';
import FetchElement from '#components/FetchElement';
import React from 'react';
import { Input, Radio, Space, Form, Row, Col, Button, Modal } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

function ManageCommonCodeRegist() {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <FetchElement
      fetch={() => params.codeGroup && restApi.get(`/cms/codes/group/${params.codeGroup}`)}
      Component={({ codeGroup, label, isActive }) => (
        <Form
          style={{ maxWidth: 800 }}
          onFinish={({ groupCode, label, isActive }) =>
            codeGroup
              ? restApi
                  .put(`/cms/codes/group/${codeGroup}`, { groupCode, label, isActive })
                  .then(() => {
                    Modal.success({
                      title: '공통코드 수정',
                      content: '코드그룹이 수정되었습니다.',
                      centered: true,
                      onOk: () => {
                        navigate('/management/common_code');
                      },
                    });
                  })
                  .catch((error) => {
                    Modal.error({
                      title: '공통코드 수정',
                      content: error?.response?.data?.message ?? '코드그룹 수정에 실패했습니다.',
                      centered: true,
                    });
                  })
              : restApi
                  .post('/cms/codes/group', { groupCode, label, isActive })
                  .then(() => {
                    Modal.success({
                      title: '공통코드 등록',
                      content: '코드그룹이 등록되었습니다.',
                      centered: true,
                      onOk: () => {
                        navigate('/management/common_code');
                      },
                    });
                  })
                  .catch((error) => {
                    Modal.error({
                      title: '공통코드 등록',
                      content: error?.response?.data?.message ?? '코드그룹 등록에 실패했습니다.',
                      centered: true,
                    });
                  })
          }
        >
          <Space style={{ width: '100%' }} direction="vertical">
            <Form.Item
              label="코드그룹명"
              required
              name="label"
              rules={[{ required: true, message: '코드그룹을 입력해주세요.' }]}
              initialValue={label}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="코드"
              required
              name="groupCode"
              rules={[{ required: true, message: '코드를 입력해주세요.' }]}
              initialValue={codeGroup}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="사용여부"
              required
              name="isActive"
              rules={[{ required: true, message: '사용여부를 선택해주세요.' }]}
              initialValue={isActive}
            >
              <Radio.Group
                options={[
                  { label: '사용', value: true },
                  { label: '미사용', value: false },
                ]}
              />
            </Form.Item>
            <Row justify="end">
              <Col>
                <Space>
                  <Button
                    htmlType="reset"
                    onClick={() => {
                      Modal.confirm({
                        title: '공통코드 등록',
                        content: (
                          <>
                            입력했던 내용이 전부 사라집니다.
                            <br />
                            계속 하시겠습니까 ?
                          </>
                        ),
                        centered: true,
                        onOk: () => {
                          navigate(
                            codeGroup ? `/management/common_code/detail/${codeGroup}` : '/management/common_code',
                            { replace: !!codeGroup },
                          );
                        },
                        okText: '확인',
                        cancelText: '취소',
                      });
                    }}
                  >
                    취소
                  </Button>
                  <Button type="primary" htmlType="submit">
                    저장
                  </Button>
                </Space>
              </Col>
            </Row>
          </Space>
        </Form>
      )}
    />
  );
}

export default ManageCommonCodeRegist;
