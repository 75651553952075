import React from 'react';
import useFetch from '#hooks/useFetch';
import moment from 'moment';
import FetchElement from '#components/FetchElement';
import { Button, Modal, Space, Descriptions, Row, Col, Table, Spin, Typography } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { restApi } from '#apis/index';
import FileUpload from '#components/datalist/FileUpload';

const Comment = React.memo(({ id }) => {
  const { data, fetch, loading } = useFetch();

  const handleFetch = React.useCallback(
    (config) => fetch(restApi.get(`/cms/community-boards/${id}/replies`, config)),
    [id],
  );

  const handleRefresh = React.useCallback(
    () => handleFetch({ params: { page: data?.pageCur, limit: 10 } }),
    [data, handleFetch],
  );

  React.useEffect(() => {
    handleFetch({ params: { limit: 10, page: 0 } });
  }, [handleFetch]);

  if (loading) {
    return <Spin />;
  }

  return (
    <Table
      dataSource={data?.results?.map((result) => ({
        ...result,
        children: result.childResponses?.length > 0 ? result.childResponses : null,
      }))}
      style={{ width: '100%', height: '100%' }}
      rowKey={({ replayId }) => replayId}
      columns={[
        {
          title: '내용',
          align: 'left',
          dataIndex: 'contents',
        },
        {
          title: '작성자',
          dataIndex: 'writer',
          width: 100,
        },
        {
          title: '공개여부',
          dataIndex: 'isSecret',
          width: 200,
          render: (value, record) => (
            <Space>
              <Typography.Text>{value ? '비공개' : '공개'}</Typography.Text>
              {value ? (
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: '댓글',
                      content: '공개 처리하시겠습니까 ?',
                      centered: true,
                      onOk: () =>
                        restApi
                          .put(`/cms/community-boards/replies/${record.replayId}`)
                          .then(handleRefresh)
                          .catch((error) =>
                            Modal.error({
                              title: '댓글',
                              content: error?.response?.data?.message ?? '수정에 실패했습니다.',
                              centered: true,
                            }),
                          ),
                    });
                  }}
                >
                  공개처리
                </Button>
              ) : (
                <Button
                  size="small"
                  type="danger"
                  onClick={() => {
                    Modal.confirm({
                      title: '댓글',
                      content: '비공개 처리하시겠습니까 ?',
                      centered: true,
                      onOk: () =>
                        restApi
                          .put(`/cms/community-boards/replies/${record.replayId}`)
                          .then(handleRefresh)
                          .catch((error) =>
                            Modal.error({
                              title: '댓글',
                              content: error?.response?.data?.message ?? '수정에 실패했습니다.',
                              centered: true,
                            }),
                          ),
                    });
                  }}
                >
                  비공개처리
                </Button>
              )}
            </Space>
          ),
        },
        {
          title: '등록일',
          dataIndex: 'createdAt',
          width: 120,
          render: (value) => moment(value).format('YYYY-MM-DD'),
        },
      ]}
      pagination={{
        position: ['bottomCenter'],
        pageSize: data?.limit,
        total: data?.total,
        current: data?.pageCur + 1,
        onChange: (page) => handleFetch({ params: { page: page - 1, limit: 10 } }),
      }}
      scroll={{ y: 1000 }}
    />
  );
});

function ReviewDetail(props) {
  const fetchElementRef = React.useRef();
  const { id } = useParams();

  return (
    <FetchElement
      ref={fetchElementRef}
      fetch={() => restApi.get(`/cms/community-boards/${id}`)}
      Component={({
        adminName,
        contents,
        createdAt,
        id,
        images,
        tags,
        isSecret,
        replyCount,
        secretAt,
        title,
        userId,
        writer,
      }) => (
        <Space direction="vertical" size="middle" style={{ width: '100%', maxWidth: 1200 }}>
          <Descriptions bordered column={1}>
            <Descriptions.Item labelStyle={{ width: 140 }} label="제목">
              {title}
            </Descriptions.Item>
            <Descriptions.Item labelStyle={{ width: 140 }} label="회원아이디">
              {userId}
            </Descriptions.Item>
            <Descriptions.Item labelStyle={{ width: 140 }} label="작성자명">
              {writer}
            </Descriptions.Item>
            <Descriptions.Item labelStyle={{ width: 140 }} label="태그">
              {tags?.join(', ')}
            </Descriptions.Item>
            <Descriptions.Item labelStyle={{ width: 140 }} label="작성내용">
              {contents}
            </Descriptions.Item>
            <Descriptions.Item labelStyle={{ width: 140 }} label="사진,영상">
              {images && (
                <FileUpload
                  fileList={images.map((image) => ({ url: image }))}
                  disabled
                  listType="picture-card"
                  hideUpload
                />
              )}
            </Descriptions.Item>
            <Descriptions.Item labelStyle={{ width: 140 }} label="댓글 수">
              {replyCount}{' '}
              <Button
                style={{ marginLeft: 20 }}
                onClick={() => {
                  Modal.info({
                    title: '댓글',
                    content: <Comment id={id} />,
                    width: 1200,
                    centered: true,
                    closable: true,
                    okButtonProps: { style: { display: 'none' } },
                  });
                }}
              >
                댓글보기
              </Button>
            </Descriptions.Item>
            <Descriptions.Item labelStyle={{ width: 140 }} label="공개 여부">
              {isSecret === true ? '비공개' : '공개'}
              {isSecret && adminName && secretAt && ` (${adminName} / ${secretAt})`}
              {isSecret ? (
                <Button
                  onClick={() => {
                    Modal.confirm({
                      title: '커뮤니티 상세',
                      content: '공개 처리하시겠습니까 ?',
                      centered: true,
                      onOk: () =>
                        restApi
                          .put(`/cms/community-boards/${id}/secret`)
                          .then(fetchElementRef.current?.refresh)
                          .catch((error) =>
                            Modal.error({
                              title: '커뮤니티 상세',
                              content: error?.response?.data?.message ?? '수정에 실패했습니다.',
                              centered: true,
                            }),
                          ),
                    });
                  }}
                  type="primary"
                  style={{ marginLeft: 20 }}
                >
                  공개처리
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    Modal.confirm({
                      title: '커뮤니티 상세',
                      content: '비공개 처리하시겠습니까 ?',
                      centered: true,
                      onOk: () =>
                        restApi
                          .put(`/cms/community-boards/${id}/secret`)
                          .then(fetchElementRef.current?.refresh)
                          .catch((error) =>
                            Modal.error({
                              title: '커뮤니티 상세',
                              content: error?.response?.data?.message ?? '수정에 실패했습니다.',
                              centered: true,
                            }),
                          ),
                    });
                  }}
                  type="danger"
                  style={{ marginLeft: 20 }}
                >
                  비공개처리
                </Button>
              )}
            </Descriptions.Item>
          </Descriptions>
          <Row justify="end">
            <Col>
              <Link to="/posts/community">
                <Button>목록</Button>
              </Link>
            </Col>
          </Row>
        </Space>
      )}
    />
  );
}

export default ReviewDetail;
