import React from 'react';
import { getFileHost } from '#apis/index';
import { restApi } from '../apis';
import { Image } from 'antd';

const GetImageFile = ({ imageId }) => {
  if (imageId) {
    return (
      <div
        style={{
          width: 150,
          height: 150,
          backgroundImage: `url(https://homegrit-serv.actbase.dev/api/files/${imageId}/thumb)`,
          backgroundPosition: ' center center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
      ></div>
    );
  } else {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <span style={{ fontWeight: 'bold' }}>이미지가 없습니다</span>
      </div>
    );
  }
};

export default GetImageFile;
