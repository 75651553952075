import React from 'react';
import { Divider } from 'antd';
import SearchForm from '../../../../components/datalist/SearchForm';
import CommTable from '../../../../components/table/CommTable';
import useQuery from '../../../../hooks/useQuery';
import useIndex from '../../../../hooks/useIndex';

function WithdrawManagementIndex(props) {
  const { getIndex } = useIndex();
  const { initializeSearchOpt } = useQuery();

  const searchField = [
    {
      width: 0.125,
      type: 'select',
      key: 'dateType',
      items: [
        { label: '가입일', value: 'Join' },
        { label: '탈퇴일', value: 'LastLogin' },
      ],
      placeholder: '날짜 옵션',
    },
    {
      width: 0.375,
      type: 'daterange',
      key: 'dateRange',
      keys: ['startDate', 'endDate'],
    },
    {
      width: 0.375,
      type: 'input',
      key: 'searchKeyword',
      placeholder: '키워드를 입력하세요.',
    },
  ];
  return (
    <div>
      <SearchForm
        extra={{
          onInit: () => {
            initializeSearchOpt();
          },
        }}
        fields={searchField}
      />
      <Divider />
      <CommTable
        url="/cms/accounts/withdrawal"
        columns={[
          {
            title: '번호',
            dataIndex: 'tableIndex',
            key: 'tableIndex',
            fixed: 'left',
            width: 50,
            render: (value, obj, index) => getIndex(index),
          },
          {
            title: 'userkey',
            dataIndex: 'userKey',
            key: 'userKey',
            width: 200,
            render: (userKey) => userKey || '-',
          },
          {
            title: '가입일',
            dataIndex: 'joinAt',
            key: 'joinAt',
            width: 200,
            render: (joinAt) => joinAt || '-',
          },
          {
            title: '탈퇴사유',
            dataIndex: 'withdrawalReason',
            key: 'withdrawalReason',
            width: 200,
            render: (value) => value || '-',
          },
          {
            title: '탈퇴일',
            dataIndex: 'withdrawalAt',
            key: 'withdrawalAt',
            width: 200,
            render: (withdrawalAt) => withdrawalAt || '-',
          },
        ]}
        rowKey={({ id }) => {
          return id;
        }}
      />
    </div>
  );
}

export default WithdrawManagementIndex;
