import { restApi } from '#apis/index';
import FetchElement from '#components/FetchElement';
import { Button, Col, Descriptions, Row, Space, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

function SubBannersDetailPage() {
  const location = useLocation();
  const params = useParams();
  const placeCode = React.useMemo(() => location.pathname.split('/')[2], [location.pathname]);
  const id = React.useMemo(() => params?.id, [params?.id]);

  return (
    <FetchElement
      fetch={() => restApi.get(`/cms/banners/${id}`)}
      Component={({ active, endAt, imageResponse, link, startAt, title }) => (
        <Space style={{ width: '100%', maxWidth: 800 }} direction="vertical">
          <Descriptions colon={false} column={1} bordered>
            <Descriptions.Item label="배너 이미지" labelStyle={{ verticalAlign: 'middle' }}>
              <Space>
                <a href={imageResponse?.image} rel="noreferrer" target="_blank">
                  <img src={imageResponse?.image} alt={imageResponse?.text} style={{ width: 150 }} />
                </a>
                <Typography.Text>대체 텍스트: {imageResponse?.text}</Typography.Text>
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label="제목">{title}</Descriptions.Item>
            <Descriptions.Item label="링크">{link}</Descriptions.Item>
            <Descriptions.Item label="노출기간">{`${moment(startAt).format('YYYY-MM-DD HH:mm:ss')} ~ ${moment(
              endAt,
            ).format('YYYY-MM-DD HH:mm:ss')}`}</Descriptions.Item>
            <Descriptions.Item label="사용여부">{active ? '사용' : '미사용'}</Descriptions.Item>
          </Descriptions>
          <Row justify="end">
            <Col>
              <Space>
                <Link to={`/banners/${placeCode}`}>
                  <Button>목록</Button>
                </Link>
                <Link to={`/banners/${placeCode}/modify/${id}`}>
                  <Button type="primary">수정</Button>
                </Link>
              </Space>
            </Col>
          </Row>
        </Space>
      )}
    />
  );
}

export default SubBannersDetailPage;
