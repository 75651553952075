import moment from 'moment';
import React from 'react';
import { Button, Col, Divider, Input, Row, Select, Space } from 'antd';
import CommTable from '#components/table/CommTable';
import SearchForm from '#components/SearchForm';
import produce from 'immer';
import { useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';

function PostsFaqPage() {
  const { tree: codesTree, map: codesMap } = useSelector((selector) => selector.codes, shallowEqual);
  const initialParams = React.useMemo(
    () => ({
      categoryCode: null,
      searchType: null,
      keyword: null,
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);

  return (
    <Space style={{ width: '100%', maxWidth: 1200 }} direction="vertical">
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initialParams);
          return initialParams;
        }}
      >
        <Row gutter={15} align="middle">
          <Col>
            <Select
              placeholder="카테고리"
              style={{ width: 120 }}
              value={params.categoryCode}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.categoryCode = value;
                  }),
                );
              }}
              options={codesTree?.QA?.items.map((value) => ({ label: value.label, value: value.code })) ?? []}
              allowClear
            />
          </Col>
          <Col>
            <Select
              placeholder="검색 옵션"
              style={{ width: 120 }}
              value={params.searchType}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.searchType = value;
                  }),
                );
              }}
              options={[
                { label: '질문', value: 'QUESTION' },
                { label: '내용', value: 'ANSWER' },
              ]}
              allowClear
            />
          </Col>
          <Col>
            <Input
              placeholder="키워드를 입력하세요"
              style={{ width: 200 }}
              value={params.keyword}
              onChange={(event) => {
                setParams(
                  produce((draft) => {
                    draft.keyword = event.target.value;
                  }),
                );
              }}
            />
          </Col>
          <Col>
            <Space>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Col>
        </Row>
      </SearchForm>
      <Divider />
      <CommTable
        url="/cms/faqs"
        columns={({ pageCur, limit }) => [
          {
            title: '번호',
            width: 80,
            render: (_, $, index) => pageCur * limit + index + 1,
          },
          {
            title: '카테고리',
            dataIndex: 'categoryCode',
            width: 120,
            render: (value) => codesMap[value],
          },
          {
            title: '질문',
            dataIndex: 'question',
            align: 'left',
            render: (value, record) => <Link to={`/posts/faq/detail/${record.id}`}>{value}</Link>,
          },
          {
            title: '사용여부',
            dataIndex: 'active',
            width: 100,
            render: (value) => (value ? '사용' : '미사용'),
          },
          {
            title: '등록일',
            dataIndex: 'createdAt',
            width: 120,
            render: (value) => moment(value).format('YYYY-MM-DD'),
          },
        ]}
        headerExtra={
          <Link to="/posts/faq/regist">
            <Button type="primary">등록</Button>
          </Link>
        }
      />
    </Space>
  );
}

export default PostsFaqPage;
