import { Button, DatePicker, Divider, Form, Radio, Row, Space } from 'antd';
import moment from 'moment';
import React from 'react';
import { StatisticsPeriod } from '../../../common/constants';
import SearchForm from '../../../components/SearchForm';
import produce from 'immer';
import { restApi } from '../../../apis';
import Chart from 'react-apexcharts';
import { DAY } from '../../../common/define';

const DailyRefundRate = () => {
  const initialParams = React.useMemo(
    () => ({
      startAt: moment().subtract(7, 'day').format('YYYY-MM-DD'),
      endAt: moment().format('YYYY-MM-DD'),
      selectDateType: 'DATE',
    }),
    [],
  );

  const [params, setParams] = React.useState(initialParams);
  const [chartData, setChartData] = React.useState(null);
  const [data, setData] = React.useState(null);

  const getServerData = React.useCallback(async (params) => {
    if (!params.startAt || !params.endAt) {
      return alert('날짜범위를 지정해주세요.');
    }
    try {
      const { data } = await restApi.get('/statistics/day/refund', {
        params,
      });

      const parsedData = {
        labels: [],
        payments: [],
        refunds: [],
        refundRates: [],
      };

      if (Array.isArray(data)) {
        data.forEach((item) => {
          let label = `${moment(item.date).format('MM.DD')}(${DAY[item.dayOfWeek]})`;
          if (params.selectDateType === 'MONTH') {
            label = `${moment(item.date).format('YYYY.MM')}`;
          } else if (params.selectDateType === 'WEEK') {
            const endDate = moment(item.date).endOf('isoWeek').format('dddd').toUpperCase();
            label = [label, `~ ${moment(item.date).endOf('isoWeek').format('MM.DD')}(${DAY[endDate]})`];
          }
          parsedData.labels.push(label);
          parsedData.payments.push(item.amount);
          parsedData.refunds.push(item.refundAmount);
          parsedData.refundRates.push(item.amount < 1 ? 0 : ((item.refundAmount / item.amount) * 100).toFixed(2));
        });
      }

      setChartData(parsedData);
    } catch (error) {
      console.warn('error in Daily_Payment_Amount :', error);
      alert('에러가 발생했습니다. 잠시후 다시 시도해주세요.');
    }
  }, []);

  React.useEffect(() => {
    getServerData(initialParams);
  }, [getServerData, initialParams]);

  return (
    <Space style={{ width: '100%', maxWidth: 800 }} direction="vertical">
      <SearchForm
        params={params}
        onInit={(params) => {
          getServerData(params);
          setParams(params);
        }}
        onReset={() => {
          setParams(initialParams);
          getServerData(initialParams);
          return initialParams;
        }}
      >
        <Row style={{ marginBottom: '10px' }}>
          <Form.Item label="조회단위">
            <Radio.Group
              onChange={(event) => {
                let startAt = params.startAt;
                let endAt = params.endAt;
                switch (event.target.value) {
                  case 'WEEK':
                    startAt = moment(startAt).startOf('isoWeek').format('YYYY-MM-DD');
                    endAt = moment(endAt).startOf('isoWeek').format('YYYY-MM-DD');
                    break;
                  case 'MONTH':
                    startAt = moment(startAt).startOf('month').format('YYYY-MM-DD');
                    endAt = moment(endAt).startOf('month').format('YYYY-MM-DD');
                    break;
                }
                setParams(
                  produce((draft) => {
                    draft.selectDateType = event.target.value;
                    draft.startAt = startAt;
                    draft.endAt = endAt;
                  }),
                );
              }}
              value={params.selectDateType}
              options={StatisticsPeriod}
            />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item label="조회기간">
            <Space>
              <DatePicker.RangePicker
                value={[params.startAt ? moment(params.startAt) : null, params.endAt ? moment(params.endAt) : null]}
                picker={
                  params.selectDateType === 'MONTH' ? 'month' : params.selectDateType === 'WEEK' ? 'week' : 'date'
                }
                onChange={(e) => {
                  if (e) {
                    let [startAt, endAt] = e;
                    if (params.selectDateType === 'WEEK') {
                      startAt = startAt.startOf('isoWeek');
                      endAt = endAt.startOf('isoWeek');
                    } else if (params.selectDateType === 'MONTH') {
                      startAt = startAt.startOf('month');
                      endAt = endAt.startOf('month');
                    }
                    setParams(
                      produce((draft) => {
                        draft.startAt = startAt.format('YYYY-MM-DD');
                        draft.endAt = endAt.format('YYYY-MM-DD');
                      }),
                    );
                  } else {
                    setParams(
                      produce((draft) => {
                        draft.startAt = null;
                        draft.endAt = null;
                      }),
                    );
                  }
                }}
                // disabledDate={
                //   params.selectDateType === 'DAY' &&
                //   ((current) => {
                //     if (params.startAt || params.endAt) {
                //       const tooEarly = params.startAt && current.diff(params.startAt, 'days') > 10;
                //       const tooLate = params.endAt && moment(params.endAt).diff(current, 'days') > 10;
                //       return !!tooEarly || !!tooLate;
                //     }
                //     return false;
                //   })
                // }
                onCalendarChange={([startAt, endAt]) => {
                  setParams(
                    produce((draft) => {
                      draft.startAt = startAt?.format('YYYY-MM-DD');
                      draft.endAt = endAt?.format('YYYY-MM-DD');
                    }),
                  );
                }}
                onOpenChange={(open) => {
                  if (open) {
                    setParams(
                      produce((draft) => {
                        draft.startAt = null;
                        draft.endAt = null;
                      }),
                    );
                  }
                }}
              />
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Form.Item>
        </Row>
      </SearchForm>
      <Divider />
      <div>일별 환불율</div>
      <Chart
        series={[
          {
            name: '결제금액',
            type: 'column',
            data: chartData?.payments || [],
          },
          {
            name: '환불금액',
            type: 'column',
            data: chartData?.refunds || [],
          },
          {
            name: '환불비율(결제금액)',
            type: 'line',
            data: chartData?.refundRates || [],
          },
        ]}
        type="line"
        width={800}
        options={{
          // stroke: {
          //   width: [0, 4],
          // },
          // title: {
          //   text: 'Traffic Sources',
          // },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [2],
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
            },
          },
          stroke: {
            show: true,
            width: 1,
            // colors: ['transparent'],
          },
          xaxis: {
            categories: chartData?.labels || [],
            // type: 'datetime',
          },
          yaxis: [
            {},
            {
              show: false,
            },
            {
              opposite: true,
            },
          ],
        }}
      />
    </Space>
  );
};

export default DailyRefundRate;
