import React, { useState } from 'react';
import { Modal, Button, Divider, Image, PageHeader, Space, Timeline, Descriptions } from 'antd';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { CloseOutlined, CheckCircleOutlined } from '@ant-design/icons';
import styles from '../../../Page.module.less';
import DeliveryInfo from '../../../../components/DeliveryInfo';

const cx = classNames.bind(styles);

function SalesProductOrderDetail(props) {
  return (
    <>
      <PageHeader
        className="site-page-header"
        title="상품주문정보 조회"
        subTitle=""
        extra={<Button onClick={() => window.close()} icon={<CloseOutlined />} />}
      />

      <div className={cx({ windowLayout: true })}>
        <section>
          <div>
            <h1 style={{ fontSize: '20px' }}>
              주문 상세정보 <span>파트너사명</span>
            </h1>
          </div>
          <div>
            <h3>
              &middot;주문번호 : / <span> 상품 주문번호 :</span>
            </h3>
          </div>
          <ul className={cx({ boardDetail: true })}>
            <li>
              <b>상품명</b>
              <div>OOOOOOOOOOOOOOOO</div>
            </li>
            <li>
              <b>옵션</b>
              <div>OOOOOOOOOOO</div>
            </li>
            <li>
              <b>주문수량</b>
              <div>1</div>
              <b>상품주문상태</b>
              <div>구매확정</div>
            </li>
            <li>
              <b>총 상품금액</b>
              <div>1,000,000원</div>
            </li>
          </ul>
        </section>

        <section>
          <div>
            <h3>&middot;배송정보</h3>
          </div>

          <ul className={cx({ boardDetail: true })}>
            <li>
              <b>발송지연사유</b>
              <div />
              <b>당일발송여부</b>
              <div>N</div>
            </li>
            <li>
              <b>발송기한</b>
              <div>0000.00.00 00:00:00</div>
              <b>예약구매여부</b>
              <div>N</div>
            </li>
            <li>
              <b>발송처리일</b>
              <div>0000.00.00 00:00:00</div>
              <b>발주확인일</b>
              <div>0000.00.00 00:00:00</div>
            </li>
            <li>
              <b>배송방법</b>
              <div>택배, 등기, 소포</div>
              <b>배송완료일</b>
              <div>0000.00.00 00:00:00</div>
            </li>
            <li>
              <b>택배사</b>
              <div>CJ대한통운</div>
              <b>배송상세상태</b>
              <div>배송완료</div>
            </li>
            <li>
              <b>구매확정일</b>
              <div>
                <p>0000.00.00 00:00:00</p>
              </div>
              <b>송장번호</b>
              <div>
                <p>38485757575</p>
                <DeliveryInfo>배송조회</DeliveryInfo>
              </div>
            </li>
            <li>
              <b>오류송장 등록 사유</b>
              <div />
              <b>오류송장 등록일</b>
              <div />
            </li>
            <li>
              <b>발송기한</b>
              <div>0000.00.00 00:00:00</div>
              <b>예약구매여부</b>
              <div>N</div>
            </li>
            <li>
              <b>개인통관고유부호</b>
              <div>N</div>
            </li>
          </ul>
        </section>
        <section>
          <div>
            <h3>&middot;주문처리이력</h3>
          </div>

          <ul className={cx({ boardDetail: true })}>
            <li>
              <b>주문</b>
              <div />
              <div>처리일 0000.00.00</div>
            </li>
            <li>
              <b>결제완료</b>
              <div />
              <div>처리일 0000.00.00</div>
            </li>
            <li>
              <b>발송처리</b>
              <div>배송방법 : 택배, 등기, 소포</div>
              <div>처리일 0000.00.00</div>
            </li>
            <li>
              <b>발주확인</b>
              <div>발송기한 : 2020-04-02</div>
              <div>처리일 0000.00.00</div>
            </li>
            <li>
              <b>집화처리</b>
              <div />
              <div>처리일 0000.00.00</div>
            </li>
            <li>
              <b>시스템 배송완료처리</b>
              <div />
              <div>처리일 0000.00.00</div>
            </li>
            <li>
              <b>배송상태최종변경</b>
              <div />
              <div>처리일 0000.00.00</div>
            </li>
            <li>
              <b>구매확정</b>
              <div>구매확정 처리자:구매회원</div>
              <div>처리일 0000.00.00</div>
            </li>
          </ul>
        </section>
        <div className={cx({ buttonBox: true })}>
          <Button onClick={() => window.close()}>닫기</Button>
        </div>
      </div>
    </>
  );
}

export default SalesProductOrderDetail;
