import React from 'react';
import { Modal, Button, PageHeader, Space, Descriptions, Spin, Form, Select } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import produce from 'immer';
import styles from '../../../Page.module.less';
import { getFileHostAndFile, restApi, restApiNotUsingToken } from '../../../../apis';
import { useReduxStore } from '../../../../hooks/useReduxStore';
import { formatPhonNumer } from '../../../../utils/formatPhoneNumber';
import { MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
const cx = classNames.bind(styles);

function MdCompleteDetail(props) {
  const { map } = useReduxStore('codes');
  const { userId } = useParams() || {};
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [partner, setPartner] = React.useState(null);

  React.useEffect(() => {
    if (!userId) return;
    restApi
      .get(`/cms/accounts/md/confirmed/${userId}`)
      .then((response) => {
        setPartner(response.data);
      })
      .catch((e) => console.warn(e));
  }, [userId]);

  if (!partner) return <Spin />;

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="MD아이디">
            {partner.userId}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="MD이름">
            {partner.userName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="휴대전화">
            {formatPhonNumer(partner.phone)}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="이메일">
            {partner.email}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="연관 파트너사">
            <ul>
              {partner?.storeList.length > 0 ? (
                partner.storeList.map((store) => {
                  return (
                    <li key={store.id} style={{ marginBottom: '10px' }}>
                      <Link to={`/store/management/${store.id}/detail`}>
                        {store.storeName}({store.userId})
                      </Link>
                    </li>
                  );
                })
              ) : (
                <li>X</li>
              )}
            </ul>
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="정산" contentStyle={{ padding: 0 }}>
            <Descriptions bordered>
              <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="정산 승인 금액">
                {partner.amount}
              </Descriptions.Item>
              <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="정산 요청 금액">
                {partner.requestAmount}
              </Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="신분증">
            <a rel="noreferrer" target="_blank" href={getFileHostAndFile(partner.identificationFile)} download>
              <img src={getFileHostAndFile(partner.identificationFile)} alt="신분증.jpg" />
            </a>
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="계좌번호">
            {map[partner.bankName]} {partner.accountNumber} / {partner.accountHolder}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="승인 신청일">
            {partner.createdAt}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="마지막 로그인">
            {partner.lastLoginAt}
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button onClick={() => navigate(`/member/md/complete/detail/modify/${userId}`)}>파트너사 수정</Button>
        <Button onClick={() => navigate('/member/md/complete')}>목록</Button>
      </div>
    </div>
  );
}

export default MdCompleteDetail;
