import React from 'react';
import { Button, Space, Descriptions } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from '../../Page.module.less';
import { restApi } from '#apis';
import { useReduxStore } from '#hooks/useReduxStore';
import ShowOrderDetailBtn from '#components/Buttons/ShowOrderDetailBtn';

const cx = classNames.bind(styles);

// TODO :: 상점 상세 정보
function ReviewGoodsDetail() {
  const { map: codeMap } = useReduxStore('codes');
  const navigate = useNavigate();
  const { id } = useParams();

  const [reviewInfo, setReviewInfo] = React.useState(null);

  React.useEffect(() => {
    if (!id) return;
    restApi.get(`/cms/product-questions/${id}`).then(({ data }) => {
      setReviewInfo(data);
    });
  }, [id]);

  const handleShowDetailStore = React.useCallback(() => {
    if (!reviewInfo?.storeId) return;
    navigate(`/store/management/${reviewInfo.storeId}/detail`);
  }, [navigate, reviewInfo]);

  const handleShowDetailProduct = React.useCallback(() => {}, []);

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="상점">
            {reviewInfo?.storeName}
            {reviewInfo?.storeName && (
              <Button onClick={handleShowDetailStore} style={{ marginLeft: 20 }}>
                상세정보
              </Button>
            )}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="문의분야">
            {codeMap?.[reviewInfo?.typeCode]}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="상품명">
            {reviewInfo?.productName}
            {/* {reviewInfo?.productName && (
              <Button onClick={handleShowDetailProduct} style={{ marginLeft: 20 }}>
                상품정보
              </Button>
            )} */}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="주문번호">
            {reviewInfo?.orderProductNumber}
            {reviewInfo?.orderProductNumber && <ShowOrderDetailBtn orderId={reviewInfo.orderProductNumber} />}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="회원아이디">
            {reviewInfo?.userId}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="회원이름">
            {reviewInfo?.userName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="문의내용">
            {reviewInfo?.contents}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="공개여부">
            {typeof reviewInfo?.isSecret === 'boolean' && (reviewInfo?.isSecret ? '비공개' : '공개')}
            {reviewInfo?.isSecret && reviewInfo?.adminName && reviewInfo?.secretAt && (
              <span>
                ({reviewInfo?.adminName} / {reviewInfo?.secretAt})
              </span>
            )}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변내용">
            {reviewInfo?.answer}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변일">
            {reviewInfo?.answerAt}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변자">
            {reviewInfo?.adminId ? `${reviewInfo?.adminId} / ` : ''} {reviewInfo?.adminName}
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button onClick={() => navigate(-1)}>목록</Button>
      </div>
    </div>
  );
}

export default ReviewGoodsDetail;
