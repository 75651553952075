import React from 'react';
import { Button, Col, DatePicker, Divider, Input, Row, Select, Space } from 'antd';
import { Link } from 'react-router-dom';
import SearchForm from '#components/SearchForm';
import CommTable from '#components/table/CommTable';
import useIndex from '#hooks/useIndex';
import { useReduxStore } from '#hooks/useReduxStore';
import produce from 'immer';
import moment from 'moment';

function PartnerSalesIndex() {
  const { map } = useReduxStore('codes');
  const { getIndex } = useIndex();

  const initailParams = React.useMemo(
    () => ({
      dateOpt: null,
      startAt: null,
      endAt: null,
      filterOpt: null,
      keywordOpt: null,
      keyword: null,
    }),
    [],
  );

  const [params, setParams] = React.useState(initailParams);

  return (
    <div>
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initailParams);
          return initailParams;
        }}
      >
        <Row gutter={[50, 15]}>
          <Col span={24}>
            <Space style={{ width: '100%' }}>
              <Select
                value={params.dateOpt}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.dateOpt = value;
                    }),
                  );
                }}
                options={[
                  { label: '신청일', value: 'createdAt' },
                  { label: '최종로그인', value: 'lastLoginAt' },
                ]}
                placeholder="날짜옵션"
                style={{ width: 120 }}
              />
              <DatePicker.RangePicker
                disabled={!params.dateOpt}
                value={[params.startAt ? moment(params.startAt) : null, params.endAt ? moment(params.endAt) : null]}
                onChange={([startAt, endAt]) => {
                  setParams(
                    produce((draft) => {
                      draft.startAt = startAt.format('YYYY-MM-DD');
                      draft.endAt = endAt.format('YYYY-MM-DD');
                    }),
                  );
                }}
              />
            </Space>
          </Col>
          <Col span={24}>
            <Space style={{ width: '100%' }}>
              <Select
                placeholder="승인 상태"
                style={{ width: 120 }}
                value={params.filterOpt}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.filterOpt = value;
                    }),
                  );
                }}
                options={[
                  { label: '승인', value: 'PAS001' },
                  { label: '승인완료', value: 'PAS002' },
                  { label: '승인반려', value: 'PAS003' },
                ]}
              />
              <Select
                placeholder="검색 옵션"
                style={{ width: 120 }}
                value={params.keywordOpt}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.keywordOpt = value;
                    }),
                  );
                }}
                options={[
                  { label: '아이디', value: 'userId' },
                  { label: '파트너사 이름', value: 'username' },
                  { label: '휴대전화', value: 'phone' },
                ]}
              />
              <Input
                placeholder="키워드를 입력하세요"
                style={{ width: 300 }}
                value={params.keyword}
                onChange={(event) => {
                  setParams(
                    produce((draft) => {
                      draft.keyword = event.target.value;
                    }),
                  );
                }}
              />
            </Space>
          </Col>
          <Col span={24}>
            <Row justify="end">
              <Col>
                <Space>
                  <Button type="primary" htmlType="submit">
                    검색
                  </Button>
                  <Button htmlType="reset">초기화</Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </SearchForm>
      <Divider />
      <CommTable
        toggleObj={{ text: '승인반려 내용 포함해서 보기', key: 'includeReject' }}
        url="/cms/accounts/partners/permission"
        columns={({ limit, pageCur, total }) => [
          {
            title: '번호',
            dataIndex: 'tableIndex',
            key: 'tableIndex',
            fixed: 'left',
            width: 50,
            render: (value, obj, index) => getIndex(index),
          },
          {
            title: '파트너사아이디',
            dataIndex: 'userId',
            key: 'userId',
            // fixed: 'left',
            width: 150,
            render: (userId, obj) => <Link to={`/member/partner/sales/detail/${obj.id}`}>{userId}</Link>,
          },
          {
            title: '파트너사이름',
            dataIndex: 'userName',
            key: 'useuserNamerId',
            // fixed: 'left',
            width: 150,
          },
          {
            title: '휴대전화',
            dataIndex: 'phone',
            key: 'phone',
            width: 100,
          },
          {
            title: '신청일',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 100,
          },
          {
            title: '승인상태',
            dataIndex: 'saleStatus',
            key: 'saleStatus',
            width: 100,
            render: (v) => map[v],
          },
          {
            title: '최종로그인',
            dataIndex: 'lastLoginAt',
            key: 'lastLoginAt',
            width: 300,
          },
        ]}
      />
    </div>
  );
}

export default PartnerSalesIndex;
