import React from 'react';
import { Divider, Row, Col, Space, Select, DatePicker, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import SearchForm from '#components/SearchForm';
import CommTable from '#components/table/CommTable';
import moment from 'moment';
import produce from 'immer';
import useIndex from '#hooks/useIndex';

function ReviewIndex() {
  const { getIndex } = useIndex();

  const initialParams = React.useMemo(
    () => ({
      startAt: null,
      endAt: null,
      isSecret: null,
      searchType: null,
      keyword: null,
    }),
    [],
  );

  const [params, setParams] = React.useState(initialParams);

  return (
    <div>
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initialParams);
          return initialParams;
        }}
      >
        <Row gutter={[50, 15]}>
          <Col span={24}>
            <Space style={{ width: '100%' }}>
              <span>등록일</span>
              <DatePicker.RangePicker
                value={[params.startAt ? moment(params.startAt) : null, params.endAt ? moment(params.endAt) : null]}
                onChange={([startAt, endAt]) => {
                  setParams(
                    produce((draft) => {
                      draft.startAt = startAt.format('YYYY-MM-DD');
                      draft.endAt = endAt.format('YYYY-MM-DD');
                    }),
                  );
                }}
              />
              <Select
                placeholder="공개여부"
                style={{ width: 120 }}
                value={params.isSecret}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.isSecret = value;
                    }),
                  );
                }}
                options={[
                  { label: '공개', value: 'false' },
                  { label: '비공개', value: 'true' },
                ]}
              />
              <Select
                placeholder="검색 옵션"
                style={{ width: 120 }}
                value={params.searchType}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.searchType = value;
                    }),
                  );
                }}
                options={[
                  { label: '내용', value: 'Contents' },
                  { label: '작성자', value: 'Writer' },
                  { label: '회원 아이디', value: 'UserId' },
                ]}
              />
              <Input
                placeholder=" 키워드를 입력하세요"
                style={{ width: 300 }}
                value={params.keyword}
                onChange={(event) => {
                  setParams(
                    produce((draft) => {
                      draft.keyword = event.target.value;
                    }),
                  );
                }}
              />
            </Space>
          </Col>
          <Col span={24}>
            <Row justify="end">
              <Col>
                <Space>
                  <Button type="primary" htmlType="submit">
                    검색
                  </Button>
                  <Button htmlType="reset">초기화</Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </SearchForm>
      <Divider />
      <CommTable
        url="/cms/community-boards"
        columns={[
          {
            title: '번호',
            dataIndex: 'orderNum',
            key: 'orderNum',
            width: 100,
            render: (value, obj, index) => getIndex(index),
          },
          {
            title: '제목',
            dataIndex: 'title',
            width: 500,
            key: 'title',
            render: (title, obj) => <Link to={`/review/community/detail/${obj.id}`}>{title}</Link>,
          },
          {
            title: '작성자',
            dataIndex: 'writer',
            key: 'writer',
            width: 200,
            render: (writer) => writer || '-',
          },
          {
            title: '회원아이디',
            dataIndex: 'userId',
            key: 'userId',
            width: 200,
            render: (userId) => userId || '-',
          },
          {
            title: '댓글',
            dataIndex: 'replyCount',
            key: 'replyCount',
            width: 200,
          },
          {
            title: '공개여부',
            dataIndex: 'isSecret',
            key: 'isSecret',
            width: 200,
            render: (value) => (value ? '비공개' : '공개'),
          },
          {
            title: '등록일',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 200,
          },
        ]}
        scroll={{ x: 1120 }}
      />
    </div>
  );
}

export default ReviewIndex;
