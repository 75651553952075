import React from 'react';
import { Spin } from 'antd';
import useFetch from '../hooks/useFetch';

const FetchElement = React.forwardRef(({ notUseLoading = false, fetch = () => {}, Component }, ref) => {
  const { data, setData, fetch: fetchHookHandler, loading } = useFetch();
  const refresh = () => {
    fetchHookHandler(fetch());
  };

  React.useEffect(() => {
    fetchHookHandler(fetch());
  }, []);

  React.useImperativeHandle(ref, () => ({
    data,
    setData,
    refresh,
  }));

  if (!notUseLoading && loading) {
    return <Spin />;
  }

  return <Component data={data} setData={setData} refresh={refresh} {...data} />;
});

export default FetchElement;
