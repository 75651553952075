import * as XLSX from 'xlsx';
import { mapKeys } from 'lodash';

const DefaultOptions = ['옵션가', '재고수량', '사용여부'];

export function downloadExcel({ title, dataJsonArray, ObjToChangeKey, options, location, isExclusive, codeMap }) {
  // key 변경해주는 help function
  const objMapper = (obj) => {
    let newObj = mapKeys(obj, (value, key) => {
      if (ObjToChangeKey[key]) return ObjToChangeKey[key];
      return key;
    });

    if (isExclusive) return newObj;

    const hasSequenceObj = {};
    for (let y = 0; y < 3; y++) {
      if (options[y]) {
        hasSequenceObj[options[y].name] = newObj[options[y].name];
      } else {
        hasSequenceObj[`선택${y + 1}`] = null;
      }
    }

    DefaultOptions.forEach((option) => {
      hasSequenceObj[option] = newObj[option];
    });

    return hasSequenceObj;
  };

  let arrJSON = dataJsonArray.map((data) => {
    return objMapper(data);
  });

  // 엑셀 시트 순서와 값 조정
  let arrJSONChangeValue = [];
  let arrJSONChangeOrder = [];
  let excelHeaderEl = [];
  let accountInfo = [];

  if (location.includes('calculate')) {
    for (let i = 0; i < arrJSON.length; i++) {
      for (let changeArr in arrJSON[i]) {
        if (changeArr == '신청금액') {
          excelHeaderEl[0] = [changeArr, arrJSON[i][changeArr]];
        } else if (changeArr == '신청자') {
          excelHeaderEl[1] = [changeArr, arrJSON[i][changeArr]];
        } else if (changeArr == '계좌정보') {
          excelHeaderEl[2] = [
            changeArr,
            codeMap[arrJSON[i][changeArr].split(' ')[0]] + ' ' + arrJSON[i][changeArr].split(' ')[1],
          ];
        } else if (changeArr == '신청일') {
          excelHeaderEl[3] = [changeArr, arrJSON[i][changeArr]];
        } else if (changeArr == '처리여부') {
          excelHeaderEl[4] = [changeArr, codeMap[arrJSON[i][changeArr]]];
        } else if (changeArr == '처리완료일') {
          excelHeaderEl[5] = [changeArr, arrJSON[i][changeArr]];
        } else if (changeArr == '처리자') {
          excelHeaderEl[6] = [changeArr, arrJSON[i][changeArr]];
        }
        // if (changeArr == '처리자') {
        //   excelHeaderEl[6] = [changeArr, arrJSON[i][changeArr]];
        // } else if (changeArr == '처리완료일') {
        //   excelHeaderEl[5] = [changeArr, arrJSON[i][changeArr]];
        // } else if (changeArr == '신청금액') {
        //   excelHeaderEl[0] = [changeArr, arrJSON[i][changeArr]];
        // } else if (changeArr == '계좌정보') {
        //   excelHeaderEl[2] = [
        //     changeArr,
        //     codeMap[arrJSON[i][changeArr].split(' ')[0]] + ' ' + arrJSON[i][changeArr].split(' ')[1],
        //   ];
        // } else if (changeArr == '신청일') {
        //   excelHeaderEl[3] = [changeArr, arrJSON[i][changeArr]];
        // } else if (changeArr == '처리여부') {
        //   excelHeaderEl[4] = [changeArr, codeMap[arrJSON[i][changeArr]]];
        // } else if (changeArr == '신청자') {
        //   excelHeaderEl[1] = [changeArr, arrJSON[i][changeArr]];
        // }
      }
      arrJSONChangeValue.push(excelHeaderEl);
      excelHeaderEl = [];
    }
    for (let i = 0; i < arrJSONChangeValue.length; i++) {
      let arr = {};
      for (let j = 0; j < arrJSONChangeValue[i].length; j++) {
        arr[arrJSONChangeValue[i][j][0]] = arrJSONChangeValue[i][j][1];
      }
      arrJSONChangeOrder.push(arr);
      arr = {};
    }
  }
  console.log(arrJSONChangeValue);

  // book이라는 게 아예 excel 파일하나라고 생각하면 편함
  let wb = XLSX.utils.book_new();
  let arrJsonToSheet = JSON.parse(JSON.stringify(arrJSONChangeOrder));
  let ws = XLSX.utils.json_to_sheet(arrJsonToSheet);
  wb.Props = {
    Title: title,
    Subject: 'Excel',
    Author: 'Master',
    CreatedDate: new Date(),
  };

  wb.SheetNames.push(title);

  // sheet를 추가 하고 싶을 때 아래 메서드 사용하세요
  // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // 타이틀에 해당하는 sheet에 work sheet 붙여주기.
  wb.Sheets[title] = ws;

  // 해당 부분으로 excel download
  XLSX.writeFile(wb, `${title}.xlsx`);
}
