import qs from 'query-string';
import React from 'react';
import { Button, Descriptions, List, Modal, Space, Typography } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from '../../Page.module.less';
import parseImageUrl from '#utils/parseImageUrl';
import handleError from '#utils/handleError';
import formatComma from '#utils/formatComma';
import { restApi } from '#apis/index';
import StoretDetails from '../../../components/App/StoreDetails';

const cx = classNames.bind(styles);

function StoreManagementDetail() {
  const { storeId } = useParams();
  const navigate = useNavigate();
  const [store, setStore] = React.useState(null);

  const dateObj = React.useMemo(
    () => ({
      MON: '월요일',
      TUE: '화요일',
      WED: '수요일',
      THU: '목요일',
      FRI: '금요일',
      SAT: '토요일',
      SUN: '일요일',
    }),
    [],
  );

  React.useEffect(() => {
    restApi
      .get(`/cms/stores/${storeId}`)
      .then((response) => setStore(response.data))
      .catch(handleError);
  }, [storeId]);

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 175 }} label="상점명">
            {store?.storeName || '-'}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 175, verticalAlign: 'middle' }} label="상점로고">
            {typeof store?.logo === 'number' && (
              <img src={parseImageUrl(store?.logo)} style={{ width: 160, height: 160 }} alt="상점로고" />
            )}
            {store?.youtubeUrl && (
                <p>{store?.youtubeUrl}</p>
            )}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 175 }} label="상점주소">
            {store?.storeAddrMap} {store?.storeAddrDetail}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 175 }} label="전화번호">
            {store?.storePhone}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 175 }} label="운영시간/휴무일">
            <List
              dataSource={
                store
                  ? [
                      ...store?.storeOpenList?.map(
                        ({ closeAt, day, isOpen, openAt }) =>
                          `${dateObj[day]} ${isOpen ? `${openAt} ~ ${closeAt}` : '휴무'}`,
                      ),
                      ...(store?.holiday ? [`휴무일 ${store?.holiday}`] : []),
                    ]
                  : []
              }
              rowKey={(_, index) => index}
              renderItem={(item) => `- ${item}`}
            />
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 175 }} label="상점소개">
            {store?.introduction}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 175, verticalAlign: 'middle' }} label="상점사진">
            <Space>
              {store?.imagesWithYoutube?.map((value, index) => {

                return parseInt(store?.images[index])>-1?<img key={`Store-Image-${index}`} src={value} alt="상점사진" />:<p>{value}</p>;
              })}
            </Space>
          </Descriptions.Item>

          <Descriptions.Item span={3} labelStyle={{ width: 175 }} label="등록상품수">
            <Space>
              <Typography.Text>{formatComma(store?.productCount)}개</Typography.Text>
              <Link
                to={`/store/goods?${qs.stringify({
                  storeId: store?.id,
                })}`}
              >
                <Button>상품보기</Button>
              </Link>
            </Space>
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button onClick={() => navigate('/store/management')}>목록</Button>
        <Button
          onClick={() => {
            Modal.info({
              title: '상세화면',
              width: 550,
              content: <StoretDetails storeId={storeId} />,
              centered: true,
              closable: true,
            });
          }}
        >
          상세화면
        </Button>
        <Button onClick={() => navigate(`/store/management/${storeId}/modify`)} type="primary">
          수정
        </Button>
      </div>
    </div>
  );
}

export default StoreManagementDetail;
