import React from 'react';
import { Modal, Upload, Button, Spin, Space } from 'antd';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import qs from 'qs';
import useAxios from '../../hooks/useAxios';
import { restApi, getFileHost } from '../../apis/index';
import getBase64 from '../../utils/getBase64';

function FileUpload({
  disabled,
  hideUpload,
  items = [],
  maxCount,
  onDoneChange,
  buttonText = '파일 선택',
  listType,
  onInit,
  extraItemRender,
  ...props
}) {
  const [previewState, setPreviewState] = React.useState({
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
  });

  const handleCancel = () => setPreviewState((prevState) => ({ ...prevState, previewVisible: false }));

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  const _items = React.useMemo(() => {
    return typeof items === 'string'
      ? Array.isArray(JSON.parse(items))
        ? JSON.parse(items)
        : [JSON.parse(items)]
      : Array.isArray(items)
      ? [...items]
      : [items];
  }, [items]);

  const filesInfos = useAxios();

  React.useEffect(() => {
    if (!Array.isArray(_items) || _items?.length < 1) return;

    filesInfos
      .loadData(
        restApi
          .get('/files/infos', {
            params: { ids: _items },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
          })
          .then(({ data, ..._notUsed }) => ({
            data: _items.map((value) => data.find(({ id }) => value === id)),
          })),
      )
      .catch((error) => {
        alert(error?.response?.data?.message ?? '서버에 연결할 수 없습니다');
      });
  }, [_items]);

  const defaultFileList = React.useMemo(() => {
    if (!_items?.length) return [];
    return (
      filesInfos.data?.map((v) => {
        return {
          uid: `${v?.id}`,
          name: `${v?.filename}`,
          status: 'done',
          type: `${v?.contentType}`,
          response: [{ id: v?.id }],
          url: `${getFileHost()}/${v?.id}`,
        };
      }) || []
    );
  }, [filesInfos.data, _items?.length]);
  const [fileList, setFileList] = React.useState([]);
  console.log(fileList);
  React.useEffect(() => {
    setFileList([...defaultFileList]);
  }, [defaultFileList]);

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
    if (fileList?.findIndex((v) => v.status !== 'done') > -1) return;
    const fileListDone = fileList.filter((v) => v?.status === 'done');

    onDoneChange(fileListDone);
  };

  const _hideUpload = () => {
    if (typeof hideUpload === 'function') {
      return hideUpload(fileList);
    }
    return hideUpload;
  };

  return filesInfos.loading ? (
    <Spin />
  ) : (
    <>
      <Upload
        disabled={disabled}
        action={getFileHost()}
        maxCount={maxCount}
        name="files"
        method="post"
        onPreview={handlePreview}
        defaultFileList={defaultFileList}
        fileList={fileList}
        onChange={handleChange}
        itemRender={(originNode, file, fileList, actions) => (
          <Space style={{ width: '100%', maxHeight: '100px', overflow: 'hidden' }} align="center">
            {!disabled && fileList[0]?.uid !== 'undefined' ? (
              <div>
                {originNode}
                {extraItemRender?.(file, fileList, actions)}
              </div>
            ) : (
              !disabled && (
                <>
                  <div style={{ marginTop: 10 }}>
                    <span style={{ color: 'red' }}>파일없음</span>
                  </div>
                </>
              )
            )}
          </Space>
        )}
        listType={listType}
        {...props}
      >
        {!_hideUpload() &&
          (listType === 'picture-card' ? (
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          ) : (
            <Button disabled={disabled} icon={<UploadOutlined />}>
              {buttonText}
            </Button>
          ))}

      </Upload>

      <Modal centered visible={previewState.previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="priview" style={{ width: '100%' }} src={previewState.previewImage} />
      </Modal>
    </>
  );
}

export default FileUpload;
